import logo from "./logo.svg";
import "./App.css";
import "./custom.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import LeaguePage from "./pages/league";
import MatchPage from "./pages/MatchDetail/match";
import CasinoPage from "./pages/casino";
import CasinoDetail from "./pages/casino-detail";
import UpdatePassword from "./pages/update-password";
import Home from "./pages/home";
import Register from "./pages/Register";
import Deposit from "./pages/Deposit";
import WithDraw from "./pages/WithDraw";
import DepositHistory from "./pages/DepositHistory";
import WithdrawHistory from "./pages/WithdrawHistory";
import AccountStatement from "./pages/AccountStatement";
import Index from "./CustomerSupport.js/Index";
import BetsHistory from "./pages/BetDetails/BetsHistory";
import CurrentBets from "./pages/BetDetails/CurrentBets/CurrentBets";
import ProfitLoss from "./pages/BetDetails/ProfitLoss";
import Turnover from "./pages/Turnover";
import ProtectedRoute from "./component/ProctectedRoute";
import ForgotPassword from "./pages/ForgotPassword";
function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} exact />{" "}
      <Route path="/register" element={<Register />} exact />
      <Route path="/" element={<Home />} />
      <Route path="/:sportType" element={<Home />} />
      <Route path="/LeaguePage" element={<LeaguePage />} />
      <Route path="/match/:eventId/:marketId" element={<MatchPage />} />
      <Route path="/casino" element={<CasinoPage />} />  
      <Route path="/casino/:type" element={<CasinoPage />} />
      <Route
        path="/deposit"
        element={
          <ProtectedRoute>
            <Deposit />
          </ProtectedRoute>
        }
      />{" "}
      <Route
        path="/withdraw"
        element={
          <ProtectedRoute>
            <WithDraw />
          </ProtectedRoute>
        }
      />
      <Route path="/CasinoDetail" element={<CasinoDetail />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/UpdatePassword" element={<UpdatePassword />} />
      <Route path="/deposit-history" element={<DepositHistory />} />
      <Route
        path="/withdraw-history"
        element={
          <ProtectedRoute>
            <WithdrawHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account-statement"
        element={
          <ProtectedRoute>
            <AccountStatement />
          </ProtectedRoute>
        }
      />
      <Route path="/customer-support" element={<Index />} />
      <Route
        path="/bets-history"
        element={
          <ProtectedRoute>
            <BetsHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/current-bets"
        element={
          <ProtectedRoute>
            <CurrentBets />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profit-and-loss"
        element={
          <ProtectedRoute>
            <ProfitLoss />
          </ProtectedRoute>
        }
      />
      <Route
        path="/turnover"
        element={
          <ProtectedRoute>
            <Turnover />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
