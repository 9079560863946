import React from "react";
import Header from "../component/header";
import { Row, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../component/Sidebar";

const ProfileLayout = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      <div className="w-100">
        <Header />
        <div className="d-flex">
          <div className="left-menu mt-3">
         <Sidebar/>
          </div>

          <div className="right-con pb-0" style={{ padding: "5px 15px" }}>
            <Row>{children}</Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
