import React, { useContext, useEffect, useState } from "react";
import SportsTab from "./CurrentBets/SportsTab";
import { apiGet } from "../../Utils/apiFetch";
import { FaAngleDown } from "react-icons/fa";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import apiPath from "../../Utils/apiPath";
import objConstant from "../../Utils/constants";
import { isEmpty, startCase } from "lodash";
import obj from "../../Utils/helpers";
import { BiSolidRightArrow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import BetSlipContext from "../../context/BetSlipContext";
import { AiTwotoneCalendar } from "react-icons/ai";
import moment from "moment";
import AuthContext from "../../context/AuthContext";
import SidebarLayout from "../../component/SidebarLayout";
import NoEvent from "../../component/NoEvent";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import ProfileLayout from "../ProfileLayout";
const BetsHistory = () => {
  const [tab, setTab] = useState("betfair");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [showBet, setShowBet] = useState("");
  const [filter, setFilter] = useState({
    betType: "betfair",
    status: "completed",
    fromPeriod: new Date(),
    toPeriod: new Date(),
    fromPeriod: previousDate,
    toPeriod: currentDate,
    page: 1,
    limit: 50,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = async (obj = filter) => {
    const { status, data } = await apiGet(apiPath.getHistory, {
      betType: obj?.betType,
      status: obj?.status,
      fromPeriod: obj?.fromPeriod,
      toPeriod: obj?.toPeriod,
      page: obj?.page || 1,
      limit: obj?.limit,
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <ProfileLayout>
      <Col md={12}>
        <div className="balance-label position-relative">
          Bets History
          <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
            <RxCross2
              className="fs-3"
              style={{ fontWeight: "800", color: "black" }}
              onClick={() => navigate("/")}
            />
          </button>
        </div>
        <div className="filter-new">
          <div className="w-100 d-flex mb-1">
            <div className="filter-new-inner">
              <Form.Label>Bet Type:</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, betType: e.target.value })
                }
                value={filter.betType}
              >
                <option value="betfair">Exchange</option>
                <option value="bookmaker">Bookmaker</option>
                <option value="fancy">Fancybet</option>{" "}
                <option value="casino">Casino</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Label>Bet Status:</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
                value={filter.status}
              >
                <option value="completed">Settled</option>
                <option value="suspend">Cancelled</option>
                <option value="voided">Voided</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
                type="date"
              />
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                disabled={filter.fromPeriod ? false : true}
                max={new Date().toISOString().split("T")[0]}
                value={filter.toPeriod}
                type="date"
              />
            </div>
            <div className="filter-new-inner">
              {" "}
              <button
                onClick={() => {
                  getData();
                  setTab(filter?.betType);
                }}
                className="get-history"
              >
                Get History
              </button>
            </div>
          </div>
          <div className="w-100 d-flex fitler-new-button">
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: currentDate,
                  toPeriod: currentDate,
                });
              }}
            >
              Today
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment().subtract(1, "days").format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              From Yesterday
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment().subtract(7, "days").format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              Last 7 days
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              Last 30 days
            </button>
          </div>
        </div>
        <div className="pt-0 inner-sidebar-content">
          <Table>
            <thead>
              <tr>
                <th scope="col">PL ID</th>
                <th scope="col"> Bet ID</th>
                <th scope="col">Bet placed</th>
                <th scope="col">IP Address </th>
                <th scope="col">Market</th>
                <th scope="col">Selection</th>
                <th scope="col">Type</th>
                <th scope="col">Odds req.</th>
                <th scope="col">Stake </th>
                <th scope="col">Profit/Loss </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.clientName || "-"}</td>
                      <td>
                        {" "}
                        {item?.sessionBetId || item?.matchBetId || "N/A"}
                      </td>
                      <td> {obj.dateFormat(item?.timeInserted)}</td>
                      <td>
                        {" "}
                        {(item?.ipAddress &&
                          item?.ipAddress.replace("::ffff:", "")) ||
                          "N/A"}
                      </td>
                      <td className="text-start">
                        {`${
                          item?.eventType === "4"
                            ? "Cricket"
                            : item?.eventType === "1"
                            ? "Soccer"
                            : item?.eventType === "2"
                            ? "Tennis"
                            : "Casino"
                        }`}
                        <span className="angle_unicode">▸</span>
                        <strong>{item?.casinoName || item?.matchName}</strong>
                        <span className="angle_unicode">▸</span>
                        {tab === "fancy" && item?.fancyName}{" "}
                        {tab === "casino" && item?.casinoType}{" "}
                        {tab === "bookmaker" && "Bookmaker"}
                        {tab === "betfair" && "Match Odds"}
                        {/* {!isEmpty(item?.fancyName)
                          ? item?.fancyName
                          : startCase(item?.betFaireType)} */}
                      </td>
                      <td>
                        {!isEmpty(item?.fancyName)
                          ? item?.betRun + "/" + item?.bhav
                          : item?.runnerName || item?.teamName}
                      </td>
                      <td> {item?.type || item?.betType || "N/A"} </td>
                      <td> {item?.bhav || "N/A"} </td>
                      <td> {item?.amount || "N/A"} </td>
                      {item?.status !== "voided" ? (
                        tab == "betfair" ||
                        tab == "bookmaker" ? (
                          <td>
                            {(item?.teamSelectionWin == item?.selectionId &&
                              item?.betType == "back") ||
                            (item?.teamSelectionWin != item?.selectionId &&
                              item?.betType == "lay") ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                - ({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        ) : (
                          <td>
                            {item?.type == "No" ? (
                              item?.decisionRun < item?.betRun ? (
                                <span className="text-success">
                                  {item?.profitAmount}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  - ({item?.loseAmount})
                                </span>
                              )
                            ) : item?.decisionRun >= item?.betRun ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                - ({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        )
                      ) : (
                        <td>0.00</td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span style={{ fontSize: "13px" }}>
                      You have no bets in this time period.
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </ProfileLayout>
  );
};

export default BetsHistory;
