import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="myprofile">
      <div
        style={{
          background: "rgb(48, 48, 48)",
          color: "white",
          padding: "8px",
          cursor: "pointer",
          fontWeight: "600",
        }}
      >
        My Profile
      </div>
      <div className="myprofile-list">
        {/* <div style={{ cursor: "pointer" }}>My Profile</div>{" "} */}
        <div
          onClick={() => navigate("/current-bets")}
          style={{ cursor: "pointer" }}
          className={location?.pathname == "/current-bets" && "active"}
        >
          Current Bets
        </div>
        <div
          onClick={() => navigate("/profit-and-loss")}
          style={{ cursor: "pointer" }}
          className={location?.pathname == "/profit-and-loss" && "active"}
        >
          Profit / Loss
        </div>
        <div
          onClick={() => navigate("/bets-history")}
          style={{ cursor: "pointer" }}
          className={location?.pathname == "/bets-history" && "active"}
        >
          Bet History
        </div>
        <div
          onClick={() => navigate("/account-statement")}
          className={location?.pathname == "/account-statement" && "active"}
          style={{ cursor: "pointer" }}
        >
          Account Statement
        </div>
        <div
          onClick={() => navigate("/deposit")}
          className={location?.pathname == "/deposit" && "active"}
          style={{ cursor: "pointer" }}
        >
          Deposit
        </div>
        <div
          onClick={() => navigate("/deposit-history")}
          className={location?.pathname == "/deposit-history" && "active"}
          style={{ cursor: "pointer" }}
        >
          Deposit History
        </div>{" "}
        <div
          onClick={() => navigate("/withdraw")}
          className={location?.pathname == "/withdraw" && "active"}
          style={{ cursor: "pointer" }}
        >
          WithDraw
        </div>
        <div
          onClick={() => navigate("/withdraw-history")}
          className={location?.pathname == "/withdraw-history" && "active"}
          style={{ cursor: "pointer" }}
        >
          WithDraw History
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
