import React, { useContext, useEffect, useState } from "react";
import trophy from "../img/trophy.png";
import { Button, Form } from "react-bootstrap";
import BetSlipContext from "../context/BetSlipContext";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { inRange, isEmpty, startCase } from "lodash";
const PlaceBet = () => {
  const {
    setShowBetDialog,
    betSlipObject,
    setBetSlipObject,
    setResetBetPosition,
    handelPlaceBetFair,
    handelPlaceFancy,
    handelPlaceSportBook,
    handelPlaceBookmaker,
    limit,
    betLoader,
    handelPlaceTie,
    handelPlaceLineMarket,
    handelPlaceToss,
  } = useContext(BetSlipContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [bidPriceDisabled, setBidPricDisabled] = useState(true);
  const placeBet = () => {
    if (!isEmpty(user)) {
      if (
        betSlipObject?.playType == "betFair" ||
        betSlipObject?.playType == "multi"
      ) {
        handelPlaceBetFair();
      } else if (betSlipObject?.playType == "fancy") {
        handelPlaceFancy();
      } else if (betSlipObject?.playType == "sportBook") {
        handelPlaceSportBook();
      } else if (betSlipObject?.playType == "bookmaker") {
        handelPlaceBookmaker();
      }
    } else {
      setShowBetDialog(false);
      navigate("/login");
    }
  };

  const clear = () => {
    let str = betSlipObject?.bidPrice?.toString();
    setBetSlipObject({
      ...betSlipObject,
      bidPrice: str.substring(0, str.length - 1),
    });
  };

  useEffect(() => {
    if (
      inRange(
        Number(betSlipObject?.bidPrice),
        limit?.minAmount || 1,
        limit?.maxAmount + 1 || 100
      )
    ) {
      setBidPricDisabled(false);
    } else {
      setBidPricDisabled(true);
    }
  }, [betSlipObject, limit]);

  const format = (number) => {
    var nf = new Intl.NumberFormat();
    return nf.format(number);
  };
  return (
    <div className="p-3">
      <p className="mb-2 f-500 ">
        <img src={trophy} style={{ maxWidth: "20px" }} />{" "}
        {betSlipObject?.teamName || betSlipObject?.object?.runnerName}
      </p>
      {/* <p className="mb-0">{startCase(betSlipObject?.betType)}</p> */}
      <Button
        style={{
          background:
            betSlipObject?.betType == "No" || betSlipObject?.betType == "lay"
              ? "#faa9ba"
              : "#89c6f1",
          padding: "2px 10px",
          color: "black",
          border: "none",
          marginRight: "5px",
          fontSize: "12px",
        }}
      >
        {" "}
        {startCase(betSlipObject?.betType)}
      </Button>{" "}
      <hr className="lgrey" />
      <div className="d-flex">
        <div className="me-2 input-fix">
          <Form.Label htmlFor="inputPassword5">Odd</Form.Label>
          <Form.Control
            disabled={true}
            value={
              betSlipObject?.playType == "fancy"
                ? `${betSlipObject?.odds}/${betSlipObject?.object?.pt}`
                : betSlipObject?.odds
            }
            className="me-2"
            aria-label="First name"
          />
        </div>
        <div className="input-fix">
          <Form.Label htmlFor="inputPassword5">Stake</Form.Label>
          <Form.Control
            value={betSlipObject?.bidPrice}
            // onKeyDown={(e) => e.preventDefault()}
            // onClick={(e) => {
            //   e.target.blur();
            // }}
            onChange={(e) =>
              setBetSlipObject({
                ...betSlipObject,
                bidPrice: e?.target?.value,
              })
            }
            type="number"
            className="me-2"
            aria-label="First name"
          />
        </div>
      </div>
      <p className="infoTxt">or Choose Your Stake Size</p>
      <hr className="lgrey" />
      {number?.map((res) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setBetSlipObject({
                ...betSlipObject,
                bidPrice: isEmpty(betSlipObject?.bidPrice)
                  ? Number(res)
                  : Number(betSlipObject?.bidPrice) + Number(res),
              });
            }}
            className="stake-value me-1"
          >
            +{res}
          </span>
        );
      })}
      <hr className="lgrey" />
      {/* <Form.Check
        className="switch"
        type="switch"
        id="custom-switch"
        label="Confirm bets before placing"
      /> */}
      <Button
        onClick={() => {
          setShowBetDialog(false);
          setResetBetPosition(true);
        }}
        variant="primary"
        className="btn-red me-1"
        size="sm"
      >
        Cancel
      </Button>
      <Button
        disabled={bidPriceDisabled}
        onClick={() => {
          placeBet();
        }}
        variant="secondary"
        className="btn-green"
        size="sm"
      >
        Place bid
      </Button>
      <p className="infoTxt">
        {" "}
        Min :{" "}
        <span style={{ fontWeight: "600", marginRight: "5px" }}>
          {format(limit?.minAmount)}
        </span>{" "}
        Max :{" "}
        <span style={{ fontWeight: "600", marginRight: "5px" }}>
          {format(limit?.maxAmount)}
        </span>{" "}
        Max winning :{" "}
        <span style={{ fontWeight: "600" }}>{format(limit?.maxProfit)}</span>
      </p>
    </div>
  );
};

export default PlaceBet;

const number = [100, 500, 1000, 5000, 10000, 25000, 100000];
const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "."];
