import React, { useContext, useEffect, useState } from "react";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { Table, Form, Col,Button } from "react-bootstrap";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";

import { RxCross2 } from "react-icons/rx";
import obj from "../../Utils/helpers";
import AuthContext from "../../context/AuthContext";

import moment from "moment";
import BetSlipContext from "../../context/BetSlipContext";

import { useNavigate } from "react-router-dom";
import ProfileLayout from "../ProfileLayout";
const ProfitLoss = () => {
  const { profileData, showDate, setShowDate, plDate } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { setMessage, message } = useContext(BetSlipContext);
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [active, setActive] = useState([]);
  const [tab, setTab] = useState("exchange");
  const [showBet, setShowBet] = useState("");
  const [data, setData] = useState([]);
  const [dropdown, setDropDown] = useState("All");
  const [overAllTotal, setOverAllTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [filter, setFilter] = useState({
    betType: "betfair",
    status: "completed",
    fromPeriod: new Date(),
    toPeriod: new Date(),
    fromPeriod: previousDate,
    toPeriod: currentDate,
    page: 1,
    limit: 50,
  });
  const getData = async (obj = filter) => {
    const { status, data } = await apiGet(apiPath.profitLoss, {
      betType: obj?.betType,
      status: obj?.status,
      fromPeriod: obj?.fromPeriod,
      toPeriod: obj?.toPeriod,
      page: obj?.page || 1,
      limit: obj?.limit,
    });
    if (status == 200) {
      if (data?.success) {
        setActive([]);
        if (obj?.betType == "casino") {
          setUpdatedData(data?.results?.data);
        } else {
          let check =
            data?.results?.data?.filter((res) => res?.bets_list?.length > 0)
              ?.length > 0;
          if (check) {
            setData(data?.results?.data);
            setUpdatedData(data?.results?.data);
            handelChange(dropdown, data?.results?.data, obj?.betType);
          } else {
            setData([]);
            setUpdatedData([]);
            setTotal(0);
            setOverAllTotal(0);
          }
        }
      }
    }
  };

  const calculateTotal = (value, newTab) => {
    let temp = value?.map((item) => {
      const resultTotalStake = item?.bets_list?.reduce((a, v) => {
        a = parseFloat(a) + parseFloat(v.amount);
        return a;
      }, 0);
      const backTotalProfitAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.betType === "back" && v?.teamSelectionWin === v?.selectionId) {
          a = parseFloat(a) + parseFloat(v.profitAmount);
        }

        return a;
      }, 0);
      const backTotalLoseAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.betType === "back" && v?.teamSelectionWin !== v?.selectionId) {
          a = parseFloat(a) + parseFloat(v.loseAmount);
        }
        return a;
      }, 0);

      let backSubTotalresult =
        backTotalProfitAmount > backTotalLoseAmount
          ? backTotalProfitAmount - backTotalLoseAmount
          : -(backTotalLoseAmount - backTotalProfitAmount);

      const layTotalProfitAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.betType === "lay" && v?.teamSelectionWin !== v?.selectionId) {
          a = parseFloat(a) + parseFloat(v.profitAmount);
        }
        return a;
      }, 0);

      const layTotalLoseAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.betType === "lay" && v?.teamSelectionWin === v?.selectionId) {
          a = parseFloat(a) + parseFloat(v.loseAmount);
        }
        return a;
      }, 0);

      let laySubTotalresult =
        layTotalProfitAmount > layTotalLoseAmount
          ? layTotalProfitAmount - layTotalLoseAmount
          : -(layTotalLoseAmount - layTotalProfitAmount);
      let marketSubTotal = backSubTotalresult + laySubTotalresult;
      const yesTotalProfitAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.type == "Yes" && v?.decisionRun >= v?.betRun) {
          a = parseFloat(a) + parseFloat(v.profitAmount);
        }
        return a;
      }, 0);

      const yesTotalLoseAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.type == "Yes" && v?.decisionRun < v?.betRun) {
          a = parseFloat(a) + parseFloat(v.loseAmount);
        }
        return a;
      }, 0);
      let yesSubTotalresult =
        yesTotalProfitAmount > yesTotalLoseAmount
          ? yesTotalProfitAmount - yesTotalLoseAmount
          : -(yesTotalLoseAmount - yesTotalProfitAmount);

      const noTotalProfitAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.type == "No" && v?.decisionRun < v?.betRun) {
          a = parseFloat(a) + parseFloat(v.profitAmount);
        }
        return a;
      }, 0);
      const noTotalLoseAmount = item?.bets_list?.reduce((a, v) => {
        if (v?.type == "No" && v?.decisionRun >= v?.betRun) {
          a = parseFloat(a) + parseFloat(v.loseAmount);
        }
        return a;
      }, 0);
      let noSubTotalresult =
        noTotalProfitAmount > noTotalLoseAmount
          ? noTotalProfitAmount - noTotalLoseAmount
          : -(noTotalLoseAmount - noTotalProfitAmount);

      let fancyMarketSubTotal = yesSubTotalresult + noSubTotalresult;
      let fancyNetAmount = fancyMarketSubTotal;
      let v =
        marketSubTotal > 0
          ? (marketSubTotal * profileData?.commission) / 100
          : "";
      let netAmount = 0;
      let betType =
        newTab == "exchange"
          ? "betfair"
          : newTab == "sportsBook"
          ? "sportBook"
          : newTab;
      if (betType == "toss" || betType == "tie") {
        netAmount = marketSubTotal;
      } else if (betType == "fancy") {
        netAmount = fancyNetAmount;
      } else {
        netAmount = marketSubTotal - v;
      }
      return netAmount;
    });
    return temp
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      ?.toFixed();
  };
  const handelChange = (value, arrary, newTab = filter?.tab) => {
    setDropDown(value);
    if (arrary?.length > 0) {
      let totalValue;
      if (value == "All") {
        totalValue = calculateTotal(arrary, newTab);
        setUpdatedData(arrary);
        setOverAllTotal(totalValue);
      } else {
        let up = arrary?.filter((res) => {
          return res?.gameType == value;
        });
        totalValue = calculateTotal(up, newTab);
        setUpdatedData(up);
      }
      setTotal(totalValue);
    } else {
      setTotal(0);
    }
  };

  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const getResult = async (obj) => {
    const { status, data } = await apiGet(apiPath.casinoGetResult, {
      userId: obj?.clientName,
      platform: obj?.platform,
      platformTxId: obj?.platformTxId,
    });
    if (status == 200) {
      if (data?.status) {
        if (data?.message !== "failed") {
          window.open(data?.data?.url, "_blank");
        } else {
          setMessage({
            ...message,
            status: true,
            errorType: "P2P",
            message: "No Result Found",
          });
        }
      } else {
        setMessage({
          ...message,
          status: true,
          errorType: "P2P",
          message: data?.message,
        });
      }
    }
  };

  const handelChangeId = (id) => {
    if (active.includes(id)) {
      setActive((prev) => {
        prev?.filter((res) => {
          return res !== id;
        });
      });
    } else {
      setActive([...active, id]);
    }
  };

  const redirectCasino = async (userId, platform, platformTxId, type) => {
    const { status, data: response_users } = await apiGet(
      apiPath.casinoWalletHistory +
        "?userId=" +
        userId +
        "&platform=" +
        platform +
        "&platformTxId=" +
        platformTxId
    );
    //console.log(response_users, status);
    if (status === 200) {
      if (response_users.status) {
        if (response_users.data?.status === "0000") {
          if (type === 1) {
            javascript: window.open(
              response_users.data?.txnUrl,
              "_blank",
              "height=900,width=1200"
            );
          } else {
            javascript: window.open(
              response_users.data?.url,
              "_blank",
              "height=900,width=1200"
            );
          }
        } else {
          // toast.error(response_users.data.desc);
        }
      } else {
        // toast.error(response_users.data.desc);
      }
    } else {
      // toast.error(response_users.data.desc);
    }
  };
  return (
    <ProfileLayout>
      <Col md={12}>
        <div className="balance-label position-relative">
          Profit / Loss
          <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
            <RxCross2
              className="fs-3"
              style={{ fontWeight: "800", color: "black" }}
              onClick={() => navigate("/")}
            />
          </button>
        </div>
        <div className="filter-new">
          <div className="w-100 d-flex mb-1">
            <div className="filter-new-inner">
              <Form.Label>Bet Type:</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, betType: e.target.value })
                }
                value={filter.betType}
              >
                <option value="betfair">Exchange</option>
                <option value="bookmaker">Bookmaker</option>
                <option value="fancy">Fancybet</option>{" "}
                <option value="casino">Casino</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Label>Bet Status:</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
                value={filter.status}
              >
                <option value="completed">Settled</option>
                <option value="suspend">Cancelled</option>
                <option value="voided">Voided</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
                type="date"
              />
            </div>{" "}
            <div className="filter-new-inner">
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                disabled={filter.fromPeriod ? false : true}
                max={new Date().toISOString().split("T")[0]}
                value={filter.toPeriod}
                type="date"
              />
            </div>
            <div className="filter-new-inner">
              {" "}
              <button
                onClick={() => {
                  getData();
                  setTab(filter?.betType);
                }}
                className="get-history"
              >
                Get History
              </button>
            </div>
          </div>
          <div className="w-100 d-flex fitler-new-button">
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: currentDate,
                  toPeriod: currentDate,
                });
              }}
            >
              Today
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment().subtract(1, "days").format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              From Yesterday
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment().subtract(7, "days").format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              Last 7 days
            </button>{" "}
            <button
              onClick={() => {
                setFilter({
                  ...filter,
                  fromPeriod: moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD"),
                  toPeriod: currentDate,
                });
              }}
            >
              Last 30 days
            </button>
          </div>
        </div>
        <div className="pt-0 inner-sidebar-content">
          {tab === "casino" ? (
            <Table>
              <thead>
                <tr>
                  <th scope="col">Market</th>
                  <th scope="col">Profit / Loss</th>
                </tr>
              </thead>
              <tbody>
                {updatedData && updatedData?.length > 0 ? (
                  updatedData?.map((item, index) => {
                    return (
                      <>
                        <tr
                          id="summary0"
                          style={{ display: "table-row" }}
                          key={index + 1}
                        >
                          <td id="title" className="align-L">
                            Casino
                            <span className="angle_unicode">▸</span>
                            <strong>
                              {" "}
                              {item?.casinoName || item?.gameType}
                            </strong>
                            <span className="angle_unicode">▸</span>
                            {obj.dateFormat(item.timeInserted)}
                          </td>
                          <td>
                            {item?.casinoName === "Auto-Roulette 1" ? (
                              <>
                                {Math.sign(
                                  item?.transactions?.realCutAmount
                                ) === -1 ? (
                                  <span className="text-danger">
                                    (
                                    {obj.currencyFormat(
                                      item?.transactions?.realCutAmount
                                    )}
                                    )
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    (
                                    {obj.currencyFormat(
                                      Math.abs(
                                        item?.transactions?.realCutAmount
                                      )
                                    )}
                                    )
                                  </span>
                                )}
                              </>
                            ) : Math.sign(item?.playerPL) === -1 ? (
                              <span className="text-danger">
                                ({obj.currencyFormat(item?.playerPL)})
                              </span>
                            ) : (
                              <span className="text-success">
                                ({obj.currencyFormat(Math.abs(item?.playerPL))})
                              </span>
                            )}
                            {active?.includes(item?.platformTxId) ? (
                              <FaCircleMinus
                                onClick={(e) => {
                                  setActive((prev) => {
                                    prev?.filter((res) => {
                                      return res !== item?.platformTxId;
                                    });
                                  });
                                }}
                                style={{ marginLeft: "10px" }}
                              />
                            ) : (
                              <FaCirclePlus
                                onClick={(e) => {
                                  if (active?.length > 0) {
                                    setActive([...active, item?.platformTxId]);
                                  } else {
                                    setActive([item?.platformTxId]);
                                  }
                                }}
                                style={{ marginLeft: "10px" }}
                              />
                            )}
                          </td>
                        </tr>
                        {active?.includes(item?.platformTxId) && (
                          <tr className="expand" id={item?.platformTxId}>
                            <td colSpan="7" className="expand_wrap">
                              <Table className="table-commission">
                                <thead>
                                  <tr>
                                    <th>Platform</th>
                                    <th>Valid Turnover</th>
                                    <th>Win/Loss</th>
                                    <th>PT/Comm.</th>
                                    <th>Profit / Loss</th>
                                  </tr>{" "}
                                </thead>
                                <tbody>
                                  <tr
                                    id="txRow0"
                                    style={{ display: "table-row" }}
                                    className="even"
                                  >
                                    <td id="betID">
                                      <Button
                                        className="theme_dark_btn"
                                        // onClick={() =>
                                        //   redirectCasino(
                                        //     item?.clientName,
                                        //     item?.platform,
                                        //     item?.platformTxId,
                                        //     1
                                        //   )
                                        // }
                                      >
                                        {item.platform}
                                      </Button>
                                    </td>
                                    <td>0.00</td>
                                    <td>{item?.playerPL}</td>
                                    <td>0.00</td>
                                    <td>{item?.playerPL}</td>
                                  </tr>
                                  <tr className="casino-grand-total">
                                    <td>Grand Total</td>
                                    <td id="totalTurnover">0.00</td>
                                    <td id="totalPayout">{item?.playerPL}</td>
                                    <td id="totalTaxRebate">0.00</td>
                                    <td id="totalBalance">{item?.playerPL}</td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <span>You have no bets in this time period.</span>
                    </td>
                  </tr>
                )}{" "}
              </tbody>
            </Table>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th scope="col">Market</th>
                  <th scope="col"> Settled Date</th>
                  <th scope="col">Profit / Loss</th>
                </tr>
              </thead>

              <tbody>
                {data && data?.length > 0 ? (
                  data?.map((item, index) => {
                    const resultTotalStake = item?.bets_list?.reduce((a, v) => {
                      a = parseFloat(a) + parseFloat(v.amount);
                      return a;
                    }, 0);
                    const backTotalProfitAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (
                          v?.betType === "back" &&
                          v?.teamSelectionWin === v?.selectionId
                        ) {
                          a = parseFloat(a) + parseFloat(v.profitAmount);
                        }
                        return a;
                      },
                      0
                    );

                    const backTotalLoseAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (
                          v?.betType === "back" &&
                          v?.teamSelectionWin !== v?.selectionId
                        ) {
                          a = parseFloat(a) + parseFloat(v.loseAmount);
                        }
                        return a;
                      },
                      0
                    );
                    let backSubTotalresult =
                      backTotalProfitAmount > backTotalLoseAmount
                        ? backTotalProfitAmount - backTotalLoseAmount
                        : -(backTotalLoseAmount - backTotalProfitAmount);
                    const layTotalProfitAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (
                          v?.betType === "lay" &&
                          v?.teamSelectionWin !== v?.selectionId
                        ) {
                          a = parseFloat(a) + parseFloat(v.profitAmount);
                        }
                        return a;
                      },
                      0
                    );
                    const layTotalLoseAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (
                          v?.betType === "lay" &&
                          v?.teamSelectionWin === v?.selectionId
                        ) {
                          a = parseFloat(a) + parseFloat(v.loseAmount);
                        }
                        return a;
                      },
                      0
                    );
                    let laySubTotalresult =
                      layTotalProfitAmount > layTotalLoseAmount
                        ? layTotalProfitAmount - layTotalLoseAmount
                        : -(layTotalLoseAmount - layTotalProfitAmount);

                    let marketSubTotal = backSubTotalresult + laySubTotalresult;
                    const yesTotalProfitAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (v?.type == "Yes" && v?.decisionRun >= v?.betRun) {
                          a = parseFloat(a) + parseFloat(v.profitAmount);
                        }
                        return a;
                      },
                      0
                    );

                    const yesTotalLoseAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (v?.type == "Yes" && v?.decisionRun < v?.betRun) {
                          a = parseFloat(a) + parseFloat(v.loseAmount);
                        }
                        return a;
                      },
                      0
                    );
                    let yesSubTotalresult =
                      yesTotalProfitAmount > yesTotalLoseAmount
                        ? yesTotalProfitAmount - yesTotalLoseAmount
                        : -(yesTotalLoseAmount - yesTotalProfitAmount);
                    const noTotalProfitAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (v?.type == "No" && v?.decisionRun < v?.betRun) {
                          a = parseFloat(a) + parseFloat(v.profitAmount);
                        }
                        return a;
                      },
                      0
                    );
                    const noTotalLoseAmount = item?.bets_list?.reduce(
                      (a, v) => {
                        if (v?.type == "No" && v?.decisionRun >= v?.betRun) {
                          a = parseFloat(a) + parseFloat(v.loseAmount);
                        }
                        return a;
                      },
                      0
                    );
                    let noSubTotalresult =
                      noTotalProfitAmount > noTotalLoseAmount
                        ? noTotalProfitAmount - noTotalLoseAmount
                        : -(noTotalLoseAmount - noTotalProfitAmount);
                    const resultCommission = item?.bets_list?.reduce((a, v) => {
                      a =
                        parseFloat(a) +
                        parseFloat(v.commission ? v.commission : 0);
                      return a;
                    }, 0);

                    let fancyMarketSubTotal =
                      yesSubTotalresult + noSubTotalresult;

                    let fancyNetAmount = fancyMarketSubTotal;
                    let netAmount =
                      marketSubTotal -
                      (marketSubTotal * profileData?.commission) / 100;

                    return (
                      <>
                        <tr
                          id="summary0"
                          style={{ display: "table-row" }}
                          key={index + 1}
                        >
                          <td className="text-start">
                            {`${
                              item?.eventType === "4"
                                ? "Cricket"
                                : item?.eventType === "1"
                                ? "Soccer"
                                : item?.eventType === "2"
                                ? "Tennis"
                                : "Casino"
                            }`}
                            <span className="angle_unicode">▸</span>
                            <strong>
                              {item?.matchName || item?.eventName}
                            </strong>
                            <span className="angle_unicode">▸</span>
                            {tab === "fancy" && item?.fancyName}{" "}
                            {tab === "bookmaker" && "Bookmaker"}
                            {tab === "betfair" && item?.market}
                          </td>

                          <td id="settledDate">
                            {" "}
                            {obj.dateFormat(item.eventDateTime)}
                          </td>
                          <td>
                            <a id="pl0" className="expand-open" href="#">
                              {tab == "fancy" ? (
                                fancyNetAmount > 0 ? (
                                  Math.abs(fancyNetAmount)
                                ) : (
                                  <span className="red">
                                    (-{Math.abs(fancyNetAmount)})
                                  </span>
                                )
                              ) : netAmount > 0 ? (
                                obj.truncateDecimals(netAmount, 2)
                              ) : (
                                <span className="red">
                                  (-
                                  {Math.abs(obj.truncateDecimals(netAmount, 2))}
                                  )
                                </span>
                              )}
                            </a>
                            {active?.includes(item?._id) ? (
                              <FaCircleMinus
                                onClick={(e) => {
                                  setActive((prev) => {
                                    prev?.filter((res) => {
                                      return res !== item?._id;
                                    });
                                  });
                                }}
                                style={{ marginLeft: "10px" }}
                              />
                            ) : (
                              <FaCirclePlus
                                onClick={(e) => {
                                  if (active?.length > 0) {
                                    setActive([...active, item?._id]);
                                  } else {
                                    setActive([item?._id]);
                                  }
                                }}
                                style={{ marginLeft: "10px" }}
                              />
                            )}
                          </td>
                        </tr>
                        {active?.includes(item?._id) &&
                          item?.bets_list?.length > 0 && (
                            <tr className="expand" key={item?._id}>
                              <td colSpan="3" className="expand_wrap">
                                <Table
                                  style={{ border: "1px solid #dee2e6" }}
                                  className="table-commission"
                                >
                                  <thead>
                                    <tr>
                                      <th>User Name</th>
                                      <th>Bet ID</th>
                                      <th>Selection</th>
                                      <th>Odds</th>
                                      <th>Stake</th>
                                      <th>Type</th>
                                      <th>Placed</th>
                                      <th>Profit/Loss</th>
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    {item?.bets_list?.map((betData, i) => {
                                      return (
                                        <tr
                                          id="txRow0"
                                          style={{ display: "table-row" }}
                                          className="even"
                                          key={i + 1}
                                        >
                                          <td id="betID">
                                            {betData?.clientName}
                                          </td>
                                          <td id="betID">
                                            {betData?.sessionBetId ||
                                              betData?.matchBetId}
                                          </td>
                                          <td id="matchSelection">
                                            {betData?.runnerName ||
                                              betData?.teamName}
                                          </td>
                                          <td id="txOddsMatched">
                                            {betData?.betRun +
                                              "/" +
                                              betData?.bhav || betData?.bhav}
                                          </td>
                                          <td id="txStake">
                                            {" "}
                                            {betData?.amount}
                                          </td>
                                          <td>
                                            {tab == "fancy" ? (
                                              <span
                                                id="matchType"
                                                className={
                                                  betData?.type === "No"
                                                    ? "lay"
                                                    : "back"
                                                }
                                              >
                                                {betData?.type}
                                              </span>
                                            ) : (
                                              <span
                                                id="matchType"
                                                className={
                                                  betData?.betType === "lay"
                                                    ? "lay"
                                                    : "back"
                                                }
                                              >
                                                {betData?.betType}
                                              </span>
                                            )}
                                          </td>
                                          <td id="placed">
                                            {" "}
                                            {obj.dateFormat(betData?.createdAt)}
                                          </td>
                                          {tab == "fancy" ? (
                                            <td id="txLiability">
                                              {betData?.type == "No" ? (
                                                betData?.decisionRun <
                                                betData?.betRun ? (
                                                  <span>
                                                    {obj.truncateDecimals(
                                                      betData?.profitAmount,
                                                      2
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span className="red">
                                                    (-
                                                    {obj.truncateDecimals(
                                                      betData?.loseAmount,
                                                      2
                                                    )}
                                                    )
                                                  </span>
                                                )
                                              ) : betData?.decisionRun >=
                                                betData?.betRun ? (
                                                <span>
                                                  {obj.truncateDecimals(
                                                    betData?.profitAmount,
                                                    2
                                                  )}
                                                </span>
                                              ) : (
                                                <span className="red">
                                                  (-
                                                  {obj.truncateDecimals(
                                                    betData?.loseAmount,
                                                    2
                                                  )}
                                                  )
                                                </span>
                                              )}
                                            </td>
                                          ) : (betData?.teamSelectionWin ==
                                              betData?.selectionId &&
                                              betData?.betType == "back") ||
                                            (betData?.teamSelectionWin !=
                                              betData?.selectionId &&
                                              betData?.betType == "lay") ? (
                                            <td id="txLiability">
                                              {obj.truncateDecimals(
                                                betData?.profitAmount,
                                                2
                                              )}
                                            </td>
                                          ) : (
                                            <td id="txLiability">
                                              <span className="red">
                                                (-
                                                {obj.truncateDecimals(
                                                  betData?.loseAmount,
                                                  2
                                                )}
                                                )
                                              </span>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    })}
                                    <tr className="sum-pl">
                                      <td colSpan="8">
                                        <dl>
                                          <dt>Total Stakes</dt>
                                          <dd id="totalStakes">
                                            {resultTotalStake}
                                          </dd>
                                          {tab == "fancy" ? (
                                            <>
                                              <dt id="backSubTotalTitle">
                                                Yes subtotal
                                              </dt>
                                              <dd id="backSubTotal">
                                                {" "}
                                                {yesSubTotalresult ? (
                                                  yesTotalProfitAmount >
                                                  yesTotalLoseAmount ? (
                                                    Math.abs(
                                                      obj.truncateDecimals(
                                                        yesSubTotalresult,
                                                        2
                                                      )
                                                    )
                                                  ) : (
                                                    <span className="red">
                                                      (-{" "}
                                                      {Math.abs(
                                                        obj.truncateDecimals(
                                                          yesSubTotalresult,
                                                          2
                                                        )
                                                      )}
                                                      )
                                                    </span>
                                                  )
                                                ) : (
                                                  Math.abs(
                                                    obj.truncateDecimals(
                                                      yesSubTotalresult,
                                                      2
                                                    )
                                                  )
                                                )}
                                              </dd>

                                              <dt id="laySubTotalTitle">
                                                No subtotal
                                              </dt>
                                              <dd id="laySubTotal">
                                                {noSubTotalresult ? (
                                                  noTotalProfitAmount >
                                                  noTotalLoseAmount ? (
                                                    Math.abs(
                                                      obj.truncateDecimals(
                                                        noSubTotalresult,
                                                        2
                                                      )
                                                    )
                                                  ) : (
                                                    <span className="red">
                                                      (-{" "}
                                                      {Math.abs(
                                                        obj.truncateDecimals(
                                                          noSubTotalresult,
                                                          2
                                                        )
                                                      )}
                                                      )
                                                    </span>
                                                  )
                                                ) : (
                                                  Math.abs(
                                                    obj.truncateDecimals(
                                                      noSubTotalresult,
                                                      2
                                                    )
                                                  )
                                                )}
                                              </dd>
                                            </>
                                          ) : (
                                            <>
                                              <dt id="backSubTotalTitle">
                                                Back subtotal
                                              </dt>
                                              <dd id="backSubTotal">
                                                {" "}
                                                {backSubTotalresult ? (
                                                  backTotalProfitAmount >
                                                  backTotalLoseAmount ? (
                                                    Math.abs(
                                                      obj.truncateDecimals(
                                                        backSubTotalresult,
                                                        2
                                                      )
                                                    )
                                                  ) : (
                                                    <span className="red">
                                                      (-{" "}
                                                      {Math.abs(
                                                        obj.truncateDecimals(
                                                          backSubTotalresult,
                                                          2
                                                        )
                                                      )}
                                                      )
                                                    </span>
                                                  )
                                                ) : (
                                                  Math.abs(
                                                    obj.truncateDecimals(
                                                      backSubTotalresult,
                                                      2
                                                    )
                                                  )
                                                )}
                                              </dd>

                                              <dt id="laySubTotalTitle">
                                                Lay subtotal
                                              </dt>
                                              <dd id="laySubTotal">
                                                {" "}
                                                {layTotalProfitAmount >
                                                layTotalLoseAmount ? (
                                                  Math.abs(
                                                    obj.truncateDecimals(
                                                      laySubTotalresult,
                                                      2
                                                    )
                                                  )
                                                ) : (
                                                  <span className="red">
                                                    (-
                                                    {Math.abs(
                                                      obj.truncateDecimals(
                                                        laySubTotalresult,
                                                        2
                                                      )
                                                    )}
                                                    )
                                                  </span>
                                                )}
                                              </dd>
                                            </>
                                          )}

                                          <dt>Market subtotal</dt>

                                          {tab == "fancy" ? (
                                            <dd id="marketTotal">
                                              {yesTotalProfitAmount >
                                                yesTotalLoseAmount ||
                                              noTotalProfitAmount >
                                                noTotalLoseAmount ? (
                                                Math.abs(
                                                  obj.truncateDecimals(
                                                    fancyMarketSubTotal,
                                                    2
                                                  )
                                                )
                                              ) : (
                                                <span className="red">
                                                  (-
                                                  {Math.abs(
                                                    obj.truncateDecimals(
                                                      fancyMarketSubTotal,
                                                      2
                                                    )
                                                  )}
                                                  )
                                                </span>
                                              )}
                                            </dd>
                                          ) : (
                                            <>
                                              <dd id="marketTotal">
                                                {marketSubTotal > 0 ? (
                                                  Math.abs(
                                                    obj.truncateDecimals(
                                                      marketSubTotal,
                                                      2
                                                    )
                                                  )
                                                ) : (
                                                  <span className="red">
                                                    (-
                                                    {Math.abs(
                                                      obj.truncateDecimals(
                                                        marketSubTotal,
                                                        2
                                                      )
                                                    )}
                                                    )
                                                  </span>
                                                )}
                                              </dd>
                                            </>
                                          )}
                                          {tab != "fancy" && (
                                            <>
                                              <dt
                                                id="commissionTitle"
                                                style={{ display: "block" }}
                                              >
                                                Commission
                                              </dt>
                                              <dd
                                                id="commission"
                                                style={{ display: "block" }}
                                              >
                                                {tab == "fancy"
                                                  ? fancyMarketSubTotal > 0
                                                    ? (fancyMarketSubTotal *
                                                        profileData?.commission) /
                                                      100
                                                    : 0
                                                  : marketSubTotal > 0
                                                  ? (marketSubTotal *
                                                      profileData?.commission) /
                                                    100
                                                  : 0}
                                              </dd>
                                            </>
                                          )}

                                          <dt className="net_total">
                                            Net Market Total
                                          </dt>
                                          {tab == "fancy" ? (
                                            <dd
                                              id="netTotal"
                                              className="net_total"
                                            >
                                              {fancyNetAmount > 0 ? (
                                                fancyNetAmount
                                              ) : (
                                                <span className="red">
                                                  (-
                                                  {Math.abs(
                                                    obj.truncateDecimals(
                                                      fancyNetAmount,
                                                      2
                                                    )
                                                  )}
                                                  )
                                                </span>
                                              )}
                                            </dd>
                                          ) : (
                                            <>
                                              <dd
                                                id="netTotal"
                                                className="net_total"
                                              >
                                                {netAmount > 0 ? (
                                                  obj.truncateDecimals(
                                                    netAmount,
                                                    2
                                                  )
                                                ) : (
                                                  <span className="red">
                                                    (-
                                                    {Math.abs(
                                                      obj.truncateDecimals(
                                                        netAmount,
                                                        2
                                                      )
                                                    )}
                                                    )
                                                  </span>
                                                )}
                                              </dd>
                                            </>
                                          )}
                                        </dl>
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <span>You have no bets in this time period.</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </ProfileLayout>
  );
};

export default ProfitLoss;
