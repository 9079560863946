import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
import SidebarLayout from "../component/SidebarLayout";
import ProfileLayout from "./ProfileLayout";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
const DepositHistory = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });

  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.depositHistory, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results]);
        } else {
          setListing(data?.results);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <div className="balance-label position-relative">
        Bets History
        <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
          <RxCross2
            className="fs-3"
            style={{ fontWeight: "800", color: "black" }}
            onClick={() => navigate("/")}
          />
        </button>
      </div>
      <div className="p-3 pt-1 inner-sidebar-content d-flex flex-column ">
        <div className="balance-deposit-sec">
          <Table>
            <thead>
              <tr>
                <th scope="col">TrxID</th> <th scope="col">Date</th>
                <th scope="col"> Deposit Type</th>
                <th scope="col">Bank</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Remark</th>
              </tr>
            </thead>
            <tbody>
              {listing?.length > 0 ? (
                listing?.map((res) => {
                  return (
                    <tr>
                      <td>{res.TransactionId}</td>{" "}
                      <td> {obj.dateFormat(res?.createdAt)}</td>
                      <td>{res.transactionType}</td>
                      <td> {res?.BankAccount || res.bank}</td>{" "}
                      <td>{res.amount}</td>
                      <td>
                        {" "}
                        <span
                          className={
                            res.status == "completed"
                              ? "text-success"
                              : res.status == "declined"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {startCase(res.status)}
                        </span>
                      </td>
                      <td>{res.remark || "-"}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span style={{ fontSize: "13px" }}>No Record Found</span>
                  </td>
                </tr>
              )}{" "}
            </tbody>
          </Table>
        </div>
      </div>
    </ProfileLayout>
  );
};

export default DepositHistory;
