import React, { useEffect, useState } from "react";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import obj from "../../Utils/constants";
import { isEmpty } from "lodash";
import { Modal } from "react-bootstrap";
import { BiSolidRightArrow } from "react-icons/bi";
import { RxCrossCircled } from "react-icons/rx";
const BookPosition = ({ showPosition, setShowPosition }) => {
  const [data, setData] = useState([]);
  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.bookPosition +
        `?eventId=${showPosition?.object?.eventId}&type=${
          obj.betCheckObj[showPosition?.object?.categoryType]
        }&selectionId=${showPosition?.object?.selectionId}&marketId=${
          showPosition?.object?.marketId
        }`
    );

    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };
  useEffect(() => {
    if (showPosition?.status && !isEmpty(showPosition?.object)) {
      getFancyBetPosition();
    }
  }, [showPosition]);

  return (
    <Modal
      show={showPosition?.status}
      onHide={() => setShowPosition({ status: false, object: {} })}
      centered
    >
      <RxCrossCircled
        style={{ position: "absolute", right: "1%", top: "1%",zIndex:"999999" }}
        size={20}
        onClick={() => setShowPosition({ status: false, object: {} })}
        color="black"
      />

      <Modal.Body>
        <div className="market-title p-2 mb-2">
          <p className="book-position-title m-0">
            <span className="first-span"> {showPosition?.object?.match}</span>{" "}
            <BiSolidRightArrow /> <span>{showPosition?.object?.fancyName}</span>
          </p>
        </div>
        <table id="table" className="table table-fancy">
          <tbody>
            {data?.bets?.length > 0 &&
              data?.bets?.map((res) => {
                return (
                  <tr>
                    <td
                      style={{
                        backgroundColor:
                          res?.position > 0
                            ? "rgb(208 232 250)"
                            : "rgb(253 226 232)",
                      }}
                    >
                      {res.betRun}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          res?.position > 0
                            ? "rgb(208 232 250)"
                            : "rgb(253 226 232)",
                      }}
                    >
                      {res.position >= 0 ? (
                        res.position
                      ) : (
                        <span style={{ color: `red` }}>
                          ({Math.abs(res.position.toFixed(2))})
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            {data?.noBet?.length > 0 &&
              data?.noBet?.map((res) => {
                return (
                  <>
                    <tr id="runsEx" style={{ display: "table-row" }}>
                      <td id="runs" className="col-back">
                        {res.profitRunRange}
                      </td>
                      <td id="exposure" className="col-back">
                        {res.positionProfitAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange}
                      </td>
                      <td id="exposure" className="col-lay text-danger">
                        {`(- ${res.positionLoseAmount.toFixed(2)})`}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay ">
                        {res.lossRunRange + 1}
                      </td>
                      <td id="exposure" className="col-lay text-danger">
                        {`(- ${res.positionLoseAmount.toFixed(2)})`}
                      </td>
                    </tr>
                  </>
                );
              })}
            {data?.yesBet?.length > 0 &&
              data?.yesBet?.map((res) => {
                return (
                  <>
                    <tr id="runsEx" style={{ display: "table-row" }}>
                      <td id="runs" className="col-back">
                        {res.profitRunRange}
                      </td>
                      <td id="exposure" className="col-back">
                        {res.positionProfitAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange}
                      </td>
                      <td id="exposure" className="col-lay text-danger">
                        {`(- ${res.positionLoseAmount.toFixed(2)})`}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange - 1}
                      </td>
                      <td id="exposure" className="col-lay text-danger">
                        {`(- ${res.positionLoseAmount.toFixed(2)})`}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default BookPosition;
