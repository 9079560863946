import React, { useContext, useEffect, useRef, useState } from "react";
import badge from "../../img/badge.png";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { isEmpty, isNumber } from "lodash";
import BetSlipContext from "../../context/BetSlipContext";
import useScroll from "../../component/Scroll";
import { useNavigate } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import BookPosition from "./BookPosition";
const Fancy = ({
  title,
  data,
  user,
  detail,
  profileData,
  setLimit,
  scrollHeight,
  position,
  setRightTab,
  BetSlip,
}) => {
  const [showPosition, setShowPosition] = useState({
    status: false,
    object: {},
  });
  const { t } = useTranslation();
  const { setShowBetDialog, setBetSlipObject, betSlipObject, showBetDialog } =
    useContext(BetSlipContext);
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "fancy" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setRightTab("betslip");
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: "fancy",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const mergeAndCalculate = (betSlipObject, apiData) => {
    const betRun = betSlipObject.odds;
    let profitAmount;
    let loseAmount;

    if (betSlipObject.betType === "Yes") {
      profitAmount = parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
      loseAmount = -parseFloat(betSlipObject.bidPrice);
    } else if (betSlipObject.betType === "No") {
      profitAmount = parseFloat(betSlipObject.bidPrice);
      loseAmount = -parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
    }

    let mergedBetRunPosition = apiData.betRunPosition.map((position) => ({
      ...position,
      position:
        position.position !== null && position.position !== undefined
          ? position.position
          : 0,
    }));

    const minBetRun = Math.min(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );
    const maxBetRun = Math.max(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );

    if (betRun > maxBetRun) {
      for (let i = maxBetRun + 1; i <= betRun; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    } else if (betRun < minBetRun) {
      for (let i = betRun + 1; i <= minBetRun - 1; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    }

    mergedBetRunPosition.forEach((item) => {
      if (betSlipObject.betType === "Yes") {
        if (item.betRun >= betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      } else if (betSlipObject.betType === "No") {
        if (item.betRun < betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      }
    });

    mergedBetRunPosition.sort((a, b) => a.betRun - b.betRun);

    const maxLossAmount = Math.min(
      ...mergedBetRunPosition.map((item) => item.position)
    );

    return maxLossAmount;
  };
  const [show, setShow] = useState("");
  const format = (number) => {
    var nf = new Intl.NumberFormat();
    return nf.format(number);
  };
  return (
    <div className="leagueBox">
      <div className="title" style={{ borderRadius: "0" }}>
        <Row>
          <Col style={{ fontWeight: "600" }} xs={7}>
            {title}
          </Col>
          <Col
            style={{ fontWeight: "600" }}
            xs={3}
            className="text-dark text-center"
          >
            <Row>
              <Col className="text-center text-white px-0">{t("NO")}</Col>
              <Col className="text-center text-white px-0">{t("YES")}</Col>
            </Row>
          </Col>{" "}
          <Col xs={2} className="text-dark text-center"></Col>
        </Row>
      </div>

      {data?.map((item, index) => {
        if (
          item?.odds?.ms == 4 ||
          item?.odds?.rt?.length == 0 ||
          item?.odds?.ms == 2 ||
          isEmpty(item?.odds?.rt)
        ) {
          return null;
        } else {
          let oldPosition = !isEmpty(
            position?.find((res) => {
              return res?.selectionId == item?.selectionId;
            })
          )
            ? position?.find((res) => {
                return res?.selectionId == item?.selectionId;
              })?.position
            : "";

          let newPosition = "";
          if (oldPosition && position) {
            let oldPositionData =
              position?.find((res) => {
                return res?.selectionId == item?.selectionId;
              }) || [];

            if (betSlipObject?.teamName === item?.name && showBetDialog) {
              betSlipObject.betType = betSlipObject?.object?.ib ? "Yes" : "No";

              newPosition =
                oldPositionData && oldPositionData?.betRunPosition
                  ? mergeAndCalculate(betSlipObject, oldPositionData)
                  : 0;
            }
          } else {
            if (betSlipObject?.teamName === item?.name && showBetDialog) {
              if (betSlipObject?.object?.ib) {
                if (Math.abs(item?.position) > 0) {
                  let backLoss =
                    Number(betSlipObject?.bidPrice) + Number(item?.position);
                  newPosition = backLoss;
                } else {
                  let backLoss = Number(betSlipObject?.bidPrice);
                  newPosition = backLoss;
                }
              } else {
                if (Math.abs(item?.position) > 0) {
                  let backProfit =
                    (betSlipObject?.bidPrice * betSlipObject?.object?.pt) / 100;
                  newPosition = Number(backProfit) + Number(item.position);
                } else {
                  let backProfit =
                    (betSlipObject?.bidPrice * betSlipObject?.object?.pt) / 100;
                  newPosition = backProfit;
                }
              }
            }
          }

          let condition =
            betSlipObject?.teamName === item?.name &&
            Math.abs(newPosition) >= 0;
          return (
            <div
              style={{ borderBottom: "1px solid #ddd" }}
              className="laegueRow"
            >
              <Row>
                <Col
                  xs={7}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="text-black selection-slip">
                    <span style={{ fontSize: "15px", paddingLeft: "10px" }}>
                      {item?.name}{" "}
                    </span>
                    {(isNumber(oldPosition) || condition) && (
                      <span
                        style={{ marginLeft: "10px",display:"flex",alignItems:"center" ,fontSize: "13px",}}
                        className={` py-1 ${"team-red-score"}`}
                      >
                        {isNumber(oldPosition)
                          ? `( ${Math?.abs(item?.position).toFixed(2)} )`
                          : ""}
                        {betSlipObject?.teamName === item?.name &&
                          Math.abs(newPosition) >= 0 && (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "5px",
                                fontSize: "13px",
                              }}
                            >
                              <FaArrowRight
                                color={item?.newPosition > 0 ? "green" : "red"}
                                style={{ marginRight: "3px" }}
                              />{" "}
                              {`( ${Math.abs(newPosition).toFixed(2)} )`}
                            </span>
                          )}
                      </span>
                    )}{" "}
                  </div>
                  <div>
                    {isNumber(oldPosition) && (
                      <Button
                        style={{
                          marginRight: "10px",
                          fontSize: "13px",
                          padding: "5px 10px",
                          border: "none",
                          background: "rgb(255, 184, 12)",
                          color: "black",
                        }}
                        onClick={() =>
                          setShowPosition({
                            status: true,
                            object: { ...item, match: detail?.eventName },
                          })
                        }
                      >
                        Book
                      </Button>
                    )}
                    {/* <BsFillInfoCircleFill
                      color="black"
                      onClick={() => setShow(!show)}
                    /> */}
                  </div>
                </Col>
                <Col xs={3} className="text-dark text-center  px-0">
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex position-relative"
                  >
                    <div
                      title={
                        item?.odds?.rt?.length > 0 ? item?.odds?.rt[0]?.rt : ""
                      }
                      id={"lay_odds_fancy_" + index}
                      onClick={() =>
                        handelChange(
                          item?.odds?.rt[0],
                          "No",
                          item?.name,
                          item,
                          index
                        )
                      }
                      className={`bluBoxs d-inline-block ${
                        Number(
                          document.getElementById("lay_odds_fancy_" + index)
                            ?.title
                        ) !== item?.odds?.rt[0]?.rt
                          ? "spark-lay"
                          : ""
                      }  ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[0]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "No"
                          ? "back-active"
                          : ""
                      }`}
                    >
                      <div style={{ fontWeight: "800", fontSize: "14px" }}>
                        {item?.odds?.rt[0]?.rt}{" "}
                      </div>
                      <span>{item?.odds?.rt[0]?.pt}</span>
                    </div>
                    <div
                      title={
                        item?.odds?.rt?.length > 0 ? item?.odds?.rt[1]?.rt : ""
                      }
                      id={"back_odds_fancy_" + index}
                      onClick={() =>
                        handelChange(
                          item?.odds?.rt[1],
                          "Yes",
                          item?.name,
                          item,
                          index
                        )
                      }
                      className={`redBoxs  d-inline-block  ${
                        Number(
                          document.getElementById("back_odds_fancy_" + index)
                            ?.title
                        ) !== item?.odds?.rt[1]?.rt
                          ? "spark-back"
                          : ""
                      }  ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[1]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "Yes"
                          ? "lay-active "
                          : ""
                      }`}
                    >
                      {" "}
                      <div style={{ fontWeight: "800", fontSize: "14px" }}>
                        {item?.odds?.rt[1]?.rt}
                      </div>
                      <span> {item?.odds?.rt[1]?.pt}</span>
                    </div>
                    {(item?.odds?.ms == 9 ||
                      item?.odds?.ms == 2 ||
                      item?.odds?.ms == 3 ||
                      item?.odds?.ms == 4) && (
                      <div class="overlay-match">
                        {item?.odds?.ms == 9
                          ? "Ball Running"
                          : item?.odds?.ms == 2
                          ? "In Active"
                          : item?.odds?.ms == 3
                          ? t("Suspended")
                          : item?.odds?.ms == 4
                          ? "Closed"
                          : ""}
                      </div>
                    )}
                  </div>
                </Col>{" "}
                <Col xs={2} className="text-dark text-center  px-0">
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "black",
                          fontSize: "12px",
                          marginBottom: "2px",
                        }}
                      >
                        {t("Min")} :{" "}
                        <span style={{ fontWeight: "600", margin: "0 3px" }}>
                          {format(localLimit?.minAmount)}
                        </span>{" "}
                      </div>{" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "black",
                          fontSize: "12px",
                        }}
                      >
                        {t("Max")} :{" "}
                        <span style={{ fontWeight: "600", margin: "0 3px" }}>
                          {format(localLimit?.maxAmount)}
                        </span>
                      </div>{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              {betSlipObject?.teamName == item?.fancyName &&
                !isEmpty(betSlipObject?.betType) &&
                betSlipObject?.playType == "fancy" && <BetSlip />}
            </div>
          );
        }
      })}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <p
          className="mb-0"
          style={{
            fontSize: "14px",
            color: "black",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            padding: "2px",
          }}
        >
          {t("Min")} : {localLimit?.minAmount} | {t("Max")} :{" "}
          {localLimit?.maxAmount}
        </p>
      </Modal>
      {showPosition?.status && !isEmpty(showPosition?.object) && (
        <BookPosition
          showPosition={showPosition}
          setShowPosition={setShowPosition}
        />
      )}
    </div>
  );
};

export default Fancy;
