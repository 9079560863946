import { isEmpty, isNumber } from "lodash";
import React from "react";

const BackLayInner = ({
  odds_back,
  odds_lay,
  check,
  betSlipObject,
  item,
  showBetDialog,
  checkDisabled,
  handelChange,
  index,
  component,
  disabelBack,
  disabelLay,
  oldLay,
  oldBack,
  indexOdds,
}) => {
  let backCheck = check(odds_back[indexOdds]) || "";
  let activeClassBack = `position-relative bluBoxs  ${
    Number(
      document
        .getElementById(
          "back_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_back[indexOdds]?.rt && "spark-back"
  }
      ${
        Number(
          document
            .getElementById(
              "back_odds_" + (item.SelectionId || item?.selectionID) + index
            )
            ?.title?.split("-")[1]
            ?.trim()
        ) !== odds_back[indexOdds]?.bv && "spark-back"
      } 
      ${
        betSlipObject?.teamName == item?.RunnerName &&
        betSlipObject?.odds == odds_back[indexOdds]?.rt &&
        betSlipObject?.betType == "back" &&
        showBetDialog
          ? "back-active"
          : ""
      }`;

  let layCheck = check(odds_lay[indexOdds]?.rt) || "";
  let activeClassLay = `position-relative redBoxs ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_lay[indexOdds]?.rt &&
    betSlipObject?.betType == "lay" &&
    showBetDialog
      ? "lay-active"
      : ""
  } ${
    Number(
      document
        .getElementById(
          "lay_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_lay[indexOdds]?.rt && "spark-lay"
  }
          ${
            Number(
              document
                .getElementById(
                  "lay_odds_" + (item.SelectionId || item?.selectionID) + index
                )
                ?.title?.split("-")[1]
                ?.trim()
            ) !== odds_lay[indexOdds]?.bv && "spark-lay"
          }
        }`;

  return (
    <div className="d-flex" style={{ cursor: "pointer", height: "100%" }}>
      {!isEmpty(odds_back[indexOdds]) ? (
        <div
          title={`${oldBack?.length > 0 && oldBack[indexOdds]?.rt}-${
            oldBack?.length > 0 && oldBack[indexOdds]?.bv
          }`}
          id={"back_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelBack) {
            } else {
              if (!checkDisabled(odds_back[indexOdds]?.rt)) {
                handelChange(
                  odds_back[indexOdds],
                  "back",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={activeClassBack}
        >
          <div className={backCheck}>
            {backCheck == "single-disabled" && (
              <span className="disabled"></span>
            )}
            <div style={{ fontWeight: "800",fontSize:"14px" }}>
              {odds_back[indexOdds]?.rt || ""}
            </div>
            <span>
              {" "}
              {component !== "bookmaker" ? odds_back[indexOdds]?.bv : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}
      {!isEmpty(odds_lay[indexOdds]) ? (
        <div
          title={`${oldLay?.length > 0 && oldLay[indexOdds]?.rt}-${
            oldLay?.length > 0 && oldLay[indexOdds]?.bv
          }`}
          id={"lay_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelLay) {
            } else {
              if (!checkDisabled(odds_lay[indexOdds]?.rt)) {
                handelChange(
                  odds_lay[indexOdds],
                  "lay",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={activeClassLay}
        >
          <div className={layCheck}>
            {" "}
            {layCheck == "single-disabled" && (
              <span className="disabled"></span>
            )}    <div style={{ fontWeight: "800",fontSize:"14px" }}>
            {odds_lay[indexOdds]?.rt || ""}</div>
            <span>
              {component !== "bookmaker" ? odds_lay[indexOdds]?.bv : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}
    </div>
  );
};

export default BackLayInner;
