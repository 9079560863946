import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Container,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { isEmpty, startCase } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import { BsBank } from "react-icons/bs";
import apiPath from "../Utils/apiPath";
import { AmountArray } from "../Utils/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AuthContext from "../context/AuthContext";
import { CiBank } from "react-icons/ci";
import "./Deposit.css";
import { FaRegCopy } from "react-icons/fa";
import UpiBg from "../assets/images/upi.png";
import PhonePe from "../assets/images/phonePe.png";
import GooglePay from "../assets/images/googlePay.png";
import PayTm from "../assets/images/payment.png";
import Bhim from "../assets/images/bhim.png";
import { IoIosArrowForward } from "react-icons/io";
import { IoQrCodeOutline } from "react-icons/io5";
import { DepositInfo } from "../component/DepositInfo";
import { toast } from "react-toastify";
import Layout from "./Layout";
import SidebarLayout from "../component/SidebarLayout";
import BetSlipContext from "../context/BetSlipContext";
import { useTranslation } from "react-i18next";
const Deposit = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const { t } = useTranslation();
  const { betLoader, setBetLoader } = useContext(BetSlipContext);
  const [image, setImage] = useState("");
  const [bank, setBank] = useState([]);
  const { amounutRefresh } = useContext(AuthContext);
  const [isLoader, setLoader] = useState(false);
  const [detail, setDetail] = useState({});
  const [show, setShow] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const onSubmit = async (body) => {
    if (checkbox) {
      setBetLoader(true);
      setLoader(true);
      let imageCheck = "";
      if (image !== "") {
        imageCheck = await uploadImage(image);
      }
      body = {
        amount: body?.amount,
        TransactionId: body?.transactionId,
        TransactionFile: image !== "" ? imageCheck : "",
        bank: select,
        AccountName: detail?.account_name || "",
        BankAccount: detail?.bank_name || "",
        transactionType: "deposit",
        bonus: body?.bonus,
        offer: offer == "first" ? "offer1" : "offer2",
      };
      const { status, data } = await apiPost(apiPath.depositAmount, body);
      if (status == 200) {
        if (data?.success) {
          setBetLoader(false);
          toast.success(data?.message);
          amounutRefresh();
          setImage("");
          setCheckBox(false);
          reset();
          setLoader(false);
        } else {
          setBetLoader(false);
          toast.error(data?.message);
          setLoader(false);
        }
      } else {
        setBetLoader(false);
        toast.error(data?.message);
        setLoader(false);
      }
    } else {
      setBetLoader(false);
      toast.error("Please Select Terms and Condition");
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "deposit",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
        setDetail(data?.results?.bankList[0]);
        setSelect(data?.results?.bankList[0]?._id);
      }
    }
  };

  const uploadImage = async (obj) => {
    let form = new FormData();
    form.append("TransactionFile", obj);
    const { status, data } = await apiPost(apiPath.reciptUpload, form);
    if (status == 200) {
      return data?.path;
    } else {
      return false;
    }
  };
  useEffect(() => {
    getBank();
  }, []);
  const [priceValue, setPriceValue] = useState("");
  const priceCalculate = async (price, value) => {
    setPriceValue(price);
    setValue("amount", Number(price) + Number(value));
  };

  const resetPrice = async () => {
    setValue("amount", "");
  };
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const [select, setSelect] = useState("bankTransfer");
  const [offer, setOffer] = useState("first");
  const [showMore, setShowMore] = useState("");
  return (
    <SidebarLayout heading="Deposit">
      <div
        // style={{ marginTop: "10px" }}
        class="main dw-p deposit-box page-content-box w-100 bg-gradual-black"
      >
        <div className="wsidth70">
          <div class="usrTrans-wrapper common-box form-f mb-66">
            <div class="withdraw-form usrTrans-form">
              <Form
                onSubmit={handleSubmit(onSubmit)}
                id="deposit_form"
                class="deposit_form"
                style={{ paddingBottom: "50px" }}
              >
                <div
                  style={{
                    height: showMore ? "auto" : "190px",
                    overflowY: "hidden",
                    background: "white",
                  }}
                  class="member-menu-box member-list select-group checkbox-style position-relative"
                >
                  <div class="title">
                    <h2>
                      <span>{t("Payment_Options")}</span>
                    </h2>
                  </div>
                  <div className="upi-column-outer ">
                    {bank?.map((res) => {
                      return (
                        <div className="position-relative upi-column mb-2">
                          <input
                            className="position-absolute"
                            checked={select === res?._id ? true : false}
                            type="radio"
                            onChange={() => {
                              setSelect(res?._id);
                              setDetail(res);
                            }}
                            style={{
                              width: "15px",
                              height: "15px",
                              opacity: "1",
                              top: "8%",
                              right: "10%",
                            }}
                          />
                          <div
                            className="d-flex flex-column align-items-start"
                            style={{ width: "90%" }}
                          >
                            {res?.depositType == "bankTransfer" && (
                              <CiBank size={23} />
                            )}{" "}
                            {res?.depositType == "qr" && (
                              <IoQrCodeOutline size={23} />
                            )}
                            {res?.depositType == "upi" && (
                              <img width={"24%"} src={UpiBg} />
                            )}
                            <span
                              style={{
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            >
                              {res?.account_name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div style={{ fontSize: "12px" }} className="mt-1">
                    <p className="p-0 m-0">1. {t("DepositLine1")}</p>
                    {!showMore && (
                      <p
                        onClick={() => setShowMore(true)}
                        style={{ fontWeight: "600" }}
                        className="text-danger"
                      >
                        {t("Show_More")}...
                      </p>
                    )}
                    {showMore && (
                      <div>
                        <p className="p-0 m-0">2. {t("DepositLine2")}</p>
                        <p className="p-0 m-0">3. {t("DepositLine3")}</p>
                        <p className="p-0 m-0">4. {t("DepositLine4")}</p>
                        <p className="p-0 m-0">5. {t("DepositLine5")}</p>
                      </div>
                    )}
                    {showMore && (
                      <p
                        onClick={() => setShowMore(false)}
                        style={{ fontWeight: "600" }}
                        className="text-danger pb-0 mb-0"
                      >
                        {t("Show_Less")}...
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    background: "white",
                  }}
                  class="member-menu-box member-list select-group checkbox-style "
                >
                  <div class="title mb-1">
                    <h2>
                      <span>{t("Payment_Detail")}</span>
                    </h2>
                  </div>
                  {detail?.depositType !== "bankTransfer" && (
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      {detail?.depositType == "qr" && (
                        <img
                          width={"70%"}
                          className="mb-2"
                          src={`${process.env.REACT_APP_API_BASE_URL}${detail?.qr_path}`}
                        />
                      )}
                      {detail?.depositType !== "qr" && (
                        <div className="w-100 d-flex justify-content-between align-items-center mb-1">
                          <div
                            style={{ fontSize: "14px" }}
                            className="d-flex flex-column align-items-start"
                          >
                            <span>{t("UPI_ID")}</span>{" "}
                            <span className="span-deposit">
                              {detail?.upiId || "-"}
                            </span>
                          </div>{" "}
                          <CopyToClipboard
                            onCopy={() => toast.success("Copied!")}
                            text={detail?.upiId || "-"}
                          >
                            <FaRegCopy />
                          </CopyToClipboard>
                        </div>
                      )}
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <div
                          style={{ fontSize: "14px" }}
                          className="d-flex flex-column align-items-start"
                        >
                          <span>{t("Account_Name")}</span>{" "}
                          <span className="span-deposit">
                            {detail?.account_name || "-"}
                          </span>
                        </div>{" "}
                        <CopyToClipboard
                          onCopy={() => toast.success("Copied!")}
                          text={detail?.account_name || "-"}
                        >
                          <FaRegCopy />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}
                  {detail?.depositType == "bankTransfer" && (
                    <div>
                      <div
                        style={{ fontSize: "14px" }}
                        className="d-flex justify-content-between align-items-center mb-1"
                      >
                        <div>
                          <span>{t("Account")} : </span>{" "}
                          <span className="span-deposit">
                            {detail?.account_number || "-"}
                          </span>
                        </div>
                        <CopyToClipboard
                          onCopy={() => toast.success("Copied!")}
                          text={detail?.account_number || "-"}
                        >
                          <FaRegCopy />
                        </CopyToClipboard>
                      </div>{" "}
                      <div
                        style={{ fontSize: "14px" }}
                        className="d-flex justify-content-between align-items-center mb-1"
                      >
                        <div>
                          <span>{t("IFSC")} : </span>{" "}
                          <span className="span-deposit">
                            {detail?.ifsc_code || "-"}
                          </span>
                        </div>
                        <CopyToClipboard
                          onCopy={() => toast.success("Copied!")}
                          text={detail?.ifsc_code || "-"}
                        >
                          <FaRegCopy />
                        </CopyToClipboard>
                      </div>{" "}
                      <div
                        style={{ fontSize: "14px" }}
                        className="d-flex justify-content-between align-items-center mb-1"
                      >
                        <div>
                          <span>{t("Account_Name")} : </span>{" "}
                          <span className="span-deposit">
                            {detail?.account_name || "-"}
                          </span>
                        </div>
                        <CopyToClipboard
                          onCopy={() => toast.success("Copied!")}
                          text={detail?.account_name || "-"}
                        >
                          <FaRegCopy />
                        </CopyToClipboard>
                      </div>{" "}
                      <div
                        style={{ fontSize: "14px" }}
                        className="d-flex justify-content-between align-items-center mb-1"
                      >
                        <div>
                          <span>{t("Bank_Name")} : </span>{" "}
                          <span className="span-deposit">
                            {detail?.bank_name || "-"}
                          </span>
                        </div>
                        <CopyToClipboard
                          onCopy={() => toast.success("Copied!")}
                          text={detail?.bank_name || "-"}
                        >
                          <FaRegCopy />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}
                </div>
                {!isEmpty(watch("bankAccount")) && (
                  <>
                    <div class="usrTrans-seperate bankInfoField bankInfo">
                      <div class="transaction-title">
                        <span>{t("Account_Name")}</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                          {...register("accountName")}
                          class="text-input"
                          id="depositAccName"
                          disabled
                        />
                      </div>
                    </div>

                    <div
                      class="usrTrans-seperate bankInfoField bankInfo "
                      style={{ position: "relative", width: `100%` }}
                    >
                      <div class="transaction-title">
                        <span>{t("Account_UPI")}</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                          {...register("bankAccount")}
                          disabled
                          class="text-input"
                          id="depositAccNo"
                        />
                        <div
                          className="input-group-btn"
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "-10",
                            bottom: "0px",
                            filter: `invert(1)`,
                          }}
                        >
                          <span
                            className="btn btn-outline-primary"
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              padding: "8px",
                              paddingTop: "0px",
                              height: "72%",
                            }}
                          >
                            <CopyToClipboard
                              text={getValues("bankAccount")}
                              onCopy={() => setCopied(true)}
                            >
                              <Button
                                className="copy-btn"
                                style={{
                                  fontSize: `13px`,
                                  padding: `5px`,
                                  backgroundColor: `#005dac`,
                                  color: `#fff`,
                                }}
                              >
                                {isCopied ? "Copied!" : "Copy"}
                              </Button>
                            </CopyToClipboard>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {detail?.depositType !== "bankTransfer" && (
                  <div
                    style={{
                      background: "white",
                    }}
                    class="member-menu-box member-list select-group checkbox-style "
                  >
                    <div class="title mb-3">
                      <h2 className="mb-0">
                        <span>{t("Pay_Using")}</span>{" "}
                        <span class="important-icon">*</span>
                      </h2>
                    </div>
                    <div className="inner-deposit">
                      <div>
                        <img src={PhonePe} />
                        <span>{t("Phone_Pe")}</span>
                      </div>{" "}
                      <div>
                        <img src={GooglePay} />
                        <span>{t("Google_Pay")}</span>
                      </div>{" "}
                      <div>
                        <img src={PayTm} />
                        <span>{t("Payment")}</span>
                      </div>{" "}
                      <div>
                        <img src={Bhim} />
                        <span>{t("BHIM")}</span>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    background: "white",
                  }}
                  class="member-menu-box member-list select-group checkbox-style "
                >
                  <div class="title mb-0">
                    <h2 className="mb-0">
                      <span>{t("Amount")}</span>{" "}
                      <span class="important-icon">*</span>
                      <i>300.00 - 50,000.00</i>
                    </h2>
                  </div>
                  <div class="deposit-amount">
                    <div class="transaction-option m-auto">
                      <input
                        style={{
                          borderRadius: "0",
                          border: "1px solid black",
                        }}
                        {...register("amount", {
                          required: {
                            message: t("Please_amount"),
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: t("Please_number"),
                          },
                          validate: (value) => {
                            if (value < 300) {
                              return t("Min_Deposit_Amount");
                            } else if (value > 50000) {
                              return t("Max_Deposit_Amount");
                            } else {
                              return true;
                            }
                          },
                        })}
                        type="text"
                        class="text-input"
                        id="depositAmt"
                        placeholder="0.00"
                        onClick={() => resetPrice()}
                      />
                    </div>
                    {errors?.amount?.message && (
                      <div class="transaction-errMsg text-danger depositMsg mb-1">
                        {errors?.amount?.message}
                      </div>
                    )}
                  </div>
                  <div class="active">
                    <ul class="col4">
                      {AmountArray?.map((item, key) => {
                        return (
                          <li
                            style={{ cursor: "pointer" }}
                            onChange={() =>
                              priceCalculate(item?.amount, watch("amount"))
                            }
                          >
                            <input type="radio" name="depositAmount" />
                            <label>
                              <span> {"+" + item?.amount} </span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* <div class="input-group money">
                      <div class="input-wrap ">
                        <input
                          type="text"
                          class=" ng-untouched ng-pristine ng-valid"
                          placeholder="0.00"
                          disabled=""
                        />
                        <a class="delete-btn red"></a>
                      </div>
                    </div> */}
                </div>

                <div
                  style={{
                    background: "white",
                  }}
                  class="usrTrans-seperate default-type"
                >
                  <div
                    class="transaction-title"
                    style={{ marginBottom: "10px" }}
                  >
                    <span>{`${t("UploadPaymount")} *`}</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <div class="fileupload-box bg-gradient-secondary">
                      <span style={{ fontSize: "11px" }}>
                        {t("UploadFile")}
                      </span>
                      <input
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        type="file"
                        class="fileInput"
                        accept="image/png, image/jiffy, image/jpeg, image/jpg"
                        id="depositReceipt"
                        name="depositReceipt"
                      />
                    </div>
                    {image !== "" && (
                      <button
                        onClick={() => setImage("")}
                        class="btn-remove removeReceipt bg-gradient-third"
                      >
                        {t("Remove_Image")}
                      </button>
                    )}
                  </div>
                  {errors?.image?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.image?.message}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    background: "white",
                  }}
                  class="usrTrans-seperate default-type"
                >
                  <div class="transaction-title">
                    <span>{`${t("UniqueRefrence")} *`}</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("transactionId", {
                        required: {
                          value: true,
                          message: t("Transaction_ID"),
                        },
                        maxLength: {
                          value: 50,
                          message: t("Transaction_characters"),
                        },
                      })}
                      style={{
                        borderRadius: "0",
                        border: "1px solid black",
                      }}
                      placeholder={t("Utr")}
                      class="text-input"
                      id="depositReferId"
                    />
                  </div>
                  {errors?.transactionId?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.transactionId?.message}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    background: "white",
                  }}
                  class="usrTrans-seperate default-type"
                >
                  <div class="transaction-title mb-2">
                    <span>{`${t("Available_Offers")} *`}</span>
                  </div>
                  <div
                    onClick={() => setOffer("first")}
                    className={`offer-tab mb-2 ${
                      offer == "first" && "offer-tab-active"
                    }`}
                  >
                    <div>
                      <p style={{ color: "grey", fontSize: "14px" }}>
                        {t("Offer1")}
                      </p>{" "}
                      <p style={{ fontSize: "13px" }}>{t("FirstDeposit")}</p>{" "}
                      <p
                        onClick={() => setShow(true)}
                        style={{ textDecoration: "underline" }}
                      >
                        ( {t("TC")} )
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => setOffer("second")}
                    className={`offer-tab ${
                      offer == "second" && "offer-tab-active"
                    }`}
                  >
                    <div>
                      <p style={{ color: "grey", fontSize: "14px" }}>
                        {t("Offer2")}
                      </p>{" "}
                      <p style={{ fontSize: "13px" }}>{t("EvertDepsoti")}</p>{" "}
                      <p
                        onClick={() => setShow(true)}
                        style={{ textDecoration: "underline" }}
                      >
                        ( {t("TC")} )
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "white",
                  }}
                  className="d-flex w-100 justify-content-start mt-2 p-2"
                >
                  <input
                    checked={checkbox}
                    onClick={(e) => setCheckBox(e.target.checked)}
                    type="checkbox"
                    style={{ marginRight: "10px", width: "22px" }}
                  />
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {t("Agree_with")}&nbsp;
                    <span>{t("payment_policy")}</span>
                  </span>
                </div>

                <div
                  style={{
                    background: "white",
                  }}
                  class="transaction-option p-2"
                >
                  <div class="transaction-btn">
                    <button
                      type="submit"
                      disabled={isLoader}
                      className="btn-submit bg-gradient-primary text-capitalize"
                    >
                      {isLoader ? t("Loading") : t("Submit")}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <DepositInfo
        show={show}
        offer={offer == "second" ? "5%" : "10%"}
        onHide={() => setShow(false)}
      />
    </SidebarLayout>
  );
};

export default Deposit;
