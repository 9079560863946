import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Layout from "../pages/Layout";
import { isEmpty } from "lodash";
import { Col } from "react-bootstrap";
import Sidebar from "./Sidebar";
const SidebarLayout = ({ heading, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "location");
  return (
    <div>
      <Layout>
        <Col
          md={["/deposit", "/withdraw"].includes(location?.pathname) ? 9 : 12}
        >
          {!isEmpty(heading) && (
            <div className="balance-label position-relative">
              {heading}
              <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
                <RxCross2
                  color="black"
                  className="fs-3"
                  style={{ fontWeight: "800" }}
                  onClick={() => navigate("/")}
                />
              </button>
            </div>
          )}
          {children}
        </Col>
        {["/deposit", "/withdraw"].includes(location?.pathname) && (
          <Col md={3} className="p-0">
            {" "}
            <Sidebar />
          </Col>
        )}
      </Layout>
    </div>
  );
};

export default SidebarLayout;
