import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const OpenBets = ({ data }) => {
  const { t } = useTranslation();
  const params = useParams();
  let condition = !isEmpty(params?.eventId)
    ? data?.filter((res) => {
        return res?.eventId == params?.eventId;
      })
    : data;
  return (
    <div className="leagueBox mb-3">
      <div
        className="title mb-1"
        style={{
          padding: "5px 8px",
          backgroundImage: "linear-gradient(133deg, #ffb80c, #ffd97b)",
          fontWeight: "600",
        }}
      >
        <Row>
          <Col className="text-dark " xs={7}>
            {t("OpenBets")}
          </Col>

          <Col xs={5} className="text-center">
            <Row>
              <Col className="text-center text-dark px-0">{t("Odds")}</Col>
              <Col className="text-center text-dark  px-0">{t("Stake")}</Col>
            </Row>
          </Col>
        </Row>
      </div>

      {condition?.length > 0 ? (
        condition?.map((item, index) => {
          if (
            item?.sessionBetList?.length > 0 ||
            item?.bookmakerList?.length > 0 ||
            item?.betList?.length > 0
          ) {
            return [
              ...item?.sessionBetList,
              ...item?.bookmakerList,
              ...item?.betList,
            ]?.map((res) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid #ddd",
                    background: "white",
                    padding: "5px 5px",
                    // fontWeight: "600",
                    fontSize: "12px",
                  }}
                  className="laegueRow"
                >
                  <Row>
                    <Col
                      xs={7}
                      className="d-flex align-items-center text-black"
                    >
                      <Button
                        style={{
                          background:
                            res?.type == "No" || res?.betType == "lay"
                              ? "#faa9ba"
                              : "#89c6f1",
                          padding: "2px 5px",
                          color: "black",
                          border: "none",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        {res?.type || startCase(res?.betType)}
                      </Button>{" "}
                      <div className="d-flex flex-column align-items-start">
                        <span style={{ fontWeight: "600" }}>
                          {res?.runnerName || res?.teamName || res?.fancyName}
                        </span>
                        {isEmpty(params?.eventId) && (
                          <span style={{ fontSize: "9px" }}>
                            {item?.eventName}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col xs={5} className="text-center text-black px-0">
                      <Row>
                        <Col className="text-center text-black">
                          {" "}
                          {!isEmpty(res?.fancyName)
                            ? `${res?.betRun} / ${res?.bhav}`
                            : res?.bhav}
                        </Col>
                        <Col className="text-center text-black">
                          {res?.amount}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            });
          }
        })
      ) : (
        <div
          style={{
            // borderBottom: "1px solid #ddd",
            // background: "black",
            padding: "5px 5px",
            // fontWeight: "600",
            fontSize: "12px",
            color: "black",
            display: "flex",
            justifyContent: "center",
            fontSize: "13px",
          }}
          // className="laegueRow"
        >
          No Bet Found
        </div>
      )}
    </div>
  );
};

export default OpenBets;
