import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import objConstant from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
import { IoIosArrowForward } from "react-icons/io";
import SidebarLayout from "../component/SidebarLayout";
import ProfileLayout from "./ProfileLayout";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
const AccountStatement = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.accountStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  const getDataCasino = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.casionStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };

  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <Container className="p-0">
        <div
          className="balance-label position-relative"
          style={{ paddingLeft: "10px" }}
        >
          Bets History
          <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
            <RxCross2
              className="fs-3"
              style={{ fontWeight: "800", color: "black" }}
              onClick={() => navigate("/")}
            />
          </button>
        </div>
        <div className="inner-sidebar-content d-flex flex-column px-2">
          <div className="balance-sec mb-2" style={{ borderRadius: "0" }}>
            <h5>Your Balances</h5>
            <h6 className="fs-4">
              {" "}
              <span>INR</span> {userCoins?.balance?.toFixed(2)}
            </h6>
          </div>
          {/* <Button
          style={{ background: "#18b0c8", color: "black", border: "none" }}
          className="mb-3"
          onClick={() => {
            getDataCasino({ page: 1, limit: 10 }, "");
            setFilter({ page: 1, limit: 10 });
          }}
        >
          International Casino Transaction
        </Button> */}

          {/* <div className="pt-0 inner-sidebar-content"> */}
            <Table>
              <thead>
                <tr>
                  <th>
                    Date
                    {/* {obj.dateFormat(
                              res?.createdAt || res?.timeInserted
                            )} */}
                  </th>
                  <th>Credit</th> <th>Debit</th>
                  <th>Balance</th> <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {listing?.length > 0 ? (
                  listing?.map((res) => {
                    return (
                      <tr>
                        <td>
                          {" "}
                          {obj.dateFormat(
                            res?.createdAt || res?.timeInserted
                          )}{" "}
                        </td>
                        <td>
                          {" "}
                          <strong
                            className={`d-block ${
                              res?.transactionType === "credit" ||
                              res?.amount >= 0
                                ? "text-success"
                                : ""
                            }`}
                          >
                            {res?.transactionType === "credit"
                              ? res?.amount?.toFixed(2)
                              : `-`}
                          </strong>
                        </td>
                        <td>
                          {" "}
                          <strong
                            className={`d-block ${
                              res?.transactionType === "credit" ||
                              res?.amount >= 0
                                ? ""
                                : "text-danger"
                            }`}
                          >
                            {res?.transactionType === "credit"
                              ? "-"
                              : `(${res?.amount?.toFixed(2)})`}
                          </strong>
                        </td>
                        <td>
                          {" "}
                          <strong className="d-block">
                            {res?.newBalance[0]?.toFixed(2)}
                          </strong>
                        </td>
                        <td> {res?.remark || "-"}</td>
                        {/* {!isEmpty(res?.casinoBetId) ? (
                            <>
                              <td width="50%">
                                Casino Bet Id
                                <strong className="d-block">
                                  {res?.casinoBetId}
                                </strong>
                              </td>
                              <td width="50%">
                                Client
                                <strong className="d-block">
                                  {res?.clientName}
                                </strong>
                              </td>
                            </>
                          ) : (
                            <>
                              <td width="50%">
                                {res?.forBet == 1
                                  ? res?.amount >= 0
                                    ? "Credits"
                                    : "Debits"
                                  : res?.transactionType}
                                <strong
                                  className={`d-block ${
                                    res?.transactionType === "credit" ||
                                    res?.amount >= 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {res?.transactionType === "credit"
                                    ? res?.amount?.toFixed(2)
                                    : `(${res?.amount?.toFixed(2)})`}
                                </strong>
                              </td>
                              <td width="50%">
                                Balance{" "}
                                <strong className="d-block">
                                  {res?.newBalance[0]?.toFixed(2)}
                                </strong>
                              </td>
                            </>
                          )} */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <span style={{ fontSize: "13px" }}>
                        No Record Found.
                      </span>
                    </td>
                  </tr>
                )}
                {/* {!isEmpty(res?.remark) && (
                          <tr>
                            <td
                              style={{ fontSize: "14px" }}
                              width="50%"
                              colSpan="4"
                            >
                              {"Remark"}
                              <strong className="d-block">
                                {res?.remark || ""}
                              </strong>

                            </td>
                          </tr>
                        )} */}
              </tbody>
            </Table>
          {/* </div> */}

          {data?.hasNextPage && (
            <div className="w-100 d-flex justify-content-center align-items-center mb-4">
              <Button
                onClick={handel}
                style={{ background: "black", border: "none" }}
              >
                View More <IoIosArrowForward />
              </Button>
            </div>
          )}
        </div>
      </Container>
    </ProfileLayout>
  );
};

export default AccountStatement;
