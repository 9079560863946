import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty, startCase } from "lodash";
import Layout from "./Layout";
import SidebarLayout from "../component/SidebarLayout";
import ProfileLayout from "./ProfileLayout";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
const WithdrawHistory = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.withDrawHistory, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results]);
        } else {
          setListing(data?.results);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [disable, setDisable] = useState(false);
  const cancelRequest = async (id) => {
    setDisable(true);
    try {
      const { status, data } = await apiPost(apiPath.cancelRequest, {
        requestId: id,
      });
      if (status == 200) {
        if (data?.success) {
          setDisable(false);
          setData(data?.results);
        } else {
          setDisable(false);
        }
      } else {
        setDisable(false);
      }
    } catch (error) {
      setDisable(false);
    }
  };

  const navigate = useNavigate()
  return (
    <ProfileLayout>    <div className="balance-label position-relative" >
            WithDraw History
            <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
              <RxCross2
                className="fs-3"
                style={{ fontWeight: "800", color: "black" }}
                onClick={() => navigate("/")}
              />
            </button>
          </div>
          <div className="p-3 pt-1 inner-sidebar-content d-flex flex-column ">
        <div className="balance-deposit-sec">
          {/* <Table bordered className=" overflow-hidden bg-white">
            {listing?.length > 0
              ? listing?.map((res) => {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="4">{obj.dateFormat(res?.createdAt)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="50%">
                            Withdraw Type
                            <strong className={`d-block`}>
                              {res.transactionType}
                            </strong>
                          </td>
                          <td width="50%">
                            Bank
                            <strong className={`d-block`}>
                              {res?.bank_name || res.bank}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            Amount
                            <strong className={`d-block`}>{res.amount}</strong>
                          </td>
                          <td width="50%">
                            Status
                            <strong className={`d-block`}>
                              <span
                                className={
                                  res.status == "completed"
                                    ? "text-success"
                                    : res.status == "declined"
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                {startCase(res.status)}
                              </span>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={res?.status == "requested" ? "" : 2}
                            width={res?.status == "requested" ? `50%` : "100%"}
                          >
                            Remark
                            <strong className={`d-block`}>{res.remark}</strong>
                          </td>
                          {res?.status == "requested" && (
                            <td width="50%">
                              <Button
                                disabled={disable}
                                onClick={() => cancelRequest(res?._id)}
                                className="m-1 p-2 btn-submit bg-gradient-primary text-capitalize"
                              >
                                Cancel Request
                              </Button>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </>
                  );
                })
              : "No record Found"}
          </Table> */}




          <Table>
            <thead>
              <tr>
                <th scope="col">Date</th>
                {/* <th scope="col">Withdraw Type</th> */}
                <th scope="col">Bank</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Remark</th>  <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {listing?.length > 0 ? (
                listing?.map((res) => {
                  return (
                    <tr>
                    
                      <td> {obj.dateFormat(res?.createdAt)}</td>
                      {/* <td>{res.transactionType || '-'}</td> */}
                      <td> {res?.BankAccount || res.bank}</td>{" "}
                      <td>{res.amount}</td>
                      <td>
                        {" "}
                        <span
                          className={
                            res.status == "completed"
                              ? "text-success"
                              : res.status == "declined"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {startCase(res.status)}
                        </span>
                      </td>
                      <td>{res.remark || "-"}</td>
                      <td> {res?.status == "requested" && (
                            <td width="50%">
                              <Button
                                disabled={disable}
                                onClick={() => cancelRequest(res?._id)}
                                className="m-1 p-2 btn-submit bg-gradient-primary text-capitalize"
                              >
                                Cancel Request
                              </Button>
                            </td>
                          )}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span style={{ fontSize: "13px" }}>No Record Found</span>
                  </td>
                </tr>
              )}{" "}
            </tbody>
          </Table>
        </div>
      </div>{" "}
    </ProfileLayout>
  );
};

export default WithdrawHistory;
