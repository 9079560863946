import { render, screen } from "@testing-library/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IoIosArrowBack } from "react-icons/io";
import loginImg from "../img/login-img.png";
import logo from "../img/logoMain.webp";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
function Login() {

  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();
  let { loginUser, user } = useContext(AuthContext);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    unregister,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  const onSubmit = (data) => {
    loginUser(data);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      navigate("/");
    }
  }, [user]);

  const [getValidateCode, setValidateCode] = useState("");
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };

  useEffect(() => {
    changeCode();
  }, []);
  
  return (
    <>
      <div className="login">
        <div
          style={{ top: "1%", left: "1%" }}
          className="d-flex position-absolute align-items-center justify-content-start"
        >
          <div
            className="left-arrow-icon d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>
        <Container className="h-100 align-middle">
          <Row className="h-100 align-items-center align-middle justify-content-center">
            <Col md={11}>
              <Row>
                <Col md={7} className="text-start">
                  <img
                    data-aos="zoom-in-down"
                    src={loginImg}
                    style={{ width: "80%" }}
                  />
                </Col>
                <Col md={5}>
                  <div data-aos="flip-up" className="text-center mb-4">
                    <img src={logo} style={{ width: "220px" }} />
                  </div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div data-aos="zoom-in" className="loginForm">
                      <div className="d-flex flex-column align-items-center mb-4">
                        <h5 className="m-0">Welcome</h5>
                        <span>Login in to you account to continue</span>
                      </div>
                      <Form.Label>Username</Form.Label>{" "}
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          className={errors.username ? "is-invalid" : ""}
                          {...register("username", {
                            required: "Please enter Username",
                          })}
                          id="username"
                        />{" "}
                        {errors.username && errors.username.message && (
                          <label className="invalid-feedback text-left">
                            {errors.username.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPassword ? "text" : "password"}
                          placeholder="Password"
                          maxLength={15}
                          {...register("password", {
                            required: "Please enter password",
                          })}
                          className={errors.password ? "is-invalid" : ""}
                        />
                        <button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShow()}
                        >
                          {!showPassword ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </button>
                        {/* </FloatingLabel> */}
                        {errors.password && errors.password.message && (
                          <label className="invalid-feedback text-left">
                            {errors.password.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Label>ValidationCode</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type="number"
                          keyboardType="numeric"
                          autocomplete="off"
                          className={`login-input ${
                            !isEmpty(watch("validateCode"))
                              ? "login-input-border"
                              : ""
                          }`}
                          maxLength="4"
                          {...register("validateCode", {
                            required: "Please enter validate code",
                            validate: {
                              validate: (value) =>
                                parseInt(value) === parseInt(getValidateCode) ||
                                "Invalid validate code",
                            },
                          })}
                          style={
                            errors.validateCode
                              ? {
                                  borderColor: "red",
                                }
                              : {}
                          }
                          onChange={(e) => {
                            if (e.target.value.length == 4) {
                              e.target.blur();
                              unregister("validateCode", { keepValue: true });
                            }
                          }}
                          placeholder="ValidationCode"
                        />
                        <div
                          style={{ left: "10px" }}
                          className="position-absolute top-0 flex items-center h-40px ml-2 space-x-3px start-icon"
                        >
                          {" "}
                          <span
                            className={`${
                              !isEmpty(watch("validateCode"))
                                ? "icon-content-selected"
                                : ""
                            } position-absolute  translate-middle-y top-50 icon-content text-18 text-black-400 top-3 left-3 z-10 icon-shield`}
                          >
                            <i class="icon-flag-bangladesh path1 z-10 text-18"></i>
                          </span>
                        </div>
                        <canvas
                          ref={canvasRef}
                          onClick={changeCode}
                          className="position-absolute translate-middle-y top-50 canvas-img"
                          id="authenticateImage"
                        />
                        {errors.validateCode &&
                          errors?.validateCode?.message && (
                            <label className="invalid-feedback text-left">
                              {errors.validateCode.message}
                            </label>
                          )}
                      </Form.Group>

              
                      {" "}
                      <Link
                        to="/ForgotPassword"
                        style={{ color: "black",fontSize:"13px" }}
                      >
                        Forgot Password?
                      </Link>
                   
                      <Button type="submit" variant="primary" className="mt-1">
                        Login
                      </Button>
                      <Button
                        onClick={() =>
                          loginUser({
                            username: "democheck",
                            password: "",
                            uniqueId: Math.random() * 10000,
                          })
                        }
                        style={{
                          backgroundImage: "linear-gradient(#ffe69d,#af9955)",
                          border: "none",
                          borderRadius: "3px",
                          fontSize: "14px",
                          color: "#151515",
                          fontWeight: "600",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        Login as Demo
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
