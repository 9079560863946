import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { apiGet, apiPost } from "../Utils/apiFetch";
import { pick, isEmpty } from "lodash";
import apiPath from "../Utils/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { io } from "socket.io-client";
import moment from "moment";
import { toast } from "react-toastify";
import i18n from "../i18n";
const AuthContext = createContext();
export default AuthContext;
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    status: false,
    message: "",
    errorType: "",
  });
  const [languageShow, setLanguageShow] = useState(false);
  const [rightTab, setRightTab] = useState("betslip");
  const [loaded, setLoaded] = useState(true);
  const [parly, setParly] = useState(false);
  const [parlyBet, setParlyBet] = useState([]);
  const [showParlyBet, setShowParlyBet] = useState(false);
  const [casinoUrl, setCasinoUrl] = useState("");
  const [sokcetId, setSocketId] = useState({});
  const [scoreDetail, setScoreDetail] = useState("");
  const [active, setActive] = useState("sports");
  const [searchTab, setSearchTab] = useState(false);
  const [userCoins, setUserCoins] = useState({});
  let [user, setUser] = useState({});
  const [announcement, setAnnouncement] = useState(false);
  const [showWidget, setShowWidget] = useState(true);
  const [activeWidget, setActiveWidget] = useState("");
  const [scrollHeight, setScrollHeight] = useState(75);
  const [footerLink, setFooterLink] = useState({
    status: false,
    type: "",
  });
  const [showDate, setShowDate] = useState({
    status: false,
    type: "",
  });
  let today = new Date();
  let days = 86400000;
  let fiveDaysAgo = new Date(today - 7 * days);
  const [plDate, setPlDate] = useState([
    {
      startDate: fiveDaysAgo,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [messagelist, setMessageList] = useState([]);
  const [refreshAmountLoader, setRefreshAmountLoader] = useState(false);
  const [plDateType, setPlDateType] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [loginError, setLoginError] = useState("");
  const [keyTime, setKeyTime] = useState("in_play");
  const [gameTab, setGameTab] = useState("cricket");
  const [profileData, setProfileData] = useState({});
  const [sportMarket, setSportMarket] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [blockList, setBlockList] = useState([]);
  const [afterLogin, setAfterLogin] = useState(false);
  const [showPosition, setShowPosition] = useState({
    status: false,
    object: {},
  });
  const [checkRefresh, setCheckRefresh] = useState(true);
  const location = useLocation();
  const [socketObj, setSocketObj] = useState(null);

  // language start

  const [lang, setLang] = useState("en");
  const handleRouteChange = async () => {
    let lang = "en";
    const language_dir = localStorage.getItem("language_dir");
    if (!isEmpty(language_dir)) {
      lang = language_dir;
    }
    i18n.changeLanguage(lang);
    setLang(language_dir);
  };

  const changeLanguage = (type) => {
    localStorage.setItem("language_dir", type);
    i18n.changeLanguage(type);
    setLang(type);
    window.location.reload();
  };

  useEffect(() => {
    handleRouteChange();
  }, []);

  // language end

  useEffect(() => {
    if (localStorage.getItem("parly") == "true") {
      setParly(true);
    } else {
      setParly(false);
    }
  }, []);

  const updateParly = (value) => {
    localStorage.setItem("parly", value);
    setParly(value);
  };
  useEffect(() => {
    if (
      location?.pathname?.split("/")[1] !== "match-details" &&
      !isEmpty(sokcetId)
    ) {
      sokcetId.disconnect();
      setSocketId({});
    }
  }, [location]);
  useEffect(() => {
    if (secureLocalStorage.getItem("parlyBet")?.length > 0) {
      setParlyBet(secureLocalStorage.getItem("parlyBet"));
    }
  }, []);

  const [invalidCount, setInvalidCount] = useState(0);
  const [invalidCountTimer, setInvalidCountTimer] = useState({
    status: false,
  });
  const [call, setCall] = useState(false);
  useEffect(() => {
    if (
      !isEmpty(localStorage.getItem("invalidCount")) &&
      localStorage.getItem("invalidCount") > 0
    ) {
      setInvalidCount(Number(localStorage.getItem("invalidCount")));
    }
    let obj = !isEmpty(localStorage?.getItem("invalidCountTime"))
      ? JSON.parse(localStorage?.getItem("invalidCountTime"))
      : {};
    if (!isEmpty(obj) && obj?.status) {
      setInvalidCountTimer(obj);
    }
  }, []);

  const handelTimeCheck = (obj) => {
    if (
      moment(new Date()).isAfter(
        moment(obj?.time).add(Number(obj?.length), "minutes")
      )
    ) {
      setInvalidCountTimer({
        ...invalidCountTimer,
        status: false,
        isCompleted: true,
      });
      localStorage.setItem(
        "invalidCountTime",
        JSON.stringify({
          ...invalidCountTimer,
          status: false,
          isCompleted: true,
        })
      );
      return {
        ...invalidCountTimer,
        status: false,
        isCompleted: true,
      };
    } else {
      return invalidCountTimer;
    }
  };

  const handelTimerFunc = () => {
    if (invalidCount > 0) {
      let count = invalidCount + 1;
      let obj = handelTimeCheck(invalidCountTimer);
      if (count === 3) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 6) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 15,
              next: 6,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 15,
                next: 6,
              })
            );
          }
        }
        // toast.error("Invalid Password your account is blocked for 15 min");
      } else if (count == 6) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 6) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 60,
              next: 12,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 60,
                next: 12,
              })
            );
          }
        }
        // toast.error("Invalid Password your account is blocked for 1 hr");
      } else if (count == 9) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 12) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 1440,
              next: 24,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 1440,
                next: 24,
              })
            );
          }
        }
        // toast.error("Invalid Password your account is blocked for 24 hr");
      } else {
        if (!obj?.status) {
          setInvalidCount(count);
          localStorage.setItem("invalidCount", count);
          // toast.error("Invalid password.");
        }
      }
    } else {
      setInvalidCount(1);
      localStorage.setItem("invalidCount", 1);
      // toast.error("Invalid password.");
    }
    setCall(false);
  };

  useEffect(() => {
    if (call) {
      handelTimerFunc();
    }
  }, [call]);

  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    secureLocalStorage.clear();
    navigate("/login");
    setShowSidebar(false);
  };

  useEffect(() => {
    if (secureLocalStorage.getItem("parlyBet")?.length > 0) {
      setParlyBet(secureLocalStorage.getItem("parlyBet"));
    }
  }, []);

  // Socket
  const [checkRefreshScore, setCheckRefreshScore] = useState(true);
  const [socketObjScore, setSocketObjScore] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const [cricketScore, setCricketScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };

  const getSocket = () => {
    if (checkRefreshScore) {
      if (!isEmpty(socketObjScore)) {
        socketObjScore.disconnect();
      }
      setSocketObjScore(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObjScore(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefreshScore(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (
      checkRefreshScore &&
      ["sports", "full-market"].includes(location?.pathname?.split("/")[1])
    ) {
      getSocket();
    }
  }, [checkRefreshScore, location?.pathname]);

  document.addEventListener("visibilitychange", function () {
    if (["sports", "full-market"].includes(location?.pathname?.split("/")[1])) {
      if (!document.hidden && !checkRefreshScore) {
        setCheckRefreshScore(true);
        if (!isEmpty(socketObjScore)) {
          socketObjScore.disconnect();
        }
      } else {
        setCheckRefreshScore(false);
      }
    }
  });

  useEffect(() => {
    if (
      !["sports", "full-market"].includes(location?.pathname?.split("/")[1])
    ) {
      if (!isEmpty(socketObjScore)) {
        socketObjScore.disconnect();
      }
    } else {
      if (!socketObjScore?.connected) {
        setCheckRefreshScore(true);
      }
    }
  }, [location?.pathname]);

  const messageList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.messageList);
    if (status === 200) {
      if (response_users.success) {
        setMessageList(response_users.results);
      }
    }
  };

  const [currentBetCount, setCurrentBetCount] = useState(0);
  const getCurrentBetCount = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.currentBetsCount
    );
    if (status === 200) {
      if (response_users.success) {
        setCurrentBetCount(response_users.results?.total);
      }
    }
  };

  useEffect(() => {
    if (showSidebar) {
      setShowSidebar(false);
    }
    if (location?.pathname?.split("/")[1] !== "full-market") {
      secureLocalStorage.removeItem("betFair");
      secureLocalStorage.removeItem("bookmaker");
      secureLocalStorage.removeItem("fancy");
      secureLocalStorage.removeItem("tie");
      secureLocalStorage.removeItem("premiumFancy");
      setShowWidget(true);
      setActiveWidget("");
    }
    if (location?.pathname?.split("/")[1] !== "profit-and-loss") {
      setShowDate({
        status: false,
        type: "",
      });
      setPlDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [location]);

  let getProfileData = async () => {
    const data = await apiGet(apiPath.userProfile);
    if (data?.status == 200) {
      if (data?.data?.success) {
        if (data?.data?.results?.userSportMarket?.length > 0) {
          setSportMarket(
            data?.data?.results?.userSportMarket?.map((res) => {
              return res?.betfairId;
            })
          );
        }

        if (data?.data?.results?.marketBlockList?.length > 0) {
          setBlockList(
            data?.data?.results?.marketBlockList?.map((res) => {
              return res?.eventId;
            })
          );
        }
        setProfileData(data?.data?.results);
        setUserCoins({
          exp: data?.data?.results?.exposure,
          balance: data?.data?.results?.totalCoins,
        });
        setTotalCount(data?.data?.results?.totalUnreadCount);
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      let timer = setInterval(() => {
        getProfileData();
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [user]);
  const [resetPassword, setResetPassword] = useState({
    status: true,
    detail: {},
  });
  let loginUser = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    body.website = hostname || "SABAEXCH";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );
    if (status === 200) {
      if (data.success) {
        setLoginError("");

        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        setUser(jwt_decode(data.results.token));
        setAfterLogin(true);
      } else {
        toast.error(data?.message);
        setLoginError(data.message);
      }
    }
  };
  const registerUser = async (body) => {
    try {
      const { status, data: response_users1 } = await apiPost(
        apiPath.registerUser,
        {
          username: body?.username,
          password: body?.password,
          phone_number: body?.number,
          refreralCode: body?.refreralCode || "",
          uniqueId: body?.uniqueId,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          const language_dir = localStorage.getItem("language_dir");
          if (isEmpty(language_dir)) {
            setLanguageShow(true);
          }
          localStorage.setItem("token", response_users1?.results?.token);
          localStorage.setItem(
            "refresh_token",
            response_users1?.results?.refresh_token
          );
          setUser(jwt_decode(response_users1?.results.token));
          navigate("/");
        } else {
          toast.error(response_users1?.message);
        }
      } else {
        toast.error(response_users1?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const amounutRefreshApi = async () => {
    setRefreshAmountLoader(true);
    const { status, data } = await apiGet(apiPath.refreshAmount);
    if (status === 200) {
      if (data.success) {
        setUserCoins({
          exp: data?.results?.exposure,
          balance: data?.results?.totalCoins,
        });
        setRefreshAmountLoader(false);
      }
    }
    setRefreshAmountLoader(false);
  };

  useEffect(() => {
    let data = localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : "";
    setUser(data);
  }, []);

  useEffect(() => {
    if (!isEmpty(user) && checkRefresh) {
      amounutRefreshApi();
      amounutRefresh();
    }
  }, [checkRefresh, user]);

  // useEffect(() => {
  //   if (!isEmpty(user)) {
  //     let interval = setInterval(() => {
  //       amounutRefreshApi();
  //     }, 30000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      getProfileData();
      messageList();
    }
  }, [user]);
  useEffect(() => {
    if (
      location?.pathname.split("/").includes("bkash") &&
      !isEmpty(location?.pathname?.split("/")[2])
    ) {
      localStorage.setItem("token", location?.pathname?.split("/")[2]);
      navigate("/");
      window.location.reload();
    }
    setCasinoUrl("");
    setCasinoUrlHome("");
  }, [location?.pathname]);

  const amounutRefresh = () => {
    setRefreshAmountLoader(true);
    setCheckRefresh(false);
    if (!isEmpty(socketObj)) {
      socketObj.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${localStorage.getItem(
        "token"
      )}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins({
        exp: message?.results?.exposure,
        balance: message?.results?.totalCoins,
      });
      setRefreshAmountLoader(false);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setSocketObj(newSocket);
    newSocket.emit("getCoins", { user_id: user ? user?.user?._id : "" });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("newMessage", (inbox) => {
      console.log(inbox, user, "use");
      if (inbox?.receiver == user?.user?._id) {
        setTotalCount((prev) => {
          return prev + 1;
        });
      }
    });
    newSocket.on("disconnect", function () {
      setRefreshAmountLoader(true);
      setCheckRefresh(true);
    });
    return () => newSocket.disconnect();
  };

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && user && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    setCasinoUrl("");
    setCasinoUrlHome("");
  }, []);

  const [casinoUrlHome, setCasinoUrlHome] = useState("");
  const launchEGTCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.easytogoCasinoAmountAdd,
        {
          prod: request?.platForm,
          type: request?.gameType,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            apiPath.doLoginAndLaunchEasyToCasino,
            {
              prod: request?.platForm,
              type: request?.gameType,
              gameCode: request?.casinoType,
              gameid: request?.gameid || false,
            }
          );
          if (status === 200 && response_users.status) {
            if (response_users.data.err === 1) {
              localStorage.setItem("launchEGTCasino", request?.platForm);
              window.location.href = response_users.data.url;
              // setCasinoUrlHome(response_users.data.url);
            } else {
              setMessage({
                ...message,
                status: true,
                errorType: "P2P",
                message: response_users?.data?.desc,
              });
            }
          } else {
            setMessage({
              ...message,
              status: true,
              errorType: "P2P",
              message: response_users?.message,
            });
          }
        } else {
          // setMessage({
          //   ...message,
          //   status: true,
          //   errorType: "P2P",
          //   message: response_users1?.message,
          // });
          toast.error(response_users1?.message);
        }
      } else {
        toast.error(response_users1?.message);
      }
    }
    // document.getElementById("loading").style.display = "none";
  };

  const launchCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.casinoAmountAdd,
        { amount: 0, platForm: request?.platForm }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            request?.platForm && request?.gameType && request?.casinoType
              ? apiPath.doLoginAndLaunchGame
              : apiPath.casinoWalletLogin,
            request?.platForm && request?.gameType && request?.casinoType
              ? request?.table
                ? {
                    userId: response_users1?.results?.aeCasinoUserId,
                    platForm: request?.platForm == "1" ? "" : request?.platForm,
                    gameType: request?.gameType,
                    gameCode: request?.casinoType,
                    gameTableId: request?.table,
                    isLaunchGameTable: true,
                  }
                : {
                    userId: response_users1?.results?.aeCasinoUserId,
                    platForm: request?.platForm == "1" ? "" : request?.platForm,
                    gameType: request?.gameType,
                    gameCode: request?.casinoType,
                  }
              : {
                  userId: response_users1?.results?.aeCasinoUserId,
                  gameType: request?.gameType,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                }
          );
          if (status === 200) {
            if (response_users.status) {
              if (response_users.data.status === "0000") {
                window.location.href = response_users.data.url;
              } else {
                setMessage({
                  ...message,
                  status: true,
                  errorType: "P2P",
                  message: response_users?.data?.desc,
                });
              }
            }
          } else {
            setMessage({
              ...message,
              status: true,
              errorType: "P2P",
              message: response_users?.message,
            });
          }
        } else {
          // setMessage({
          //   ...message,
          //   status: true,
          //   errorType: "P2P",
          //   message: response_users1?.message,
          // });
          toast.error(response_users1?.message);
        }
      } else {
        // setMessage({
        //   ...message,
        //   status: true,
        //   errorType: "P2P",
        //   message: response_users1?.message,
        // });
        toast.error(response_users1?.message);
      }
    }
    // document.getElementById("loading").style.display = "none";
  };

  const dolaunchCasinoNew = async (request) => {
    if (request?.gameCode) {
      const { status, data: response_users1 } = await apiPost(
        request?.type == "first"
          ? apiPath.doLoginAndLaunchIQCasino
          : apiPath.doLoginAndLaunchEasyToCasinoNormal,
        {
          gameid: request?.gameCode,
          legacy: request?.legacy || "",
        }
      );
      if (status === 200) {
        if (response_users1.status) {
          window.location.href = response_users1.data;
        } else {
          toast.error(
            response_users1?.message == "success"
              ? "Casino is in maintenance mode if you have any query please contact upline"
              : response_users1?.message ||
                  "Casino is in maintenance mode if you have any query please contact upline"
          );
        }
      }
    }
  };
  const dolaunchCasinoRyoal = async (request) => {
    const { status, data: response_users1 } = await apiPost(
      apiPath.doLoginAndLaunchRoyaCasino,
      {
        game_code: request?.game_code,
        game_id: request?.game_id,
        game_name: request?.game_name,
        provider: request?.provider_name,
        category: request?.category,
        deviceType: "web",
        isLobby: request?.category == "LOBBY" ? true : false,
      }
    );
    if (status === 200) {
      if (response_users1.status) {
        window.location.href = response_users1.data;
      } else {
        toast.error(
          response_users1?.message == "success"
            ? "Casino is in maintenance mode if you have any query please contact upline"
            : response_users1?.message ||
                "Casino is in maintenance mode if you have any query please contact upline"
        );
      }
    }
  };
  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    showSidebar,
    setShowSidebar,
    handleShowSidebar,
    setGameTab,
    dolaunchCasinoNew,
    gameTab,
    keyTime,
    setKeyTime,
    loginError,
    profileData,
    setShowPosition,
    showPosition,
    userCoins,
    setUserCoins,
    amounutRefresh: amounutRefreshApi,
    scrollHeight,
    setScrollHeight,
    showDate,
    setShowDate,
    plDate,
    setPlDate,
    plDateType,
    setPlDateType,
    getProfileData,
    refreshAmountLoader,
    setSearchTab,
    searchTab,
    showWidget,
    setShowWidget,
    setActiveWidget,
    activeWidget,
    messagelist: messagelist,
    announcement,
    setAnnouncement,
    launchEGTCasino,
    launchCasino,
    active,
    setActive,
    setFooterLink,
    footerLink,
    setMessage,
    message,
    setParly: updateParly,
    parly,
    parlyBet,
    setParlyBet,
    showParlyBet,
    setShowParlyBet,
    getCurrentBetCount,
    currentBetCount,
    casinoUrl,
    setCasinoUrl,
    loaded,
    setLoaded,
    scoreDetail,
    setScoreDetail,
    casinoUrlHome,
    setCasinoUrlHome,
    sokcetId,
    setSocketId,
    resetPassword,
    setResetPassword,
    registerUser: registerUser,
    scoreAll: [...score, ...cricketScore],
    rightTab,
    setRightTab,
    totalCount,
    setTotalCount,
    afterLogin,
    setAfterLogin,
    changeLanguage,
    lang,
    languageShow,
    setLanguageShow,
    sportMarket,
    blockList,
    dolaunchCasinoRyoal,
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
