import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import SportsTab from "./SportsTab";
import { apiGet } from "../../../Utils/apiFetch";
import apiPath from "../../../Utils/apiPath";
import { isEmpty, startCase } from "lodash";
import obj from "../../../Utils/helpers";
import objConstant from "../../../Utils/constants";
import AuthContext from "../../../context/AuthContext";
import { BiSolidRightArrow } from "react-icons/bi";
import SidebarLayout from "../../../component/SidebarLayout";
import NoEvent from "../../../component/NoEvent";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Layout from "../../Layout";
import ProfileLayout from "../../ProfileLayout";
const CurrentBets = () => {
  const { user } = useContext(AuthContext);
  const [showBet, setShowBet] = useState("");
  const [exchange, setExchange] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [parly, setParly] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [tab, setTab] = useState("betfair");
  const [matched, setMatched] = useState(true);
  const navigate = useNavigate();
  const handleShowBet = (data) => {
    if (data == showBet) {
      setShowBet("");
    } else {
      setShowBet(data);
    }
  };
  const getExchangeBet = async () => {
    const { status, data } = await apiGet(apiPath.getCurrentBetsExchanges);
    if (status == 200) {
      if (data?.success) {
        setExchange(data?.results);
      }
    }
  };
  const getFancyBet = async () => {
    const { status, data } = await apiGet(apiPath.getFancyBets);
    if (status == 200) {
      if (data?.success) {
        setFancy(data?.results);
      }
    }
  };
  const getBookmakerBet = async () => {
    const { status, data } = await apiGet(apiPath.getBookmakerBets);
    if (status == 200) {
      if (data?.success) {
        setBookmaker(data?.results);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getBookmakerBet();
      getFancyBet();
      getExchangeBet();
    }
  }, [user]);

  let object = {
    betfair: matched ? exchange?.matched : exchange?.unMatched,
    fancy: fancy,
    bookmaker: bookmaker,
  };

  useEffect(() => {
    setMatched(true);
  }, [tab]);

  return (
    <ProfileLayout>
      <Col md={12}>
        <div className="balance-label position-relative">
          Current Bets
          <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
            <RxCross2
              className="fs-3"
              style={{ fontWeight: "800", color: "black" }}
              onClick={() => navigate("/")}
            />
          </button>
        </div>
        <div className="filter-new">
          <div className="w-100 d-flex mb-1">
            <div className="filter-new-inner">
              <Form.Label>Bet Type:</Form.Label>
              <Form.Select onChange={(e) => setTab(e.target.value)} value={tab}>
                <option value="betfair">Exchange</option>
                <option value="bookmaker">Bookmaker</option>
                <option value="fancy">Fancybet</option>{" "}
                {/* <option value="casino">Casino</option> */}
              </Form.Select>
            </div>{" "}
          </div>
        </div>
        <div className="pt-0 inner-sidebar-content">
          <Table>
            <thead>
              <tr>
                <th scope="col">PL ID</th>
                <th scope="col"> Bet ID</th>
                <th scope="col">Bet placed</th>
                <th scope="col">IP Address </th>
                <th scope="col">Market</th>
                <th scope="col">Selection</th>
                <th scope="col">Type</th>
                <th scope="col">Odds req.</th>
                <th scope="col">Stake </th>
                <th scope="col">Profit/Loss </th>
              </tr>
            </thead>
            <tbody>

         
            {object[tab] && object[tab]?.length > 0 ? (
              object[tab]?.map((item) => {
                return (
                  <tr>
                    <td>{item?.clientName || "-"}</td>
                    <td> {item?.sessionBetId || item?.matchBetId || "N/A"}</td>
                    <td> {obj.dateFormat(item?.timeInserted)}</td>
                    <td>
                      {" "}
                      {(item?.ipAddress &&
                        item?.ipAddress.replace("::ffff:", "")) ||
                        "N/A"}
                    </td>
                    <td className="text-start">
                      {`${
                        item?.eventType === "4"
                          ? "Cricket"
                          : item?.eventType === "1"
                          ? "Soccer"
                          : item?.eventType === "2"
                          ? "Tennis"
                          : "Casino"
                      }`}
                      <span className="angle_unicode">▸</span>
                      <strong>{item?.matchName}</strong>
                      <span className="angle_unicode">▸</span>
                      {tab === "fancy" && item?.fancyName}{" "}
                      {tab === "bookmaker" && "Bookmaker"}
                      {tab === "betfair" && "Match Odds"}
                    </td>
                    <td>
                      {tab === "fancy"
                        ? item?.betRun + "/" + item?.bhav
                        : item?.runnerName || item?.teamName}
                    </td>
                    <td>
                      {" "}
                      {item?.type
                        || item?.betType || "N/A"}{" "}
                    </td>
                    <td> {item?.bhav || "N/A"} </td>
                    <td> {item?.amount || "N/A"} </td>
                    <td>
                      {item?.betType == "back" || item?.type == "Yes" ? (
                        <span className="text-success">
                          {item?.profitAmount}
                        </span>
                      ) : (
                        <span className="text-danger">
                          - ({item?.loseAmount})
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <span>You have no bets in this time period.</span>
                </td>
              </tr>
            )}   </tbody>
          </Table>
        </div>
      </Col>
    </ProfileLayout>
  );
};

export default CurrentBets;
