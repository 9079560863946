import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  startTransition,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AuthContext from "../../context/AuthContext";
import BetSlipContext from "../../context/BetSlipContext";
import { inRange, isEmpty } from "lodash";
import { useNavigate, useParams, Link } from "react-router-dom";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { io } from "socket.io-client";
import ReactPlayer from "react-player";
import secureLocalStorage from "react-secure-storage";
import obj from "../../Utils/helpers";
import jwt_decode from "jwt-decode";
import Layout from "../Layout";
import Selection from "./Selection";
import Fancy from "./Fancy";
import { Button, Form } from "react-bootstrap";
import OpenBets from "../../component/OpenBets";
import { useTranslation } from "react-i18next";
function MatchPage() {
  const parmas = useParams();
  const {
    user,
    profileData,
    setShowPosition,
    setSocketId,
    setRightTab,
    blockList,
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const {
    setBetSlipObject,
    setShowBetDialog,
    betSlipObject,
    showBetDialog,
    resetBetSlipObject,
    resetBetPosition,
    setResetBetPosition,
    betPlace,
    setLimit,
    limit,
    handelPlaceFancy,
    handelPlaceBookmaker,
  } = useContext(BetSlipContext);
  const [scrollHeight, setScrollHeight] = useState(75);
  const [tab, setTab] = useState("tv");
  const ref = useRef(null);
  const [mute, setMute] = useState(true);
  const [marketNew, setMarketNew] = useState([]);
  const [marketId, setMarketId] = useState([]);
  const [position, setPosition] = useState([]);
  const [runner, setRunner] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [checkTvRefresh, setTvCheckRefresh] = useState(true);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [marketName, setMarketName] = useState("Match Odds");
  const [fancySportTab, setFancySportTab] = useState("fancy");
  const [runnerBackOddsOld, setRuunerBackOddsOld] = useState([]);
  const [runnerLayOddsOld, setRuunerlayOddsOld] = useState([]);
  const [betFairMs, setBetFairMs] = useState(1);
  const [runnerBackOdds, setRunnerBackOdds] = useState([]);
  const [runnerLayOdds, setRunnerLayOdds] = useState([]);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [fancyHeader, setFancyHeader] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [totalMatched, setTotalMatched] = useState(0);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [bookmakerMs, setBookmakerMs] = useState(1);
  const [ms, setMs] = useState(0);
  const [sportsBookPosition, setSportsBookPosition] = useState([]);
  const [socketObj, setSocketObj] = useState(null);
  const [sportsBook, setSportsBook] = useState([]);
  const [detail, setDetail] = useState({});
  const getMatchDetails = async (info) => {
    if (!info) {
      document.getElementById("loading").style.display = "flex";
    }
    let userId = !isEmpty(localStorage.getItem("token"))
      ? jwt_decode(localStorage.getItem("token"))?.user?._id
      : "";
    let obj = { userId: userId, eventId: parmas?.eventId };
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail,
      obj
    );
    if (status === 200) {
      if (response_users?.success) {
        if (response_users?.results?.resultStatus) {
          navigate("/");
        }
        if (
          !isEmpty(localStorage.getItem("token")) &&
          response_users?.results?.status == "in_play" &&
          response_users?.results?.channel != "false" &&
          !response_users?.results?.eventName.includes(" SRL ")
        ) {
          setTab("tv");
        } else {
          setTab("score");
        }
        if (response_users?.results?.marketList?.length > 1) {
          let matchOdds = response_users?.results?.marketList?.find((res) => {
            return res?.market == "Match Odds";
          });
          if (!isEmpty(matchOdds)) {
            setMarketName("Match Odds");
            setRunner(matchOdds?.jsonData || []);
          }
          let matchOddsNot = response_users?.results?.marketList?.filter(
            (res) => {
              return res?.market !== "Match Odds";
            }
          );
          if (matchOddsNot?.length > 0) {
            setMarketNew(matchOddsNot || []);
          }
          let id = response_users?.results?.marketList?.map((res) => {
            return res?.marketId;
          });
          setMarketId(id);
          getBetPositionMulti(id, response_users?.results?.gameType);
          getMatchOddsMulti(id);
        } else {
          setMarketName("Match Odds");
          setRunner(response_users?.results?.jsonData || []);
          let id = [parmas?.marketId];
          getBetPositionMulti(id, response_users?.results?.gameType);
          getMatchOddsMulti(id);
          setMarketId(id);
        }
        if (info !== "fav") {
          setFancySportTab((prev) => {
            if (info) {
              return prev;
            } else {
              if (response_users?.results?.eventType == 4) {
                return "fancy";
              } else {
                return "sport";
              }
            }
          });
        }
        setDetail(response_users?.results);
        if (response_users?.results?.jsonBookmakerData?.length > 0) {
          setBookmaker(
            response_users?.results?.jsonBookmakerData?.map((res) => {
              let id = response_users?.results?.jsonData?.find((item) => {
                return (
                  item?.RunnerName?.toLowerCase() ==
                  res?.runnerName?.toLowerCase()
                );
              });
              return {
                ...res,
                selectionID: id?.SelectionId,
                SelectionId: id?.SelectionId,
                runnerName: id?.RunnerName,
              };
            }) || []
          );
        } else {
          setBookmaker(response_users?.results?.jsonData || []);
        }
      }
    }
    if (!info) {
      document.getElementById("loading").style.display = "none";
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id?.join(",")}&multi=true&eventId=${parmas?.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        let matchOdds = socket_data?.find((res) => {
          return res?.mi == parmas?.marketId;
        });
        let matchOddsNot = socket_data?.filter((res) => {
          return res?.mi !== parmas?.marketId;
        });
        if (!isEmpty(matchOdds)) {
          if (matchOdds.rt?.length) {
            const back_odds = matchOdds.rt.filter((rt) => rt.ib) || [];
            const lay_odds = matchOdds.rt.filter((rt) => !rt.ib) || [];
            setRunnerBackOdds(back_odds);
            setMs(matchOdds?.ms);
            setRunnerLayOdds(lay_odds);
            setTotalMatched(matchOdds?.totalMatched);
          }
        }
        if (matchOddsNot?.length > 0) {
          setMarketNew((prev) => {
            return prev?.map((res) => {
              let temp = socket_data?.find((temp) => {
                return temp?.mi == res?.marketId;
              });
              if (temp?.mi == res?.marketId) {
                return {
                  ...res,
                  oldObj: res?.odds || {},
                  odds: {
                    mi: temp?.mi,
                    ms: temp?.ms,
                    totalMatched: temp?.totalMatched,
                    ts: temp?.ts,
                    rt: temp?.rt,
                  },
                };
              } else {
                return res;
              }
            });
          });
        }
      }
    }
  };

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          if (!isEmpty(user)) {
            setFancyPosition((prev) => {
              setFancy(
                message.results.map((res) => {
                  let obj = prev?.find(
                    (item) => item.selectionId == res.selectionId
                  );

                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                    newPosition:
                      Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                  };
                })
              );

              return prev;
            });
          } else {
            setFancy((prev) => {
              return message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              });
            });
          }
        }
      }
    }
  };

  const listenBetfairOdds = (event) => {
    if (event?.results?.length > 0) {
      const socket_data = event?.results;
      let matchOdds = socket_data?.find((res) => {
        return res?.mi == parmas?.marketId;
      });
      let matchOddsNot = socket_data?.filter((res) => {
        return res?.mi !== parmas?.marketId;
      });
      if (!isEmpty(matchOdds)) {
        if (matchOdds.rt?.length > 0) {
          const back_odds = matchOdds.rt.filter((rt) => rt.ib) || [];
          const lay_odds = matchOdds.rt.filter((rt) => !rt.ib) || [];
          setMs(matchOdds?.ms);
          setTotalMatched(matchOdds?.totalMatched);
          setRunnerBackOdds((prev) => {
            setRuunerBackOddsOld(prev);
            return back_odds;
          });
          setRunnerLayOdds((prev) => {
            setRuunerlayOddsOld(prev);
            return lay_odds;
          });
        }
      }
      if (matchOddsNot?.length > 0) {
        setMarketNew((prev) => {
          return prev?.map((res) => {
            let tempUpdated = socket_data?.find((temp) => {
              return temp?.mi == res?.marketId;
            });
            if (tempUpdated?.mi == res?.marketId) {
              return {
                ...res,
                oldObj: res?.odds || {},
                odds: {
                  mi: tempUpdated?.mi,
                  ms: tempUpdated?.ms,
                  totalMatched: tempUpdated?.totalMatched,
                  ts: tempUpdated?.ts,
                  rt: tempUpdated?.rt,
                },
              };
            } else {
              return res;
            }
          });
        });
      }
    }

    // const socket_data =
    //   (event &&
    //     event?.results?.find((item) => item.marketId == parmas?.marketId)) ||
    //   [];
    // if (socket_data && parmas?.marketId == socket_data?.mi) {
    //   if (socket_data.ms) {
    //     setBetFairMs(socket_data.ms);
    //   }
    //   if (socket_data.rt?.length) {
    //     const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
    //     const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
    //     setRunnerBackOdds((prev) => {
    //       setRuunerBackOddsOld(prev);
    //       return back_odds;
    //     });
    //     setMs(socket_data?.ms);
    //     setRunnerLayOdds((prev) => {
    //       setRuunerlayOddsOld(prev);
    //       return lay_odds;
    //     });
    //     setTotalMatched(socket_data?.totalMatched);
    //   }
    // }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === parmas?.eventId) {
      setBookmaker((prev) => {
        return prev?.map((res) => {
          let temp = message?.results?.rt?.find((item) => {
            return item?.name === res?.RunnerName;
          });
          if (temp?.name === res?.RunnerName) {
            return {
              ...res,
              ...temp,
            };
          } else {
            return { ...res, ...temp };
          }
        });
      });
      const back_odds = message?.results?.rt.filter((rt) => rt.ib) || [];
      const lay_odds = message?.results?.rt.filter((rt) => !rt.ib) || [];
      setBookmakerLayOdds(lay_odds);
      setBookmakerMs(!isEmpty(message?.results?.ms) ? message?.results?.ms : 1);
      setBookmakerBackOdds(back_odds);
    }
  };
  const listenDiamondFancy = (message) => {
    if (message?.eventId === parmas?.eventId) {
      if (message?.results?.length > 0) {
        if (!isEmpty(user)) {
          setFancyPosition((prev) => {
            setFancy(
              message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );

            return prev;
          });
        } else {
          setFancy((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
        }
      }
    }
  };

  const getSocket = () => {
    if (detail?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?token=${randomId}&userType=front&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?user_id=${randomId}&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketId(newSocket);
      setSocketObj(newSocket);
      if (!isEmpty(user)) {
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        if (detail?.gameType == "cricket") {
          newSocket.on("listenDiamondFancy", listenDiamondFancy);
        }
        // newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.emit("setPremiumFancy", {
          user_id: user?.user?._id ? user?.user?._id : randomId,
          eventId: parmas.eventId,
        });
      } else {
        if (detail?.gameType == "cricket") {
          newSocket.on("listenDiamondFancy", listenDiamondFancy);
        }
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        // newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
      }
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.disconnect();
    }
  };

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const getBetPositionMulti = async (id, game) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${parmas.eventId}&type=${
          game || detail?.gameType
        }&marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        updatePosition(response_users?.results);
        // if (response_users?.results?.betFair?.length > 0) {
        //   setMarketNew((current) =>
        //     current?.map((res) => {
        //       return {
        //         ...res,
        //         jsonData: res?.jsonData?.map((newObj) => {
        //           let obj = response_users?.results?.betFair?.find(
        //             (item) => item.selectionId == newObj?.SelectionId
        //           );
        //           if (!isEmpty(obj)) {
        //             return {
        //               ...newObj,
        //               position:
        //                 obj.selectionId == newObj?.SelectionId
        //                   ? obj?.position
        //                   : 0,
        //               newPosition: 0,
        //             };
        //           } else {
        //             return {
        //               ...newObj,
        //               position: 0,
        //               newPosition: 0,
        //             };
        //           }
        //         }),
        //       };
        //     })
        //   );
        // }
      }
    }
  };
  const updatePosition = (object) => {
    setPosition(object);
    setRunner((current) =>
      object?.betFair?.length > 0
        ? current.map((res) => {
            let obj = object?.betFair.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setBookmaker((current) =>
      object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == `B${res?.SelectionId}`
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    if (object?.betFair?.length > 0) {
      setMarketNew((res) => {
        return {
          ...res,
          jsonData: res?.jsonData?.map((item) => {
            let position = object?.betFair?.find((obj) => {
              return obj?.selectionId == item?.SelectionId;
            });
            if (isEmpty(position)) {
              return item;
            } else {
              return {
                ...item,
                position:
                  Math.abs(position?.position) > 0 ? position?.position : 0,
              };
            }
          }),
        };
      });
    }
  };
  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betFancyPosition +
        `?eventId=${parmas.eventId}&type=${detail.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        secureLocalStorage.setItem(
          "fancy",
          response_users?.results?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.position,
              betRunPosition: res?.betRunPosition,
              fName: res?.fancyName,
            };
          })
        );
        updatedFancyPosition(
          response_users?.results?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.position,
              betRunPosition: res?.betRunPosition,
              fName: res?.fancyName,
            };
          })
        );
      }
    }
  };
  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancy((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };
  const getSportBookBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPremiumFancyPosition +
        `?eventId=${parmas.eventId}&type=${detail.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        secureLocalStorage.setItem(
          "premiumFancy",
          response_users?.results || []
        );
        setSportsBookPosition(response_users?.results);
        setSportsBook((current) => {
          return current?.map((res) => {
            let obj = response_users?.results?.filter((item) => {
              return item?.fancyName == res?.marketName;
            });
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };

  const handelBetFairPositionsMulti = (slipObj) => {
    let selectionsTemp = [...marketNew]
      ?.filter((res) => {
        return res?.marketId == slipObj?.newMarketId;
      })
      ?.map((item) => {
        return {
          ...item,
          position: item?.position || 0,
          newPosition: item?.newPosition || 0,
        };
      });
    let newArray = selectionsTemp[0]?.jsonData;
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          });
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            } else {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            }
          });
        }
      }
    }
    setMarketNew((prev) => {
      return prev?.map((res) => {
        if (res?.marketId == slipObj?.newMarketId) {
          return { ...res, jsonData: newArray };
        } else {
          return res;
        }
      });
    });
  };
  const handelBookmakerPositions = (slipObj) => {
    let selectionsTemp = [...bookmaker]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (slipObj?.bidPrice > 0) {
      if (slipObj?.object.ib) {
        let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
        let backLoss = Number(slipObj?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - Number(backLoss),
                };
              }
            })
          );
        } else {
          let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          let backLoss = Number(slipObj?.bidPrice);

          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);

          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              }
            })
          );
        } else {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner];
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };
  const resetPosition = (type) => {
    if (type == "betFair") {
      setRunner((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      setBookmaker((current) =>
        current?.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: Math.abs(newObj?.position) > 0 ? newObj?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
    setResetBetPosition(false);
    resetBetSlipObject();
  };

  useEffect(() => {
    if (checkRefresh && detail?.eventId) {
      getSocket();
    }
  }, [checkRefresh, detail]);

  useEffect(() => {
    if (resetBetPosition) {
      resetPosition(betSlipObject?.playType);
    }
  }, [resetBetPosition]);

  useEffect(() => {
    if (showBetDialog) {
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject);
        } else if (betSlipObject?.playType == "multi") {
          handelBetFairPositionsMulti(betSlipObject);
        } else if (betSlipObject?.playType == "bookmaker") {
          handelBookmakerPositions(betSlipObject);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      if (betPlace?.betType == "fancy") {
        getFancyBetPosition();
        getCurrentBets();
      } else if (betPlace?.betType == "sportBook") {
        getSportBookBetPosition();
        getCurrentBets();
      } else {
        getBetPositionMulti(marketId);
        getCurrentBets();
      }
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      resetPosition(betPlace?.betType);
    }
  }, [betPlace, marketId]);

  useEffect(() => {
    let interval = setInterval(() => {
      getSocket();
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getMatchDetails();
    // getMatchOdds();
    // getSportBookList();
    getFancyData();
  }, [parmas]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (!showBetDialog) {
        getMatchDetails(true);
        // getMatchOdds();
        // getSportBookList();
        getFancyData();
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [parmas, showBetDialog]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (!isEmpty(detail)) {
        startTransition(() => {
          getBetPositionMulti(marketId);
          getFancyBetPosition();
          // getSportBookBetPosition();
        });
      }
    }
  }, [detail, marketId]);
  const navigate = useNavigate();

  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight) + 2
      : 0;
    setScrollHeight(header);
  }, []);

  const placeBet = () => {
    if (!isEmpty(user)) {
      if (betSlipObject?.playType == "fancy") {
        handelPlaceFancy();
      } else if (betSlipObject?.playType == "bookmaker") {
        handelPlaceBookmaker();
      }
    } else {
      navigate("/login");
    }
  };
  const [bidPriceDisabled, setBidPricDisabled] = useState(true);
  useEffect(() => {
    if (
      inRange(
        Number(betSlipObject?.bidPrice),
        limit?.minAmount || 1,
        limit?.maxAmount + 1 || 100
      )
    ) {
      setBidPricDisabled(false);
    } else {
      setBidPricDisabled(true);
    }
  }, [betSlipObject, limit]);

  const BetSlip = () => {
    return (
      <div
        style={{
          background: "#303030",
          // borderRadius: "5px",
          padding: "10px",
          marginTop: "8px",
        }}
      >
        <div className="d-flex justify-content-end align-items-center w-100">
          <div className="d-flex align-items-center justify-content-end w-50">
            {" "}
            <Button
              style={{
                height: "28px",
                fontSize: "13px",
                width: "100px",
                borderRadius: "5px",
              }}
              onClick={() => setResetBetPosition(true)}
              variant="primary"
              className="btn-red me-1"
              size="sm"
            >
              Cancel
            </Button>
            <Form.Control
              disabled={true}
              value={
                betSlipObject?.playType == "fancy"
                  ? `${betSlipObject?.odds}/${betSlipObject?.object?.pt}`
                  : betSlipObject?.odds
              }
              style={{
                height: "28px",
                fontSize: "15px",
                width: "90px",
                borderRadius: "5px",
              }}
              className="me-2"
              aria-label="First name"
            />
            <Form.Control
              value={betSlipObject?.bidPrice}
              style={{
                height: "28px",
                fontSize: "15px",
                width: "90px",
                borderRadius: "5px",
              }}
              onChange={(e) =>
                setBetSlipObject({
                  ...betSlipObject,
                  bidPrice: e?.target?.value,
                })
              }
              type="number"
              className="me-2"
              aria-label="First name"
            />{" "}
            <Button
              disabled={bidPriceDisabled}
              onClick={() => {
                placeBet();
              }}
              style={{
                height: "28px",
                fontSize: "13px",
                width: "100px",
                borderRadius: "5px",
              }}
              variant="secondary"
              className="btn-green"
              size="sm"
            >
              Place bid
            </Button>
          </div>{" "}
        </div>
        <div>
          <div className="mt-2 d-flex justify-content-end align-items-center w-100">
            {" "}
            {number?.map((res) => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    background: "rgba(227, 248, 255, 0.8)",
                    color: "black",
                    width: "100px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setBetSlipObject({
                      ...betSlipObject,
                      bidPrice: isEmpty(betSlipObject?.bidPrice)
                        ? Number(res)
                        : Number(betSlipObject?.bidPrice) + Number(res),
                    });
                  }}
                  className="stake-value me-1"
                >
                  +{res}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const [dataCurrent, setDataCurrent] = useState([]);
  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(apiPath.getBetsData, {
      eventId: parmas?.eventId,
      marketId: parmas?.marketId,
    });
    if (status === 200) {
      if (response_users.success) {
        setDataCurrent(
          [
            ...response_users.results?.FancyBet,
            ...response_users.results?.BookmakerBet,
            ...response_users.results?.SporBooksBet,
            ...response_users.results?.BetFairBet?.matched,
          ].sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        );
      }
    }
  };

  useEffect(() => {
    getCurrentBets();
  }, []);
  return (
    <Layout>
      <Col md={9}>
        <div
          data-aos="zoom-in-up"
          className="BlackBox mb-2"
          style={{ borderRadius: "0" }}
        >
          {/* <div className="heads">
            <p className="Date mb-0">
              {" "}
              {obj.matchDateTimeDetail(detail?.eventDateTime)}
            </p>
            <Row>
              <Col className="text-left">
                {detail?.eventName?.split(" v ")[0]}
              </Col>
              <Col className="text-end">
                {detail?.eventName?.split(" v ")[1]}
              </Col>
            </Row>
          </div> */}
          <div style={{ color: "white", padding: "10px" }}>
            <div style={{ fontSize: "18px" }}>{detail?.eventName}</div>
            <p className="Date mb-0" style={{ fontSize: "12px" }}>
              {" "}
              {obj.matchDateTimeDetail(detail?.eventDateTime)}
            </p>
          </div>
          <div className="B-body">
            <Tabs
              activeKey={tab}
              onSelect={(e) => setTab(e)}
              id="uncontrolled-tab-example"
              fill
            >
              {!isEmpty(user) &&
                detail?.status == "in_play" &&
                !detail?.eventName.includes(" SRL ") && (
                  <Tab eventKey="tv" title={t("Live")}>
                    {/* {checkTvRefresh && (
                      <>
                        <ReactPlayer
                          muted={mute}
                          controls={false}
                          ref={ref}
                          playsinline={true}
                          loop={true}
                          playing={true}
                          id="videoFrame"
                          style={{
                            overflowX: "hidden",
                            overflow: "hidden",
                            backgroundColor: "#000000",
                          }}
                          width="100%"
                          height="300px"
                          url={`https://crickexpo.in/tv/?EventId=${parmas?.eventId}`}
                          config={{
                            file: {
                              attributes: {
                                preload: "none",
                                //forceHLS: true,
                                crossOrigin: "true",
                              },
                              hlsOptions: {
                                //autoStartLoad: false,
                                startLevel: 3,
                              },
                            },
                          }}
                        />
                      </>
                    )} */}
                    <iframe
                      title="Live TV Player"
                      // onClick={() => setControls(true)}
                      src={`https://crickexpo.in/tv/?EventId=${detail?.eventId}`}
                      // src={`https://5por-tt1.top/getscore.php?chid=${detail?.channel}`}
                      id="ContentPlaceHolder1_myFrame1"
                      style={{ width: "100%", height: "230px" }}
                      class="sc-dUjcNx eLUYUT"
                    />
                  </Tab>
                )}
              <Tab eventKey="score" title={t("Scorecard")}>
                <iframe
                  className=" w-100"
                  style={{
                    height: "180px",
                    backgroundColor: "#000000",
                  }}
                  id="scoreId"
                  src={detail?.scoreURL}
                ></iframe>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="mb-2">
          {detail?.matchOdds == "on" &&
            detail?.hasMatchOdds &&
            runner?.length > 0 &&
            runnerBackOdds?.length > 0 && (
              <Selection
                runner={runner}
                totalMatched={totalMatched}
                name="Match Odds"
                title={t("MatchOdds")}
                scrollHeight={scrollHeight}
                marketId={parmas?.marketId}
                backOdds={detail?.matchOdds == "on" ? runnerBackOdds : []}
                layOdds={detail?.matchOdds == "on" ? runnerLayOdds : []}
                oldBack={detail?.matchOdds == "on" ? runnerBackOddsOld : []}
                oldLay={detail?.matchOdds == "on" ? runnerLayOddsOld : []}
                detail={detail}
                ms={
                  blockList?.includes(detail?.eventId)
                    ? 3
                    : detail?.matchOdds == "on"
                    ? ms
                    : 3
                }
                position={
                  position?.betFair?.length > 0 ? position?.betFair : []
                }
                profileData={
                  detail?.matchSetting?.length > 0
                    ? { ...detail, arrayCheck: "details" }
                    : { ...profileData, arrayCheck: "profile" }
                }
                typeGame="betFair"
                marketName={"Match Odds"}
                setShowBetDialog={setShowBetDialog}
                setLimit={setLimit}
                setBetSlipObject={setBetSlipObject}
                user={user}
                setRightTab={setRightTab}
              />
            )}

          {detail?.bookMaker == "on" &&
            detail?.gameType == "cricket" &&
            bookmaker?.length > 0 &&
            detail?.hasBookmaker && (
              <Selection
                name="Bookmaker"
                runner={bookmaker}
                title={t("Bookmaker")}
                BetSlip={BetSlip}
                scrollHeight={scrollHeight}
                marketId={parmas?.marketId}
                backOdds={detail?.bookMaker == "on" ? bookmakerBackOdds : []}
                layOdds={detail?.bookMaker == "on" ? bookmakerLayOdds : []}
                oldBack={detail?.bookMaker == "on" ? [] : []}
                oldLay={detail?.bookMaker == "on" ? [] : []}
                detail={detail}
                ms={detail?.bookMaker == "on" ? bookmakerMs : 3}
                position={
                  position?.bookmaker?.length > 0 ? position?.bookmaker : []
                }
                profileData={
                  detail?.matchSetting?.length > 0
                    ? { ...detail, arrayCheck: "details" }
                    : { ...profileData, arrayCheck: "profile" }
                }
                typeGame="bookmaker"
                totalMatched={0}
                marketName={"Bookmaker"}
                setShowBetDialog={setShowBetDialog}
                setLimit={setLimit}
                setBetSlipObject={setBetSlipObject}
                user={user}
                setRightTab={setRightTab}
              />
            )}
          {marketNew?.length > 0 &&
            marketNew?.map((res) => {
              return (
                <Selection
                  runner={res?.jsonData}
                  name={res?.market}
                  totalMatched={
                    !isEmpty(res?.odds) ? res?.odds?.totalMatched : 0
                  }
                  title={res?.market}
                  marketId={res?.marketId}
                  scrollHeight={scrollHeight}
                  backOdds={
                    detail?.matchOdds == "on"
                      ? !isEmpty(res?.odds) && res?.odds?.rt?.length > 0
                        ? res?.odds?.rt?.filter((rt) => rt.ib)
                        : []
                      : []
                  }
                  layOdds={
                    detail?.matchOdds == "on"
                      ? !isEmpty(res?.odds) && res?.odds?.rt?.length > 0
                        ? res?.odds?.rt?.filter((rt) => !rt.ib)
                        : []
                      : []
                  }
                  oldBack={
                    detail?.matchOdds == "on"
                      ? !isEmpty(res?.oldObj) && res?.oldObj?.rt?.length > 0
                        ? res?.oldObj?.rt?.filter((rt) => rt.ib)
                        : []
                      : []
                  }
                  oldLay={
                    detail?.matchOdds == "on"
                      ? !isEmpty(res?.oldObj) && res?.oldObj?.rt?.length > 0
                        ? res?.oldObj?.rt?.filter((rt) => !rt.ib)
                        : []
                      : []
                  }
                  detail={detail}
                  ms={!isEmpty(res?.odds) ? res?.odds?.ms : 3}
                  position={
                    position?.betFair?.length > 0 ? position?.betFair : []
                  }
                  profileData={
                    detail?.matchSetting?.length > 0
                      ? { ...detail, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                  typeGame="multi"
                  marketName={res?.market}
                  setShowBetDialog={setShowBetDialog}
                  setLimit={setLimit}
                  setBetSlipObject={setBetSlipObject}
                  user={user}
                  setRightTab={setRightTab}
                />
              );
            })}
        </div>
        {detail?.gameType == "cricket" && detail?.hasFancy && (
          <div data-aos="zoom-in-up" className="mb-4">
            <Fancy
              title={t("Fancy")}
              data={fancy}
              BetSlip={BetSlip}
              user={user}
              detail={detail}
              position={fancyPosition}
              setLimit={setLimit}
              scrollHeight={scrollHeight}
              setShowPosition={setShowPosition}
              profileData={
                detail?.matchSetting?.length > 0
                  ? { ...detail, arrayCheck: "details" }
                  : { ...profileData, arrayCheck: "profile" }
              }
              setRightTab={setRightTab}
            />
          </div>
        )}
        {/* {dataCurrent?.length > 0 && (
          <div data-aos="zoom-in-up" className="mb-4">
            <Tabs
              id="uncontrolled-tab-example"
              className="mb-3"
              defaultActiveKey={"current"}
            >
              <Tab eventKey="current" title={t("OpenBets")}>
                <OpenBets data={dataCurrent} />
              </Tab>
            </Tabs>
          </div>
        )} */}
      </Col>
    </Layout>
  );
}

export default MatchPage;
const number = [25, 50, 100, 1000, 10000, 25000];
