import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import BetSlipContext from "../../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLayInner from "./BackLayInner";
import { useTranslation } from "react-i18next";
import NewOddLay from "./NewOddLay";
const BackLay = ({
  component,
  handelChange,
  odds_lay,
  odds_back,
  index,
  item,
  limit,
  disabelBack,
  disabelLay,
  conditionNew,
  oldBack,
  oldLay,
  isInPlay
}) => {
    const {t} = useTranslation()
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  let checkDisabled = (odds) => {
    if (Number(odds) > 0) {
      if (Number(odds) > limit?.oddsLimit) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  let check = (odds) => {
    if (!conditionNew) {
      if (Number(odds) > 0 || !isEmpty(odds)) {
        if (Number(odds) > limit?.oddsLimit) {
          return "single-disabled";
        } else {
          return "";
        }
      } else {
        return "single-disabled";
      }
    } else {
      return "";
    }
  };
  return (
    <Col md={6} className="text-dark text-center p-0">
      <Row className="mx-0 position-relative" style={{paddingRight:"4px"}}>
        <Col className="text-center px-0" md={6}>
          <NewOddLay
            odds_back={odds_back}
            odds_lay={odds_lay}
            check={check}
            betSlipObject={betSlipObject}
            item={item}
            indexOdds={1}
            showBetDialog={showBetDialog}
            checkDisabled={checkDisabled}
            handelChange={handelChange}
            index={index}
            component={component}
            disabelBack={disabelBack}
            disabelLay={disabelLay}
            oldLay={oldLay}
            oldBack={oldBack} type='back'
          />
        </Col>
        <Col className="text-center px-0" md={6}>
          <NewOddLay
            odds_back={odds_back}
            odds_lay={odds_lay}
            check={check}
            betSlipObject={betSlipObject}
            item={item}
            type='lay'
            indexOdds={1}
            showBetDialog={showBetDialog}
            checkDisabled={checkDisabled}
            handelChange={handelChange}
            index={index}
            component={component}
            disabelBack={disabelBack}
            disabelLay={disabelLay}
            oldLay={oldLay}
            oldBack={oldBack}
          />
        </Col>
        {conditionNew && !isInPlay && <div className="overlay-match">{t("Suspended")}</div>}
      </Row>
    </Col>
  );
};

export default BackLay;
