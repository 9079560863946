const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;

export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const validationRules = {
	email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
	password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
	passwordMessage: 'Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.',
	characters: /^[a-zA-Z_ ]*$/,
	numbers: /^[0-9]*$/,
	removeWhitespace: /^[a-zA-Z0-9]+$/,
	numberNew: /^[0-9]*$/,
	numberWithDot: /^\d*(\.\d{0,10})?$/,
}
export const preventMaxInput = (e) => {
	e.target.value = e.target.value.trimStart()
	e.target.value = e.target.value.replace(/  +/g, ' ')
}
export const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "."];
export const premiumCategoryArray = [
  { type: 1, name: "Popular" },
  { type: 3, name: "Match" },
  { type: 5, name: "Innings" },
  { type: 10, name: "Over" },
  { type: 2, name: "Batsman" },
  { type: 6, name: "Partnership" },
  { type: 7, name: "Group" },
  { type: 9, name: "Range" },
  { type: 11, name: "Head-to-Head" },
  { type: 8, name: "Player" },
];
export const priceData = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 100,
  },
  {
    id: 4,
    value: 200,
  },
  {
    id: 5,
    value: 1000,
  },
  {
    id: 6,
    value: 5000,
  },
];
export const headerData = [
  {
    class: "text-start",
    title: "Market",
    widht: "30%",
  },
  {
    class: "text-end",
    title: "Sports",
    widht: "",
  },
  {
    class: "text-end",
    title: "Selection",
    widht: "",
  },
  {
    class: "text-end",
    title: "Type",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet ID",
    widht: "",
  },
  {
    class: "text-end",
    title: "Bet placed",
    widht: "",
  },
  {
    class: "text-end",
    title: "Odds req.",
    widht: "",
  },
  {
    class: "text-end",
    title: "Matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Avg. odds matched",
    widht: "",
  },
  {
    class: "text-end",
    title: "Date matched",
    widht: "",
  },
];
export const betFairData = {
  data: [
    {
      bmi: "1.205777993",
      ip: 1,
      mi: 2572760,
      ms: 1,
      eti: "4",
      eid: "31859931",
      grt: "2022-11-01T08:20:58.203Z",
      rt: [
        {
          ri: 10301,
          rt: 1.49,
          bv: 9134.79,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.48,
          bv: 30747.88,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.47,
          bv: 16964.29,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.5,
          bv: 11305.92,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.51,
          bv: 72391.18,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.52,
          bv: 10997.18,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3,
          bv: 5652.96,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.98,
          bv: 43.56,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.96,
          bv: 36885.43,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 3.05,
          bv: 4641.48,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.1,
          bv: 14535.58,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.15,
          bv: 7993.98,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
      ],
    },
  ],
  messageType: "match_odds",
};
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];
export const bookmakerJsonData = {
  data: [
    {
      bmi: 454109,
      ip: 1,
      mi: 2576457,
      ms: 1,
      eid: 31859931,
      grt: "2022-11-01T08:20:58.819Z",
      rt: [
        {
          ri: "4596251",
          rt: 1.49,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596251",
          rt: 1.51,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.13,
          bv: 520,
          pr: 0,
          af: 0,
          ib: true,
          pt: 0,
        },
        {
          ri: "4596252",
          rt: 2.14,
          bv: 537,
          pr: 0,
          af: 0,
          ib: false,
          pt: 0,
        },
      ],
    },
  ],
  messageType: "fancy",
};
export const fancyJsonData = [
  {
    _id: "636c853e02114d244506b413",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460152",
    selectionId: "4601521",
    centralizedId: "2576919",
    fancyName: "MATCH 1ST OVER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460152,
        selectionID: 4601521,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460152,
      ip: 1,
      mi: 2576919,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:57:05.680Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b414",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460153",
    selectionId: "4601531",
    centralizedId: "2576920",
    fancyName: "6 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460153,
        selectionID: 4601531,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460153,
      ip: 1,
      mi: 2576920,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:25:28.779Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b415",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460154",
    selectionId: "4601541",
    centralizedId: "2576921",
    fancyName: "6 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460154,
        selectionID: 4601541,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460154,
      ip: 1,
      mi: 2576921,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:41.665Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b416",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460155",
    selectionId: "4601551",
    centralizedId: "2576922",
    fancyName: "20 OVER RUN IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460155,
        selectionID: 4601551,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460155,
      ip: 1,
      mi: 2576922,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.477Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b417",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460157",
    selectionId: "4601571",
    centralizedId: "2576923",
    fancyName: "20 OVER RUN ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460157,
        selectionID: 4601571,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460157,
      ip: 1,
      mi: 2576923,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:39.511Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b418",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460158",
    selectionId: "4601581",
    centralizedId: "2576924",
    fancyName: "FALL OF 1ST WKT IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460158,
        selectionID: 4601581,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460158,
      ip: 1,
      mi: 2576924,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:24.539Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b419",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460159",
    selectionId: "4601591",
    centralizedId: "2576925",
    fancyName: "FALL OF 1ST WKT ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460159,
        selectionID: 4601591,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460159,
      ip: 1,
      mi: 2576925,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.478Z",
      rt: [
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601591",
          rt: 24,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b41a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460160",
    selectionId: "4601601",
    centralizedId: "2576926",
    fancyName: "1ST 2 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460160,
        selectionID: 4601601,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460160,
      ip: 1,
      mi: 2576926,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.480Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460161",
    selectionId: "4601611",
    centralizedId: "2576927",
    fancyName: "1ST 2 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460161,
        selectionID: 4601611,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460161,
      ip: 1,
      mi: 2576927,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:35.892Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460162",
    selectionId: "4601621",
    centralizedId: "2576928",
    fancyName: "1ST 3 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460162,
        selectionID: 4601621,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460162,
      ip: 1,
      mi: 2576928,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.481Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460163",
    selectionId: "4601631",
    centralizedId: "2576929",
    fancyName: "1ST 3 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460163,
        selectionID: 4601631,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460163,
      ip: 1,
      mi: 2576929,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:32.976Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460164",
    selectionId: "4601641",
    centralizedId: "2576930",
    fancyName: "1ST 4 WKT RUNS IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460164,
        selectionID: 4601641,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460164,
      ip: 1,
      mi: 2576930,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.482Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b41f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460165",
    selectionId: "4601651",
    centralizedId: "2576931",
    fancyName: "1ST 4 WKT RUNS ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460165,
        selectionID: 4601651,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460165,
      ip: 1,
      mi: 2576931,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:39:28.613Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b420",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460166",
    selectionId: "4601661",
    centralizedId: "2576932",
    fancyName: "1ST WKT PSHIP BOUNDARIES IND (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460166,
        selectionID: 4601661,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460166,
      ip: 1,
      mi: 2576932,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.731Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b421",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460167",
    selectionId: "4601671",
    centralizedId: "2576933",
    fancyName: "1ST WKT PSHIP BOUNDARIES ENG (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460167,
        selectionID: 4601671,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460167,
      ip: 1,
      mi: 2576933,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.483Z",
      rt: [
        {
          ri: "4601671",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601671",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b422",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460168",
    selectionId: "4601681",
    centralizedId: "2576934",
    fancyName: "1ST WKT LOST TO IND BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460168,
        selectionID: 4601681,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460168,
      ip: 1,
      mi: 2576934,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:04.736Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b423",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460169",
    selectionId: "4601691",
    centralizedId: "2576935",
    fancyName: "1ST WKT LOST TO ENG BALLS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460169,
        selectionID: 4601691,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 9,
    status: "open",
    odds: {
      bmi: 460169,
      ip: 1,
      mi: 2576935,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.484Z",
      rt: [
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601691",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b424",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460170",
    selectionId: "4601701",
    centralizedId: "2576936",
    fancyName: "R SHARMA RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460170,
        selectionID: 4601701,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460170,
      ip: 1,
      mi: 2576936,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.486Z",
      rt: [
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601701",
          rt: 42,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b425",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460171",
    selectionId: "4601711",
    centralizedId: "2576937",
    fancyName: "KL RAHUL RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460171,
        selectionID: 4601711,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460171,
      ip: 1,
      mi: 2576937,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.712Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b426",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460172",
    selectionId: "4601721",
    centralizedId: "2576938",
    fancyName: "J BUTTLER RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460172,
        selectionID: 4601721,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460172,
      ip: 1,
      mi: 2576938,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.487Z",
      rt: [
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601721",
          rt: 26,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b427",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460173",
    selectionId: "4601731",
    centralizedId: "2576939",
    fancyName: "A HALES RUN (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460173,
        selectionID: 4601731,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460173,
      ip: 1,
      mi: 2576939,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.491Z",
      rt: [
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601731",
          rt: 21,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b428",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460174",
    selectionId: "4601741",
    centralizedId: "2576940",
    fancyName: "KL RAHUL BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460174,
        selectionID: 4601741,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460174,
      ip: 1,
      mi: 2576940,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:30.740Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b429",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460175",
    selectionId: "4601751",
    centralizedId: "2576941",
    fancyName: "R SHARMA BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460175,
        selectionID: 4601751,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460175,
      ip: 1,
      mi: 2576941,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.492Z",
      rt: [
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 115,
        },
        {
          ri: "4601751",
          rt: 6,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 85,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460176",
    selectionId: "4601761",
    centralizedId: "2576942",
    fancyName: "J BUTTLER BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460176,
        selectionID: 4601761,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460176,
      ip: 1,
      mi: 2576942,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.494Z",
      rt: [
        {
          ri: "4601761",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601761",
          rt: 5,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460177",
    selectionId: "4601771",
    centralizedId: "2576943",
    fancyName: "A HALES BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460177,
        selectionID: 4601771,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460177,
      ip: 1,
      mi: 2576943,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.495Z",
      rt: [
        {
          ri: "4601771",
          rt: 3,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 100,
        },
        {
          ri: "4601771",
          rt: 4,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 100,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460178",
    selectionId: "4601781",
    centralizedId: "2576944",
    fancyName: "H M B FACE BY KL RAHUL (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460178,
        selectionID: 4601781,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460178,
      ip: 1,
      mi: 2576944,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:07:11.009Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b42d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460179",
    selectionId: "4601791",
    centralizedId: "2576945",
    fancyName: "H M B FACE BY R SHARMA (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460179,
        selectionID: 4601791,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460179,
      ip: 1,
      mi: 2576945,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.499Z",
      rt: [
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601791",
          rt: 34,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460180",
    selectionId: "4601801",
    centralizedId: "2576946",
    fancyName: "H M B FACE BY J BUTTLER (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460180,
        selectionID: 4601801,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460180,
      ip: 1,
      mi: 2576946,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.500Z",
      rt: [
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601801",
          rt: 23,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b42f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460181",
    selectionId: "4601811",
    centralizedId: "2576947",
    fancyName: "H M B FACE BY A HALES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460181,
        selectionID: 4601811,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 7,
    status: "open",
    odds: {
      bmi: 460181,
      ip: 1,
      mi: 2576947,
      ms: 9,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.501Z",
      rt: [
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 110,
        },
        {
          ri: "4601811",
          rt: 18,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 90,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b430",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460182",
    selectionId: "4601821",
    centralizedId: "2576948",
    fancyName: "TOTAL MATCH FOURS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460182,
        selectionID: 4601821,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460182,
      ip: 1,
      mi: 2576948,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:25.336Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b431",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460183",
    selectionId: "4601831",
    centralizedId: "2576949",
    fancyName: "TOTAL MATCH SIXES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460183,
        selectionID: 4601831,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460183,
      ip: 1,
      mi: 2576949,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:31.866Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b432",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460184",
    selectionId: "4601841",
    centralizedId: "2576950",
    fancyName: "TOTAL MATCH WKTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460184,
        selectionID: 4601841,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460184,
      ip: 1,
      mi: 2576950,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:37.679Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b433",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460185",
    selectionId: "4601851",
    centralizedId: "2576951",
    fancyName: "TOTAL MATCH WIDES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460185,
        selectionID: 4601851,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460185,
      ip: 1,
      mi: 2576951,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:43.510Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b434",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460186",
    selectionId: "4601861",
    centralizedId: "2576952",
    fancyName: "TOTAL MATCH EXTRAS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460186,
        selectionID: 4601861,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460186,
      ip: 1,
      mi: 2576952,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:48.629Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b435",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460187",
    selectionId: "4601871",
    centralizedId: "2576953",
    fancyName: "TOTAL MATCH CAUGHT OUTS (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460187,
        selectionID: 4601871,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460187,
      ip: 1,
      mi: 2576953,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:53.716Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b436",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460188",
    selectionId: "4601881",
    centralizedId: "2576954",
    fancyName: "TOTAL MATCH BOWLED (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460188,
        selectionID: 4601881,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460188,
      ip: 1,
      mi: 2576954,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:54:58.788Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b437",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460189",
    selectionId: "4601891",
    centralizedId: "2576955",
    fancyName: "TOTAL MATCH LBW (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460189,
        selectionID: 4601891,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460189,
      ip: 1,
      mi: 2576955,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:02.432Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b438",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460190",
    selectionId: "4601901",
    centralizedId: "2576956",
    fancyName: "TOTAL MATCH FIFTIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460190,
        selectionID: 4601901,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460190,
      ip: 1,
      mi: 2576956,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:06.789Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b439",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460191",
    selectionId: "4601911",
    centralizedId: "2576957",
    fancyName: "HIGHEST SCORING OVER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460191,
        selectionID: 4601911,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460191,
      ip: 1,
      mi: 2576957,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:11.884Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43a",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460192",
    selectionId: "4601921",
    centralizedId: "2576958",
    fancyName: "TOP BATSMAN RUNS IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460192,
        selectionID: 4601921,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460192,
      ip: 1,
      mi: 2576958,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:16.930Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43b",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460193",
    selectionId: "4601931",
    centralizedId: "2576959",
    fancyName: "3 WKT OR MORE BY BOWLER IN MATCH (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460193,
        selectionID: 4601931,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460193,
      ip: 1,
      mi: 2576959,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:22.698Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43c",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460194",
    selectionId: "4601941",
    centralizedId: "2576960",
    fancyName: "6 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460194,
        selectionID: 4601941,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460194,
      ip: 1,
      mi: 2576960,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T08:17:45.863Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b43d",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460195",
    selectionId: "4601951",
    centralizedId: "2576961",
    fancyName: "10 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460195,
        selectionID: 4601951,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460195,
      ip: 1,
      mi: 2576961,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.504Z",
      rt: [
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601951",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43e",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460196",
    selectionId: "4601961",
    centralizedId: "2576962",
    fancyName: "15 OVER RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460196,
        selectionID: 4601961,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460196,
      ip: 1,
      mi: 2576962,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.506Z",
      rt: [
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601961",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b43f",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460197",
    selectionId: "4601971",
    centralizedId: "2576963",
    fancyName: "LAMBI RUN IND L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460197,
        selectionID: 4601971,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460197,
      ip: 1,
      mi: 2576963,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.507Z",
      rt: [
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601971",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b440",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460198",
    selectionId: "4601981",
    centralizedId: "2576964",
    fancyName: "6 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460198,
        selectionID: 4601981,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460198,
      ip: 1,
      mi: 2576964,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.510Z",
      rt: [
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601981",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b441",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460199",
    selectionId: "4601991",
    centralizedId: "2576965",
    fancyName: "10 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460199,
        selectionID: 4601991,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460199,
      ip: 1,
      mi: 2576965,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.512Z",
      rt: [
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4601991",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b442",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460200",
    selectionId: "4602001",
    centralizedId: "2576966",
    fancyName: "15 OVER RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460200,
        selectionID: 4602001,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460200,
      ip: 1,
      mi: 2576966,
      ms: 1,
      eid: 31891821,
      grt: "2022-11-10T08:27:51.513Z",
      rt: [
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: false,
          pt: 105,
        },
        {
          ri: "4602001",
          rt: 1,
          bv: 0,
          pr: 1,
          af: 0,
          ib: true,
          pt: 95,
        },
      ],
    },
  },
  {
    _id: "636c853e02114d244506b443",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460201",
    selectionId: "4602011",
    centralizedId: "2576967",
    fancyName: "LAMBI RUN ENG L.D.O.",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460201,
        selectionID: 4602011,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 8,
    status: "open",
    odds: {
      bmi: 460201,
      ip: 1,
      mi: 2576967,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T07:38:50.120Z",
      rt: [],
    },
  },
  {
    _id: "636c853e02114d244506b444",
    matchId: "6368a2919b49ce1be03dedde",
    eventId: "31891821",
    marketId: "460202",
    selectionId: "4602021",
    centralizedId: "2576968",
    fancyName: "TOTAL MATCH BOUNDARIES (ADV)",
    eventDateTime: "2022-11-10T08:00:01.000Z",
    jsonData: [
      {
        marketID: 460202,
        selectionID: 4602021,
        runnerName: "The Bet",
        isWinner: false,
      },
    ],
    marketStatus: 1,
    marketType: 10,
    categoryType: 2,
    status: "open",
    odds: {
      bmi: 460202,
      ip: 1,
      mi: 2576968,
      ms: 4,
      eid: 31891821,
      grt: "2022-11-10T06:55:29.109Z",
      rt: [],
    },
  },
];
export const easyToGoCasino = [
  // {
  //   product: "EVOLUTION GAMING (S)",
  //   code: "1006",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_blackjack-half.jpg",
  // },
  // {
  //   product: "ORIENTAL GAMING",
  //   code: "98",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_supernowa-half.jpg",
  // },
  // {
  //   product: "GREATWALL",
  //   code: "23",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_7mojos-half.jpg",
  // },
  // {
  //   product: "918KAYA",
  //   code: "13",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_horsebook-half.jpg",
  // },
  // {
  //   product: "PLAYBOY",
  //   code: "82",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_minesweeper-half.jpg",
  // },
  // {
  //   product: "CLUBSUNCITY",
  //   code: "67",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_skytrader-half.jpg",
  // },
  {
    product: "GENESIS",
    code: "131",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_32card-half.jpg",
  },
  {
    product: "MICROGAMING",
    code: "35",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_rummy-half.jpg",
  },
  {
    product: "GAMINGSOFT SLOT",
    code: "2",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_binary-half.jpg",
  },
  // {
  //   product: "GAMEPLAY",
  //   code: "151",
  //   productType: "SLOT",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_andarBahar-half.jpg",
  // },
  {
    product: "KMG",
    code: "143",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_sicbo-half.jpg",
  },
  {
    id: 1,
    product: "KINGMAKER",
    code: "117",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_cardMatka-half.jpg",
  },
  {
    product: "CMD",
    code: "88",
    productType: "SPORTBOOK",
    productTypeCode: "3",
    image: "assets/images/home/banner_sevenUpDown-half.jpg",
  },
  {
    product: "1G Poker",
    code: "146",
    productType: "CARD AND BOARD",
    productTypeCode: "5",
    image: "assets/images/home/banner_bpoker-half.jpg",
  },
];


export const AmountArray = [
  { id: 1, amount: 300 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];

export const PokerGames = [
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "",
  },

  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/7.webp",
    title: "Poker",
    gameCode: "67564",
    legacy: "67564_2",
  },
  {
    image: "../assets/images/poker/8.webp",
    title: "32 Cards Casino",
    gameCode: "56967",
    legacy: "56967_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
    gameCode: "87564",
    legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
  {
    image: "../assets/images/poker/11.webp",
    title: "Race 20-20",
    gameCode: "90100",
    legacy: "90100_2",
  },
  {
    image: "../assets/images/poker/12.webp",
    title: "Bollywood Casino",
    gameCode: "67570",
    legacy: "67570_2",
  },
  {
    image: "../assets/images/poker/13.webp",
    title: "Six Player Poker",
    gameCode: "67565",
    legacy: "67565_2",
  },
  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
  },
  {
    image: "../assets/images/poker/15.webp",
    title: "Sic-bo",
    gameCode: "98566",
    legacy: "98566_2",
  },
  {
    image: "../assets/images/poker/16.webp",
    title: "Mufflis Teenpati",
    gameCode: "67601",
    legacy: "67601_3",
  },
  {
    image: "../assets/images/poker/17.webp",
    title: "Trio",
    gameCode: "67610",
    legacy: "67610_2",
  },
  {
    image: "../assets/images/poker/18.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
  },
  {
    image: "../assets/images/poker/19.webp",
    title: "Casino Meter",
    gameCode: "67575",
    legacy: "67575_2",
  },
  {
    image: "../assets/images/poker/20.webp",
    title: "Poker 20-20",
    gameCode: "67567",
    legacy: "67567_2",
  },
  {
    image: "../assets/images/poker/21.webp",
    title: "2 Card Teenpati",
    gameCode: "67660",
    legacy: "67660_2",
  },
  {
    image: "../assets/images/poker/22.webp",
    title: "Queen Race",
    gameCode: "67620",
    legacy: "67620_2",
  },
  {
    image: "../assets/images/poker/23.webp",
    title: "Teenpati Test",
    gameCode: "67630",
    legacy: "67630_2",
  },
  {
    image: "../assets/images/poker/24.webp",
    title: "Casino War",
    gameCode: "67580",
    legacy: "67580_2",
  },
  {
    image: "../assets/images/poker/25.webp",
    title: "The Trap",
    gameCode: "67680",
    legacy: "67680_2",
  },
  {
    image: "../assets/images/poker/26.webp",
    title: "Teenpati Open",
    gameCode: "67640",
    legacy: "67640_2",
  },
  {
    image: "../assets/images/poker/27.webp",
    title: "29 Card Baccarat",
    gameCode: "67690",
    legacy: "67690_2",
  },
  {
    image: "../assets/images/poker/28.webp",
    title: "Race to 17",
    gameCode: "67710",
    legacy: "67710_2",
  },
  {
    image: "../assets/images/poker/29.webp",
    title: "Roulette (Virtual)",
    gameCode: "98792",
    legacy: "98792_3",
  },
  {
    image: "../assets/images/poker/30.webp",
    title: "Dragon Tiger (Virtual)",
    gameCode: "98794",
    legacy: "98794_3",
  },
  {
    image: "../assets/images/poker/31.webp",
    title: "Amar Akbar Anthony (Virtual)",
    gameCode: "98795",
    legacy: "98795_3",
  },
  {
    image: "../assets/images/poker/32.webp",
    title: "Six Player Poker (Virtual)",
    gameCode: "67566",
    legacy: "67566_3",
  },
  {
    image: "../assets/images/poker/33.webp",
    title: "Poker (Virtual)",
    gameCode: "67563",
    legacy: "67563_3",
  },
  {
    image: "../assets/images/poker/34.webp",
    title: "32 Card Casino (Virtual)",
    gameCode: "56966",
    legacy: "56966_3",
  },
  {
    image: "../assets/images/poker/35.webp",
    title: "Teenpatti One-Day (Virtual)",
    gameCode: "56766",
    legacy: "56766_3",
  },
  {
    image: "../assets/images/poker/36.webp",
    title: "Andhar Bahar (Virtual)",
    gameCode: "87565",
    legacy: "87565_3",
  },
  {
    image: "../assets/images/poker/37.webp",
    title: "Teenpatti T20 (Virtual)",
    gameCode: "56769",
    legacy: "56769_3",
  },
  {
    image: "../assets/images/poker/38.webp",
    title: "Hi Low (Virtual)",
    gameCode: "56969",
    legacy: "56969_3",
  },
  {
    image: "../assets/images/poker/39.webp",
    title: "Matka (Virtual)",
    gameCode: "92036",
    legacy: "92036_3",
  },
  {
    image: "../assets/images/poker/40.webp",
    title: "7 Up & Down (Virtual)",
    gameCode: "98793",
    legacy: "98793_3",
  },
];

export const AviatorGames = [
  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    legacy: "67722-1_8",
    title: "AviatorX",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    legacy: "67722-2_8",
    title: "Aviator",
  },
];

export const gamesLiveArray = [
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-114.png",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-114",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    
  },
  
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-133",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
    cateogry: "EVO",
    type: "GAME-SHOWS",
    isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-169",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-246.png",
    name: "Gonzo's Treasure Map",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-246",
    cateogry: "EVO",
    type: "GAME-SHOWS",isPopular:true
  },
  
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-023.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-023",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-028.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-028",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-034.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-034",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-035.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-035",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-044.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-044",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-045.png",
    name: "First Person Dream Catcher",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-045",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
 

  
  {
    img: "../assets/images/game-shows/PP-LIVE-075.webp",
    name: "Auto Mega Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-075",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-011",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-044",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin a Win Wild live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild B X2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-088",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-086",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-028",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-055",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    gameType: "LIVE",
    platForm: "PT",
    casinoType: "PT-LIVE-003",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  
  {
    img: "../assets/images/game-shows/PP-LIVE-062.png",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-062",
    cateogry: "PP",
    type: "GAME-SHOWS"
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Bet on Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-030",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-077.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-077",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-031",
    cateogry: "PT",
    type: "GAME-SHOWS",isPopular:true
  },
  {
    img: "../assets/images/game-shows/PT-LIVE-090.png",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-090",
    cateogry: "PT",
    type: "GAME-SHOWS"
  },
  {
    img: "../assets/images/game-shows/PT-LIVE-090.png",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-090",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PT-LIVE-108.png",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-108",
    cateogry: "PT",
    type: "GAME-SHOWS"
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-076.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-076",
    cateogry: "PP",
    type: "GAME-SHOWS",isPopular:true
  },

  // {
  //   img: "../assets/images/game-shows/GAME-SHOW-31.webp",
  //   name: "Quantum BlackJack Plus",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-029",
  //   cateogry: "PT",
  //   type: "GAME-SHOWS",
  // },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "1",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "2",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "3",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "4",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "5",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6001",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6002",
    isPopular:true
  },
  
 
  
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6003",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6004",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6005",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6006",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-179",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-038",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-159",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-134.png",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-134",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
 

  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-255.png",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-255",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-210.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-210",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-245.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-245",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-244.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-244",
    cateogry: "EVO",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    table: "1",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    table: "2",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    table: "3",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    table: "4",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    table: "5",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    table: "6",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-109.png",
    name: "Baccarat Classic",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-109",
    cateogry: "PT",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-001.png",
    name: "Baccarat Classic",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-005.png",
    name: "Baccarat Classic",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-005",
    cateogry: "PT",
    type: "BACCARAT",
    
  },
  {
    img: "../assets/images/Baccarat/PP-LIVE-001.png",
    name: "Baccarat Classic",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-001",
    cateogry: "PP",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/PP-LIVE-010.png",
    name: "Baccarat Classic",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-010",
    cateogry: "PP",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
    isPopular:true
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
    isPopular:true

  },
  {
    img: "../assets/images/Baccarat/PP-LIVE-050.png",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-050",
    cateogry: "PP",
    type: "BACCARAT",
  

  },
  {
    img: "../assets/images/Baccarat/PP-LIVE-051.png",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-051",
    cateogry: "PP",
    type: "BACCARAT",
 

  },
  {
    img: "../assets/images/Baccarat/PP-LIVE-052.png",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-052",
    cateogry: "PP",
    type: "BACCARAT",
 

  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-256.png",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-256",
    cateogry: "EVO",
    type: "BACCARAT",
  },


 
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-182.png",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-182",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  

  
  
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-135.png",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-135",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-136.png",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-136",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-160.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-160",
    cateogry: "EVO",
    type: "BACCARAT",
  },

  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-161.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-161",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-253.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-253",
    cateogry: "EVO",
    type: "BACCARAT",
  },

  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-254.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-254",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  

  
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-153.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-153",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-017.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-017",
    cateogry: "EVO",
    type: "BACCARAT",
  },



  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-255",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-036.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-036",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-041.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-041",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-046.png",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-046",
    cateogry: "EVO",
    type: "BACCARAT",
  },

 




  
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-17.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-18.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-19.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-20.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-21.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-22.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-26.webp",
  //   name: "Speed Baccarat",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-002",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-27.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "SEXY",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-002",
  //   cateogry: "SEXY",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-28.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-003",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1001",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1002",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1003",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1004",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1005",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1006",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5001",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5002",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5003",
   
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5004",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5005",
    
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5006",
    
  },
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-080",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-079",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-008",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-013",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-020",
    cateogry: "EVO",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    table:"71",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    cateogry: "SEXY",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-029",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-026",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-060",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-102.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PP-LIVE-102",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-13.webp",
  //   name: "Roulette",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-009",
  //   cateogry: "VENUS",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-028",
    cateogry: "PP",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-100.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-100",
    cateogry: "PT",
    type: "ROULETTE",
   
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-102.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PP-LIVE-102",
    cateogry: "PT",
    type: "ROULETTE",
   
  },

  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-025",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
    isPopular:true
  },
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-170.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-170",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-171.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-171",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-180.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-180",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-257.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-257",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  
  
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-209.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-209",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-206.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-206",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  
  {
    img: "../assets/images/Roulette/EVOLUTION-LIVE-081.png",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-081",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  
  
  
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
  //   name: "HOTROAD-LIVE-004",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-004",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "5003",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
  //   name: "HOTROAD-LIVE-004",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-004",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "5004",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
  //   name: "HOTROAD-LIVE-004",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-004",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "5005",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
  //   name: "HOTROAD-LIVE-004",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-004",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "5006",
  // },
  
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
  //   name: "HOTROAD-LIVE-002",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-002",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "1003",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
  //   name: "HOTROAD-LIVE-002",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-002",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "1004",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
  //   name: "HOTROAD-LIVE-002",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-002",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "1005",
  // },
  // {
  //   img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
  //   name: "HOTROAD-LIVE-002",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-002",
  //   cateogry: "HOTROAD",
  //   type: "ROULETTE",
  //   table: "1006",
  // },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4001",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4002",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4003",
   
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4004",
   
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4005",
   
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4006",
   
  },
  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-172",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-259.png",
    name: "First Person Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-259",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-260.png",
    name: "Instant Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-260",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },

  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-003",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-211",
    cateogry: "EVO",
    type: "DICE",
    isPopular:true
  },
 
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
    isPopular:true
  },

  {
    img: "../assets/images/Dice/PP-LIVE-079.webp",
    name: "Sic bo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-079",
    cateogry: "PP",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/PT-LIVE-099.png",
    name: "Speed SicBo",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-099",
    cateogry: "PT",
    type: "DICE",
    isPopular:true
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-031.png",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-031",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-047.png",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-047",
    cateogry: "EVO",
    type: "DICE",
  },
  
  // {
  //   img: "../assets/images/Dice/DICE-6.webp",
  //   name: "Red Blue Duel",
  //   platForm: "SEXYBCRT",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-010",
  //   cateogry: "SEXY",
  //   type: "DICE",
  // },
  // {
  //   img: "../assets/images/Dice/DICE-7.webp",
  //   name: "Sic Bo",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-007",
  //   cateogry: "VENUS",
  //   type: "DICE",
  // },
  
  // {
  //   img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
  //   name: "HOTROAD-LIVE-006",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-006",
  //   cateogry: "HOTROAD",
  //   type: "DICE",
  //   table: "4003",
  // },
  // {
  //   img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
  //   name: "HOTROAD-LIVE-006",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-006",
  //   cateogry: "HOTROAD",
  //   type: "DICE",
  //   table: "4004",
  // },
  // {
  //   img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
  //   name: "HOTROAD-LIVE-006",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-006",
  //   cateogry: "HOTROAD",
  //   type: "DICE",
  //   table: "4005",
  // },
  // Tradiotional Start
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2001",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2002",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2003",
   
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2004",
   
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2005",
   
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2006",
   
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7001",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7002",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7003",
 
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
    cateogry: "EVO",
    type: "TRADITIONAL",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
    cateogry: "EVO",
    type: "TRADITIONAL",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-004",
    cateogry: "EVO",
    type: "TRADITIONAL",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-077",
    cateogry: "EVO",
    type: "TRADITIONAL",
    isPopular:true
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "EVO",
    type: "TRADITIONAL",
    isPopular:true
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
    isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-002",
    cateogry: "PT",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "TRADITIONAL",isPopular:true
  },

  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    cateogry: "SEXY",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-004",
    cateogry: "PT",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/PT-LIVE-087.png",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
    cateogry: "PT",
    type: "TRADITIONAL"
  },
  
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-043",
    cateogry: "PP",
    type: "TRADITIONAL",isPopular:true
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    cateogry: "SEXY",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/Dice/MX-LIVE-017_SEXY.png",
    name: "xocdia",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-017",
    cateogry: "SEXY",
    type: "TRADITIONAL",isPopular:true
  },
  {
    img: "../assets/images/TraditionalGames/EVOLUTION-LIVE-033.png",
    name: "Teen Patti Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-033",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  // {
  //   img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
  //   name: "HOTROAD-LIVE-003",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-003",
  //   cateogry: "HOTROAD",
  //   type: "TRADITIONAL",
  //   table: "2003",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
  //   name: "HOTROAD-LIVE-003",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-003",
  //   cateogry: "HOTROAD",
  //   type: "TRADITIONAL",
  //   table: "2004",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
  //   name: "HOTROAD-LIVE-003",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-003",
  //   cateogry: "HOTROAD",
  //   type: "TRADITIONAL",
  //   table: "2005",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
  //   name: "HOTROAD-LIVE-003",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-003",
  //   cateogry: "HOTROAD",
  //   type: "TRADITIONAL",
  //   table: "2006",
  // },

  // {
  //   img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
  //   name: "HOTROAD-LIVE-008",
  //   platForm: "HOTROAD",
  //   gameType: "LIVE",
  //   casinoType: "HOTROAD-LIVE-008",
  //   cateogry: "HOTROAD",
  //   type: "TRADITIONAL",
  //   table: "7003",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
  //   name: "Teen Patti",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-026",
  //   cateogry: "PT",
  //   type: "TRADITIONAL",
  // },
  // Tradiotional End

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
  //   name: "Lightning Black Jack",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-181",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-208",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-227.png",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-227",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-012",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-007.png",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-009.png",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-009",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
  //   name: "Black Jack A",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-002",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
  //   name: "Casino Hold'em",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-015",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
  //   name: "Caribbean Stud Poker",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-024",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/PT-LIVE-007.webp",
  //   name: "All bets Blackjack",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-007",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },

  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // {
  //   img: "../assets/images/CardGames/PT-LIVE-009.webp",
  //   name: "Sette E Mezzo",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-009",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },
  // Table Start  (casino Done) -------

  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-073.webp",
    name: "Video Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-073",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-069.webp",
    name: "Elite Aviator Club",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-069",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-070.webp",
    name: "Interstellar Run",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-070",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-071.webp",
    name: "Olympus Glory",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-071",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-066.webp",
    name: "Coin Pusher",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-066",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-067.webp",
    name: "Cockfighting Arena",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-067",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-063.webp",
    name: "Tongits",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-063",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  
  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-062.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-062",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-049.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-040.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name: "7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  

  {
    img: "../assets/images/Table/TABLE-6.webp",
    name: "Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/NETENT-TABLE-001.png",
    name: "Classic Blackjack",
    platForm: "NETENT",
    gameType: "TABLE",
    casinoType: "NETENT-TABLE-001",
    cateogry: "NETENT",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-021.webp",
    name: "Number Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-021",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-032.webp",
    name: "Andar Bahar",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  
  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  
  

  {
    img: "../assets/images/Table/KM-TABLE-038.webp",
    name: "Blackjack",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-038",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-042.webp",
    name: "Minesweeper",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-042",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-035.webp",
    name: "Bola Golek",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-035",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-030.webp",
    name: "jhandimunda",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  // Table End KINGMAKER (casino Done) -------

  // Table Start JILI (casino Done) -------

  {
    img: "../assets/images/JILI/JILI-TABLE-057.webp",
    name: "Fish Prawn Crab",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-057",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-058.webp",
    name: "Crash Cricket",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-058",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-047.png",
    name: "Crash Bonus",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-047",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-048.png",
    name: "Pool Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-048",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-042.png",
    name: "Mini Flush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-042",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },
  
  {
    img: "../assets/images/Table/JILI-TABLE-033.png",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-031.png",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },

  // Table End JILI (casino Done) -------

  // Other

  {
    img: "../assets/images/Others/MX-LIVE-015_SEXY.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "MX-LIVE-015",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    cateogry: "SEXY",
    type: "OTHERS",
  },
  {
    img: "../assets/images/Others/HOTROAD-LIVE-005.webp",
    name: "HOTROAD-LIVE-005",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-005",
    cateogry: "HOTROAD",
    type: "OTHERS",
    table: "3001",
  },
  // {
  //   img: "../assets/images/Others/VN-LIVE-008.webp",
  //   name: "Thai Fish Prawn Crab",
  //   casinoType: "VN-LIVE-008",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   cateogry: "VENUS",
  //   type: "OTHERS",
  // },
  // Other
  // Slot Start PP (casino Done) -----
  
  {
    img: "../assets/images/slot/NETENT-SLOT-008.png",
    name: "Starburst™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-008",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-017.png",
    name: "Starburst™ XXXtreme",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-017",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-009.png",
    name: "Gonzo's Quest™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-009",
    cateogry: "NETENT",
    type: "SLOT",
  },
  
  {
    img: "../assets/images/slot/NETENT-SLOT-014.png",
    name: "Divine Fortune™ Megaways™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-014",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-013.png",
    name: "Divine Fortune™ Megaways™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-013",
    cateogry: "NETENT",
    type: "SLOT",
  },
  

  {
    img: "../assets/images/slot/NETENT-SLOT-010.png",
    name: "Finn's Golden Tavern™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-010",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-018.png",
    name: "Twin Spin™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-018",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-025.png",
    name: "Jimi Hendrix Online Slot™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-025",
    cateogry: "NETENT",
    type: "SLOT",
  },
  
  {
    img: "../assets/images/slot/NETENT-SLOT-006.png",
    name: "Parthenon: Quest for Immortality™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-006",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-027.png",
    name: "Wild Wild West: The Great Train Heist™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-027",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-001.png",
    name: "Reel Rush™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-001",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-002.png",
    name: "Dracula™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-002",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-003.png",
    name: "Riches of Midgard: Land and Expand™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-003",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-004.png",
    name: "Dark King: Forbidden Riches™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-004",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-005.png",
    name: "Dead or Alive 2™ Feature Buy",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-005",
    cateogry: "NETENT",
    type: "SLOT",
  },
  // {
  //   img: "../assets/images/slot/NETENT-SLOT-006.png",
  //   name: "Parthenon: Quest for Immortality™",
  //   platForm: "NETENT",
  //   gameType: "SLOT",
  //   casinoType: "NETENT-SLOT-006",
  //   cateogry: "NETENT",
  //   type: "SLOT",
  // },
  {
    img: "../assets/images/slot/NETENT-SLOT-007.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-007",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-011.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-011",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-012.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-012",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-015.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-015",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-016.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-016",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-019.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-019",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-020.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-020",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-021.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-021",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-022.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-022",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-023.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-023",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-024.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-024",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-026.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-026",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-028.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-028",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-029.png",
    name: "Jungle Spirit: Call of the Wild™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-029",
    cateogry: "NETENT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/NETENT-SLOT-177.webp",
    name: "101 Candies",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-177",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-178.webp",
    name: "Big Bang Boom",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-178",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-179.webp",
    name: "Crab Trap™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-179",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-180.webp",
    name: "Jack and the Beanstalk Remastered",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-180",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-181.webp",
    name: "Thrill to Grill™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-181",
    cateogry: "NETENT",
    type: "SLOT",
  },
  // {
  //   img: "../assets/images/slot/PT-SLOT-082.webp",
  //   name: "Football Rules",
  //   platForm: "PP",
  //   gameType: "SLOT",
  //   casinoType: "PT-SLOT-082",
  //   cateogry: "PP",
  //   type: "SLOT",
  // },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-448.webp",
    name: "Arowanas Luck",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-448",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-449.webp",
    name: "Arowanas Luck",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-449",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-450.webp",
    name: "Arowanas Luck",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-450",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-409.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-409",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-410.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-410",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-413.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-413",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-414.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-414",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-415.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-415",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-416.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-416",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-417.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-417",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-418.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-418",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-419.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-419",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-420.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-420",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-421.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-421",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-422.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-422",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-423.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-423",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-424.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-424",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-425.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-425",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-426.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-426",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-427.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-427",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-005.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-005",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/KM-SLOT-002.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-002",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----

  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
    name: "Rich Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-018",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
    name: "Candy Dynasty",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-017",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
    name: "Doggy Wealth",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-007",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
    name: "Caishen Coming",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-016",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
    name: "Chef Lady",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-006",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
    name: "Maya King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-009",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
    name: "Pirate King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-010",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
    name: "Great Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-013",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
    name: "Ultra Treasure",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-014",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
    name: "Monkey King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-019",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-075.webp",
    name: "Mahjong Win",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-075",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-076.webp",
    name: "Super Hoo Hey How",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-076",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/slot/SG-SLOT-106.webp",
  //   name: "Fiery Sevens Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-106",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-074.webp",
  //   name: "Mega7",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-074",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-116.webp",
  //   name: "Legacy Of Kong Maxways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-116",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-069.webp",
  //   name: "888",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-069",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-112.webp",
  //   name: "Fruits Mania",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-112",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-076.webp",
  //   name: "DoubleFlame",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-076",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-110.webp",
  //   name: "Space Conquest",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-110",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-081.webp",
  //   name: "Triple Panda",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-081",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-077.webp",
  //   name: "Book of Myth",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-077",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-095.webp",
  //   name: "Poker Ways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-095",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },

  // {
  //   img: "../assets/images/slot/SG-SLOT-086.webp",
  //   name: "Mayan Gems",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-086",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-075.webp",
  //   name: "JokersTreasure",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-075",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-030.webp",
  //   name: "5FortuneSA",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-030",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-103.webp",
  //   name: "Sexy Vegas",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-103",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-107.webp",
  //   name: "Lucky Koi Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-107",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-082.webp",
  //   name: "Gold Panther",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-082",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-032.webp",
  //   name: "Golden Lotus SE",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-032",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-105.webp",
  //   name: "Royale House",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-105",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-063.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-063",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-064.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-064",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-065.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-065",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-066.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-066",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-067.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-067",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-068.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-068",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-069.png",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-069",
    cateogry: "PT",
    type: "SLOT",
  },
 
  {
    img: "../assets/images/slot/PT-SLOT-070.webp",
    name: "Heart of the Frontier",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-070",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-071.webp",
    name: "Thai Paradise",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-071",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-072.webp",
    name: "Legacy of the Wild",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-072",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-073.webp",
    name: "Archer",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-073",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-003.webp",
    name: "Buffalo Blitz",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-003",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-004.webp",
    name: "Captain Treasure",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-004",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-010.webp",
    name: "Funky Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-010",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-011.webp",
    name: "Highway Kings",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-011",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-014.webp",
    name: "Zhao Cai Tong Zi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-014",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-013.webp",
    name: "Jin Qian Wa",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-013",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-015.webp",
    name: "Ugga Bugga",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-015",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-016.webp",
    name: "Safari Heat",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-016",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-002.webp",
    name: "Arowanas Luck",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-002",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-019.webp",
    name: "Gem Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-019",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-008.webp",
    name: "Blue Wizard",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-008",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-018.webp",
    name: "Bonus Bears",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-018",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-017.webp",
    name: "Buffalo Blitz II",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-017",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-007.webp",
    name: "Sky Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-007",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-020.webp",
    name: "Feng Kuang Ma Jiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-020",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-021.webp",
    name: "Epic Ape",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-021",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-024.webp",
    name: "Vacation Station Deluxe",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-024",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-025.webp",
    name: "Eternal Lady",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-025",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-026.webp",
    name: "Golden Macaque",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-026",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-027.webp",
    name: "Fei Long Zai Tian",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-027",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-028.webp",
    name: "Yun Cong Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-028",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-029.webp",
    name: "Jinfu Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-029",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-030.webp",
    name: "Hot Gems",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-030",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-032.webp",
    name: "Dolphin Reef",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-032",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-033.webp",
    name: "Jinns Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-033",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-034.webp",
    name: "Tiger, Turtle, Dragon, Phoenix",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-034",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-035.webp",
    name: "Heart of the Jungle",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-035",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-036.webp",
    name: "Heavenly Ruler",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-036",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-037.webp",
    name: "Ox Riches",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-037",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-038.webp",
    name: "Ice Cave",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-038",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-039.webp",
    name: "Queen of Wands",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-039",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-040.webp",
    name: "Si Xiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-040",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-041.webp",
    name: "Panther Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-041",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-043.webp",
    name: "Asian Fantasy",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-043",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-044.webp",
    name: "Fortune Lions",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-044",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-045.webp",
    name: "Hot KTV",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-045",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-046.webp",
    name: "Fortune Day",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-046",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-047.webp",
    name: "Ri Ri Jin Cai",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-047",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-048.webp",
    name: "Sun WuKong",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-048",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-049.webp",
    name: "Ji Xiang 8",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-049",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-050.webp",
    name: "Jade Emperor Yu Huang Da Di",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-050",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-051.webp",
    name: "Halloween Fortune 2",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-051",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-060.webp",
    name: "Chinese Kitchen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-060",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-061.webp",
    name: "Geisha Story",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-061",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-062.webp",
    name: "Fortunes of the Fox",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-062",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-108.webp",
    name: "Moneybags Man 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-108",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-106.webp",
    name: "FortuneNeko",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-106",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-107.webp",
    name: "Trump Card",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-105.webp",
    name: "BookOfMystery",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-105",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-001.webp",
    name: "Burglar",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-001",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-003.webp",
    name: "Chef Doeuvre",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-003",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-004.webp",
    name: "Lucky Miner",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-004",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-005.webp",
    name: "Candy Land",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-005",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-006.webp",
    name: "Crazy Scientist",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-006",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-007.webp",
    name: "Super Dumpling",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-007",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-008.webp",
    name: "Cash Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-008",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-009.webp",
    name: "Wild Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-010.webp",
    name: "Flirting Scholar Tang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-011.webp",
    name: "Winning Mask",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-012.webp",
    name: "Wu kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-013.webp",
    name: "Llama Adventure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-014.webp",
    name: "Formosa Bear",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-015.webp",
    name: "Lucky Qilin",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-016.webp",
    name: "New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-017.webp",
    name: "Four Treasures",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-018.webp",
    name: "Open Sesame",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-019.webp",
    name: "Banana Saga",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-019",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-021.webp",
    name: "Dancing Pa Pa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-021",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-022.webp",
    name: "Dragon King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-022",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-023.webp",
    name: "Magic Show",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-023",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-024.webp",
    name: "Cock Fight",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-024",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-025.webp",
    name: "Dragon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-025",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-026.webp",
    name: "Rooster In Love",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-026",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-027.webp",
    name: "Monkey King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-028.webp",
    name: "Moonlight Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-028",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-029.webp",
    name: "Mahjong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-029",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-030.webp",
    name: "Guan Gong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-030",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-031.webp",
    name: "Billionaire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-031",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-032.webp",
    name: "Dragon Warrior",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-032",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-033.webp",
    name: "Kingsman",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-033",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-034.webp",
    name: "Curvy Magician",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-034",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-035.webp",
    name: "RollingIn Money",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-035",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-036.webp",
    name: "Chef Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-036",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-045.webp",
    name: "Lucky Dragons",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-045",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-046.webp",
    name: "Lucky Lion",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-046",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-047.webp",
    name: "Coffeeholics",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-047",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-048.webp",
    name: "Napoleon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-048",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-049.webp",
    name: "Lucky Phoenix",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-049",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-050.webp",
    name: "WinningMask II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-050",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-051.webp",
    name: "Open Sesame II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-051",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-052.webp",
    name: "Flirting Scholar Tang II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-052",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-053.webp",
    name: "Fortune Horse",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-053",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-054.webp",
    name: "Xi Yang Yang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-054",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-055.webp",
    name: "Panda Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-055",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-056.webp",
    name: "Zelda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-056",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-057.webp",
    name: "Mr Bao",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-057",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-058.webp",
    name: "One Punch Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-058",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-059.webp",
    name: "Guardians of The Galaxy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-059",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-060.webp",
    name: "Street Fighter",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-060",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-061.webp",
    name: "Star Wars",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-061",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-062.webp",
    name: "War of Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-062",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-063.webp",
    name: "Daji",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-063",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-068.webp",
    name: "Gems Gems",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-068",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-069.webp",
    name: "Dragons World",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-069",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-071.webp",
    name: "Egypt Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-071",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-072.webp",
    name: "Fortune Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-072",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-073.webp",
    name: "Pirate Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-073",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-078.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-078",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-080.webp",
    name: "Inca Empire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-080",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-081.webp",
    name: "Ninja Rush",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-081",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-082.webp",
    name: "Sun Archer",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-082",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-083.webp",
    name: "Mystery of Nine Tales",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-083",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-085.webp",
    name: "Miner Babe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-085",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-086.webp",
    name: "Moneybags Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-086",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-087.webp",
    name: "Double Wilds",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-087",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-088.webp",
    name: "Pop Pop Fruity",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-088",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-089.webp",
    name: "Spindrift",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-089",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-090.webp",
    name: "Spindrift 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-090",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-091.webp",
    name: "Jungle Jungle",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-091",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-092.webp",
    name: "Dragons Gate",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-092",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-096.webp",
    name: "Rex Brothers",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-096",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-097.webp",
    name: "Wonder Elephant",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-097",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----

  // {
  //   img: "../assets/images/slot/FC-SLOT-025.webp",
  //   name: "SUGAR BANG BANG",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-025",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-021.webp",
  //   name: "Lucky Fortunes",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-021",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-020.webp",
  //   name: "Robin Hood",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-020",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-019.webp",
  //   name: "GLORY OF ROME",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-019",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-001.webp",
  //   name: "GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-001",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-002.webp",
  //   name: "THREE LITTLE PIGS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-002",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-003.webp",
  //   name: "HOT POT PARTY",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-003",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-004.webp",
  //   name: "NIGHT MARKET",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-004",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-005.webp",
  //   name: "PONG PONG HU",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-005",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-006.webp",
  //   name: "PANDA DRAGON BOAT",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-006",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-007.webp",
  //   name: "CHINESE NEW YEAR",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-007",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-008.webp",
  //   name: "FORTUNE KOI",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-008",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-009.webp",
  //   name: "HAPPY DUO BAO",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-009",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-010.webp",
  //   name: "	DA LE MEN",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-010",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-011.webp",
  //   name: "ANIMAL RACING",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-011",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-012.webp",
  //   name: "LUXURY GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-012",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-013.webp",
  //   name: "MAGIC BEANS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-013",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-014.webp",
  //   name: "COWBOYS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-014",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-015.webp",
  //   name: "WAR OF THE UNIVERSE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-015",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-016.webp",
  //   name: "TREASURE CRUISE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-016",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
  {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-056.webp",
    name: "Happy Taxi",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-057.webp",
    name: "Gold Rush",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-054.webp",
    name: "Crazy Pusher",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-053.webp",
    name: "Book of Gold",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-052.webp",
    name: "Thor X",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-052",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-003.webp",
    name: "Chin Shi Huang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-010.webp",
    name: "God Of Martial",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-012.webp",
    name: "Hawaii Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-012",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-016.webp",
    name: "Bao boon chin",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-018.webp",
    name: "XiYangYang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-019.webp",
    name: "FortunePig",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-025.webp",
    name: "Dragon Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-025",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-031.webp",
    name: "Boxing King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-032.webp",
    name: "Matka India",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-032",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-037.webp",
    name: "Lucky Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-039.webp",
    name: "Secret Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-039",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-040.webp",
    name: "RomaX",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-040",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-041.webp",
    name: "Super Rich",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-041",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-042.webp",
    name: "Golden Empire",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-048.webp",
    name: "Agent Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  //slot spade start
  {
    img: "../assets/images/slot/SG-SLOT-106.png",
    name: "Fiery Sevens Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-074.png",
    name: "Mega7",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-116.png",
    name: "Legacy Of Kong Maxways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-116",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-069.webp",
    name: "888",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-112.webp",
    name: "Fruits Mania",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-076.png",
    name: "DoubleFlame",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-076",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-110.webp",
    name: "Space Conquest",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-110",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-081.png",
    name: "TriplePanda",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-081",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-077.png",
    name: "BookofMyth",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-077",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-095.png",
    name: "Poker Ways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-095",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-086.png",
    name: "Mayan Gems",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-086",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-075.png",
    name: "JokersTreasure",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-075",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-030.png",
    name: "5FortuneSA",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-030",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-103.webp",
    name: "Sexy Vegas",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-103",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-107.png",
    name: "Lucky Koi Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-107",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-082.png",
    name: "GoldPanther",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-082",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-032.webp",
    name: "GoldenLotusSE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-032",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-105.png",
    name: "Royale House",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-105",
    cateogry: "SPADE",
    type: "SLOT",
  },

  //slot spade end
  {
    img: "../assets/images/slot/FC-SLOT-025.png",
    name: "SUGAR BANG BANG",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-025",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-023.png",
    name: "GRAND BLUE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-023",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-024.png",
    name: "TREASURE RAIDERS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-024",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-021.png",
    name: "Lucky Fortunes",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-021",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-020.png",
    name: "Robin Hood",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-020",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-019.png",
    name: "GLORY OF ROME",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-019",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-001.png",
    name: "GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-001",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-002.png",
    name: "THREE LITTLE PIGS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-002",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-003.png",
    name: "HOT POT PARTY",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-003",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-004.png",
    name: "NIGHT MARKET",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-004",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-005.png",
    name: "PONG PONG HU",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-005",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-006.png",
    name: "PANDA DRAGON BOAT",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-006",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-007.png",
    name: "CHINESE NEW YEAR",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-007",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-008.png",
    name: "FORTUNE KOI",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-008",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-009.png",
    name: "HAPPY DUO BAO",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-009",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-010.png",
    name: "DA LE MEN",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-010",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-011.png",
    name: "ANIMAL RACING",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-011",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-012.png",
    name: "LUXURY GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-012",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-013.png",
    name: "MAGIC BEANS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-013",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-014.png",
    name: "COWBOYS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-014",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-015.png",
    name: "WAR OF THE UNIVERSE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-015",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-016.png",
    name: "TREASURE CRUISE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-016",
    cateogry: "FC",
    type: "SLOT",
  },

  //slot FC start

  //slot FC end

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JDB-FISH-008.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-009.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-010.webp",
    name: "Fishing YiLuFa",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-002",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-011.webp",
    name: "Dragon Master",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-004",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-012.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-013.webp",
    name: "Fishing Legend",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-005",
    cateogry: "JDB",
    type: "FISHING",
  },

  // Fishing Start SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/SG-FISH-001.webp",
    name: "Fishing God",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-001",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-002.webp",
    name: "Fishing War",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-002",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-003.webp",
    name: "Alien Hunter",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-003",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-004.webp",
    name: "Zombie Party",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-004",
    cateogry: "SPADE",
    type: "FISHING",
  },
  // Fishing End SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
 
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-003.webp",
    name: "Jackpot Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-003",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
 
  
  {
    img: "../assets/images/Fishing/JILI-FISH-008.webp",
    name: "Boom Legend",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-008",
    cateogry: "JILI",
    type: "FISHING",
  },

  // Fishing End JILI (casino Done) -----
  // EGAME SPRIBE
  {
    img: "../assets/images/Egame/22001.png",
    name: "Aviator",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-001",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22002.png",
    name: "Dice",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-003",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22008.png",
    name: "Mini Roulette",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-008",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22005.png",
    name: "Mines",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-005",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22006.png",
    name: "Spribe Hilo",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-006",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22004.png",
    name: "Plinko",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-004",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22003.png",
    name: "Goal",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-002",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22009.png",
    name: "Hotline",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-009",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22007.png",
    name: "Keno",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-007",
    cateogry: "SPRIBE",
    type: "EGAME",
  },

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name: "Lucky Color Game",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name: "King Of Football",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name: "Super Super Fruit",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name: "Jogo Do Bicho",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name: "Beer Tycoon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-005.webp",
    name: "CAISHEN PARTY",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-003.webp",
    name: "Birds and Animals",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-009.webp",
    name: "Classic Mario",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-004.webp",
    name: "Happy New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-008.webp",
    name: "Huaguoshan Legends",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JJDB-SLOT-019",
    cateogry: "JDB",
    type: "EGAME",
  },

  // Egame End JDB (casino Done) -----

  //PP EGAME START

  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name: "Fantastic League",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-001",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name: "Penalty Shootout",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name: "Greyhound Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-005",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name: "Horse Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name: "Force 1",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name: "Darts",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-007",
    cateogry: "PP",
    type: "EGAME",
  },

  //PP EGAME END

  // AWC Start -----

  // AWC BG Start -----
  {
    img: "../assets/images/Awc/BG-LIVE-001.webp",
    name: "Live Casino BG",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-001",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-002.webp",
    name: "Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-002",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-003.webp",
    name: "Roulette Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-004.webp",
    name: "Sicbo Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-005.webp",
    name: "Dragon tiger",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-005",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-006.webp",
    name: "Speed Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-006",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-007.webp",
    name: "Mi Card Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-007",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-008.webp",
    name: "Full Color Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-008",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-009.webp",
    name: "BullBull",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-009",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-010.webp",
    name: "Win Three Cards",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-010",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-011.webp",
    name: "RedBlack",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-011",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-012.webp",
    name: "SpeedSicBo",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-013.webp",
    name: "SeDie",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-013",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-014.webp",
    name: "CasinoWar",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-014",
    cateogry: "BG",
    type: "AWC",
  },
  // AWC BG END -----
// AWC BTG START -----
  {
    img: "../assets/images/Awc/BTG-SLOT-001.webp",
    name: "Apollo Pays",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-001",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-002.webp",
    name: "Castle of Terror",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-002",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-003.webp",
    name: "Rasputin Megaways",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-003",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-004.webp",
    name: "Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-004",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-005.webp",
    name: "Extra Chilli",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-005",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-006.webp",
    name: "White Rabbit",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-006",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-007.webp",
    name: "Millionaire",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-007",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-008.webp",
    name: "Golden Catch",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-008",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-009.webp",
    name: "Danger High Voltage",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-009",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-010.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-010",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-011.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-011",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-012.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-012",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-013.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-013",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-014.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-014",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-015.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-015",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-016.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-016",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-017.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-017",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-018.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-018",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-019.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-019",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-020.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-020",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-021.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-021",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-022.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-022",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-023.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-023",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-024.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-024",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-025.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-025",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-026.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-026",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-027.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-027",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-028.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-028",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-029.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-029",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-030.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-030",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-031.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-031",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-032.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-032",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-033.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-033",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-034.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-034",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-035.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-035",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-036.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-036",
    cateogry: "BTG",
    type: "AWC",
  },
  
  {
    img: "../assets/images/Awc/BTG-SLOT-038.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-038",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-039.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-039",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-040.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-040",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-041.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-041",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-042.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-042",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-043.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-043",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-044.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-044",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-045.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-045",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-046.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-046",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-047.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-047",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-048.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-048",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-049.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-049",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-050.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-050",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-051.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-051",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-052.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-052",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-053.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-053",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-054.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-054",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-055.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-055",
    cateogry: "BTG",
    type: "AWC",
  },

  // AWC BTG END -----
  // AWC E1SPORT START -----
  {
    img: "../assets/images/Awc/E1-ESPORTS-001.webp",
    name: "E1Sports",
    platForm: "E1SPORT",
    gameType: "ESPORTS",
    casinoType: "E1-ESPORTS-001",
    cateogry: "E1SPORT",
    type: "AWC",
  },
    // AWC E1SPORT END -----

      // AWC NLC START -----
  {
    img: "../assets/images/Awc/NLC-SLOT-001.webp",
    name: "Das x Boot",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-001",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-002.webp",
    name: "Mental",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-002",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-003.webp",
    name: "Punk Toilet",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-003",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-004.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-004",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-005.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-005",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-006.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-006",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-007.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-007",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-008.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-008",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-009.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-009",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-010.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-010",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-011.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-011",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-012.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-012",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-013.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-013",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-014.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-014",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-015.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-015",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-016.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-016",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-017.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-017",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-018.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-018",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-019.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-019",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-020.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-020",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-021.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-021",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-022.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-022",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-023.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-023",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-024.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-024",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-025.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-025",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-026.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-026",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-027.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-027",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-028.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-028",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-029.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-029",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-030.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-030",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-031.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-031",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-032.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-032",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-033.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-033",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-034.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-034",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-035.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-035",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-036.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-036",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-037.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-037",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-038.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-038",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-039.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-039",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-040.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-040",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-041.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-041",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-042.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-042",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-043.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-043",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-044.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-044",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-045.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-045",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-046.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-046",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-047.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-047",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-048.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-048",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-049.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-049",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-050.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-050",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-051.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-051",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-052.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-052",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-053.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-053",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-054.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-054",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-055.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-055",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-056.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-056",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-057.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-057",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-058.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-058",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-059.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-059",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-060.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-060",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-061.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-061",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-062.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-062",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-063.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-063",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-064.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-064",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-065.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-065",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-066.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-066",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-067.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-067",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-068.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-068",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-069.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-069",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-070.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-070",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-071.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-071",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-072.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-072",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-073.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-073",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-074.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-074",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-075.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-075",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-076.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-076",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-077.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-077",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-078.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-078",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-079.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-079",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-080.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-080",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-081.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-081",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-082.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-082",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-083.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-083",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-084.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-084",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-085.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-085",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-086.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-086",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-087.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-087",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-088.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-088",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-089.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-089",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-090.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-090",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-091.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-091",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-092.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-092",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-093.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-093",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-094.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-094",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-095.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-095",
    cateogry: "NLC",
    type: "AWC",
  },
        // AWC NLC END -----
 // AWC RT START -----
  {
    img: "../assets/images/Awc/RT-SLOT-001.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-001",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-002.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-002",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-003.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-003",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-004.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-004",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-005.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-005",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-006.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-006",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-007.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-007",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-008.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-008",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-009.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-009",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-010.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-010",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-011.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-011",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-012.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-012",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-013.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-013",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-014.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-014",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-015.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-015",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-016.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-016",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-017.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-017",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-018.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-018",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-019.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-019",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-020.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-020",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-021.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-021",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-022.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-022",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-023.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-023",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-024.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-024",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-025.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-025",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-026.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-026",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-027.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-027",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-028.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-028",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-029.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-029",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-030.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-030",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-031.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-031",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-032.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-032",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-033.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-033",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-034.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-034",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-035.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-035",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-036.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-036",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-037.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-037",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-038.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-038",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-039.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-039",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-040.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-040",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-041.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-041",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-042.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-042",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-0433.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-043",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-044.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-044",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-045.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-045",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-046.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-046",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-047.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-047",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-048.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-048",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-049.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-049",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-050.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-050",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-051.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-051",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-052.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-052",
    cateogry: "RT",
    type: "AWC",
  },
   // AWC RT END -----
 // AWC SV388 START -----

  {
    img: "../assets/images/Awc/SV-LIVE-001.webp",
    name: "SV388",
    platForm: "SV388",
    gameType: "LIVE",
    casinoType: "SV-LIVE-001",
    cateogry: "SV388",
    type: "AWC",
  },
   // AWC SV388 END -----
    // AWC YESBINGO START -----
  {
    img: "../assets/images/Awc/YesBingo-BINGO-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-011",
    cateogry: "YesBingo",
    type: "AWC",
  },
 
  {
    img: "../assets/images/Awc/YesBingo-FH-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "FH",
    casinoType: "YesBingo-FH-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-011",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-012.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-012",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-013.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-013",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-014.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-014",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-015.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-015",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-016.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-016",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-017.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-017",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-018.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-018",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-019.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-019",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-020.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-020",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-021.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-021",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-022.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-022",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-023.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-023",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-024.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-024",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-025.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-025",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-026.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-026",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-027.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-027",
    cateogry: "YesBingo",
    type: "AWC",
  },
  
  {
    img: "../assets/images/Awc/YesBingo-SLOT-029.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-029",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-030.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-030",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-031.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-031",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
      // AWC YESBINGO END -----
          // AWC YL START -----
  {
    img: "../assets/images/Awc/YL-EGAME-001.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-001",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-002.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-002",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-003.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-003",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-004.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-004",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-005.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-005",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-006.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-006",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-007.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-007",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-008.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-008",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-009.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-009",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-010.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-010",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-011.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-011",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-012.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-012",
    cateogry: "YL",
    type: "AWC",
  },
       // AWC YL END -----
  // AWC End -----
];

export const RoyalGamming = [
  {
    game_id: "900000",
    game_name: "Royal Gaming Live Lobby",
    category: "LOBBY",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Lobby",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_live_lobby.webp",
    game_code: "RG-LIVELOBBY",
  },
  {
    game_id: "999999",
    game_name: "Royal Gaming Mobile Lobby",
    category: "LOBBY",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Lobby",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG_MLOBBY",
  },

  {
    game_id: "600000",
    game_name: "JiLi Lobby",
    category: "LOBBY",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/jili_lobby1.webp",
    game_code: "jili_lobby",
  },  {
    game_id: "000000",
    game_name: "GAP LOBBY",
    category: "LOBBY",
    provider_name: "GAPLOBBY",
    sub_provider_name: "GAPLOBBY",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/gap-logo.webp",
    game_code: "gap_lobby",
  },
  {
    game_id: "100000",
    game_name: "Ezugi Lobby",
    category: "LOBBY",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_blackjackgold5.jpg",
    game_code: "ezg_lobby",
  },
  
  {
    game_id: "105001",
    game_name: "Blackjack",
    category: "blackjack",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_blackjackgold5.jpg",
    game_code: "ezg_blackjack",
  },
  {
    game_id: "105002",
    game_name: "Baccarat",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ezg_baccarat.webp",
    game_code: "ezg_baccarat",
  },
  {
    game_id: "105003",
    game_name: "Roulette",
    category: "roulette",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_roulettegold3.jpg",
    game_code: "ezg_roulette",
  },
  {
    game_id: "105004",
    game_name: "Bet on Numbers",
    category: "lottery",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ezg_betonnumbers.webp",
    game_code: "ezg_betonnumbers",
  },
  {
    game_id: "105005",
    game_name: "Automatic Roulette",
    category: "roulette",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/auto-roulette-thumbb.webp",
    game_code: "ezg_autoroulette",
  },
  {
    game_id: "105006",
    game_name: "Unlimited Blackjack",
    category: "blackjack",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ezg_unlimitedblackjack.webp",
    game_code: "ezg_unlimitedblackjack",
  },
  {
    game_id: "105007",
    game_name: "Lucky 7",
    category: "lucky7",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/lucky7_228001.webp",
    game_code: "ezg_lucky7",
  },
  {
    game_id: "105008",
    game_name: "Sic Bo",
    category: "sicbo",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/sicbo-thumbnail.webp",
    game_code: "ezg_sicbo",
  },
  {
    game_id: "105009",
    game_name: "Casino Holde’m",
    category: "poker",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_casinoholdem.jpg",
    game_code: "ezg_casinoholdem",
  },
  {
    game_id: "105010",
    game_name: "Bet on Teen Patti",
    category: "poker",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/beton_teenpatti.webp",
    game_code: "ezg_betonteenpatti",
  },
  {
    game_id: "105011",
    game_name: "Teen Patti",
    category: "poker",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/teen_patti_227100.webp",
    game_code: "ezg_teenpatti",
  },
  {
    game_id: "105012",
    game_name: "32 Cards",
    category: "32cards",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/32cards.webp",
    game_code: "ezg_32cards",
  },
  {
    game_id: "105013",
    game_name: "Baccarat Knockout",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/ezugi/ezg_goldenbaccaratknockout.webp",
    game_code: "ezg_baccaratknockout",
  },
  {
    game_id: "105014",
    game_name: "Baccarat Super Six",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ezg_baccaratsuper6.webp",
    game_code: "ezg_baccaratsuper6",
  },
  {
    game_id: "105015",
    game_name: "Dragon Tiger",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ezg_dragontiger.webp",
    game_code: "ezg_dragontiger",
  },
  {
    game_id: "105017",
    game_name: "Marina Casino Baccarat 1",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/ezugi/ezg_marinacasinobaccarat1.webp",
    game_code: "ezg_baccaratqueenco",
  },
  {
    game_id: "105018",
    game_name: "RoulettePortomaso",
    category: "roulette",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_portomasocasinoroulette.jpg",
    game_code: "ezg_rouletteportomaso",
  },
  {
    game_id: "105019",
    game_name: "Andar Bahar",
    category: "andar_bahar",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/andar_bahar_228000.webp",
    game_code: "ezg_andarbahar",
  },
  {
    game_id: "105020",
    game_name: "OTT Andar Bahar",
    category: "andar_bahar",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/ezugi/ott-ab-thumbnail.webp",
    game_code: "ezg_ottandarbahar",
  },
  {
    game_id: "105021",
    game_name: "One Day Teen Patti Classic",
    category: "poker",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/ezugi/ezg_onedayteenpatticlassic.webp",
    game_code: "ezg_onedayteenpatticlassic",
  },
  {
    game_id: "105022",
    game_name: "One Day Teen Patti",
    category: "poker",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_onedayteenpatticlassic.jpg",
    game_code: "ezg_onedayteenpatti",
  },
  {
    game_id: "105023",
    game_name: "Cricket War",
    category: "baccarat",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "ezg_cricketwar",
  },
  {
    game_id: "200000",
    game_name: "Peek Baccarat",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evolution/evo_peekbaccarat.webp",
    game_code: "evo_peekbaccarat",
  },
  {
    game_id: "200138",
    game_name: "Super Sic Bo",
    category: "Live Games",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_supersicbo.webp",
    game_code: "evo_supersicbo",
  },
  {
    game_id: "200139",
    game_name: "Dragon Tiger",
    category: "Live Games",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_dragontiger.webp",
    game_code: "evo_dragontiger",
  },
  {
    game_id: "200140",
    game_name: "Speed Baccarat A",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarata.webp",
    game_code: "evo_livespeedbaccarata",
  },
  {
    game_id: "200141",
    game_name: "Speed Baccarat B",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratb.webp",
    game_code: "evo_livespeedbaccaratb",
  },
  {
    game_id: "200142",
    game_name: "Speed Baccarat C",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratc.webp",
    game_code: "evo_livespeedbaccaratc",
  },
  {
    game_id: "200143",
    game_name: "Speed Baccarat D",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratd.webp",
    game_code: "evo_livespeedbaccaratd",
  },
  {
    game_id: "200144",
    game_name: "Speed Baccarat E",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarate.webp",
    game_code: "evo_livespeedbaccarate",
  },
  {
    game_id: "200145",
    game_name: "Speed Baccarat F",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratf.webp",
    game_code: "evo_livespeedbaccaratf",
  },
  {
    game_id: "200146",
    game_name: "Speed Baccarat G",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratg.webp",
    game_code: "evo_livespeedbaccaratg",
  },
  {
    game_id: "200147",
    game_name: "Speed Baccarat H",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarath.webp",
    game_code: "evo_livespeedbaccarath",
  },
  {
    game_id: "200148",
    game_name: "Speed Baccarat I",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_livespeedbaccarati",
  },
  {
    game_id: "200149",
    game_name: "No Comm Speed Baccarat",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_nocommspeedbaccarat.webp",
    game_code: "evo_nocommspeedbaccarat",
  },
  {
    game_id: "200150",
    game_name: "No Commission Baccarat",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_nocommissionbaccarat.webp",
    game_code: "evo_nocommissionbaccarat",
  },
  {
    game_id: "200151",
    game_name: "Baccarat A",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccarata.webp",
    game_code: "evo_live_baccarat_a",
  },
  {
    game_id: "200152",
    game_name: "Baccarat B",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratb.webp",
    game_code: "evo_livebaccaratb",
  },
  {
    game_id: "200153",
    game_name: "Baccarat C",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratc.webp",
    game_code: "evo_livebaccaratc",
  },
  {
    game_id: "200154",
    game_name: "Baccarat Squeeze",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratsqueeze.webp",
    game_code: "evo_livebaccaratsqueeze",
  },
  {
    game_id: "200155",
    game_name: "Baccarat Control Squeeze",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/evo_livebaccaratcontrolsqueeze.webp",
    game_code: "evo_livebaccaratcontrolsqueeze",
  },
  {
    game_id: "200156",
    game_name: "Salon Prive Baccarat A",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivbaccarat.webp",
    game_code: "evo_salonprivbaccarat",
  },
  {
    game_id: "200157",
    game_name: "Blackjack A",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacka.webp",
    game_code: "evo_liveblackjacka",
  },
  {
    game_id: "200158",
    game_name: "Blackjack B",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackb.webp",
    game_code: "evo_liveblackjackb",
  },
  {
    game_id: "200159",
    game_name: "Free Bet Blackjack",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_freebetblackjack.webp",
    game_code: "evo_freebetblackjack",
  },
  {
    game_id: "200160",
    game_name: "Infinite Blackjack",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_infiniteblackjack.webp",
    game_code: "evo_infiniteblackjack",
  },
  {
    game_id: "200161",
    game_name: "Blackjack Party",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackparty.webp",
    game_code: "evo_liveblackjackparty",
  },
  {
    game_id: "200162",
    game_name: "Blackjack C",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackc.webp",
    game_code: "evo_liveblackjackc",
  },
  {
    game_id: "200163",
    game_name: "Blackjack D",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackd.webp",
    game_code: "evo_liveblackjackd",
  },
  {
    game_id: "200164",
    game_name: "Blackjack Silver A",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver1.webp",
    game_code: "evo_liveblackjacksilver1",
  },
  {
    game_id: "200165",
    game_name: "Blackjack Silver B",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver2.webp",
    game_code: "evo_liveblackjacksilver2",
  },
  {
    game_id: "200166",
    game_name: "Blackjack Silver C",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver3.webp",
    game_code: "evo_liveblackjacksilver3",
  },
  {
    game_id: "200167",
    game_name: "Blackjack Silver D",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver4.webp",
    game_code: "evo_liveblackjacksilver4",
  },
  {
    game_id: "200168",
    game_name: "Blackjack Silver E",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver5.webp",
    game_code: "evo_liveblackjacksilver5",
  },
  {
    game_id: "200169",
    game_name: "Blackjack Silver F",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver6.webp",
    game_code: "evo_liveblackjacksilver6",
  },
  {
    game_id: "200170",
    game_name: "Blackjack Silver G",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacksilver7.webp",
    game_code: "evo_liveblackjacksilver7",
  },
  {
    game_id: "200171",
    game_name: "Blackjack VIP A",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipa.webp",
    game_code: "evo_liveblackjackvipa",
  },
  {
    game_id: "200172",
    game_name: "Blackjack VIP B",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipb.webp",
    game_code: "evo_liveblackjackvipb",
  },
  {
    game_id: "200173",
    game_name: "Blackjack VIP C",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipc.webp",
    game_code: "evo_liveblackjackvipc",
  },
  {
    game_id: "200174",
    game_name: "Blackjack VIP D",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipd.webp",
    game_code: "evo_liveblackjackvipd",
  },
  {
    game_id: "200175",
    game_name: "Blackjack VIP E",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipe.webp",
    game_code: "evo_liveblackjackvipe",
  },
  {
    game_id: "200176",
    game_name: "Blackjack VIP G",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipg.webp",
    game_code: "evo_liveblackjackvipg",
  },
  {
    game_id: "200177",
    game_name: "Blackjack VIP H",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackviph.webp",
    game_code: "evo_liveblackjackviph",
  },
  {
    game_id: "200178",
    game_name: "Blackjack VIP I",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackvipi.webp",
    game_code: "evo_liveblackjackvipi",
  },
  {
    game_id: "200179",
    game_name: "Blackjack VIP L",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipl.webp",
    game_code: "evo_blackjackvipl",
  },
  {
    game_id: "200180",
    game_name: "Blackjack VIP M",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipm.webp",
    game_code: "evo_blackjackvipm",
  },
  {
    game_id: "200181",
    game_name: "Blackjack VIP O",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipo.webp",
    game_code: "evo_blackjackvipo",
  },
  {
    game_id: "200182",
    game_name: "Blackjack VIP P",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/2019_mar_live_blackjack_male_dealer_holding_bonus_card_11350_1987_1.webp",
    game_code: "evo_blackjackvipp",
  },
  {
    game_id: "200183",
    game_name: "Blackjack VIP R",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipr.webp",
    game_code: "evo_blackjackvipr",
  },
  {
    game_id: "200184",
    game_name: "Blackjack VIP S",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvips.webp",
    game_code: "evo_blackjackvips",
  },
  {
    game_id: "200185",
    game_name: "Blackjack VIP T",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipt.webp",
    game_code: "evo_blackjackvipt",
  },
  {
    game_id: "200186",
    game_name: "Blackjack Platinum VIP",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackplatinumvip.webp",
    game_code: "evo_liveblackjackplatinumvip",
  },
  {
    game_id: "200187",
    game_name: "Blackjack Fortune VIP",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackfortunevip.webp",
    game_code: "evo_liveblackjackfortunevip",
  },
  {
    game_id: "200188",
    game_name: "Blackjack Grand VIP",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackgrandvip.webp",
    game_code: "evo_liveblackjackgrandvip",
  },
  {
    game_id: "200189",
    game_name: "Blackjack Diamond VIP",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjackdiamondvip.webp",
    game_code: "evo_liveblackjackdiamondvip",
  },
  {
    game_id: "200190",
    game_name: "Salon Prive Blackjack A",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonpriveblackjack1.webp",
    game_code: "evo_salonpriveblackjack1",
  },
  {
    game_id: "200191",
    game_name: "Salon Prive Blackjack С",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonpriveblackjack2.jpg",
    game_code: "evo_salonpriveblackjack2",
  },
  {
    game_id: "200192",
    game_name: "Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveroulette.webp",
    game_code: "evo_liveroulette",
  },
  {
    game_id: "200193",
    game_name: "Immersive Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveimmersiveroulette.webp",
    game_code: "evo_liveimmersiveroulette",
  },
  {
    game_id: "200194",
    game_name: "Auto-Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveauto-roulette.webp",
    game_code: "evo_liveauto-roulette",
  },
  {
    game_id: "200195",
    game_name: "Speed Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedroulette.webp",
    game_code: "evo_livespeedroulette",
  },
  {
    game_id: "200196",
    game_name: "VIP Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveviproulette.webp",
    game_code: "evo_liveviproulette",
  },
  {
    game_id: "200197",
    game_name: "French Roulette Gold",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livefrenchroulettegold.webp",
    game_code: "evo_livefrenchroulettegold",
  },
  {
    game_id: "200198",
    game_name: "American Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveamericanroulette.webp",
    game_code: "evo_liveamericanroulette",
  },
  {
    game_id: "200199",
    game_name: "Double Ball Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livedoubleballroulette.webp",
    game_code: "evo_livedoubleballroulette",
  },
  {
    game_id: "200200",
    game_name: "Speed Auto Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedautoroulette.webp",
    game_code: "evo_speedautoroulette",
  },
  {
    game_id: "200201",
    game_name: "Auto-Roulette VIP",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveauto-roulettevip.webp",
    game_code: "evo_liveauto-roulettevip",
  },
  {
    game_id: "200202",
    game_name: "Benelux Slingshot (Auto-Roulette La Partage)",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/evo_livebeneluxslingshotauto-roulettelapartage.webp",
    game_code: "evo_livebeneluxslingshotauto-roulettelapartage",
  },
  {
    game_id: "200203",
    game_name: "Salon Prive Roulette 1",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonpriveroulette1.webp",
    game_code: "evo_salonpriveroulette1",
  },
  {
    game_id: "200204",
    game_name: "Side Bet City",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_sidebetcity.webp",
    game_code: "evo_sidebetcity",
  },
  {
    game_id: "200205",
    game_name: "Casino Hold'em",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livecasinoholdem.webp",
    game_code: "evo_livecasinoholdem",
  },
  {
    game_id: "200206",
    game_name: "2 Hand Casino Hold'em",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_2handcasinoholdem.webp",
    game_code: "evo_2handcasinoholdem",
  },
  {
    game_id: "200207",
    game_name: "Extreme Texas Hold'em",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveextremetexasholdem.webp",
    game_code: "evo_liveextremetexasholdem",
  },
  {
    game_id: "200208",
    game_name: "Triple Card Poker",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livetriplecardpoker.webp",
    game_code: "evo_livetriplecardpoker",
  },
  {
    game_id: "200209",
    game_name: "Caribbean Stud Poker",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livecaribbeanstudpoker.webp",
    game_code: "evo_livecaribbeanstudpoker",
  },
  {
    game_id: "200210",
    game_name: "Texas Hold'em Bonus Poker",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_texasholdembonuspoker.webp",
    game_code: "evo_texasholdembonuspoker",
  },
  {
    game_id: "200211",
    game_name: "Dream Catcher",
    category: "Live Wheel of Fortune",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livedreamcatcher.webp",
    game_code: "evo_livedreamcatcher",
  },
  {
    game_id: "200212",
    game_name: "Football Studio",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_footballstudio.webp",
    game_code: "evo_football_studio",
  },
  {
    game_id: "200213",
    game_name: "Blackjack Lobby",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_liveblackjacklobby.webp",
    game_code: "evo_liveblackjacklobby",
  },
  {
    game_id: "200214",
    game_name: "Baccarat Lobby",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratlobby.webp",
    game_code: "evo_livebaccaratlobby",
  },
  {
    game_id: "200215",
    game_name: "TopCard Lobby",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_topcardlobby.webp",
    game_code: "evo_top_games_lobby",
  },
  {
    game_id: "200216",
    game_name: "Roulette Lobby",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/roulette-lobby-thumb.webp",
    game_code: "evo_liveroulettelobby",
  },
  {
    game_id: "200217",
    game_name: "First Person Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersonroulette.webp",
    game_code: "evo_firstpersonroulette",
  },
  {
    game_id: "200218",
    game_name: "First Person Blackjack",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersonblackjack.webp",
    game_code: "evo_firstpersonblackjack",
  },
  {
    game_id: "200219",
    game_name: "First Person Dream Catcher",
    category: "Wheel of Fortune",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersondreamcatcher.webp",
    game_code: "evo_firstpersondreamcatcher",
  },
  {
    game_id: "200220",
    game_name: "Poker Lobby",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_pokerlobby.webp",
    game_code: "evo_pokerlobby",
  },
  {
    game_id: "200221",
    game_name: "Game Shows Lobby",
    category: "Live Games",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_gameshowslobby.webp",
    game_code: "evo_gameshowslobby",
  },
  {
    game_id: "200222",
    game_name: "Japanese Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/japanese-roulette-thumb.webp",
    game_code: "evo_nativespeakingjapaneseroulette",
  },
  {
    game_id: "200223",
    game_name: "Deal or No Deal",
    category: "Live Games",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_dealnodeal.webp",
    game_code: "evo_dealornodeal",
  },
  {
    game_id: "200224",
    game_name: "Salon Prive Baccarat B",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivbaccarat2.webp",
    game_code: "evo_salonprivbaccarat2",
  },
  {
    game_id: "200225",
    game_name: "Speed Blackjack A",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.webp",
    game_code: "evo_speedblackjacka",
  },
  {
    game_id: "200226",
    game_name: "Speed Blackjack B",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjackb.webp",
    game_code: "evo_speedblackjackb",
  },
  {
    game_id: "200227",
    game_name: "Speed Blackjack C",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjackc.webp",
    game_code: "evo_speedblackjackc",
  },
  {
    game_id: "200228",
    game_name: "Speed Blackjack D",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjackd.webp",
    game_code: "evo_speedblackjackd",
  },
  {
    game_id: "200229",
    game_name: "Three Card Poker Lobby",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/threecardpoker-thumb.webp",
    game_code: "evo_threecardpokerlobby",
  },
  {
    game_id: "200230",
    game_name: "Ultimate Texas Holdem Lobby",
    category: "Live Poker",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_ultimatetexasholdemlobby.webp",
    game_code: "evo_ultimatetexasholdemlobby",
  },
  {
    game_id: "200231",
    game_name: "First Person Baccarat Lobby",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersonbaccarat.webp",
    game_code: "evo_firstpersonbaccaratlobby",
  },
  {
    game_id: "200232",
    game_name: "Scalable Blackjack Lobby",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_scalableblackjacklobby.webp",
    game_code: "evo_scalableblackjacklobby",
  },
  {
    game_id: "200233",
    game_name: "Blackjack VIP Q",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipq.webp",
    game_code: "evo_blackjackvipq",
  },
  {
    game_id: "200234",
    game_name: "Blackjack VIP Z",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipz.webp",
    game_code: "evo_blackjackvipz",
  },
  {
    game_id: "200235",
    game_name: "Speed Baccarat J",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedbaccaratj.webp",
    game_code: "evo_speedbaccaratj",
  },
  {
    game_id: "200236",
    game_name: "Blackjack VIP F",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipf.webp",
    game_code: "evo_blackjackvipf",
  },
  {
    game_id: "200237",
    game_name: "Mega Ball",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/MegaBall_Thumbnail.webp",
    game_code: "evo_megaball",
  },
  {
    game_id: "200238",
    game_name: "First Person Mega Ball",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/FirstPersonMegaBall_Thumbnail.webp",
    game_code: "evo_firstpersonmegaball",
  },
  {
    game_id: "200239",
    game_name: "First Person Dragon Tiger",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersondragontiger.webp",
    game_code: "evo_firstpersondragontiger",
  },
  {
    game_id: "200240",
    game_name: "First Person Top Card",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersontopcard.webp",
    game_code: "evo_firstpersontopcard",
  },
  {
    game_id: "200241",
    game_name: "First Person Baccarat",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_firstpersonbaccarat.webp",
    game_code: "evo_firstpersonbaccarat",
  },
  {
    game_id: "200242",
    game_name: "Power Blackjack",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_powerblackjack.webp",
    game_code: "evo_powerblackjack",
  },
  {
    game_id: "200243",
    game_name: "Instant Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/instant-roulette-thumbnail.webp",
    game_code: "evo_instantroulette",
  },
  {
    game_id: "200244",
    game_name: "Salon Prive Blackjack B",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivblackjackb.webp",
    game_code: "evo_salonprivblackjackb",
  },
  {
    game_id: "200245",
    game_name: "Salon Prive Blackjack D",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivblackjackd.webp",
    game_code: "evo_salonprivblackjackd",
  },
  {
    game_id: "200246",
    game_name: "Salon Prive Blackjack F",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivblackjackf.webp",
    game_code: "evo_salonprivblackjackf",
  },
  {
    game_id: "200247",
    game_name: "Salon Prive Baccarat C",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivbaccaratc.webp",
    game_code: "evo_salonprivbaccaratc",
  },
  {
    game_id: "200248",
    game_name: "Salon Prive Baccarat D",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivbaccaratd.webp",
    game_code: "evo_salonprivbaccaratd",
  },
  {
    game_id: "200249",
    game_name: "Craps",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/Craps_Thumbnail.webp",
    game_code: "evo_craps",
  },
  {
    game_id: "200250",
    game_name: "Speed Baccarat K",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccaratf.jpg",
    game_code: "evo_speedbaccaratk",
  },
  {
    game_id: "200251",
    game_name: "No Comm Speed Baccarat B",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratc.webp",
    game_code: "evo_nocommspeedbaccaratb",
  },
  {
    game_id: "200252",
    game_name: "Blackjack VIP Alpha",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipr.webp",
    game_code: "evo_blackjackvipalpha",
  },
  {
    game_id: "200253",
    game_name: "Blackjack VIP Beta",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipr.webp",
    game_code: "evo_blackjackvipbeta",
  },
  {
    game_id: "200254",
    game_name: "Blackjack VIP Gamma",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipl.webp",
    game_code: "evo_blackjackvipgamma",
  },
  {
    game_id: "200255",
    game_name: "First Person Craps",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/craps-first-person-thumbnail.webp",
    game_code: "evo_firstpersoncraps",
  },
  {
    game_id: "200256",
    game_name: "Blackjack VIP X",
    category: "Live Blackjack",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvipr.webp",
    game_code: "evo_blackjackvipx",
  },
  {
    game_id: "200257",
    game_name: "Indian Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_indianroulette.webp",
    game_code: "evo_indianroulette",
  },
  {
    game_id: "200258",
    game_name: "No Comm Speed Baccarat C",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livebaccaratc.webp",
    game_code: "evo_nocommspeedbaccaratc",
  },
  {
    game_id: "200259",
    game_name: "Korean speaking Speed Baccarat",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_koreanspeakingspeedbaccarat",
  },
  {
    game_id: "200260",
    game_name: "Speed Baccarat L",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_speedbaccaratl",
  },
  {
    game_id: "200261",
    game_name: "Speed Baccarat M",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_speedbaccaratm",
  },
  {
    game_id: "200262",
    game_name: "Speed Baccarat N",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_speedbaccaratn",
  },
  {
    game_id: "200263",
    game_name: "Speed Baccarat O",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_speedbaccarato",
  },
  {
    game_id: "200264",
    game_name: "Speed Baccarat P",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.webp",
    game_code: "evo_speedbaccaratp",
  },
  {
    game_id: "200265",
    game_name: "Speed Blackjack E",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjacke",
  },
  {
    game_id: "200266",
    game_name: "Speed Blackjack G",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackg",
  },
  {
    game_id: "200267",
    game_name: "Speed Blackjack H",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackh",
  },
  {
    game_id: "200268",
    game_name: "Speed Blackjack I",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjacki",
  },
  {
    game_id: "200269",
    game_name: "Speed Blackjack J",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackj",
  },
  {
    game_id: "200270",
    game_name: "Speed Blackjack K",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackk",
  },
  {
    game_id: "200271",
    game_name: "Speed Blackjack M",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackm",
  },
  {
    game_id: "200272",
    game_name: "Speed Blackjack L",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_speedblackjacka.jpg",
    game_code: "evo_speedblackjackl",
  },
  {
    game_id: "200273",
    game_name: "Brazilian Roulette",
    category: "Live Roulette",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_brazilianroulette.webp",
    game_code: "evo_brazilianroulette",
  },
  {
    game_id: "200274",
    game_name: "Speed Baccarat Q",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_livespeedbaccarati.jpg",
    game_code: "evo_speedbaccaratq",
  },
  {
    game_id: "200275",
    game_name: "Blackjack VIP 1",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip1",
  },
  {
    game_id: "200276",
    game_name: "Blackjack VIP 2",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip2",
  },
  {
    game_id: "200277",
    game_name: "Blackjack VIP 3",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip3",
  },
  {
    game_id: "200278",
    game_name: "Blackjack VIP 4",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip4",
  },
  {
    game_id: "200279",
    game_name: "Blackjack VIP 5",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip5",
  },
  {
    game_id: "200280",
    game_name: "Blackjack VIP 6",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip6",
  },
  {
    game_id: "200281",
    game_name: "Blackjack VIP 7",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip7",
  },
  {
    game_id: "200282",
    game_name: "Blackjack VIP 8",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip8",
  },
  {
    game_id: "200283",
    game_name: "Blackjack VIP 9",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip9",
  },
  {
    game_id: "200284",
    game_name: "Blackjack VIP 10",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip10",
  },
  {
    game_id: "200285",
    game_name: "Blackjack VIP 11",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip11",
  },
  {
    game_id: "200286",
    game_name: "Blackjack VIP 12",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip12",
  },
  {
    game_id: "200287",
    game_name: "Blackjack VIP 13",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip13",
  },
  {
    game_id: "200288",
    game_name: "Blackjack VIP 14",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip14",
  },
  {
    game_id: "200289",
    game_name: "Blackjack VIP 15",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip15",
  },
  {
    game_id: "200290",
    game_name: "Blackjack VIP 16",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_blackjackvip.webp",
    game_code: "evo_blackjackvip16",
  },
  {
    game_id: "200291",
    game_name: "Gonzo's Treasure Hunt",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_gonzostreasurehunt_th.webp",
    game_code: "evo_gonzostreasurehunt",
  },
  {
    game_id: "200292",
    game_name: "First Person American Roulette",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/evo_firstpersonamericanroulette.webp",
    game_code: "evo_firstpersonamericanroulette",
  },
  {
    game_id: "200293",
    game_name: "Japanese Speed Baccarat A",
    category: "Live Baccarat",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_japanesespeedbaccarat.webp",
    game_code: "evo_japanesespeedbaccarat",
  },
  {
    game_id: "200294",
    game_name: "Salon Privé Blackjack E",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_salonprivblackjackd.jpg",
    game_code: "evo_salonprivblackjacke",
  },
  {
    game_id: "200295",
    game_name: "Fan Tan",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_fantan.webp",
    game_code: "evo_fantan",
  },
  {
    game_id: "200296",
    game_name: "Evolution Top Games",
    category: "Live Dealer",
    provider_name: "DC",
    sub_provider_name: "Evolution Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/evo_topgames.webp",
    game_code: "evo_evolutiontopgames",
  },
  {
    game_id: "201203",
    game_name: "KENO",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_keno.webp",
    game_code: "sbe_keno",
  },
  {
    game_id: "201204",
    game_name: "ROULETTE",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_roulette.webp",
    game_code: "sbe_roulette",
  },
  {
    game_id: "201205",
    game_name: "HILO",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_hilo.webp",
    game_code: "sbe_hilo",
  },
  {
    game_id: "201206",
    game_name: "AVIATOR",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_aviator.webp",
    game_code: "sbe_aviator",
  },
  {
    game_id: "201207",
    game_name: "DICE",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_dice.webp",
    game_code: "sbe_dice",
  },
  {
    game_id: "201208",
    game_name: "MINES",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_mines.webp",
    game_code: "sbe_mines",
  },
  {
    game_id: "201209",
    game_name: "PLINKO",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_plinko.webp",
    game_code: "sbe_plinko",
  },
  {
    game_id: "201210",
    game_name: "GOAL",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/sbe_goal.webp",
    game_code: "sbe_goal",
  },
  {
    game_id: "223001",
    game_name: "Hotline",
    category: "Casual Games",
    provider_name: "DC",
    sub_provider_name: "Spribe",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/spb_hotline/thumb.webp",
    game_code: "spb_hotline",
  },
  {
    game_id: "250001",
    game_name: "Blazing Hot",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/1.jpg",
    game_code: "popok_blazing_hot",
  },
  {
    game_id: "250002",
    game_name: "Diamond Flash",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/2.jpg",
    game_code: "diamond_flash",
  },
  {
    game_id: "250003",
    game_name: "Ultra Hot",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/3.jpg",
    game_code: "ultra_hot",
  },
  {
    game_id: "250004",
    game_name: "20 Lucky Bell",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/4.jpg",
    game_code: "20_lucky_bell",
  },
  {
    game_id: "250005",
    game_name: "40 Lucky Bell",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/5.jpg",
    game_code: "40_lucky_bell",
  },
  {
    game_id: "250006",
    game_name: "Hot Bar",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/6.jpg",
    game_code: "hot_bar",
  },
  {
    game_id: "250007",
    game_name: "20 Hot Bar",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/7.jpg",
    game_code: "20_hot_bar",
  },
  {
    game_id: "250008",
    game_name: "40 Hot Bar",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/8.jpg",
    game_code: "40_hot_bar",
  },
  {
    game_id: "250009",
    game_name: "40 Hot Bar Extra",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/9.jpg",
    game_code: "40_hot_bar_extra",
  },
  {
    game_id: "250010",
    game_name: "100 Lucky Bell",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/10.jpg",
    game_code: "100_lucky_bell",
  },
  {
    game_id: "250011",
    game_name: "Ultra Wheel",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/11.jpg",
    game_code: "ultra_wheel",
  },
  {
    game_id: "250012",
    game_name: "Mega Joker",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/12.jpg",
    game_code: "mega_joker",
  },
  {
    game_id: "250013",
    game_name: "Frozen Fruits",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/13.jpg",
    game_code: "frozen_fruits",
  },
  {
    game_id: "250014",
    game_name: "Diamond Phantom",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/14.jpg",
    game_code: "diamond_phantom",
  },
  {
    game_id: "250015",
    game_name: "Poki Wild",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/15.jpg",
    game_code: "poki_wild",
  },
  {
    game_id: "250016",
    game_name: "Lucky Jungle",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/20.jpg",
    game_code: "lucky_jungle",
  },
  {
    game_id: "250017",
    game_name: "Lucky Jungle 1024",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/21.jpg",
    game_code: "lucky_jungle_1024",
  },
  {
    game_id: "250018",
    game_name: "Novel of Alexander",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/22.jpg",
    game_code: "novel_of_alexander",
  },
  {
    game_id: "250019",
    game_name: "Sultans tale",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/23.jpg",
    game_code: "sultans_tale",
  },
  {
    game_id: "250020",
    game_name: "Los Apaches",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/24.jpg",
    game_code: "los_apaches",
  },
  {
    game_id: "250021",
    game_name: "Magic treasures of Egypt",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/25.jpg",
    game_code: "magic_treasures_of_egypt",
  },
  {
    game_id: "250022",
    game_name: "Lost Treasure",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/27.jpg",
    game_code: "lost_treasure",
  },
  {
    game_id: "250023",
    game_name: "Yummy: Buy Feature",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/28.jpg",
    game_code: "yummy_buy_feature",
  },
  {
    game_id: "250024",
    game_name: "Octopus Life",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/29.jpg",
    game_code: "octopus_life",
  },
  {
    game_id: "250025",
    game_name: "Huga Chaga",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/30.jpg",
    game_code: "huga_chaga",
  },
  {
    game_id: "250026",
    game_name: "Yummy: Trick or Treat",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/31.jpg",
    game_code: "yummy_trick_or_treat",
  },
  {
    game_id: "250027",
    game_name: "Happy Easter",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/32.jpg",
    game_code: "happy_easter",
  },
  {
    game_id: "250028",
    game_name: "Hot Muchacho",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/33.jpg",
    game_code: "hot_muchacho",
  },
  {
    game_id: "250029",
    game_name: "Lucky Smile",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/34.jpg",
    game_code: "lucky_smile",
  },
  {
    game_id: "250030",
    game_name: "Happy Valentines Day",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/35.jpg",
    game_code: "happy_valentines_day",
  },
  {
    game_id: "250031",
    game_name: "Era Of Dragons",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/36.jpg",
    game_code: "era_of_dragons",
  },
  {
    game_id: "250032",
    game_name: "Lucky Patricks Day",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/37.jpg",
    game_code: "lucky_patricks_day",
  },
  {
    game_id: "250033",
    game_name: "Vikings Mystery",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/38.jpg",
    game_code: "vikings_mystery",
  },
  {
    game_id: "250034",
    game_name: "Hot Angels",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/40.jpg",
    game_code: "hot_angels",
  },
  {
    game_id: "250035",
    game_name: "Goddesses of Zeus",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/41.jpg",
    game_code: "goddesses_of_zeus",
  },
  {
    game_id: "250036",
    game_name: "Crazy Poki",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/42.jpg",
    game_code: "crazy_poki",
  },
  {
    game_id: "250037",
    game_name: "Temple of Heroes",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/44.jpg",
    game_code: "temple_of_heroes",
  },
  {
    game_id: "250038",
    game_name: "Richy Panda",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/45.jpg",
    game_code: "richy_panda",
  },
  {
    game_id: "250039",
    game_name: "Gold Of Egypt",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/46.jpg",
    game_code: "gold_of_egypt",
  },
  {
    game_id: "250040",
    game_name: "Flaming Fruit",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/48.jpg",
    game_code: "flaming_fruit",
  },
  {
    game_id: "250041",
    game_name: "Incredible Genie",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/51.jpg",
    game_code: "incredible_genie",
  },
  {
    game_id: "250042",
    game_name: "Los Toros",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/52.jpg",
    game_code: "los_toros",
  },
  {
    game_id: "250043",
    game_name: "Black Booze",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/54.jpg",
    game_code: "black_booze",
  },
  {
    game_id: "250044",
    game_name: "Ronin",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/55.jpg",
    game_code: "ronin",
  },
  {
    game_id: "250045",
    game_name: "Flaming Phoenix",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/62.jpg",
    game_code: "flaming_phoenix",
  },
  {
    game_id: "250046",
    game_name: "FruitBall",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/71.jpg",
    game_code: "fruitball",
  },
  {
    game_id: "250047",
    game_name: "Ho Ho Ho",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/76.jpg",
    game_code: "ho_ho_ho",
  },
  {
    game_id: "250048",
    game_name: "Wild Queen",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/86.jpg",
    game_code: "wild_queen",
  },
  {
    game_id: "250049",
    game_name: "Magic Time",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/87.jpg",
    game_code: "magic_time",
  },
  {
    game_id: "250050",
    game_name: "Wukong",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/90.jpg",
    game_code: "wukong",
  },
  {
    game_id: "250051",
    game_name: "Age Of Vikings",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/93.jpg",
    game_code: "age_of_vikings",
  },
  {
    game_id: "250052",
    game_name: "FastMaster",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/94.jpg",
    game_code: "fastmaster",
  },
  {
    game_id: "250053",
    game_name: "Milky Farm",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/96.jpg",
    game_code: "milky_farm",
  },
  {
    game_id: "250054",
    game_name: "Fast Fruits",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/103.jpg",
    game_code: "fast_fruits",
  },
  {
    game_id: "250055",
    game_name: "Akn",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/109.jpg",
    game_code: "akn",
  },
  {
    game_id: "250056",
    game_name: "Roulette",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/500.jpg",
    game_code: "roulette",
  },
  {
    game_id: "250057",
    game_name: "Multi Fruit",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/501.jpg",
    game_code: "multi_fruit",
  },
  {
    game_id: "250058",
    game_name: "Diamond Fruits",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/502.jpg",
    game_code: "diamond_fruits",
  },
  {
    game_id: "250059",
    game_name: "Hi Lo",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/600.jpg",
    game_code: "hi_lo",
  },
  {
    game_id: "250060",
    game_name: "Plinko",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/601.jpg",
    game_code: "plinko",
  },
  {
    game_id: "250061",
    game_name: "Diamond Quest",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/602.jpg",
    game_code: "diamond_quest",
  },
  {
    game_id: "250062",
    game_name: "Magic Idol",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/603.jpg",
    game_code: "magic_idol",
  },
  {
    game_id: "250063",
    game_name: "Gogo Banana",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/604.jpg",
    game_code: "gogo_banana",
  },
  {
    game_id: "250064",
    game_name: "Electric Crash",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/605.jpg",
    game_code: "electric_crash",
  },
  {
    game_id: "250065",
    game_name: "Baccarat",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/606.jpg",
    game_code: "baccarat",
  },
  {
    game_id: "250066",
    game_name: "Jacks Or Better",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/608.jpg",
    game_code: "jacks_or_better",
  },
  {
    game_id: "250067",
    game_name: "Instant Keno",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/609.jpg",
    game_code: "instant_keno",
  },
  {
    game_id: "250068",
    game_name: "Red Hot Line",
    category: "Others",
    provider_name: "POPOK",
    sub_provider_name: "POPOK",
    status: "ACTIVE",
    url_thumb: "https://st.pokgaming.com/gameThumbnails/300x300/612.jpg",
    game_code: "red_hot_line",
  },
  {
    game_id: "280000",
    game_name: "Vivo Lobby",
    category: "LOBBY",
    provider_name: "DC",
    sub_provider_name: "Vivo Games",
    status: "ACTIVE",
    url_thumb:
      "https://vivogaming.com/wp-content/uploads/2022/02/VivoGaming_White_Landscape.webp",
    game_code: "vivo_lobby",
  },
  {
    game_id: "325000",
    game_name: "Roulette",
    category: "Others",
    provider_name: "TVBET",
    sub_provider_name: "tvbet",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/tvbet/roulette.webp",
    game_code: "tvbet_roulette",
  },
  {
    game_id: "325001",
    game_name: "Blackjack",
    category: "Others",
    provider_name: "TVBET",
    sub_provider_name: "tvbet",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/tvbet/blackjack.webp",
    game_code: "tvbet_blackjack",
  },
  {
    game_id: "325002",
    game_name: "Poker",
    category: "Others",
    provider_name: "TVBET",
    sub_provider_name: "tvbet",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/tvbet/poker.webp",
    game_code: "tvbet_poker",
  },
  {
    game_id: "325003",
    game_name: "Baccarat",
    category: "Others",
    provider_name: "TVBET",
    sub_provider_name: "tvbet",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/tvbet/baccarat.webp",
    game_code: "tvbet_baccarat",
  },
  {
    game_id: "350001",
    game_name: "Baccarat Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/baccarat_lobby.webp",
    game_code: "ea_baccarat_lobby",
  },
  {
    game_id: "350002",
    game_name: "Roulette Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/roulette_lobby.webp",
    game_code: "ea_roulette_lobby",
  },
  {
    game_id: "350003",
    game_name: "Sicbo Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/sicbo_lobby.webp",
    game_code: "ea_sicbo_lobby",
  },
  {
    game_id: "350004",
    game_name: "Blackjack Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/blackjack_lobby.webp",
    game_code: "ea_blackjack_lobby",
  },
  {
    game_id: "350005",
    game_name: "Bull Bull Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/bullbull_lobby.webp",
    game_code: "ea_bull_bull_lobby",
  },
  {
    game_id: "350006",
    game_name: "E-game Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/Egame_lobby.webp",
    game_code: "ea_e-game_lobby",
  },
  {
    game_id: "350007",
    game_name: "Six Cards Party Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/sixcardsparty_lobby.webp",
    game_code: "ea_six_cards_party_lobby",
  },
  {
    game_id: "350008",
    game_name: "Andar Bahar Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/andarbahar_lobby.webp",
    game_code: "ea_andar_bahar_lobby",
  },
  {
    game_id: "350009",
    game_name: "Dragon Tiger Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/dragontiger_lobby.webp",
    game_code: "ea_dragon_tiger_lobby",
  },
  {
    game_id: "350010",
    game_name: "Teen Patti Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/teenpatti_lobby.webp",
    game_code: "ea_teen_patti_lobby",
  },
  {
    game_id: "350011",
    game_name: "Baccarat Commission",
    category: "Bacarrat",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/baccarat_commission.webp",
    game_code: "ea_baccarat_commission",
  },
  {
    game_id: "350012",
    game_name: "Baccarat Non-Commission",
    category: "Bacarrat",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/baccarat_non_comm.webp",
    game_code: "ea_baccarat_non-commission",
  },
  {
    game_id: "350013",
    game_name: "X-Baccarat",
    category: "Bacarrat",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/X_baccarat.webp",
    game_code: "ea_x-baccarat",
  },
  {
    game_id: "350014",
    game_name: "Roulette",
    category: "Roulette",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/roulette.webp",
    game_code: "ea_roulette",
  },
  {
    game_id: "350015",
    game_name: "Flaming Roulette",
    category: "Roulette",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/flame_roulette.webp",
    game_code: "ea_flaming_roulette",
  },
  {
    game_id: "350016",
    game_name: "Sicbo",
    category: "Sic Bo",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/sicbo.webp",
    game_code: "ea_sicbo",
  },
  {
    game_id: "350017",
    game_name: "Thai HiLo",
    category: "Others",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/thai_hilo.webp",
    game_code: "ea_thai_hiLo",
  },
  {
    game_id: "350018",
    game_name: "E-Sicbo ",
    category: "Sic Bo",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egames_sicbo.webp",
    game_code: "ea_e-sicbo",
  },
  {
    game_id: "350019",
    game_name: "E-Thai HiLo",
    category: "Others",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egame_thai_hilo.webp",
    game_code: "ea_e-thai_hiLo",
  },
  {
    game_id: "350020",
    game_name: "Ultimate Blackjack",
    category: "blackjack",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/ultimate_blackjack.webp",
    game_code: "ea_ultimate_blackjack",
  },
  {
    game_id: "350021",
    game_name: "Andar Bahar Commission",
    category: "Andar Bahar",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/andarbahar_com.webp",
    game_code: "ea_andar_bahar_commission",
  },
  {
    game_id: "350022",
    game_name: "Andar Bahar Non-Commission",
    category: "Andar Bahar",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/AndarBahar_noncom.webp",
    game_code: "ea_andar_bahar_non-commission",
  },
  {
    game_id: "350023",
    game_name: "Teen Patti",
    category: "TeenPatti",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/live_teen_patti.webp",
    game_code: "ea_teen_patti",
  },
  {
    game_id: "350024",
    game_name: "Bull Bull",
    category: "Others",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/bullbull.webp",
    game_code: "ea_bull_bull",
  },
  {
    game_id: "350025",
    game_name: "e-Bull Bull",
    category: "Others",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egames_bullbull.webp",
    game_code: "ea_e-bull_bull",
  },
  {
    game_id: "350026",
    game_name: "e-Bull Bull (S)",
    category: "Others",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egames_bullbull(S).webp",
    game_code: "ea_e-bull_bull_S",
  },
  {
    game_id: "350027",
    game_name: "Dragon Tiger",
    category: "Dragon Tiger",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/Dragontiger.webp",
    game_code: "ea_dragon_tiger",
  },
  {
    game_id: "350028",
    game_name: "e-WX Dragon Tiger",
    category: "Dragon Tiger",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egames_DragonTiger.webp",
    game_code: "ea_wx_dragon_tiger",
  },
  {
    game_id: "350029",
    game_name: "e-WX Dragon Tiger (S)",
    category: "Dragon Tiger",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/egames_WXDragonTiger(S).webp",
    game_code: "ea_wx_dragon_tiger_S",
  },
  {
    game_id: "375001",
    game_name: "Aurum Roulette",
    category: "others",
    provider_name: "CREED",
    sub_provider_name: "CREED",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/creed/aurum_roulette.webp",
    game_code: "creed_aurum_roulette",
  },
  {
    game_id: "375002",
    game_name: "Roulette Deluxe",
    category: "others",
    provider_name: "CREED",
    sub_provider_name: "CREED",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/creed/roulette_deluxe.webp",
    game_code: "creed_roulette_deluxe",
  },
  {
    game_id: "375003",
    game_name: "Roulette Aurum",
    category: "others",
    provider_name: "CREED",
    sub_provider_name: "CREED",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/creed/roulette_aurum.webp",
    game_code: "creed_roulette_aurum",
  },
  {
    game_id: "375004",
    game_name: "Roulette VIP",
    category: "others",
    provider_name: "CREED",
    sub_provider_name: "CREED",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/creed/roulette_vip.webp",
    game_code: "creed_roulette_vip",
  },
  {
    game_id: "375005",
    game_name: "Blackjack",
    category: "others",
    provider_name: "CREED",
    sub_provider_name: "CREED",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/creed/blackjack.webp",
    game_code: "creed_blackjack",
  },
  {
    game_id: "400001",
    game_name: "Baccarat Classic",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_baccarat_classic.webp",
    game_code: "MX-LIVE-001",
  },
  {
    game_id: "400002",
    game_name: "Baccarat",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_baccarat.webp",
    game_code: "MX-LIVE-002",
  },
  {
    game_id: "400003",
    game_name: "Baccarat Insurance",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_baccarat_insurance.webp",
    game_code: "MX-LIVE-003",
  },
  {
    game_id: "400004",
    game_name: "DragonTiger",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_dragon_tiger.webp",
    game_code: "MX-LIVE-006",
  },
  {
    game_id: "400006",
    game_name: "Roulette",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_roulette.webp",
    game_code: "MX-LIVE-009",
  },
  {
    game_id: "400007",
    game_name: "Red Blue Duel",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_red_blue_duel.webp",
    game_code: "MX-LIVE-010",
  },
  {
    game_id: "400008",
    game_name: "2020 Teen Patti",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_teen_patti_2020.webp",
    game_code: "MX-LIVE-011",
  },
  {
    game_id: "400009",
    game_name: "Andar Bahar",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamcasino.live/gap_casino/awc_aesexy/aes_andar_bahar.webp",
    game_code: "MX-LIVE-012",
  },
  {
    game_id: "400010",
    game_name: "1 Day Teen Patti",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SEXYBCRT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/teen_patti.webp",
    game_code: "MX-LIVE-013",
  },
  {
    game_id: "400020",
    game_name: "KINGMAKER LOBBY",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker_lobby.webp",
    game_code: "KINGMAKER-LOBBY",
  },
  {
    game_id: "400021",
    game_name: "thai-hi-lo2",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/thaihilo.webp",
    game_code: "KM-TABLE-009",
  },
  {
    game_id: "400022",
    game_name: "belangkai2",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/belangkai.webp",
    game_code: "KM-TABLE-010",
  },
  {
    game_id: "400023",
    game_name: "dragon-tiger2",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/dragontiger.webp",
    game_code: "KM-TABLE-011",
  },
  {
    game_id: "400024",
    game_name: "fish-prawn-crab2",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/fishprawncrab2.webp",
    game_code: "KM-TABLE-013",
  },
  {
    game_id: "400025",
    game_name: "vn_fish-prawn-crab",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/vietfishprawncrab.webp",
    game_code: "KM-TABLE-014",
  },
  {
    game_id: "400026",
    game_name: "sicbo",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/sicbo.webp",
    game_code: "KM-TABLE-015",
  },
  {
    game_id: "400027",
    game_name: "taixiu",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/taixiu.webp",
    game_code: "KM-TABLE-017",
  },
  {
    game_id: "400028",
    game_name: "keno",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/keno.webp",
    game_code: "KM-TABLE-018",
  },
  {
    game_id: "400029",
    game_name: "thai-fpc",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/thaifishprawncrab.webp",
    game_code: "KM-TABLE-019",
  },
  {
    game_id: "400030",
    game_name: "Bài Cào",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-020",
  },
  {
    game_id: "400031",
    game_name: "NumberMatka",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/numbermatka.webp",
    game_code: "KM-TABLE-021",
  },
  {
    game_id: "400032",
    game_name: "CardMatka",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/cardmatka.webp",
    game_code: "KM-TABLE-022",
  },
  {
    game_id: "400033",
    game_name: "Xocdia2",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/xocdia.webp",
    game_code: "KM-TABLE-023",
  },
  {
    game_id: "400034",
    game_name: "Fan Tan Classic",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/fantanclassic.webp",
    game_code: "KM-TABLE-024",
  },
  {
    game_id: "400035",
    game_name: "Fruit Roulette",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/fruitroulette.webp",
    game_code: "KM-TABLE-025",
  },
  {
    game_id: "400036",
    game_name: "European Roulette",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-027",
  },
  {
    game_id: "400037",
    game_name: "7 Up 7 Down",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/7up7down.webp",
    game_code: "KM-TABLE-028",
  },
  {
    game_id: "400038",
    game_name: "Bai Buu",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-029",
  },
  {
    game_id: "400039",
    game_name: "Jhandi Munda",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/jhandimunda.webp",
    game_code: "KM-TABLE-030",
  },
  {
    game_id: "400040",
    game_name: "Baccarat",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/Bacarrat.webp",
    game_code: "KM-TABLE-026",
  },
  {
    game_id: "400041",
    game_name: "Andar Bahar",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-032",
  },
  {
    game_id: "400042",
    game_name: "Pai Kang",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-033",
  },
  {
    game_id: "400043",
    game_name: "Kingmaker Pok Deng",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "KM-TABLE-034",
  },
  {
    game_id: "400044",
    game_name: "Bola Golek",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/bolagolek.webp",
    game_code: "KM-TABLE-035",
  },
  {
    game_id: "400045",
    game_name: "Coin Toss",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/cointoss.webp",
    game_code: "KM-TABLE-036",
  },
  {
    game_id: "400046",
    game_name: "Cards Hi Lo",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/cardshilow.webp",
    game_code: "KM-TABLE-037",
  },
  {
    game_id: "400047",
    game_name: "Blackjack",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/blackjack.webp",
    game_code: "KM-TABLE-038",
  },
  {
    game_id: "400048",
    game_name: "32 Cards",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/32cards.webp",
    game_code: "KM-TABLE-039",
  },
  {
    game_id: "400049",
    game_name: "Plinko",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/plinko.webp",
    game_code: "KM-TABLE-040",
  },
  {
    game_id: "400050",
    game_name: "Minesweeper",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/minesweeper.webp",
    game_code: "KM-TABLE-042",
  },
  {
    game_id: "400051",
    game_name: "Bonus Dice",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/bonusdice.webp",
    game_code: "KM-TABLE-043",
  },
  {
    game_id: "400052",
    game_name: "Dota Hi-Lo",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/kingmaker/dota_hi_lo.webp",
    game_code: "KM-TABLE-044",
  },
  {
    game_id: "400053",
    game_name: "Monkey King Roulette",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "KINGMAKER",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/kingmaker/monkey_king_roulette.webp",
    game_code: "KM-TABLE-045",
  },
  {
    game_id: "400101",
    game_name: "Thai Shiny Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-001",
  },
  {
    game_id: "400102",
    game_name: "Thai Money Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-002",
  },
  {
    game_id: "400103",
    game_name: "Thai Lucky Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-003",
  },
  {
    game_id: "400104",
    game_name: "Thai Happy Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/thai_happy_lottery.webp",
    game_code: "AE-LOTTO-004",
  },
  {
    game_id: "400105",
    game_name: "Thai Gov. Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-005",
  },
  {
    game_id: "400106",
    game_name: "Hang Seng Index Morning",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-006",
  },
  {
    game_id: "400107",
    game_name: "Hang Seng Index Noon",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-007",
  },
  {
    game_id: "400108",
    game_name: "Nikkei 225 Morning",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-008",
  },
  {
    game_id: "400109",
    game_name: "Nikkei 225 Noon",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-009",
  },
  {
    game_id: "400110",
    game_name: "Chinese stocks Morning",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-010",
  },
  {
    game_id: "400111",
    game_name: "Chinese stocks Noon",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-011",
  },
  {
    game_id: "400112",
    game_name: "Thai stock market Noon",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-012",
  },
  {
    game_id: "400113",
    game_name: "Thai stock market Evening",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-013",
  },
  {
    game_id: "400114",
    game_name: "YeeKee",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-014",
  },
  {
    game_id: "400115",
    game_name: "Super YeeKee",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-015",
  },
  {
    game_id: "400116",
    game_name: "Happy YeeKee",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-016",
  },
  {
    game_id: "400117",
    game_name: "LAO Gov. Lottery",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-017",
  },
  {
    game_id: "400118",
    game_name: "Malaysia Magnum 4D",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-018",
  },
  {
    game_id: "400119",
    game_name: "Malaysia DaMaCai 4D",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-019",
  },
  {
    game_id: "400120",
    game_name: "Singapore 4D",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-020",
  },
  {
    game_id: "400121",
    game_name: "YeeKee Lotto",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-021",
  },
  {
    game_id: "400122",
    game_name: "Taiwan Pick 4",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-022",
  },
  {
    game_id: "400123",
    game_name: "Japan Numbers4",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-023",
  },
  {
    game_id: "400124",
    game_name: "Hanoi",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-024",
  },
  {
    game_id: "400125",
    game_name: "Capital",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-025",
  },
  {
    game_id: "400126",
    game_name: "Quang Ninh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-026",
  },
  {
    game_id: "400127",
    game_name: "Bac Ninh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-027",
  },
  {
    game_id: "400128",
    game_name: "Hai Phong",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-028",
  },
  {
    game_id: "400129",
    game_name: "Nam Dinh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-029",
  },
  {
    game_id: "400130",
    game_name: "Thai Binh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-030",
  },
  {
    game_id: "400131",
    game_name: "Thua Thien-Hua",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-031",
  },
  {
    game_id: "400132",
    game_name: "Phu Yen",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-032",
  },
  {
    game_id: "400133",
    game_name: "Quang Nam",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-033",
  },
  {
    game_id: "400134",
    game_name: "Dak Lak",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-034",
  },
  {
    game_id: "400135",
    game_name: "Da Nang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-035",
  },
  {
    game_id: "400136",
    game_name: "Khanh Hoa",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-036",
  },
  {
    game_id: "400137",
    game_name: "Quang Binh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-037",
  },
  {
    game_id: "400138",
    game_name: "Quang Tri",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-038",
  },
  {
    game_id: "400139",
    game_name: "Binh Dinh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-039",
  },
  {
    game_id: "400140",
    game_name: "Gia Lai",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-040",
  },
  {
    game_id: "400141",
    game_name: "Ninh Thuan",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-041",
  },
  {
    game_id: "400142",
    game_name: "Quang Ngai",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-042",
  },
  {
    game_id: "400143",
    game_name: "Dac Nong",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-043",
  },
  {
    game_id: "400144",
    game_name: "Kon Tum",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-044",
  },
  {
    game_id: "400145",
    game_name: "Ho Chi Minh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-045",
  },
  {
    game_id: "400146",
    game_name: "Dong Thap",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-046",
  },
  {
    game_id: "400147",
    game_name: "Ca Mau",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-047",
  },
  {
    game_id: "400148",
    game_name: "Vung Tau",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-048",
  },
  {
    game_id: "400149",
    game_name: "Bac Lieu",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-049",
  },
  {
    game_id: "400150",
    game_name: "Ben Tre",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-050",
  },
  {
    game_id: "400151",
    game_name: "Can Tho",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-051",
  },
  {
    game_id: "400152",
    game_name: "Soc Trang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-052",
  },
  {
    game_id: "400153",
    game_name: "Dong Nai",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-053",
  },
  {
    game_id: "400154",
    game_name: "Binh Thuan",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-054",
  },
  {
    game_id: "400155",
    game_name: "An Giang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-055",
  },
  {
    game_id: "400156",
    game_name: "Tay Ninh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-056",
  },
  {
    game_id: "400157",
    game_name: "Vinh Long",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-057",
  },
  {
    game_id: "400158",
    game_name: "Binh Duong",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-058",
  },
  {
    game_id: "400159",
    game_name: "Tra Vinh",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-059",
  },
  {
    game_id: "400160",
    game_name: "Binh Phuoc",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-060",
  },
  {
    game_id: "400161",
    game_name: "Hau Giang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-061",
  },
  {
    game_id: "400162",
    game_name: "Long An",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-062",
  },
  {
    game_id: "400163",
    game_name: "Kien Giang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-063",
  },
  {
    game_id: "400164",
    game_name: "Tien Giang",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-064",
  },
  {
    game_id: "400165",
    game_name: "Da Lat",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-065",
  },
  {
    game_id: "400166",
    game_name: "HN 45s",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-066",
  },
  {
    game_id: "400167",
    game_name: "HN 1min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-067",
  },
  {
    game_id: "400168",
    game_name: "HN 1.5min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-068",
  },
  {
    game_id: "400169",
    game_name: "HN 2min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-069",
  },
  {
    game_id: "400170",
    game_name: "HN 5min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-070",
  },
  {
    game_id: "400171",
    game_name: "HCM 45s",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-071",
  },
  {
    game_id: "400172",
    game_name: "HCM 1min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-072",
  },
  {
    game_id: "400173",
    game_name: "HCM 1.5min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-073",
  },
  {
    game_id: "400174",
    game_name: "HCM 2min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-074",
  },
  {
    game_id: "400175",
    game_name: "HCM 5min",
    category: "LOTTO",
    provider_name: "AWC",
    sub_provider_name: "AE",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "AE-LOTTO-075",
  },
  {
    game_id: "400191",
    game_name: "Horsebook",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "HORSEBOOK",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/hrb_horsebook.webp",
    game_code: "HRB-LIVE-001",
  },
  {
    game_id: "400201",
    game_name: "BbqBurger",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-104",
  },
  {
    game_id: "400202",
    game_name: "Glamorous Girl",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-103",
  },
  {
    game_id: "400203",
    game_name: "Star Line",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-102",
  },
  {
    game_id: "400204",
    game_name: "Blossom Of Wealth",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-101",
  },
  {
    game_id: "400205",
    game_name: "Maya Gold Crazy",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-099",
  },
  {
    game_id: "400206",
    game_name: "MarvelousIV",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-098",
  },
  {
    game_id: "400207",
    game_name: "Wonder Elephant",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-097",
  },
  {
    game_id: "400208",
    game_name: "Rex Brothers",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-096",
  },
  {
    game_id: "400209",
    game_name: "NinjaX",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-095",
  },
  {
    game_id: "400210",
    game_name: "Kong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-094",
  },
  {
    game_id: "400211",
    game_name: "LuckyDiamond",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-093",
  },
  {
    game_id: "400212",
    game_name: "DragonsGate",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-092",
  },
  {
    game_id: "400213",
    game_name: "JungleJungle",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-091",
  },
  {
    game_id: "400214",
    game_name: "Spindrift2",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-090",
  },
  {
    game_id: "400215",
    game_name: "Spindrift",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-089",
  },
  {
    game_id: "400216",
    game_name: "PopPopFruity",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-088",
  },
  {
    game_id: "400217",
    game_name: "DoubleWilds",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-087",
  },
  {
    game_id: "400218",
    game_name: "MoneybagsMan",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-086",
  },
  {
    game_id: "400219",
    game_name: "MinerBabe",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-085",
  },
  {
    game_id: "400220",
    game_name: "GoldenDisco",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-084",
  },
  {
    game_id: "400221",
    game_name: "MysteryofNineTales",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-083",
  },
  {
    game_id: "400222",
    game_name: "SunArcher",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-082",
  },
  {
    game_id: "400223",
    game_name: "NinjaRush",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-081",
  },
  {
    game_id: "400224",
    game_name: "Inca Empire",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-080",
  },
  {
    game_id: "400225",
    game_name: "Lucky Fuwa",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-079",
  },
  {
    game_id: "400226",
    game_name: "Fire Bull",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-078",
  },
  {
    game_id: "400227",
    game_name: "Super Niubi Deluxe",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-077",
  },
  {
    game_id: "400228",
    game_name: "Funky King Kong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-076",
  },
  {
    game_id: "400229",
    game_name: "Treasure Bowl",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-075",
  },
  {
    game_id: "400230",
    game_name: "Mjoinir",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-074",
  },
  {
    game_id: "400231",
    game_name: "Pirate Treasure",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-073",
  },
  {
    game_id: "400232",
    game_name: "Fortune Treasure",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-072",
  },
  {
    game_id: "400233",
    game_name: "Egypt Treasure",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-071",
  },
  {
    game_id: "400234",
    game_name: "Super Niubi",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-070",
  },
  {
    game_id: "400235",
    game_name: "Dragons World",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-069",
  },
  {
    game_id: "400236",
    game_name: "GemsGems",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-068",
  },
  {
    game_id: "400237",
    game_name: "GoLaiFu",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-067",
  },
  {
    game_id: "400238",
    game_name: "BirdsParty",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-066",
  },
  {
    game_id: "400239",
    game_name: "LuckyRacing",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-065",
  },
  {
    game_id: "400240",
    game_name: "PokerKing",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-064",
  },
  {
    game_id: "400241",
    game_name: "Daji",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-063",
  },
  {
    game_id: "400242",
    game_name: "WarofBeauty",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-062",
  },
  {
    game_id: "400243",
    game_name: "StarWars",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-061",
  },
  {
    game_id: "400244",
    game_name: "StreetFighter",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-060",
  },
  {
    game_id: "400245",
    game_name: "GuardiansofTheGalaxy",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-059",
  },
  {
    game_id: "400246",
    game_name: "OnePunchMan",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-058",
  },
  {
    game_id: "400247",
    game_name: "MrBao",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-057",
  },
  {
    game_id: "400248",
    game_name: "Zelda",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-056",
  },
  {
    game_id: "400249",
    game_name: "PandaPanda",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-055",
  },
  {
    game_id: "400250",
    game_name: "XiYangYang",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-054",
  },
  {
    game_id: "400251",
    game_name: "FortuneHorse",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-053",
  },
  {
    game_id: "400252",
    game_name: "FlirtingScholarTangII",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-052",
  },
  {
    game_id: "400253",
    game_name: "OpenSesameII",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-051",
  },
  {
    game_id: "400254",
    game_name: "WinningMaskII",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-050",
  },
  {
    game_id: "400255",
    game_name: "LuckyPhoenix",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-049",
  },
  {
    game_id: "400256",
    game_name: "Napoleon",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-048",
  },
  {
    game_id: "400257",
    game_name: "Coffeeholics",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-047",
  },
  {
    game_id: "400258",
    game_name: "LuckyLion",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-046",
  },
  {
    game_id: "400259",
    game_name: "LuckyDragons",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-045",
  },
  {
    game_id: "400260",
    game_name: "Legendary5",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-044",
  },
  {
    game_id: "400261",
    game_name: "TripleKingKong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-043",
  },
  {
    game_id: "400262",
    game_name: "OrientAnimals",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-042",
  },
  {
    game_id: "400263",
    game_name: "LuckySeven",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-041",
  },
  {
    game_id: "400264",
    game_name: "Fa Da Cai",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-040",
  },
  {
    game_id: "400265",
    game_name: "Mining Upstart",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-039",
  },
  {
    game_id: "400266",
    game_name: "ChefPanda",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-036",
  },
  {
    game_id: "400267",
    game_name: "RollingInMoney",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-035",
  },
  {
    game_id: "400268",
    game_name: "CurvyMagician",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-034",
  },
  {
    game_id: "400269",
    game_name: "Kingsman",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-033",
  },
  {
    game_id: "400270",
    game_name: "DragonWarrior",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-032",
  },
  {
    game_id: "400271",
    game_name: "Billionaire",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-031",
  },
  {
    game_id: "400272",
    game_name: "GuanGong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-030",
  },
  {
    game_id: "400273",
    game_name: "Mahjong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-029",
  },
  {
    game_id: "400274",
    game_name: "MoonlightTreasure",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-028",
  },
  {
    game_id: "400275",
    game_name: "MonkeyKing",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-027",
  },
  {
    game_id: "400276",
    game_name: "RoosterInLove",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-026",
  },
  {
    game_id: "400277",
    game_name: "Dragon",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-025",
  },
  {
    game_id: "400278",
    game_name: "CockFight",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-024",
  },
  {
    game_id: "400279",
    game_name: "MagicShow",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-023",
  },
  {
    game_id: "400280",
    game_name: "DragonKing",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-022",
  },
  {
    game_id: "400281",
    game_name: "DancingPaPa",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-021",
  },
  {
    game_id: "400282",
    game_name: "OlympianTemple",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-020",
  },
  {
    game_id: "400283",
    game_name: "BananaSaga",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-019",
  },
  {
    game_id: "400284",
    game_name: "OpenSesame",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-018",
  },
  {
    game_id: "400285",
    game_name: "FourTreasures",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-017",
  },
  {
    game_id: "400286",
    game_name: "NewYear",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-016",
  },
  {
    game_id: "400287",
    game_name: "LuckyQilin",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-015",
  },
  {
    game_id: "400288",
    game_name: "FormosaBear",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-014",
  },
  {
    game_id: "400289",
    game_name: "LlamaAdventure",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-013",
  },
  {
    game_id: "400290",
    game_name: "WuKong",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-012",
  },
  {
    game_id: "400291",
    game_name: "WinningMask",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-011",
  },
  {
    game_id: "400292",
    game_name: "FlirtingScholarTang",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-010",
  },
  {
    game_id: "400293",
    game_name: "WildBeauty",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-009",
  },
  {
    game_id: "400294",
    game_name: "CashMan",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-008",
  },
  {
    game_id: "400295",
    game_name: "SuperDumpling",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-007",
  },
  {
    game_id: "400296",
    game_name: "CrazyScientist",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-006",
  },
  {
    game_id: "400297",
    game_name: "CandyLand",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-005",
  },
  {
    game_id: "400298",
    game_name: "LuckyMiner",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-004",
  },
  {
    game_id: "400299",
    game_name: "ChefDoeuvre",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-003",
  },
  {
    game_id: "400300",
    game_name: "CrystalRealm",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-002",
  },
  {
    game_id: "400301",
    game_name: "Burglar",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-001",
  },
  {
    game_id: "400302",
    game_name: "ProsperityTiger",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "JDB",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "JDB-SLOT-100",
  },
  {
    game_id: "400331",
    game_name: "LUDO",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "LUDO",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "LUDO-TABLE-001",
  },
  {
    game_id: "400340",
    game_name: "RT SLOT LOBBY",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/rt_lobby.webp",
    game_code: "RT-SLOT-LOBBY",
  },
  {
    game_id: "400341",
    game_name: "AncientScript",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Ancient_Script_520x520.webp",
    game_code: "RT-SLOT-001",
  },
  {
    game_id: "400342",
    game_name: "ArcadeBomb",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Arcade_Bomb_520x520.webp",
    game_code: "RT-SLOT-002",
  },
  {
    game_id: "400343",
    game_name: "BlueDiamond",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Blue_Diamond_520x520.webp",
    game_code: "RT-SLOT-003",
  },
  {
    game_id: "400344",
    game_name: "ChineseTreasures",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Chinese_Treasures_343x200.webp",
    game_code: "RT-SLOT-004",
  },
  {
    game_id: "400345",
    game_name: "Cinderella",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Cinderella_520x520.webp",
    game_code: "RT-SLOT-005",
  },
  {
    game_id: "400346",
    game_name: "CrazyGenie",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Crazy_Genie_520x520.webp",
    game_code: "RT-SLOT-006",
  },
  {
    game_id: "400347",
    game_name: "DevilsNumber",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Devils_Number_520x520.webp",
    game_code: "RT-SLOT-007",
  },
  {
    game_id: "400348",
    game_name: "DivineWays",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Divine_Ways_520x520.webp",
    game_code: "RT-SLOT-008",
  },
  {
    game_id: "400349",
    game_name: "DragonsFire",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Dragons_Fire_520x520.webp",
    game_code: "RT-SLOT-009",
  },
  {
    game_id: "400350",
    game_name: "DragonsLuck",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Dragons_Luck_520x520.webp",
    game_code: "RT-SLOT-010",
  },
  {
    game_id: "400351",
    game_name: "DragonsLuckPowerReels",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Dragons_Luck_Power_Reels_520x520.webp",
    game_code: "RT-SLOT-011",
  },
  {
    game_id: "400352",
    game_name: "ElvenMagic",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Elven_Magic_520x520.webp",
    game_code: "RT-SLOT-012",
  },
  {
    game_id: "400353",
    game_name: "EmeraldDiamond",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Emerald_Diamond_520x520.webp",
    game_code: "RT-SLOT-013",
  },
  {
    game_id: "400354",
    game_name: "EpicJourney",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Epic_Journey_520x520.webp",
    game_code: "RT-SLOT-014",
  },
  {
    game_id: "400355",
    game_name: "EsqueletoMariachi",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Esqueleto_Mariachi_520x520.webp",
    game_code: "RT-SLOT-015",
  },
  {
    game_id: "400356",
    game_name: "FiveStar",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Five_Star_520x520.webp",
    game_code: "RT-SLOT-016",
  },
  {
    game_id: "400357",
    game_name: "FiveStarPowerReels",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Five_Star_Power_Reels_520x520.webp",
    game_code: "RT-SLOT-017",
  },
  {
    game_id: "400358",
    game_name: "FlamingFox",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Flaming_Fox_520x520.webp",
    game_code: "RT-SLOT-018",
  },
  {
    game_id: "400359",
    game_name: "FortuneCharm",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Fortune_Charm_520x520.webp",
    game_code: "RT-SLOT-019",
  },
  {
    game_id: "400360",
    game_name: "FortuneFest",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Fortune_Fest_520x520.webp",
    game_code: "RT-SLOT-020",
  },
  {
    game_id: "400361",
    game_name: "FortuneHouse",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_fortune_house.webp",
    game_code: "RT-SLOT-021",
  },
  {
    game_id: "400362",
    game_name: "FruitBlox",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Fruit_Blox_520x520.webp",
    game_code: "RT-SLOT-022",
  },
  {
    game_id: "400363",
    game_name: "GemsGoneWild",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Gems_Gone_Wild_520x520.webp",
    game_code: "RT-SLOT-023",
  },
  {
    game_id: "400364",
    game_name: "Gemtastic",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Gemtasic_520x520.webp",
    game_code: "RT-SLOT-024",
  },
  {
    game_id: "400365",
    game_name: "GodOfWealth",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_God_Of_Wealth_520x520.webp",
    game_code: "RT-SLOT-025",
  },
  {
    game_id: "400366",
    game_name: "GoldStar",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Gold_Star_520x520.webp",
    game_code: "RT-SLOT-026",
  },
  {
    game_id: "400367",
    game_name: "GoldenLotus",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Golden_Lotus_520x520.webp",
    game_code: "RT-SLOT-027",
  },
  {
    game_id: "400368",
    game_name: "GrandWheel",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Grand_Wheel_520x520.webp",
    game_code: "RT-SLOT-029",
  },
  {
    game_id: "400369",
    game_name: "ImperialPalace",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Imperial_Palace_520x520.webp",
    game_code: "RT-SLOT-030",
  },
  {
    game_id: "400370",
    game_name: "JackInAPot",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Jack_In_A_Pot_520x520.webp",
    game_code: "RT-SLOT-031",
  },
  {
    game_id: "400371",
    game_name: "JackpotQuest",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Jackpot_Quest_520x520.webp",
    game_code: "RT-SLOT-032",
  },
  {
    game_id: "400372",
    game_name: "JadeCharms",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Jade_Charms_520x520.webp",
    game_code: "RT-SLOT-033",
  },
  {
    game_id: "400373",
    game_name: "JesterSpins",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Jester_Spins_520x520.webp",
    game_code: "RT-SLOT-034",
  },
  {
    game_id: "400374",
    game_name: "JingleBells",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Jingle_Bells_520x520.webp",
    game_code: "RT-SLOT-035",
  },
  {
    game_id: "400375",
    game_name: "LaserFruit",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Laser_Fruit_520x520.webp",
    game_code: "RT-SLOT-036",
  },
  {
    game_id: "400376",
    game_name: "LionDance",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lion_Dance_520x520.webp",
    game_code: "RT-SLOT-037",
  },
  {
    game_id: "400377",
    game_name: "LuckyEaster",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lucky_Easter_520x520.webp",
    game_code: "RT-SLOT-038",
  },
  {
    game_id: "400378",
    game_name: "LuckyFortuneCat",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lucky_Fortune_Cat_520x520.webp",
    game_code: "RT-SLOT-039",
  },
  {
    game_id: "400379",
    game_name: "LuckyHalloween",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lucky_Halloween_520x520.webp",
    game_code: "RT-SLOT-040",
  },
  {
    game_id: "400380",
    game_name: "LuckyValentine",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lucky_Valentine_520x520.webp",
    game_code: "RT-SLOT-041",
  },
  {
    game_id: "400381",
    game_name: "LuckyWizard",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Lucky_Wizard_520x520.webp",
    game_code: "RT-SLOT-042",
  },
  {
    game_id: "400382",
    game_name: "MagicGate",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Masquerade_520x520.webp",
    game_code: "RT-SLOT-043",
  },
  {
    game_id: "400383",
    game_name: "Masquerade",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Masquerade_520x520.webp",
    game_code: "RT-SLOT-044",
  },
  {
    game_id: "400384",
    game_name: "MayanGods",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Mayan_Gods_520x520.webp",
    game_code: "RT-SLOT-045",
  },
  {
    game_id: "400385",
    game_name: "MegaJade",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "RT-SLOT-046",
  },
  {
    game_id: "400386",
    game_name: "MegaPyramid",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Mega_Pyramid_520x520.webp",
    game_code: "RT-SLOT-047",
  },
  {
    game_id: "400387",
    game_name: "MegaRise",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Mega_Rise_520x520.webp",
    game_code: "RT-SLOT-048",
  },
  {
    game_id: "400388",
    game_name: "MidasGold",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Midas_Gold_520x520.webp",
    game_code: "RT-SLOT-049",
  },
  {
    game_id: "400389",
    game_name: "MysteryReels",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Mystery_Reels_520x520.webp",
    game_code: "RT-SLOT-050",
  },
  {
    game_id: "400390",
    game_name: "MysticWheel",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Mystic_Wheel_520x520.webp",
    game_code: "RT-SLOT-052",
  },
  {
    game_id: "400391",
    game_name: "NinjaWays",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Ninja_Ways_520x520.webp",
    game_code: "RT-SLOT-053",
  },
  {
    game_id: "400392",
    game_name: "OceanFortune",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Ocean_Fortune_520x520.webp",
    game_code: "RT-SLOT-054",
  },
  {
    game_id: "400393",
    game_name: "PersianFortune",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Persian_Fortune_520x520.webp",
    game_code: "RT-SLOT-055",
  },
  {
    game_id: "400394",
    game_name: "PhoenixFirePowerReels",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Phoenix_Fire_Power_Reels_520x520.webp",
    game_code: "RT-SLOT-056",
  },
  {
    game_id: "400395",
    game_name: "PiggyPirates",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "RT-SLOT-057",
  },
  {
    game_id: "400396",
    game_name: "PiratesPlenty",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Pirates_Plenty_520x520.webp",
    game_code: "RT-SLOT-058",
  },
  {
    game_id: "400397",
    game_name: "PussNBoots",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Puss_N_Boots_520x520.webp",
    game_code: "RT-SLOT-059",
  },
  {
    game_id: "400398",
    game_name: "RainbowJackpots",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Rainbow_Jackpots_520x520.webp",
    game_code: "RT-SLOT-060",
  },
  {
    game_id: "400399",
    game_name: "RasLegend",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Ras_Legend_520x520.webp",
    game_code: "RT-SLOT-061",
  },
  {
    game_id: "400400",
    game_name: "Reactor",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Reactor_520x520.webp",
    game_code: "RT-SLOT-062",
  },
  {
    game_id: "400401",
    game_name: "RedDiamond",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Red_Diamond_520x520.webp",
    game_code: "RT-SLOT-063",
  },
  {
    game_id: "400402",
    game_name: "RedPhoenixRising",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Red_Phoenix_Rising_520x520.webp",
    game_code: "RT-SLOT-064",
  },
  {
    game_id: "400403",
    game_name: "ReelHeist",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Reel_Heist_520x520.webp",
    game_code: "RT-SLOT-065",
  },
  {
    game_id: "400404",
    game_name: "RocketMen",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Rocket_Men_520x520.webp",
    game_code: "RT-SLOT-066",
  },
  {
    game_id: "400405",
    game_name: "RoyalGems",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Royal_Gems_520x520.webp",
    game_code: "RT-SLOT-067",
  },
  {
    game_id: "400406",
    game_name: "SnowWildandthe7Features",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Snow_Wild_and_the_7_Features_520x520.webp",
    game_code: "RT-SLOT-068",
  },
  {
    game_id: "400407",
    game_name: "SpinTown",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Stage_888_520x520.webp",
    game_code: "RT-SLOT-069",
  },
  {
    game_id: "400408",
    game_name: "Stage888",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Stage_888_520x520.webp",
    game_code: "RT-SLOT-070",
  },
  {
    game_id: "400409",
    game_name: "TempleOfGold",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Temple_Of_Gold_520x520.webp",
    game_code: "RT-SLOT-072",
  },
  {
    game_id: "400410",
    game_name: "Ten_Elements",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Ten_Elements_520x520.webp",
    game_code: "RT-SLOT-073",
  },
  {
    game_id: "400411",
    game_name: "The_Equalizer",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_The_Equalizer_520x520.webp",
    game_code: "RT-SLOT-074",
  },
  {
    game_id: "400412",
    game_name: "TheGreatestTrainRobbery",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_The_Greatest_Train_Robbery_520x520.webp",
    game_code: "RT-SLOT-075",
  },
  {
    game_id: "400413",
    game_name: "ThorsLightning",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Thors_Lightning_520x520.webp",
    game_code: "RT-SLOT-076",
  },
  {
    game_id: "400414",
    game_name: "TikiFruits",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Tiki_Fruits_520x520.webp",
    game_code: "RT-SLOT-078",
  },
  {
    game_id: "400415",
    game_name: "TotemLightning",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Totem_Lightning_520x520.webp",
    game_code: "RT-SLOT-079",
  },
  {
    game_id: "400416",
    game_name: "TotemLightningPowerReels",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Totem_Lightning_Power_Reels_520x520.webp",
    game_code: "RT-SLOT-080",
  },
  {
    game_id: "400417",
    game_name: "TreasureMine",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Treasure_Mine_520x520.webp",
    game_code: "RT-SLOT-081",
  },
  {
    game_id: "400418",
    game_name: "VickyVentura",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Vicky_Ventura_520x520.webp",
    game_code: "RT-SLOT-082",
  },
  {
    game_id: "400419",
    game_name: "WildFight",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "RT-SLOT-084",
  },
  {
    game_id: "400420",
    game_name: "WildNords",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Wild_Nords_520x520.webp",
    game_code: "RT-SLOT-085",
  },
  {
    game_id: "400421",
    game_name: "WildSpartans",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Wild_Spartans_520x520.webp",
    game_code: "RT-SLOT-086",
  },
  {
    game_id: "400422",
    game_name: "WildWildChest",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Wild_Wild_Chest_520x520.webp",
    game_code: "RT-SLOT-087",
  },
  {
    game_id: "400423",
    game_name: "WinterWonders",
    category: "SLOT",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/Game_RT_Winter_Wonders_520x520.webp",
    game_code: "RT-SLOT-088",
  },
  {
    game_id: "400424",
    game_name: "Baccarat",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_baccarat.webp",
    game_code: "RT-TABLE-001",
  },
  {
    game_id: "400425",
    game_name: "Roulette",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_roulette.webp",
    game_code: "RT-TABLE-002",
  },
  {
    game_id: "400436",
    game_name: "RT TABLE LOBBY",
    category: "TABLE",
    provider_name: "AWC",
    sub_provider_name: "RT",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/rt_lobby_2.webp",
    game_code: "RT-TABLE-LOBBY",
  },
  {
    game_id: "400441",
    game_name: "Virtual Sports",
    category: "VIRTUAL",
    provider_name: "AWC",
    sub_provider_name: "SABA",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "SABA-VIRTUAL-001",
  },
  {
    game_id: "400446",
    game_name: "CockFight",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "SV388",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/sv388_cockfight.webp",
    game_code: "SV-LIVE-001",
  },
  {
    game_id: "400450",
    game_name: "VENUS LOBBY",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/venus_lobby.webp",
    game_code: "VENUS-LOBBY",
  },
  {
    game_id: "400451",
    game_name: "Baccarat Classic",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/venus_baccarat.webp",
    game_code: "VN-LIVE-001",
  },
  {
    game_id: "400452",
    game_name: "Baccarat Speed",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/venus_baccarat.webp",
    game_code: "VN-LIVE-002",
  },
  {
    game_id: "400453",
    game_name: "Baccarat Insurance",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/venus_baccarat.webp",
    game_code: "VN-LIVE-003",
  },
  {
    game_id: "400454",
    game_name: "DragonTiger",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/venus_dragon_tiger.webp",
    game_code: "VN-LIVE-006",
  },
  {
    game_id: "400455",
    game_name: "SicBo",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/rt_mega_jade.webp",
    game_code: "VN-LIVE-007",
  },
  {
    game_id: "400456",
    game_name: "FishPrawnCrab",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/awc_aesexy/venus_fish_prawn_crab.webp",
    game_code: "VN-LIVE-008",
  },
  {
    game_id: "400457",
    game_name: "Roulette",
    category: "LIVE",
    provider_name: "AWC",
    sub_provider_name: "VENUS",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/awc_aesexy/venus_roulette.webp",
    game_code: "VN-LIVE-009",
  },
  {
    game_id: "425000",
    game_name: "Kingmidas Lobby",
    category: "lobby",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "kingmidas_lobby",
  },
  {
    game_id: "425001",
    game_name: "Thai Hi Lo 2",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/thai_hi_lo_2.webp",
    game_code: "kingmidas_thai_hi_lo_2",
  },
  {
    game_id: "425002",
    game_name: "Fish Prawn Crab 2",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/fish_prawn_crab_2.webp",
    game_code: "kingmidas_fish_prawn_crab_2",
  },
  {
    game_id: "425003",
    game_name: "Belangkai 2",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/belangkai_2.webp",
    game_code: "kingmidas_belangkai_2",
  },
  {
    game_id: "425004",
    game_name: "Dragon Tiger 2",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/dragon_tiger_2.webp",
    game_code: "kingmidas_dragon_tiger_2",
  },
  {
    game_id: "425005",
    game_name: "Vietnam Fish Prawn Crab",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamdelhi.com/kingmidas/vietnam_fish_prawn_crab.webp",
    game_code: "kingmidas_vietnam_fish_prawn_crab",
  },
  {
    game_id: "425006",
    game_name: "Sic Bo",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/sic_bo.webp",
    game_code: "kingmidas_sic_bo",
  },
  {
    game_id: "425007",
    game_name: "Tai Xiu",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/tai_xiu.webp",
    game_code: "kingmidas_tai_xiu",
  },
  {
    game_id: "425008",
    game_name: "Max Keno",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/max_keno.webp",
    game_code: "kingmidas_max_keno",
  },
  {
    game_id: "425009",
    game_name: "Poker Roulette",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/poker_roulette.webp",
    game_code: "kingmidas_poker_roulette",
  },
  {
    game_id: "425010",
    game_name: "Thai Fish Prawn Crab",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/thai_fish_prawn_crab.webp",
    game_code: "kingmidas_thai_fish_prawn_crab",
  },
  {
    game_id: "425011",
    game_name: "Xoc Dia Lightning",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/xoc_dia_lightning.webp",
    game_code: "kingmidas_xoc_dia_lightning",
  },
  {
    game_id: "425012",
    game_name: "Fan Tan Classic",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/fan_tan_classic.webp",
    game_code: "kingmidas_fan_tan_classic",
  },
  {
    game_id: "425013",
    game_name: "Triple Chance",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/triple_chance.webp",
    game_code: "kingmidas_triple_chance",
  },
  {
    game_id: "425014",
    game_name: "Fruit Roulette",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/fruit_roulette.webp",
    game_code: "kingmidas_fruit_roulette",
  },
  {
    game_id: "425015",
    game_name: "Bai Cao",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/bai_cao.webp",
    game_code: "kingmidas_bai_cao",
  },
  {
    game_id: "425016",
    game_name: "Bai Buu",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/bai_buu.webp",
    game_code: "kingmidas_bai_buu",
  },
  {
    game_id: "425017",
    game_name: "European Roulette",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/european_roulette.webp",
    game_code: "kingmidas_european_roulette",
  },
  {
    game_id: "425018",
    game_name: "7 Up 7 Down",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/7_up_7_down.webp",
    game_code: "kingmidas_7_up_7_down",
  },
  {
    game_id: "425019",
    game_name: "Gao Gae",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/gao_gae.webp",
    game_code: "kingmidas_gao_gae",
  },
  {
    game_id: "425020",
    game_name: "Jhandi Munda",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/jhandi_munda.webp",
    game_code: "kingmidas_jhandi_munda",
  },
  {
    game_id: "425021",
    game_name: "Andar Bahar",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/andar_bahar.webp",
    game_code: "kingmidas_andar_bahar",
  },
  {
    game_id: "425022",
    game_name: "Baccarat",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/baccarat.webp",
    game_code: "kingmidas_baccarat",
  },
  {
    game_id: "425023",
    game_name: "Kingmaker Pok Deng",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/kingmaker_pok_deng.webp",
    game_code: "kingmidas_kingmaker_pok_deng",
  },
  {
    game_id: "425024",
    game_name: "Bola Golek",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/bola_golek.webp",
    game_code: "kingmidas_bola_golek",
  },
  {
    game_id: "425025",
    game_name: "Pai Kang",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/pai_kang.webp",
    game_code: "kingmidas_pai_kang",
  },
  {
    game_id: "425026",
    game_name: "Coin Toss",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/coin_toss.webp",
    game_code: "kingmidas_coin_toss",
  },
  {
    game_id: "425027",
    game_name: "Cards Hi Lo",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/cards_hi_lo.webp",
    game_code: "kingmidas_cards_hi_lo",
  },
  {
    game_id: "425028",
    game_name: "Blackjack",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/blackjack.webp",
    game_code: "kingmidas_blackjack",
  },
  {
    game_id: "425029",
    game_name: "32 Cards",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/32_cards.webp",
    game_code: "kingmidas_32_cards",
  },
  {
    game_id: "425030",
    game_name: "Plinko",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/plinko.webp",
    game_code: "kingmidas_plinko",
  },
  {
    game_id: "425031",
    game_name: "Cash Rocket",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/cash_rocket.webp",
    game_code: "kingmidas_cash_rocket",
  },
  {
    game_id: "425032",
    game_name: "Bonus Dice",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/bonus_dice.webp",
    game_code: "kingmidas_bonus_dice",
  },
  {
    game_id: "425033",
    game_name: "Minesweeper",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/minesweeper.webp",
    game_code: "kingmidas_minesweeper",
  },
  {
    game_id: "425034",
    game_name: "Dota Hi-Lo",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/dota_hi-lo.webp",
    game_code: "kingmidas_dota_hi-lo",
  },
  {
    game_id: "425035",
    game_name: "Teen Patti",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/teen_patti.webp",
    game_code: "kingmidas_teen_patti",
  },
  {
    game_id: "425036",
    game_name: "Monkey King Roulette",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/monkey_king_roulette.webp",
    game_code: "kingmidas_monkey_king_roulette",
  },
  {
    game_id: "425037",
    game_name: "Heist",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/heist.webp",
    game_code: "kingmidas_heist",
  },
  {
    game_id: "425038",
    game_name: "5 Card Poker",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/5_card_poker.webp",
    game_code: "kingmidas_5_card_poker",
  },
  {
    game_id: "425039",
    game_name: "KM Virtual Horse Racing",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamdelhi.com/kingmidas/km_virtual_horse_racing.webp",
    game_code: "kingmidas_km_virtual_horse_racing",
  },
  {
    game_id: "425040",
    game_name: "Bola Tangkas",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/bola_tangkas.webp",
    game_code: "kingmidas_bola_tangkas",
  },
  {
    game_id: "425041",
    game_name: "Color Game",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/color_game.webp",
    game_code: "kingmidas_color_game",
  },
  {
    game_id: "425042",
    game_name: "KM Power Ball",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/km_power_ball.webp",
    game_code: "kingmidas_km_power_ball",
  },
  {
    game_id: "425043",
    game_name: "KM Marble Knockout",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/km_marble_knockout.webp",
    game_code: "kingmidas_km_marble_knockout",
  },
  {
    game_id: "425044",
    game_name: "KM Virtual Hound Racing",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamdelhi.com/kingmidas/km_virtual_hound_racing.webp",
    game_code: "kingmidas_km_virtual_hound_racing",
  },
  {
    game_id: "425045",
    game_name: "Sugar Blast",
    category: "Slot Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/sugar_blast.webp",
    game_code: "kingmidas_sugar_blast",
  },
  {
    game_id: "425046",
    game_name: "KM Virtual Treadmill Racing",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamdelhi.com/kingmidas/km_virtual_treadmill_racing.webp",
    game_code: "kingmidas_km_virtual_treadmill_racing",
  },
  {
    game_id: "425047",
    game_name: "Ludo",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/ludo.webp",
    game_code: "kingmidas_ludo",
  },
  {
    game_id: "425048",
    game_name: "KM Virtual Animal Race",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamdelhi.com/kingmidas/km_virtual_animal_race.webp",
    game_code: "kingmidas_km_virtual_animal_race",
  },
  {
    game_id: "425049",
    game_name: "Tongits",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/tongits.webp",
    game_code: "kingmidas_tongits",
  },
  {
    game_id: "425050",
    game_name: "Pusoy",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/pusoy.webp",
    game_code: "kingmidas_pusoy",
  },
  {
    game_id: "425051",
    game_name: "Cockfighting Arena",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/cockfighting_arena.webp",
    game_code: "kingmidas_cockfighting_arena",
  },
  {
    game_id: "425052",
    game_name: "Coin Pusher",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/coin_pusher.webp",
    game_code: "kingmidas_coin_pusher",
  },
  {
    game_id: "425053",
    game_name: "Gems of Valhalla",
    category: "Slot Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/gems_of_valhalla.webp",
    game_code: "kingmidas_gems_of_valhalla",
  },
  {
    game_id: "425054",
    game_name: "Sugar Blast Frenzy",
    category: "Slot Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/sugar_blast_frenzy.webp",
    game_code: "kingmidas_sugar_blast_frenzy",
  },
  {
    game_id: "425055",
    game_name: "Cleopatra's Treasure",
    category: "Slot Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/cleopatra's_treasure.webp",
    game_code: "kingmidas_cleopatra's_treasure",
  },
  {
    game_id: "425056",
    game_name: "Rooster Blitz",
    category: "Slot Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/rooster_blitz.webp",
    game_code: "kingmidas_rooster_blitz",
  },
  {
    game_id: "425057",
    game_name: "Olympus Glory",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/olympus_glory.webp",
    game_code: "kingmidas_olympus_glory",
  },
  {
    game_id: "425058",
    game_name: "Interstellar Run",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/interstellar_run.webp",
    game_code: "kingmidas_interstellar_run",
  },
  {
    game_id: "425059",
    game_name: "Elite Aviator Club",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/elite_aviator_club.webp",
    game_code: "kingmidas_elite_aviator_club",
  },
  {
    game_id: "425060",
    game_name: "Jackpot Jump",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/jackpot_jump.webp",
    game_code: "kingmidas_jackpot_jump",
  },
  {
    game_id: "425061",
    game_name: "Penguin Panic",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/penguin_panic.webp",
    game_code: "kingmidas_penguin_panic",
  },
  {
    game_id: "425062",
    game_name: "Video Poker",
    category: "Table Game",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/kingmidas/video_poker.webp",
    game_code: "kingmidas_video_poker",
  },
  {
    game_id: "435063",
    game_name: "Kingmidas Reqrds/ FreeBets",
    category: "FreeBets",
    provider_name: "KINGMIDAS",
    sub_provider_name: "Kingmidas",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "kingmidas_rewards",
  },
  {
    game_id: "450001",
    game_name: "Baccarat Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_bc",
  },
  {
    game_id: "450002",
    game_name: "Roulette Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_rl",
  },
  {
    game_id: "450003",
    game_name: "Sicbo Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_sb",
  },
  {
    game_id: "450004",
    game_name: "Blackjack Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_bj",
  },
  {
    game_id: "450005",
    game_name: "Casino Hold’em Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_ch",
  },
  {
    game_id: "450007",
    game_name: "Bull Bull Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_bb",
  },
  {
    game_id: "450008",
    game_name: "Slot Game Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_sg",
  },
  {
    game_id: "450010",
    game_name: "Andar Bahar Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_ab",
  },
  {
    game_id: "450011",
    game_name: "Dragon Tiger Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_dt",
  },
  {
    game_id: "450012",
    game_name: "Teen Patti Lobby",
    category: "LOBBY",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/",
    game_code: "N2Live_tp",
  },
  {
    game_id: "450013",
    game_name: "Dragon Tiger Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/DragonTiger_LIVE.webp",
    game_code: "N2Live_20002",
  },
  {
    game_id: "450014",
    game_name: "RNG Dragon Tiger Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/DragonTiger_RNG.webp",
    game_code: "N2Live_21001",
  },
  {
    game_id: "450015",
    game_name: "RNG Dragon Tiger Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/DragonTiger_RNG.webp",
    game_code: "N2Live_21002",
  },
  {
    game_id: "450016",
    game_name: "Roulette",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Roulette_LIVE.webp",
    game_code: "N2Live_50002",
  },
  {
    game_id: "450017",
    game_name: "Lucky Boost Roulette",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/RouletteLB_LIVE.webp",
    game_code: "N2Live_50003",
  },
  {
    game_id: "450018",
    game_name: "RNG Roulette",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Roulette_RNG.webp",
    game_code: "N2Live_51002",
  },
  {
    game_id: "450019",
    game_name: "RNG Lucky Boost Roulette",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/RouletteLB_RNG.webp",
    game_code: "N2Live_51003",
  },
  {
    game_id: "450020",
    game_name: "RNG Roulette PT *",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/RoulettePT_RNG.webp",
    game_code: "N2Live_52002",
  },
  {
    game_id: "450021",
    game_name: "Lucky Boost Sicbo",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/SicboLB_LIVE.webp",
    game_code: "N2Live_60001",
  },
  {
    game_id: "450022",
    game_name: "RNG Lucky Boost Sicbo",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/SicboLB_RNG.webp",
    game_code: "N2Live_61001",
  },
  {
    game_id: "450023",
    game_name: "RNG Sicbo PT *",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/SicboPT_RNG.webp",
    game_code: "N2Live_62001",
  },
  {
    game_id: "450024",
    game_name: "RNG Baccarat Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Baccarat_RNG.webp",
    game_code: "N2Live_91091",
  },
  {
    game_id: "450025",
    game_name: "RNG Baccarat Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Baccarat_RNG.webp",
    game_code: "N2Live_91092",
  },
  {
    game_id: "450026",
    game_name: "Ultimate Blackjack",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/BJultimate_LIVE.webp",
    game_code: "N2Live_100004",
  },
  {
    game_id: "450027",
    game_name: "RNG Traditional Blackjack PT *",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/BJTraditional_RNG.webp",
    game_code: "N2Live_110001",
  },
  {
    game_id: "450028",
    game_name: "RNG Free Bet Blackjack PT *",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/BJfreebet_RNG.webp",
    game_code: "N2Live_110002",
  },
  {
    game_id: "450029",
    game_name: "RNG Blackjack Switch PT *",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/BJswith_RNG.webp",
    game_code: "N2Live_110003",
  },
  {
    game_id: "450030",
    game_name: "RNG Casino Hold’em",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/CasinoHoldem_en_RNG.webp",
    game_code: "N2Live_210001",
  },
  {
    game_id: "450031",
    game_name: "Bull Bull",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Bullbull_LIVE.webp",
    game_code: "N2Live_300001",
  },
  {
    game_id: "450032",
    game_name: "RNG Bull Bull",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/Bullbull_RNG.webp",
    game_code: "N2Live_310001",
  },
  {
    game_id: "450033",
    game_name: "Andar Bahar Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaher_LIVE.webp",
    game_code: "N2Live_500001",
  },
  {
    game_id: "450034",
    game_name: "Andar Bahar Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaher_LIVE.webp",
    game_code: "N2Live_500002",
  },
  {
    game_id: "450035",
    game_name: "RNG Andar Bahar Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaher_RNG.webp",
    game_code: "N2Live_510001",
  },
  {
    game_id: "450036",
    game_name: "RNG Andar Bahar Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaher_RNG.webp",
    game_code: "N2Live_510002",
  },
  {
    game_id: "450037",
    game_name: "RNG Speed Andar Bahar Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaherSpeed_RNG.webp",
    game_code: "N2Live_510003",
  },
  {
    game_id: "450038",
    game_name: "RNG Speed Andar Bahar Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/AndarBaherSpeed_RNG.webp",
    game_code: "N2Live_510004",
  },
  {
    game_id: "450039",
    game_name: "Teen Patti",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/TeenPatti_LIVE.webp",
    game_code: "N2Live_500101",
  },
  {
    game_id: "450040",
    game_name: "RNG Teen Patti",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/TeenPatti_RNG.webp",
    game_code: "N2Live_510101",
  },
  {
    game_id: "450042",
    game_name: "Baccarat Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "N2Live_90091",
  },
  {
    game_id: "450043",
    game_name: "Baccarat Non-Commission",
    category: "Others",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "N2Live_90092",
  },
  {
    game_id: "450101",
    game_name: "Super Road Machin",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_SuperRoadMachine.webp",
    game_code: "sghd_super_road",
  },
  {
    game_id: "450102",
    game_name: "The Great Speech",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_TheGreatSpeech.webp",
    game_code: "sghd_great_speech",
  },
  {
    game_id: "450103",
    game_name: "Civilization of Bull Bull",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_CivilizationOfBullbull.webp",
    game_code: "sghd_bull_bull",
  },
  {
    game_id: "450104",
    game_name: "Super Road Machine 2",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_SuperRoadMachine2.webp",
    game_code: "sghd_super_road2",
  },
  {
    game_id: "450105",
    game_name: "Book of Antique",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_BookOfAntique.webp",
    game_code: "sghd_book_antique",
  },
  {
    game_id: "450106",
    game_name: "Vault Blast",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_VaultBlast.webp",
    game_code: "sghd_vault_blast",
  },
  {
    game_id: "450107",
    game_name: "Sushi Saga",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_SushSaga.webp",
    game_code: "sghd_sushi_saga",
  },
  {
    game_id: "450108",
    game_name: "Giant Fruits",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_GiantFruits.webp",
    game_code: "sghd_giant_fruits",
  },
  {
    game_id: "450109",
    game_name: "Civilization of Bull Bull 2",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_CivilizationOfBullbull2.webp",
    game_code: "sghd_bull_bull2",
  },
  {
    game_id: "450110",
    game_name: "Summoner’s Valley",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_SummonersValley.webp",
    game_code: "sghd_summoner_valley",
  },
  {
    game_id: "450111",
    game_name: "Fortune Lions",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_FortuneLions.webp",
    game_code: "sghd_fortune_lions",
  },
  {
    game_id: "450112",
    game_name: "Witch’s Pact",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "cdn.dreamdelhi.com/n2live/slot_WitchsPact.webp",
    game_code: "sghd_witchs_pact",
  },
  {
    game_id: "450113",
    game_name: "Doom Squad",
    category: "SLOT",
    provider_name: "N2Live",
    sub_provider_name: "N2Live",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/n2live/n2_dqs_301x180.webp",
    game_code: "sghd_doom_squad",
  },
  {
    game_id: "460001",
    game_name: "Lucky 7",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/lucky7.webp",
    game_code: "betgames_lucky7",
  },
  {
    game_id: "460002",
    game_name: "Lucky 5",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/lucky5.webp",
    game_code: "betgames_lucky5",
  },
  {
    game_id: "460003",
    game_name: "Bet On Poker",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/bet_on_poker.webp",
    game_code: "betgames_bet_on_poker",
  },
  {
    game_id: "460004",
    game_name: "Bet On Baccarat",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/bet_on_baccarat.webp",
    game_code: "betgames_bet_on_baccarat",
  },
  {
    game_id: "460005",
    game_name: "Wheel Of Fortune",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/wheel_of_fortune.webp",
    game_code: "betgames_wheel_of_fortune",
  },
  {
    game_id: "460006",
    game_name: "War Of Bets",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/war_of_bets.webp",
    game_code: "betgames_war_of_bets",
  },
  {
    game_id: "460007",
    game_name: "Lucky 6",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/lucky6.webp",
    game_code: "betgames_lucky6",
  },
  {
    game_id: "460008",
    game_name: "Dice Duel",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/dice_duel.webp",
    game_code: "betgames_dice_duel",
  },
  {
    game_id: "460009",
    game_name: "Speedy 7",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/speedy7.webp",
    game_code: "betgames_speedy7",
  },
  {
    game_id: "460010",
    game_name: "6+ Poker",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/6poker.webp",
    game_code: "betgames_6+_poker",
  },
  {
    game_id: "460011",
    game_name: "Classic Wheel",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/classic_wheel.webp",
    game_code: "betgames_classic_wheel",
  },
  {
    game_id: "460012",
    game_name: "Football Grid",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/football_grid.webp",
    game_code: "betgames_football_grid",
  },
  {
    game_id: "460013",
    game_name: "Skyward",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/skyward.webp",
    game_code: "betgames_skyward",
  },
  {
    game_id: "460014",
    game_name: "Instant Lucky 7",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/instant_lucky7.webp",
    game_code: "betgames_instant_lucky7",
  },
  {
    game_id: "460015",
    game_name: "T-Basket 24/7",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/t-basket_24/7.webp",
    game_code: "betgames_t-basket_24/7",
  },
  {
    game_id: "460016",
    game_name: "T-Kick 24/7",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/t-kick_24/7.webp",
    game_code: "betgames_t-kick_24/7",
  },
  {
    game_id: "460017",
    game_name: "Starzle",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/starzle.webp",
    game_code: "betgames_starzle",
  },
  {
    game_id: "460018",
    game_name: "Crypt of Giza",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/crypt_of_giza.webp",
    game_code: "betgames_crypt_of_giza",
  },
  {
    game_id: "460020",
    game_name: "Lucky Kicks",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/lucky_kicks.webp",
    game_code: "betgames_lucky_kicks",
  },
  {
    game_id: "460021",
    game_name: "Flash Roulette",
    category: "others",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/flash_roulette.webp",
    game_code: "betgames_flash_roulette",
  },
  {
    game_id: "460022",
    game_name: "Citrus Splash",
    category: "slot",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/citrus_splash.webp",
    game_code: "betgames_citrus_splash",
  },
  {
    game_id: "460023",
    game_name: "Candy Coins",
    category: "slot",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/candy_coins.webp",
    game_code: "betgames_candy_coins",
  },
  {
    game_id: "460024",
    game_name: "Lotus Wealth Wonders",
    category: "slot",
    provider_name: "BETGAMES",
    sub_provider_name: "BETGAMES",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/betgames/lotus_wealth_wonders.webp",
    game_code: "betgames_lotus_wealth_wonders",
  },
  {
    game_id: "500001",
    game_name: "Teen Patti",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Teen-Patti_1654236438589.26.jpg",
    game_code: "TP",
  },
  {
    game_id: "500002",
    game_name: "Baccarat",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Baccarat_1654236281166.404.jpg",
    game_code: "BAC",
  },
  {
    game_id: "500003",
    game_name: "Poker",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Poker_1654236328983.9788.jpg",
    game_code: "PK",
  },
  {
    game_id: "500004",
    game_name: "Worli Matka",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Worli-Matka_1654236474317.4768.jpg",
    game_code: "WM",
  },
  {
    game_id: "500005",
    game_name: "Akbar Romeo Walter",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Akbar-Romeo-Walter_1654236226939.502.jpg",
    game_code: "ARW",
  },
  {
    game_id: "500006",
    game_name: "32 Cards",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/32-Cards_1654236218602.8164.jpg",
    game_code: "C32",
  },
  {
    game_id: "500007",
    game_name: "Lucky 7",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Lucky-7_1657523911243.0068.jpg",
    game_code: "UD7",
  },
  {
    game_id: "500008",
    game_name: "King Race",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/king-race_1657541506838.9695.jpg",
    game_code: "CR",
  },
  {
    game_id: "500009",
    game_name: "3 Player Teenpatti",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/3-player-teen-patti_1654236210953.2373.jpg",
    game_code: "D3TP",
  },
  {
    game_id: "500011",
    game_name: "Roulette",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Roulette_1654236426380.0781.jpg",
    game_code: "RT",
  },
  {
    game_id: "500015",
    game_name: "Teen patti 2020",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Teen-Patti-2020_1654236450045.9844.jpg",
    game_code: "TP20",
  },
  {
    game_id: "500016",
    game_name: "Classic Andar Bahar",
    category: "LIVE",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/Andar-Bahar_1654236263135.5576.jpg",
    game_code: "AB",
  },
  {
    game_id: "500201",
    game_name: "Virtual Teenpatti",
    category: "VIRTUAL",
    provider_name: "SUNO",
    sub_provider_name: "SuperNowa",
    status: "ACTIVE",
    url_thumb:
      "http://files.worldcasinoonline.com/Document/Game/1-RNG-Teen-Patti_1654236403712.0127.jpg",
    game_code: "VTP",
  },
  {
    game_id: "530001",
    game_name: "Fortune God",
    category: "Slots",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_fortune_god",
  },
  {
    game_id: "530002",
    game_name: "Book Hotfire",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_book_hotfire",
  },
  {
    game_id: "530003",
    game_name: "50 Jokers Hotfire",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_50_jokers_hotfire",
  },
  {
    game_id: "530004",
    game_name: "Scarab Fortunes",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_scarab_fortunes",
  },
  {
    game_id: "530005",
    game_name: "5 Sevens Dice",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_5_sevens_dice",
  },
  {
    game_id: "530006",
    game_name: "Book of Luck",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "nothumb",
    game_code: "nmatic_book_of_ luck",
  },
  {
    game_id: "530007",
    game_name: "Gold Fever",
    category: "Others",
    provider_name: "NMATIC",
    sub_provider_name: "Nmatic",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/nmatic/gold_fever.webp",
    game_code: "nmatic_gold_fever",
  },
  {
    game_id: "550000",
    game_name: "SportRadar iframe",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_sportradar_iframe",
  },
  {
    game_id: "550002",
    game_name: "Soccer",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_soccer",
  },
  {
    game_id: "550003",
    game_name: "Tennis",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_tennis",
  },
  {
    game_id: "550004",
    game_name: "Cricket",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_cricket",
  },
  {
    game_id: "550005",
    game_name: "Badminton",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_badminton",
  },
  {
    game_id: "550006",
    game_name: "Basketball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_basketball",
  },
  {
    game_id: "550007",
    game_name: "Baseball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_baseball",
  },
  {
    game_id: "550008",
    game_name: "Table Tennis",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_table_tennis",
  },
  {
    game_id: "550009",
    game_name: "Kabaddi",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_kabaddi",
  },
  {
    game_id: "550010",
    game_name: "MMA",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_mma",
  },
  {
    game_id: "550011",
    game_name: "Ice Hockey",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ice_hockey",
  },
  {
    game_id: "550012",
    game_name: "Volleyball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_volleyball",
  },
  {
    game_id: "550013",
    game_name: "Squash",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_squash",
  },
  {
    game_id: "550014",
    game_name: "Rugby",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_rugby",
  },
  {
    game_id: "550015",
    game_name: "Darts",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_darts",
  },
  {
    game_id: "550016",
    game_name: "Snooker",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_snooker",
  },
  {
    game_id: "550017",
    game_name: "American Football",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_american_football",
  },
  {
    game_id: "550018",
    game_name: "Futsal",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "SportsBook",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_futsal",
  },
  {
    game_id: "551000",
    game_name: "EXCHANGE",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_exchange_iframe",
  },
  {
    game_id: "551001",
    game_name: "Cricket",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_cricket",
  },
  {
    game_id: "551002",
    game_name: "Soccer",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_soccer",
  },
  {
    game_id: "551003",
    game_name: "Tennis",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_tennis",
  },
  {
    game_id: "551004",
    game_name: "Greyhound Racing",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_greyhound_racing",
  },
  {
    game_id: "551005",
    game_name: "Horse Racing",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_horse_racing",
  },
  {
    game_id: "551006",
    game_name: "American Football",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_american_football",
  },
  {
    game_id: "551007",
    game_name: "Mixed Martial Arts",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_mixed_martial_arts",
  },
  {
    game_id: "551008",
    game_name: "Boxing",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_boxing",
  },
  {
    game_id: "551009",
    game_name: "Volleyball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_volley_ball",
  },
  {
    game_id: "551010",
    game_name: "Snooker",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_snooker",
  },
  {
    game_id: "551011",
    game_name: "Basketball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_basketball",
  },
  {
    game_id: "551012",
    game_name: "Baseball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_baseball",
  },
  {
    game_id: "551013",
    game_name: "Dream Kabaddi",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_dream_kabaddi",
  },
  {
    game_id: "551014",
    game_name: "Kabaddi",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Exchange",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_ex_kabaddi",
  },
  {
    game_id: "552001",
    game_name: "Soccer",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_soccer",
  },
  {
    game_id: "552002",
    game_name: "Tennis",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_tennis",
  },
  {
    game_id: "552003",
    game_name: "Cricket",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_cricket",
  },
  {
    game_id: "552004",
    game_name: "Badminton",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_badminton",
  },
  {
    game_id: "552005",
    game_name: "Basketball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_basketball",
  },
  {
    game_id: "552006",
    game_name: "Baseball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_baseball",
  },
  {
    game_id: "552007",
    game_name: "Table Tennis",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_table_tennis",
  },
  {
    game_id: "552008",
    game_name: "Kabaddi",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_kabaddi",
  },
  {
    game_id: "552009",
    game_name: "MMA",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_mma",
  },
  {
    game_id: "552010",
    game_name: "Ice Hockey",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_ice_hockey",
  },
  {
    game_id: "552011",
    game_name: "Volleyball",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_volleyball",
  },
  {
    game_id: "552012",
    game_name: "Squash",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_squash",
  },
  {
    game_id: "552013",
    game_name: "Rugby",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_rugby",
  },
  {
    game_id: "552014",
    game_name: "Darts",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_darts",
  },
  {
    game_id: "552015",
    game_name: "Snooker",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_snooker",
  },
  {
    game_id: "552016",
    game_name: "American Football",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_american_football",
  },
  {
    game_id: "552017",
    game_name: "Futsal",
    category: "sports",
    provider_name: "SAP",
    sub_provider_name: "Premium",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "sap_pr_futsal",
  },
  {
    game_id: "553001",
    game_name: "Virtual Football English League",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_englishleague.webp",
    game_code: "vs_virtual_football_english_league",
  },
  {
    game_id: "553002",
    game_name: "Virtual Football Nations Cup ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_nationscup.webp",
    game_code: "vs_virtual_football_nations_cup",
  },
  {
    game_id: "553003",
    game_name: "Virtual Football Asian Cup ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_asiancup.webp",
    game_code: "vs_virtual_football_asian_cup",
  },
  {
    game_id: "553004",
    game_name: "Virtual Football Cup EuroCup ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_europecup.webp",
    game_code: "vs_virtual_football_cup_europe_cup",
  },
  {
    game_id: "553005",
    game_name: "Virtual Football World Cup ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_worldcup.webp",
    game_code: "vs_virtual_football_world_cup",
  },
  {
    game_id: "553006",
    game_name: "Virtual Football Bundesliga ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_bundesliga.webp",
    game_code: "vs_virtual_football_bundesliga",
  },
  {
    game_id: "553007",
    game_name: "Virtual Football League Mode ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_leaguemode.webp",
    game_code: "vs_virtual_football_league_mode",
  },
  {
    game_id: "553008",
    game_name: "Virtual Football Champions Cup",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_championscup.webp",
    game_code: "vs_virtual_football_champions_cup",
  },
  {
    game_id: "553009",
    game_name: "Virtual Cricket In-Play ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_cricket1.webp",
    game_code: "vs_virtual_cricket_in_play",
  },
  {
    game_id: "553010",
    game_name: "Virtual Baseball In-Play ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_basketball.webp",
    game_code: "vs_virtual_baseball_in_play",
  },
  {
    game_id: "553011",
    game_name: "Virtual Tennis In-Play ",
    category: "Sports",
    provider_name: "SAP",
    sub_provider_name: "Virtuals",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_tennis.webp",
    game_code: "vs_virtual_tennis_in_play",
  },

  {
    game_id: "600001",
    game_name: "Royal Fishing",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Royal_Fishing.webp",
    game_code: "jili_royal_fishing",
  },
  {
    game_id: "600002",
    game_name: "Bombing Fishing",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Bombing_Fishing.webp",
    game_code: "jili_bombing_fishing",
  },
  {
    game_id: "600003",
    game_name: "Dinosaur Tycoon",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Dinosaur_Tycoon.webp",
    game_code: "jili_dinosaur_tycoon",
  },
  {
    game_id: "600004",
    game_name: "Jackpot Fishing",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Jackpot_Fishing.webp",
    game_code: "jili_jackpot_fishing",
  },
  {
    game_id: "600005",
    game_name: "Dragon Fortune",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Dragon_Fortune.webp",
    game_code: "jili_dragon_fortune",
  },
  {
    game_id: "600006",
    game_name: "Mega Fishing",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/gap_casino/jili/Jili_Mega_Fishing.jpg",
    game_code: "jili_mega_fishing",
  },
  {
    game_id: "600007",
    game_name: "Boom Legend",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Boom_Legend.webp",
    game_code: "jili_boom_legend",
  },
  {
    game_id: "600008",
    game_name: "Happy Fishing",
    category: "Fish Shooting",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Happy_Fishing.webp",
    game_code: "jili_happy_fishing",
  },
  {
    game_id: "600009",
    game_name: "Gem Party",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Gem_Party.webp",
    game_code: "jili_gem_party",
  },
  {
    game_id: "600010",
    game_name: "Hot Chilli",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Hot_Chilli.webp",
    game_code: "jili_hot_chilli",
  },
  {
    game_id: "600011",
    game_name: "Chin Shi Huang",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Chin_Shj_Huang.webp",
    game_code: "jili_chin_shi_huang",
  },
  {
    game_id: "600012",
    game_name: "War Of Dragons",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_War_of_Drangons.webp",
    game_code: "jili_war_of_dragons",
  },
  {
    game_id: "600013",
    game_name: "Fortune Tree",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Fortune_Tree.webp",
    game_code: "jili_fortune_tree",
  },
  {
    game_id: "600014",
    game_name: "Lucky Ball",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Lucky_Ball.webp",
    game_code: "jili_lucky_ball",
  },
  {
    game_id: "600015",
    game_name: "Hyper Burst",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Hyper_Burst.webp",
    game_code: "jili_hyper_burst",
  },
  {
    game_id: "600016",
    game_name: "Shanghai Beauty",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Shanghai_Beauty.webp",
    game_code: "jili_shanghai_beauty",
  },
  {
    game_id: "600017",
    game_name: "Fa Fa Fa",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_FaFaFa.webp",
    game_code: "jili_fa_fa_fa",
  },
  {
    game_id: "600018",
    game_name: "God Of Martial",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_God_of_Martial.webp",
    game_code: "jili_god_of_martial",
  },
  {
    game_id: "600019",
    game_name: "SevenSevenSeven",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_777.webp",
    game_code: "jili_sevensevenseven",
  },
  {
    game_id: "600020",
    game_name: "Crazy777",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Crazy_777.webp",
    game_code: "jili_crazy777",
  },
  {
    game_id: "600021",
    game_name: "Bubble Beauty",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Bubble_Beauty.webp",
    game_code: "jili_bubble_beauty",
  },
  {
    game_id: "600022",
    game_name: "Bao boon chin",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Bao_Boon_Chin.webp",
    game_code: "jili_bao_boon_chin",
  },
  {
    game_id: "600023",
    game_name: "Crazy FaFaFa",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Crazy_fafafa.webp",
    game_code: "jili_crazy_fafafa",
  },
  {
    game_id: "600024",
    game_name: "XiYangYang",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Xiyangyang.webp",
    game_code: "jili_xiyangyang",
  },
  {
    game_id: "600025",
    game_name: "FortunePig",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Fortune_Pig.webp",
    game_code: "jili_fortunepig",
  },
  {
    game_id: "600026",
    game_name: "Candy Baby",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Candy_Baby.webp",
    game_code: "jili_candy_baby",
  },
  {
    game_id: "600027",
    game_name: "Fengshen",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/gap_casino/jili/Jili_Feng_Shen.jpg",
    game_code: "jili_fengshen",
  },
  {
    game_id: "600028",
    game_name: "Crazy Golden Bank",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Crazy_Golden_Bank.webp",
    game_code: "jili_crazy_golden_bank",
  },
  {
    game_id: "600029",
    game_name: "Lucky Goldbricks",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Lucky_Goldbricks.webp",
    game_code: "jili_lucky_goldbricks",
  },
  {
    game_id: "600030",
    game_name: "Dragon Treasure",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Dragon_Treasure.webp",
    game_code: "jili_dragon_treasure",
  },
  {
    game_id: "600031",
    game_name: "Charge Buffalo",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Charge_Buffalo.webp",
    game_code: "jili_charge_buffalo",
  },
  {
    game_id: "600032",
    game_name: "Super Ace",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Super_Ace.webp",
    game_code: "jili_super_ace",
  },
  {
    game_id: "600033",
    game_name: "Golden Queen",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Golden_Queen.webp",
    game_code: "jili_golden_queen",
  },
  {
    game_id: "600034",
    game_name: "Monkey Party",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb:
      "http://cdn.dreamcasino.live/gap_casino/jili/Jili_Monkey_Party.jpg",
    game_code: "jili_monkey_party",
  },
  {
    game_id: "600035",
    game_name: "Money Coming",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Money_Coming.webp",
    game_code: "jili_money_coming",
  },
  {
    game_id: "600036",
    game_name: "Jungle King",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Jungle_King.webp",
    game_code: "jili_jungle_king",
  },
  {
    game_id: "600037",
    game_name: "Boxing King",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Boxing_King.webp",
    game_code: "jili_boxing_king",
  },
  {
    game_id: "600038",
    game_name: "Secret Treasure",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Secret_Treasure.webp",
    game_code: "jili_secret_treasure",
  },
  {
    game_id: "600039",
    game_name: "Pharaoh Treasure",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Pharaoh_Treasure.webp",
    game_code: "jili_pharaoh_treasure",
  },
  {
    game_id: "600040",
    game_name: "Lucky Coming",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Lucky_Coming.webp",
    game_code: "jili_lucky_coming",
  },
  {
    game_id: "600041",
    game_name: "Super Rich",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Super_Rich.webp",
    game_code: "jili_super_rich",
  },
  {
    game_id: "600042",
    game_name: "RomaX",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/gap_casino/jili/Jili_Roma_X.jpg",
    game_code: "jili_romax",
  },
  {
    game_id: "600043",
    game_name: "Golden Empire",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Golden_Empire.webp",
    game_code: "jili_golden_empire",
  },
  {
    game_id: "600044",
    game_name: "Fortune Gems",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Fortune_Gems.webp",
    game_code: "jili_fortune_gems",
  },
  {
    game_id: "600045",
    game_name: "Fairness Games",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Fairness_Games.webp",
    game_code: "jili_fairness_games",
  },
  {
    game_id: "600046",
    game_name: "Dragon & Tiger",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Dragon&Tiger.webp",
    game_code: "jili_dragon_tiger",
  },
  {
    game_id: "600047",
    game_name: "Dice",
    category: "Video Slots",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Dice.webp",
    game_code: "jili_dice",
  },
  {
    game_id: "600048",
    game_name: "7 UP-DOWN",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_7_UpDown.webp",
    game_code: "jili_7_updown",
  },
  {
    game_id: "600049",
    game_name: "Lucky Number",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Lucky_Number.webp",
    game_code: "jili_lucky_number",
  },
  {
    game_id: "600050",
    game_name: "TeenPatti",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Teen_Patti.webp",
    game_code: "jili_teenpatti",
  },
  {
    game_id: "600051",
    game_name: "AK47",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_AK47.webp",
    game_code: "jili_ak47",
  },
  {
    game_id: "600052",
    game_name: "Andar Bahar",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Andar_Bahar.webp",
    game_code: "jili_andar_bahar",
  },
  {
    game_id: "600053",
    game_name: "Rummy",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Rummy.webp",
    game_code: "jili_rummy",
  },
  {
    game_id: "600054",
    game_name: "Number King",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Number_King.webp",
    game_code: "jili_number_king",
  },
  {
    game_id: "600055",
    game_name: "Poker King",
    category: "Live Popular",
    provider_name: "JiLi",
    sub_provider_name: "JiLi Gaming",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/jili/Jili_Poker_King.webp",
    game_code: "jili_poker_king",
  },
  {
    game_id: "650001",
    game_name: "Slot",
    category: "Others",
    provider_name: "DARWIN",
    sub_provider_name: "DARWIN",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "",
  },
  {
    game_id: "650002",
    game_name: "Crash",
    category: "Others",
    provider_name: "DARWIN",
    sub_provider_name: "DARWIN",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "",
  },
  {
    game_id: "650005",
    game_name: "Baccarat",
    category: "Others",
    provider_name: "DARWIN",
    sub_provider_name: "DARWIN",
    status: "ACTIVE",
    url_thumb: "https",
    game_code: "darwin_Baccarat",
  },
  {
    game_id: "650006",
    game_name: "Blackjack",
    category: "Others",
    provider_name: "DARWIN",
    sub_provider_name: "DARWIN",
    status: "ACTIVE",
    url_thumb: "https",
    game_code: "darwin_Blackjack",
  },
  {
    game_id: "675001",
    game_name: "Big Bash Bonanza",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/big_bash_bonanza.webp",
    game_code: "fiesta777_big_bash_bonanza",
  },
  {
    game_id: "675002",
    game_name: "Big Cash Wheel",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/big_cash_wheel.webp",
    game_code: "fiesta777_big_cash_wheel",
  },
  {
    game_id: "675003",
    game_name: "Buffalo 777",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/buffalo777.webp",
    game_code: "fiesta777_buffalo777",
  },
  {
    game_id: "675004",
    game_name: "Buffalo safari",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/buffalo_safari.webp",
    game_code: "fiesta777_buffalo_safari",
  },
  {
    game_id: "675005",
    game_name: "Chinna Street",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/chinna_street.webp",
    game_code: "fiesta777_chinna_street",
  },
  {
    game_id: "675006",
    game_name: "Celopatra",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/celopatra.webp",
    game_code: "fiesta777_celopatra",
  },
  {
    game_id: "675007",
    game_name: "Crazy7",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/crazy7.webp",
    game_code: "fiesta777_crazy7",
  },
  {
    game_id: "675008",
    game_name: "Day of Dead",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/day_of_dead.webp",
    game_code: "fiesta777_day_of_dead",
  },
  {
    game_id: "675009",
    game_name: "Fortune Lion",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/fortune_lion.webp",
    game_code: "fiesta777_fortune_lion",
  },
  {
    game_id: "675010",
    game_name: "Fruit777",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/fruit777.webp",
    game_code: "fiesta777_fruit777",
  },
  {
    game_id: "675011",
    game_name: "GoldenWheel",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/golden_wheel.webp",
    game_code: "fiesta777_golden_wheel",
  },
  {
    game_id: "675012",
    game_name: "Life of Luxury",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/life_of_luxury.webp",
    game_code: "fiesta777_life_of_luxury",
  },
  {
    game_id: "675013",
    game_name: "Pirates of Caribbean",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/pirates_of_caribbean.webp",
    game_code: "fiesta777_pirates_of_caribbean",
  },
  {
    game_id: "675014",
    game_name: "Super ball Keno",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/super_ball_keno.webp",
    game_code: "fiesta777_super_ball_keno",
  },
  {
    game_id: "675015",
    game_name: "Vega 7's ",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/vega7's.webp",
    game_code: "fiesta777_vega7's ",
  },
  {
    game_id: "675016",
    game_name: "xmas magic",
    category: "others",
    provider_name: "FIESTA777",
    sub_provider_name: "FIESTA777",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamdelhi.com/fiesta777/xmas_magic.webp",
    game_code: "fiesta777_xmas_magic",
  },
  {
    game_id: "750001",
    game_name: "7's Club",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/sevens_club.webp",
    game_code: "tc_sevens_club",
  },
  {
    game_id: "750002",
    game_name: "Big Dig Gold",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/big_dig_gold.webp",
    game_code: "tc_big_dig_gold",
  },
  {
    game_id: "750003",
    game_name: "Big Papa's Burger",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/big_papas_burger.webp",
    game_code: "tc_big_papas_burger",
  },
  {
    game_id: "750004",
    game_name: "Board Quest",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/board_quest.webp",
    game_code: "tc_board_quest",
  },
  {
    game_id: "750005",
    game_name: "Bounty Seas",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/bounty_seas.webp",
    game_code: "tc_bounty_seas",
  },
  {
    game_id: "750006",
    game_name: "Carnaval do Rio",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/carnaval_do_rio.webp",
    game_code: "tc_carnaval_do_rio",
  },
  {
    game_id: "750007",
    game_name: "Catrina: Amor Eterno",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/catrina_amor_eterno.webp",
    game_code: "tc_catrina_amor_eterno",
  },
  {
    game_id: "750008",
    game_name: "Chicken Squad",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/chicken_squad.webp",
    game_code: "tc_chicken_squad",
  },
  {
    game_id: "750009",
    game_name: "Clover Up",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/clover_up.webp",
    game_code: "tc_clover_up",
  },
  {
    game_id: "750010",
    game_name: "Crush on Wilds",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/crush_on_wilds.webp",
    game_code: "tc_crush_on_wilds",
  },
  {
    game_id: "750011",
    game_name: "Dwarf Treasures",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/dwarf_treasures.webp",
    game_code: "tc_dwarf_treasures",
  },
  {
    game_id: "750012",
    game_name: "El Dorado Ritual",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/el_dorado.webp",
    game_code: "tc_el_dorado",
  },
  {
    game_id: "750013",
    game_name: "Elsa's Bierhaus",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/elsas_bierhaus.webp",
    game_code: "tc_elsa_bierhaus",
  },
  {
    game_id: "750014",
    game_name: "Extra Luck",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_extra_luck",
  },
  {
    game_id: "750015",
    game_name: "Far West",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_far_fest",
  },
  {
    game_id: "750016",
    game_name: "Fish & Hooks",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/fish&hooks.webp",
    game_code: "tc_fish&hooks",
  },
  {
    game_id: "750017",
    game_name: "Friducha",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/friducha.webp",
    game_code: "tc_friducha",
  },
  {
    game_id: "750018",
    game_name: "Gladius of Rome",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/gladius_of_rome.webp",
    game_code: "tc_gladius_of_rome",
  },
  {
    game_id: "750019",
    game_name: "GOAL Crash",
    category: "Crash",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_goal_crash",
  },
  {
    game_id: "750020",
    game_name: "GOAL Crash América",
    category: "Crash",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_goal_crash_america",
  },
  {
    game_id: "750021",
    game_name: "GOAL Crash Bicolor",
    category: "Crash",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_goal_crash_bicolor",
  },
  {
    game_id: "750022",
    game_name: "GOAL Crash Pixbet",
    category: "Crash",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_goal_crash_pixbet",
  },
  {
    game_id: "750023",
    game_name: "Gods Odyssey",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/gods_dyssey.webp",
    game_code: "tc_gods_dyssey",
  },
  {
    game_id: "750024",
    game_name: "Golden BOMBlins",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/golden_bomblins.webp",
    game_code: "tc_golden_bomblins",
  },
  {
    game_id: "750025",
    game_name: "Halloween Mansion",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/halloween.webp",
    game_code: "tc_halloween",
  },
  {
    game_id: "750026",
    game_name: "Hunters: Cursed Masks",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/hunters_cursed_marks.webp",
    game_code: "tc_hunters_cursed_marks",
  },
  {
    game_id: "750027",
    game_name: "Iced Fossils",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_iced_fossils",
  },
  {
    game_id: "750028",
    game_name: "Jade of Cleopatra",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/jade_of_cleopatra.webp",
    game_code: "tc_jade_of_cleopatra",
  },
  {
    game_id: "750029",
    game_name: "Joker Wheel",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/joker_wheel.webp",
    game_code: "tc_joker_wheel",
  },
  {
    game_id: "750030",
    game_name: "Joker's Kingdom",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/jokers_kingdom.webp",
    game_code: "tc_jokers_kingdom",
  },
  {
    game_id: "750031",
    game_name: "Luxury",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_luxury",
  },
  {
    game_id: "750032",
    game_name: "Mega Chef",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/mega_chef.webp",
    game_code: "tc_mega_chef",
  },
  {
    game_id: "750033",
    game_name: "Mega Chef Christmas Edition",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/mega_chef_xmas.webp",
    game_code: "tc_mega_chef_xmas",
  },
  {
    game_id: "750034",
    game_name: "Millionaire",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_millionaire",
  },
  {
    game_id: "750035",
    game_name: "Monsters fear Bullets",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/monsters_fear_bullets.webp",
    game_code: "tc_monsters_fear_bullets",
  },
  {
    game_id: "750036",
    game_name: "Mystic Buffalo",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/mystic_buffalo.webp",
    game_code: "tc_mystic_buffallo",
  },
  {
    game_id: "750037",
    game_name: "Neymar Jr. GOAL Crash",
    category: "Crash",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_neymar_goal_crash",
  },
  {
    game_id: "750038",
    game_name: "Nile Treasures",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/nile_treasures.webp",
    game_code: "tc_nile_treasures",
  },
  {
    game_id: "750039",
    game_name: "Pinocchio's Journey",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/pinocho_360x240.webp",
    game_code: "tc_pinnocho_360x240",
  },
  {
    game_id: "750040",
    game_name: "Rebel World",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/rebel_world.webp",
    game_code: "tc_rebel_world",
  },
  {
    game_id: "750041",
    game_name: "RedQueen in Wonderland",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/red_queen_in_wonderland.webp",
    game_code: "tc_red_queen_in_wonderland",
  },
  {
    game_id: "750042",
    game_name: "Rise and Fall of RA",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_rise_and_fall_ra",
  },
  {
    game_id: "750043",
    game_name: "Saint Fermin",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/saint_fermin.webp",
    game_code: "tc_saint_fermin",
  },
  {
    game_id: "750044",
    game_name: "Santa's Workshop",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/santas_workshop.webp",
    game_code: "tc_santas_workshop",
  },
  {
    game_id: "750045",
    game_name: "Soccer",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/wild_cup_soccer.webp",
    game_code: "tc_wind_cup_soccer",
  },
  {
    game_id: "750046",
    game_name: "Starfall Mission",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/starfall_mission.webp",
    game_code: "tc_starfall_mission",
  },
  {
    game_id: "750047",
    game_name: "SteamStorm",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/steam_storm.webp",
    game_code: "tc_stram_storm",
  },
  {
    game_id: "750048",
    game_name: "Sugar Frenzy",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/sugar_frenzy.webp",
    game_code: "tc_sugar_frenzy",
  },
  {
    game_id: "750049",
    game_name: "Sunset Highways",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/sunset_highways.webp",
    game_code: "tc_sunset_highways",
  },
  {
    game_id: "750050",
    game_name: "Super",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC.webp",
    game_code: "tc_super",
  },
  {
    game_id: "750051",
    game_name: "The Walking Wilds",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/the_walking_wild.webp",
    game_code: "tc_the_walking_wilds",
  },
  {
    game_id: "750052",
    game_name: "Thunder Rock",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/thunder_rock.webp",
    game_code: "tc_thunder_rock",
  },
  {
    game_id: "750053",
    game_name: "Tomoe's Sushi Bar",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/tomoes_sushi_bar.webp",
    game_code: "tc_tomoes_sushi_bar",
  },
  {
    game_id: "750054",
    game_name: "Wanted WILDS",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/walking_wild.webp",
    game_code: "tc_walking_wild",
  },
  {
    game_id: "750055",
    game_name: "Yin Yang",
    category: "Slots",
    provider_name: "TC",
    sub_provider_name: "3Cherry",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/TC/yin_yang.webp",
    game_code: "tc_ying_yang",
  },
  {
    game_id: "800001",
    game_name: "Virtual Cricket In-Play ",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_cricket1.webp",
    game_code: "VCI",
  },
  {
    game_id: "800002",
    game_name: "Virtual Football Bundesliga",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_bundesliga.webp",
    game_code: "VFB",
  },
  {
    game_id: "800003",
    game_name: "Virtual Football English League",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_englishleague.webp",
    game_code: "VFEL",
  },
  {
    game_id: "800004",
    game_name: "Virtual Football League Mode ",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_leaguemode.webp",
    game_code: "VFLM",
  },
  {
    game_id: "800005",
    game_name: "Virtual Football World Cup",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_worldcup.webp",
    game_code: "VFWC",
  },
  {
    game_id: "800006",
    game_name: "Virtual Football Champions Cup",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_championscup.webp",
    game_code: "VFCC",
  },
  {
    game_id: "800007",
    game_name: "Virtual Football Asian Cup",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_asiancup.webp",
    game_code: "VFAS",
  },
  {
    game_id: "800008",
    game_name: "Virtual Football Europe Cup",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_europecup.webp",
    game_code: "VFEC",
  },
  {
    game_id: "800009",
    game_name: "Virtual Football Nations Cup",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_fb_nationscup.webp",
    game_code: "VFNC",
  },
  {
    game_id: "800010",
    game_name: "Virtual Basketball",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_basketball.webp",
    game_code: "VBL",
  },
  {
    game_id: "800011",
    game_name: "Virtual Tennis In-Play",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_tennis.webp",
    game_code: "VTI",
  },
  {
    game_id: "800012",
    game_name: "Virtual Baseball In-Play",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_baseball.webp",
    game_code: "VBI",
  },
  {
    game_id: "800013",
    game_name: "Virtual Horses",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_horses.webp",
    game_code: "VHK",
  },
  {
    game_id: "800014",
    game_name: "Virtual Dogs",
    category: "VIRTUAL",
    provider_name: "VSBR",
    sub_provider_name: "Virtual Sports",
    status: "ACTIVE",
    url_thumb: "http://cdn.dreamcasino.live/vsbr_dogs.webp",
    game_code: "VDK",
  },
  {
    game_id: "850001",
    game_name: "Zodiac Adventure",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/zodiac-adventure.jpg",
    game_code: "macaw_zodiac_adventure",
  },
  {
    game_id: "850002",
    game_name: "Hot Balloon Deluxe",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb:
      "http://static.macawgaming.com/thumbnails/hot-balloon-deluxe.png",
    game_code: "macaw_hot_balloon_deluxe",
  },
  {
    game_id: "850003",
    game_name: "Suleiman The Magnificent",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://static.macawgaming.com/thumbnails/suleiman-the-magnificent.jpg",
    game_code: "macaw_suleiman_the_magnificent",
  },
  {
    game_id: "850004",
    game_name: "Joker's Smile",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/jokers-smile.png",
    game_code: "macaw_jokers_smile",
  },
  {
    game_id: "850005",
    game_name: "Abrakadabra",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/abrakadabra.png",
    game_code: "macaw_abrakadabra",
  },
  {
    game_id: "850006",
    game_name: "Walking Death",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/walking-death.png",
    game_code: "macaw_walking_death",
  },
  {
    game_id: "850007",
    game_name: "Fruit Cocktail",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/fruit-cocktail.png",
    game_code: "macaw_fruit_cocktail",
  },
  {
    game_id: "850008",
    game_name: "Rio 40 Graus",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/rio-40-graus.png",
    game_code: "macaw_rio_graus",
  },
  {
    game_id: "850009",
    game_name: "Lucky 8",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/lucky-eight.png",
    game_code: "macaw_lucky_eight",
  },
  {
    game_id: "850010",
    game_name: "Neon City Wheel",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/neon-city-wheel.png",
    game_code: "macaw_neon_city_wheel",
  },
  {
    game_id: "850011",
    game_name: "Mac Ludo",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "macaw_mac_ludo",
  },
  {
    game_id: "850012",
    game_name: "Fortune Melody",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/fortune-melody.png",
    game_code: "macaw_fortune_melody",
  },
  {
    game_id: "850013",
    game_name: "Mysterious Night",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/mysterious-night.png",
    game_code: "macaw_mysterious_night",
  },
  {
    game_id: "850014",
    game_name: "Hot Balloon",
    category: "Others",
    provider_name: "MACAW",
    sub_provider_name: "Macaw Gaming",
    status: "ACTIVE",
    url_thumb: "http://static.macawgaming.com/thumbnails/hot-balloon.png",
    game_code: "macaw_hot_balloon",
  },
  {
    game_id: "860001",
    game_name: "Aviator",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_aviator",
  },
  {
    game_id: "860002",
    game_name: "Dice",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860003",
    game_name: "Goal",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860004",
    game_name: "Plinko",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860005",
    game_name: "Mines",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860006",
    game_name: "Hi Lo",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860007",
    game_name: "Keno",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860008",
    game_name: "Mini Roulette",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860009",
    game_name: "Hotline",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860010",
    game_name: "Balloon",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_crypto",
  },
  {
    game_id: "860011",
    game_name: "Poker",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_poker",
  },
  {
    game_id: "860012",
    game_name: "Keno 80",
    category: "Others",
    provider_name: "SPRIBE",
    sub_provider_name: "SPRIBE",
    status: "ACTIVE",
    url_thumb: "no_thumb",
    game_code: "spribe_keno",
  },

  {
    game_id: "900001",
    game_name: "7 Up Down",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_7down.webp",
    game_code: "RG-7UD01",
  },
  {
    game_id: "900004",
    game_name: "Teen Patti",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti.webp",
    game_code: "RG-TP01",
  },
  {
    game_id: "900005",
    game_name: "Dragon Tiger",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger.webp",
    game_code: "RG-DT01",
  },
  {
    game_id: "900007",
    game_name: "20-20 Poker",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-CH01",
  },
  {
    game_id: "900008",
    game_name: "Andar Bahar",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-AB01",
  },
  {
    game_id: "900009",
    game_name: "Baccarat",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cricket_war.webp",
    game_code: "RG-BAC01",
  },
  {
    game_id: "900010",
    game_name: "Amar Akbar Anthony",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_a3.webp",
    game_code: "RG-AAA01",
  },
  {
    game_id: "900011",
    game_name: "3 Cards Judgement",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cards_judgement.webp",
    game_code: "RG-3CJ01",
  },
  {
    game_id: "900012",
    game_name: "Casino War",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_war.webp",
    game_code: "RG-CAW-01",
  },
  {
    game_id: "900013",
    game_name: "Worli Matka",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_worli_matka.webp",
    game_code: "RG-WM01",
  },
  {
    game_id: "900014",
    game_name: "Bollywood Casino",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_bollywood_casino.webp",
    game_code: "RG-BC01",
  },
  {
    game_id: "900015",
    game_name: "Lottery",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lottery.webp",
    game_code: "RG-LOT01",
  },
  {
    game_id: "900016",
    game_name: "20-20 Cricket",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_book_cricket.webp",
    game_code: "RG-2020CKT01",
  },
  {
    game_id: "900017",
    game_name: "HighLow",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_high_low.webp",
    game_code: "RG-HL01",
  },
  {
    game_id: "900018",
    game_name: "Muflis Teen Patti",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_muflis_teenpatti.webp",
    game_code: "RG-MTP01",
  },
  {
    game_id: "900019",
    game_name: "One Day Teenpatti",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/teen_patti_one_day.webp",
    game_code: "RG-1TP101",
  },
  {
    game_id: "900020",
    game_name: "Foot Ball Studio",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/football_studio.webp",
    game_code: "RG-FBS101",
  },
  {
    game_id: "900021",
    game_name: "Lucky7",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lucky7.webp",
    game_code: "RG-LK7101",
  },
  {
    game_id: "900022",
    game_name: "Casino Meter",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/casino_meter.webp",
    game_code: "RG-CAM101",
  },
  {
    game_id: "900023",
    game_name: "Akbar Romeo Walter",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_arw.webp",
    game_code: "RG-ARW101",
  },
  {
    game_id: "900024",
    game_name: "32 Cards",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/32_cards.webp",
    game_code: "RG-32C101",
  },
  {
    game_id: "900025",
    game_name: "2 Cards Teenpatti",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_2card_tp.webp",
    game_code: "RG-2TP101",
  },
  {
    game_id: "900026",
    game_name: "29 Card Baccarat",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/29_card_baccarat.webp",
    game_code: "RG-29BC101",
  },
  {
    game_id: "900027",
    game_name: "Dragon Tiger One day",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/dragon_tiger_one_day.webp",
    game_code: "RG-1DT101",
  },
  {
    game_id: "900028",
    game_name: "Poker",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/poker.webp",
    game_code: "RG-POK101",
  },
  {
    game_id: "900029",
    game_name: "Movie Casino",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/movie_casino.webp",
    game_code: "RG-MOC101",
  },
  {
    game_id: "900030",
    game_name: "Casino Queen",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-CAQ101",
  },
  {
    game_id: "900031",
    game_name: "Dragon Tiger Lion",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dtl.webp",
    game_code: "RG-DTL101",
  },
  {
    game_id: "900032",
    game_name: "Race To 17",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/race_to_17.webp",
    game_code: "RG-RT17101",
  },
  {
    game_id: "900033",
    game_name: "Race T20",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-RTT101",
  },
  {
    game_id: "900034",
    game_name: "Side Bet City",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/side_bet_city.webp",
    game_code: "RG-SBC101",
  },
  {
    game_id: "900035",
    game_name: "Super Over",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/super_over.webp",
    game_code: "RG-SPO101",
  },
  {
    game_id: "900036",
    game_name: "Speedy7",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-SY7101",
  },
  {
    game_id: "900037",
    game_name: "Trio",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/trio.webp",
    game_code: "RG-TRI101",
  },
  {
    game_id: "900038",
    game_name: "The Trap",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/the_trap.webp",
    game_code: "RG-TRP101",
  },
  {
    game_id: "900039",
    game_name: "2 card Teen Patti One day ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/2_card_teen_patti_one%20day.webp",
    game_code: "RG-12TP101",
  },
  {
    game_id: "900040",
    game_name: "Center Card One day ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1CC101",
  },
  {
    game_id: "900041",
    game_name: "High Card One day ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1HC101",
  },
  {
    game_id: "900042",
    game_name: "Muflis TeenPatti One day",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1MTP101",
  },
  {
    game_id: "900043",
    game_name: "Poker One Day ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1POK101",
  },
  {
    game_id: "900044",
    game_name: "Super Over One day ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1SPO101",
  },
  {
    game_id: "900045",
    game_name: "Speedy7 One day",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1SY7101",
  },
  {
    game_id: "900046",
    game_name: "Center Card ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card.webp",
    game_code: "RG-CC101",
  },
  {
    game_id: "900047",
    game_name: "High Card",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-HC101",
  },
  {
    game_id: "900048",
    game_name: "Queen Race ",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-QR101",
  },
  {
    game_id: "900049",
    game_name: "Teenpatti dev",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti.webp",
    game_code: "RG_GTP102",
  },
  {
    game_id: "900050",
    game_name: "Worli Matka_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_worli_matka.webp",
    game_code: "RG_GWM102",
  },
  {
    game_id: "900051",
    game_name: "Dus Ka Dum (Cards)_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_book_cricket.webp",
    game_code: "RG_GTTC102",
  },
  {
    game_id: "900052",
    game_name: "Trio_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/trio.webp",
    game_code: "RG_GTRI102",
  },
  {
    game_id: "900053",
    game_name: "Side Bet City_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/side_bet_city.webp",
    game_code: "RG_GSBC102",
  },
  {
    game_id: "900054",
    game_name: "SicBo_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG_GSB102",
  },
  {
    game_id: "900055",
    game_name: "Auto Roulette_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_roulette.jpg",
    game_code: "RG_GRT102",
  },
  {
    game_id: "900056",
    game_name: "Queen Race_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG_GQR102",
  },
  {
    game_id: "900057",
    game_name: "Muflis TeenPatti_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_muflis_teenpatti.webp",
    game_code: "RG_GMTP102",
  },
  {
    game_id: "900058",
    game_name: "Lottery_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lottery.webp",
    game_code: "RG_GLOT102",
  },
  {
    game_id: "900059",
    game_name: "Lucky7_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lucky7.webp",
    game_code: "RG_GLK7102",
  },
  {
    game_id: "900060",
    game_name: "High Low_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_high_low.webp",
    game_code: "RG_GHL102",
  },
  {
    game_id: "900061",
    game_name: "High Card_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG_GHC102",
  },
  {
    game_id: "900062",
    game_name: "Dragon Tiger Lion_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dtl.webp",
    game_code: "RG_GDTL102",
  },
  {
    game_id: "900063",
    game_name: "Dragon Tiger_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger.webp",
    game_code: "RG_GDT102",
  },
  {
    game_id: "900064",
    game_name: "Cricket War_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cricket_war.webp",
    game_code: "RG_GCW102",
  },
  {
    game_id: "900065",
    game_name: "Center Card_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card.webp",
    game_code: "RG_GCC102",
  },
  {
    game_id: "900066",
    game_name: "Bollywood Casino_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_bollywood_casino.webp",
    game_code: "RG_GBOC102",
  },
  {
    game_id: "900067",
    game_name: "Baccarat_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cricket_war.webp",
    game_code: "RG_GBAC102",
  },
  {
    game_id: "900068",
    game_name: "Akbar Romeo Walter_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_arw.webp",
    game_code: "RG_GARW102",
  },
  {
    game_id: "900069",
    game_name: "Amar Akbar Anthony_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_a3.webp",
    game_code: "RG_GA3102",
  },
  {
    game_id: "900070",
    game_name: "3 Cards Judgement_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cards_judgement.webp",
    game_code: "RG_G3CJ102",
  },
  {
    game_id: "900071",
    game_name: "2 Cards Teenpatti_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb:
      "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_2card_tp_vr.jpg",
    game_code: "RG_G2TP102",
  },
  {
    game_id: "900072",
    game_name: "29 Card Baccarat_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/29_card_baccarat.webp",
    game_code: "RG_G29BC102",
  },
  {
    game_id: "900073",
    game_name: "Casino War_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_war.webp",
    game_code: "RG_TCAW102",
  },
  {
    game_id: "900074",
    game_name: "7 Up Down_V2",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_7down.webp",
    game_code: "RG_7UD02",
  },
  {
    game_id: "900075",
    game_name: "Roulette",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_7down.webp",
    game_code: "RG-ROT101",
  },
  {
    game_id: "900076",
    game_name: "Poker",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/poker.webp",
    game_code: "RG-POK101",
  },
  {
    game_id: "900077",
    game_name: "Bollywood Casino",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_7down.webp",
    game_code: "RG-BOC102",
  },
  {
    game_id: "900078",
    game_name: "Dragon Tiger One day",
    category: "TABLE",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/dragon_tiger_one_day.webp",
    game_code: "RG-1DT102",
  },
  {
    game_id: "901001",
    game_name: "VR 7 Up Down",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_down_vr.webp",
    game_code: "RG-7UD01-VR",
  },
  {
    game_id: "901002",
    game_name: "VR Auto Roulette",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_vr_roulette.jpg",
    game_code: "RG-ART01-VR",
  },
  {
    game_id: "901003",
    game_name: "VR Teen Patti",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti_vr.webp",
    game_code: "RG-TP01-VR",
  },
  {
    game_id: "901004",
    game_name: "VR Dragon Tiger",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger_vr.webp",
    game_code: "RG-DT01-VR",
  },
  {
    game_id: "901005",
    game_name: "VR Cricket War",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cric_war_vr.webp",
    game_code: "RG-CW01-VR",
  },
  {
    game_id: "901006",
    game_name: "VR Baccarat",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_baccarat_vr.webp",
    game_code: "RG-BAC01-VR",
  },
  {
    game_id: "901007",
    game_name: "VR Amar Akbar Anthony",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_a3_vr.webp",
    game_code: "RG-AAA01-VR",
  },
  {
    game_id: "901008",
    game_name: "VR 3 Cards Judgement",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cj_vr.webp",
    game_code: "RG-3CJ01-VR",
  },
  {
    game_id: "901009",
    game_name: "VR Casino War",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_war_vr.webp",
    game_code: "RG-CAW01-VR",
  },
  {
    game_id: "901010",
    game_name: "VR Worli Matka",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_worli_matka_vr.webp",
    game_code: "RG-WM01-VR",
  },
  {
    game_id: "901011",
    game_name: "VR Bollywood Casino",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_bollywood_casino_vr.webp",
    game_code: "RG-BOC01-VR",
  },
  {
    game_id: "901012",
    game_name: "VR Lottery",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lottery_vr.webp",
    game_code: "RG-LOT01-VR",
  },
  {
    game_id: "901013",
    game_name: "VR Book Cricket",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dus_kadum_vr.webp",
    game_code: "RG-BCRIC01-VR",
  },
  {
    game_id: "901014",
    game_name: "VR HighLow",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_high_low_vr.webp",
    game_code: "RG-HL01-VR",
  },
  {
    game_id: "901015",
    game_name: "VR Muflis Teen Patti",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_muflis_tp_vr.webp",
    game_code: "RG-MTP01-VR",
  },
  {
    game_id: "901016",
    game_name: "Dragon Tiger One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/dragon_tiger_one_day_vr.webp",
    game_code: "RG-1DT101-VR",
  },
  {
    game_id: "901017",
    game_name: "One Day Teenpatti VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/teen_patti_one_day_vr.webp",
    game_code: "RG-1TP101-VR",
  },
  {
    game_id: "901018",
    game_name: "VR 29 Card Baccarat",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/29_card_baccarat_vr.webp",
    game_code: "RG-29BC101",
  },
  {
    game_id: "901019",
    game_name: "VR 2 Cards Teenpatti",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_2card_tp_vr.webp",
    game_code: "RG-2TP101",
  },
  {
    game_id: "901020",
    game_name: "VR 32 Cards",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_32cards_vr.webp",
    game_code: "RG-32C101",
  },
  {
    game_id: "901021",
    game_name: "VR Andar Bahar",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_andar_bahar_vr.webp",
    game_code: "RG-AB101",
  },
  {
    game_id: "901022",
    game_name: "VR Akbar Romeo Walter",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_arw_vr.webp",
    game_code: "RG-ARW101",
  },
  {
    game_id: "901023",
    game_name: "VR Casino Meter",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_meter_vr.webp",
    game_code: "RG-CAM101",
  },
  {
    game_id: "901024",
    game_name: "VR Casino Queen",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-CAQ101",
  },
  {
    game_id: "901025",
    game_name: "VR 20-20 Poker",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-CH101",
  },
  {
    game_id: "901026",
    game_name: "VR Dragon Tiger Lion",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dtl_vr.webp",
    game_code: "RG-DTL101",
  },
  {
    game_id: "901027",
    game_name: "VR Foot Ball Studio",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_fb_studio_vr.webp",
    game_code: "RG-FBS101",
  },
  {
    game_id: "901028",
    game_name: "VR Lucky7",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lucky7_vr.webp",
    game_code: "RG-LK7101",
  },
  {
    game_id: "901029",
    game_name: "VR Movie Casino",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_movie_casino_vr.webp",
    game_code: "RG-MOC101",
  },
  {
    game_id: "901030",
    game_name: "VR Poker",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_poker_vr.webp",
    game_code: "RG-POK101-VR",
  },
  {
    game_id: "901031",
    game_name: "VR Race To 17",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_rto17_vr.webp",
    game_code: "RG-RT17101",
  },
  {
    game_id: "901032",
    game_name: "VR Race T20",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_king_race_vr.webp",
    game_code: "RG-RTT101",
  },
  {
    game_id: "901033",
    game_name: "VR Side Bet City",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_sb_city_vr.webp",
    game_code: "RG-SBC101",
  },
  {
    game_id: "901034",
    game_name: "VR Super Over",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_super_over_vr.webp",
    game_code: "RG-SPO101",
  },
  {
    game_id: "901035",
    game_name: "VR Speedy7",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cards_judgement.webp",
    game_code: "RG-SY7101",
  },
  {
    game_id: "901036",
    game_name: "VR Trio",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_trio_vr.webp",
    game_code: "RG-TRI101",
  },
  {
    game_id: "901037",
    game_name: "VR The Trap",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_the_trap_vr.webp",
    game_code: "RG-TRP101",
  },
  {
    game_id: "901038",
    game_name: "2 card Teen Patti One day VR ",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rg/2_card_teen_patti_one_day_vr.webp",
    game_code: "RG-12TP101-VR ",
  },
  {
    game_id: "901039",
    game_name: "Center Card One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card_one_day_vr.webp",
    game_code: "RG-1CC101-VR ",
  },
  {
    game_id: "901040",
    game_name: "High Card One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1HC101-VR",
  },
  {
    game_id: "901041",
    game_name: "Muflis TeenPatti One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/muflis_one_day_vr.webp",
    game_code: "RG-1MTP101-VR",
  },
  {
    game_id: "901042",
    game_name: "Poker One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/poker_1day_vr.webp",
    game_code: "RG-1POK101-VR",
  },
  {
    game_id: "901043",
    game_name: "Super Over One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/super_over_one_day_vr.webp",
    game_code: "RG-1SPO101-VR",
  },
  {
    game_id: "901044",
    game_name: "Speedy7 One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-1SY7101-VR",
  },
  {
    game_id: "901045",
    game_name: "Center Card VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card_vr.webp",
    game_code: "RG-CC101-VR",
  },
  {
    game_id: "901046",
    game_name: "High Card VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/high_card_vr.webp",
    game_code: "RG-HC101-VR",
  },
  {
    game_id: "901047",
    game_name: "Queen Race VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/queen_race_vr.webp",
    game_code: "RG-QR101-VR",
  },
  {
    game_id: "901048",
    game_name: "Casino War VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_war_vr.webp",
    game_code: "RG_CAW102_VR",
  },
  {
    game_id: "901049",
    game_name: "29 Card Baccarat VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/29_card_baccarat_vr.webp",
    game_code: "RG_G29BC102_VR",
  },
  {
    game_id: "901050",
    game_name: "2 Cards Teenpatti VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_2card_tp_vr.webp",
    game_code: "RG_G2TP102_VR",
  },
  {
    game_id: "901051",
    game_name: "32 Cards VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_32cards_vr.webp",
    game_code: "RG_G32C102_VR",
  },
  {
    game_id: "901052",
    game_name: "3 Cards Judgement VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cj_vr.webp",
    game_code: "RG_G3CJ102_VR",
  },
  {
    game_id: "901053",
    game_name: "Amar Akbar Anthony VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_a3_vr.webp",
    game_code: "RG_GA3102_VR",
  },
  {
    game_id: "901054",
    game_name: "Andar Bahar VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_andar_bahar_vr.webp",
    game_code: "RG_GAB102_VR",
  },
  {
    game_id: "901055",
    game_name: "Akbar Romeo Walter VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_arw_vr.webp",
    game_code: "RG_GARW102_VR",
  },
  {
    game_id: "901056",
    game_name: "Baccarat VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_baccarat_vr.webp",
    game_code: "RG_GBAC102_VR",
  },
  {
    game_id: "901057",
    game_name: "Bollywood Casino VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_bollywood_casino_vr.webp",
    game_code: "RG_GBOC102_VR",
  },
  {
    game_id: "901058",
    game_name: "Casino Meter VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_meter_vr.webp",
    game_code: "RG_GCAM102_VR",
  },
  {
    game_id: "901059",
    game_name: "Center Card VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card.webp",
    game_code: "RG_GCC102_VR",
  },
  {
    game_id: "901060",
    game_name: "Cricket War VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cric_war_vr.webp",
    game_code: "RG_GCW102_VR",
  },
  {
    game_id: "901061",
    game_name: "Dragon Tiger Lion VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dtl_vr.webp",
    game_code: "RG_GDTL102_VR",
  },
  {
    game_id: "901062",
    game_name: "footballstudio_test",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_fb_studio_vr.webp",
    game_code: "RG_GFBS102_VR",
  },
  {
    game_id: "901063",
    game_name: "High Card VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/high_card_vr.webp",
    game_code: "RG_GHC102_VR",
  },
  {
    game_id: "901064",
    game_name: "High Low VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_high_low_vr.webp",
    game_code: "RG_GHL102_VR",
  },
  {
    game_id: "901065",
    game_name: "Lucky7 VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_lucky7_vr.jpg",
    game_code: "RG_GLK7102_VR",
  },
  {
    game_id: "901066",
    game_name: "Lottery VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lottery_vr.webp",
    game_code: "RG_GLOT102_VR",
  },
  {
    game_id: "901067",
    game_name: "Movie Casino VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_movie_casino_vr.webp",
    game_code: "RG_GMOC102_VR",
  },
  {
    game_id: "901068",
    game_name: "Muflis TeenPatti VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_muflis_tp_vr.jpg",
    game_code: "RG_GMTP102_VR",
  },
  {
    game_id: "901069",
    game_name: "Poker VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_poker_vr.webp",
    game_code: "RG_GPOK102_VR",
  },
  {
    game_id: "901070",
    game_name: "Queen Race VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/queen_race_vr.webp",
    game_code: "RG_GQR102_VR",
  },
  {
    game_id: "901071",
    game_name: "Auto Roulette VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_vr_roulette.jpg",
    game_code: "RG_GRT102_VR",
  },
  {
    game_id: "901072",
    game_name: "Race To 17 VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_rto17_vr.webp",
    game_code: "RG_GRT17102_VR",
  },
  {
    game_id: "901073",
    game_name: "Race T20 VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_king_race_vr.webp",
    game_code: "RG_GRTT102_VR",
  },
  {
    game_id: "901074",
    game_name: "Side Bet City VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_sb_city_vr.webp",
    game_code: "RG_GSBC102_VR",
  },
  {
    game_id: "901075",
    game_name: "Super Over VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_super_over_vr.webp",
    game_code: "RG_GSPO102_VR",
  },
  {
    game_id: "901076",
    game_name: "teenpatti_test012",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/testtp_vr.webp",
    game_code: "RG_GTP102_VR",
  },
  {
    game_id: "901077",
    game_name: "Trio VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_trio_vr.webp",
    game_code: "RG_GTRI102_VR",
  },
  {
    game_id: "901078",
    game_name: "The Trap VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_the_trap_vr.webp",
    game_code: "RG_GTRP102_VR",
  },
  {
    game_id: "901079",
    game_name: "Dus Ka Dum (Cards) VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dus_kadum_vr.webp",
    game_code: "RG_GTTC102_VR",
  },
  {
    game_id: "901080",
    game_name: "Worli Matka VR_dev",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_worli_matka_vr.webp",
    game_code: "RG_GWM102_VR",
  },
  {
    game_id: "901081",
    game_name: "2 Cards Teenpatti VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_2card_tp_vr.webp",
    game_code: "RG_G2TP102_VR",
  },
  {
    game_id: "901082",
    game_name: "29 Card Baccarat VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/29_card_baccarat_vr.webp",
    game_code: "RG_G29BC102_VR",
  },
  {
    game_id: "901083",
    game_name: "3 Cards Judgement VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_3cj_vr.webp",
    game_code: "RG_G3CJ102_VR",
  },
  {
    game_id: "901084",
    game_name: "32 Cards VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_32cards_vr.webp",
    game_code: "RG_G32C102_VR",
  },
  {
    game_id: "901085",
    game_name: "Akbar Romeo Walter VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_arw_vr.webp",
    game_code: "RG_GARW102_VR",
  },
  {
    game_id: "901086",
    game_name: "Amar Akbar Anthony VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_a3_vr.webp",
    game_code: "RG_GA3102_VR",
  },
  {
    game_id: "901087",
    game_name: "Andar Bahar VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_andar_bahar_vr.webp",
    game_code: "RG_GAB102_VR",
  },
  {
    game_id: "901088",
    game_name: "Auto Roulette VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_vr_roulette.jpg",
    game_code: "RG_GRT102_VR",
  },
  {
    game_id: "901089",
    game_name: "Baccarat VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_baccarat_vr.webp",
    game_code: "RG_GBAC102_VR",
  },
  {
    game_id: "901090",
    game_name: "Bollywood Casino VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_bollywood_casino_vr.webp",
    game_code: "RG_GBOC102_VR",
  },
  {
    game_id: "901091",
    game_name: "Casino Meter VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_meter_vr.webp",
    game_code: "RG_GCAM102_VR",
  },
  {
    game_id: "901092",
    game_name: "Casino War VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_casino_war_vr.webp",
    game_code: "RG_CAW102_VR",
  },
  {
    game_id: "901093",
    game_name: "Center Card VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card.webp",
    game_code: "RG_GCC102_VR",
  },
  {
    game_id: "901094",
    game_name: "Cricket War VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_cric_war_vr.webp",
    game_code: "RG_GCW102_VR",
  },
  {
    game_id: "901095",
    game_name: "Dragon Tiger Lion VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dtl_vr.webp",
    game_code: "RG_GDTL102_VR",
  },
  {
    game_id: "901096",
    game_name: "Dragon Tiger VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_dtl_vr.jpg",
    game_code: "RG_GDT102_VR",
  },
  {
    game_id: "901097",
    game_name: "Dus Ka Dum (Cards) VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dus_kadum_vr.webp",
    game_code: "RG_GTTC102_VR",
  },
  {
    game_id: "901098",
    game_name: "Foot Ball Studio VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_fb_studio_vr.webp",
    game_code: "RG_GFBS102_VR",
  },
  {
    game_id: "901099",
    game_name: "High Card VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/high_card_vr.webp",
    game_code: "RG_GHC102_VR",
  },
  {
    game_id: "901100",
    game_name: "High Low VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_high_low_vr.webp",
    game_code: "RG_GHL102_VR",
  },
  {
    game_id: "901101",
    game_name: "Lottery VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_lottery_vr.webp",
    game_code: "RG_GLOT102_VR",
  },
  {
    game_id: "901102",
    game_name: "Lucky7 VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_lucky7_vr.jpg",
    game_code: "RG_GLK7102_VR",
  },
  {
    game_id: "901103",
    game_name: "Movie Casino VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_movie_casino_vr.webp",
    game_code: "RG_GMOC102_VR",
  },
  {
    game_id: "901104",
    game_name: "Muflis TeenPatti VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://royal-gaming.s3.eu-west-2.amazonaws.com/rg_muflis_tp_vr.jpg",
    game_code: "RG_GMTP102_VR",
  },
  {
    game_id: "901105",
    game_name: "Poker VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_poker_vr.webp",
    game_code: "RG_GPOK102_VR",
  },
  {
    game_id: "901106",
    game_name: "Queen Race VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/queen_race_vr.webp",
    game_code: "RG_GQR102_VR",
  },
  {
    game_id: "901107",
    game_name: "Race T20 VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_king_race_vr.webp",
    game_code: "RG_GRTT102_VR",
  },
  {
    game_id: "901108",
    game_name: "Race To 17 VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_rto17_vr.webp",
    game_code: "RG_GRT17102_VR",
  },
  {
    game_id: "901109",
    game_name: "Side Bet City VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_sb_city_vr.webp",
    game_code: "RG_GSBC102_VR",
  },
  {
    game_id: "901110",
    game_name: "Super Over VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_super_over_vr.webp",
    game_code: "RG_GSPO102_VR",
  },
  {
    game_id: "901111",
    game_name: "Teenpatti VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti_vr.webp",
    game_code: "RG_GTP102_VR",
  },
  {
    game_id: "901112",
    game_name: "The Trap VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_the_trap_vr.webp",
    game_code: "RG_GTRP102_VR",
  },
  {
    game_id: "901113",
    game_name: "Trio VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_trio_vr.webp",
    game_code: "RG_GTRI102_VR",
  },
  {
    game_id: "901114",
    game_name: "Worli Matka VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_worli_matka_vr.webp",
    game_code: "RG_GWM102_VR",
  },
  {
    game_id: "901115",
    game_name: "Teen Patti One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/teen_patti_one_day_vr.webp",
    game_code: "RG_G1TP102",
  },
  {
    game_id: "901116",
    game_name: "Dragon Tiger One  Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/dragon_tiger_one_day_vr.webp",
    game_code: "RG-1DT102-VR",
  },
  {
    game_id: "901117",
    game_name: "Muflis Teenpatti  One  Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/muflis_one_day_vr.webp",
    game_code: "RG-1MTP102-VR",
  },
  {
    game_id: "901118",
    game_name: "2 Card Teenpatti One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rg/2_card_teen_patti_one_day_vr.webp",
    game_code: "RG-12TP102-VR",
  },
  {
    game_id: "901119",
    game_name: "Super Over One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/super_over_one_day_vr.webp",
    game_code: "RG-1SPO102-VR",
  },
  {
    game_id: "901120",
    game_name: "Center Card One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/center_card_one_day_vr.webp",
    game_code: "RG-1CC102-VR",
  },
  {
    game_id: "901121",
    game_name: "VR 7 Up Down 2",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_7up_down_vr.webp",
    game_code: "RG-7UD02-VR",
  },
  {
    game_id: "901122",
    game_name: "VR Dragon Tiger 5",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger_vr.webp",
    game_code: "RG-DT05-VR",
  },
  {
    game_id: "901123",
    game_name: "Ludo Cricket",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://bo.royalgaming.online/assets/Logo/Royal-Gaming-logo.svg",
    game_code: "RG-VTGLUD101",
  },
  {
    game_id: "901124",
    game_name: "VR One Card Meter",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/1card_meter_vr.webp",
    game_code: "RG-OCM-VR",
  },
  {
    game_id: "901125",
    game_name: "VR Instant Teenpatti 1",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/instant_tp1_vr.webp",
    game_code: "RG-ITP-VR",
  },
  {
    game_id: "901126",
    game_name: "VR Instant Teenpatti 2",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/instant_tp2_vr.webp",
    game_code: "RG-ITP2-VR",
  },
  {
    game_id: "901127",
    game_name: "Ludo Cricket T10 2 Player ",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://bo.royalgaming.online/assets/Logo/Royal-Gaming-logo.svg",
    game_code: "RG-L2PT102-VR",
  },
  {
    game_id: "901128",
    game_name: "Note Number One Day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/note_number_vr.webp",
    game_code: "RG-1NN-VR",
  },
  {
    game_id: "901129",
    game_name: "Kaun Banega Crorepati VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/kbc.webp",
    game_code: "RG-KBC-VR",
  },
  {
    game_id: "901130",
    game_name: "One Card 20 20 VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/1card_2020_vr.webp",
    game_code: "RG-OCTT-VR",
  },
  {
    game_id: "901131",
    game_name: "One Card One day VR",
    category: "Virtual",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Virtual",
    status: "ACTIVE",
    url_thumb:
      "https://rg-assests.royalgaming.online/lobby-icon/1card_1day_vr.webp",
    game_code: "RG-1OC101",
  },
  {
    game_id: "902000",
    game_name: "Yuvi Games Mobile Lobby",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games Lobby",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-MLOBBY",
  },
  {
    game_id: "902001",
    game_name: "7 Up & Down Mini ",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VT7UP03-MINI",
  },
  {
    game_id: "902002",
    game_name: "Dragon Tiger Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGDT103-MINI",
  },
  {
    game_id: "902003",
    game_name: "Amar Akbar Anthony Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGA3103-MINI",
  },
  {
    game_id: "902004",
    game_name: "Teen Patti Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGTP103-MINI",
  },
  {
    game_id: "902005",
    game_name: "Hi Low Mini ",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGHL103-MINI",
  },
  {
    game_id: "902006",
    game_name: "Cricket War Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGCW103-MINI",
  },
  {
    game_id: "902007",
    game_name: "Teen Patti Muflis Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGMTP103-MINI",
  },
  {
    game_id: "902008",
    game_name: "2 card Teen Patti Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTG2TP103-MINI",
  },
  {
    game_id: "902009",
    game_name: "Dus Ka Dum (cards)  Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGTTC103-MINI",
  },
  {
    game_id: "902010",
    game_name: "Dragon Tiger Lion Mini ",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGDTL103-MINI",
  },
  {
    game_id: "902011",
    game_name: "Football Studio mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGFBS103-MINI",
  },
  {
    game_id: "902012",
    game_name: "Lucky7  Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGLK7103-MINI",
  },
  {
    game_id: "902013",
    game_name: "Akbar Romeo Walter Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGARW103-MINI",
  },
  {
    game_id: "902014",
    game_name: "Super Over Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGSPO103-MINI",
  },
  {
    game_id: "902015",
    game_name: "Baccarat Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGBAC103-MINI",
  },
  {
    game_id: "902016",
    game_name: "Poker Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGPOK103-MINI",
  },
  {
    game_id: "902017",
    game_name: "Andar Bahar mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGAB103-MINI",
  },
  {
    game_id: "902018",
    game_name: "Trio Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGTRI103-MINI",
  },
  {
    game_id: "902019",
    game_name: "side bet city Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_sb_city_vr.webp",
    game_code: "YG-VTGSBC103-MINI",
  },
  {
    game_id: "902020",
    game_name: "Race to 17 Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-VTGRT17103-MINI",
  },
  {
    game_id: "902501",
    game_name: "Trio Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-TRI104-MINI",
  },
  {
    game_id: "902502",
    game_name: "Race to 17 Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-RT17104-MINI",
  },
  {
    game_id: "902503",
    game_name: "Poker Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-POK104-MINI",
  },
  {
    game_id: "902504",
    game_name: "Cricket War Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-CW104-MINI",
  },
  {
    game_id: "902505",
    game_name: "2 card Teen Patti Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-2TP104-MINI",
  },
  {
    game_id: "902506",
    game_name: "Akbar Romeo Walter Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-ARW104-MINI",
  },
  {
    game_id: "902507",
    game_name: "Baccarat Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-BAC104-MINI",
  },
  {
    game_id: "902508",
    game_name: "Super Over Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-SPO104-MINI",
  },
  {
    game_id: "902509",
    game_name: "Lucky7  Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-LK7104-MINI",
  },
  {
    game_id: "902510",
    game_name: "Dragon Tiger Lion Mini ",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-DTL104-MINI",
  },
  {
    game_id: "902511",
    game_name: "Football Studio mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-FBS104-MINI",
  },
  {
    game_id: "902512",
    game_name: "Teen Patti Muflis Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-MTP104-MINI",
  },
  {
    game_id: "902513",
    game_name: "7 Up & Down Mini ",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-UP104-MINI",
  },
  {
    game_id: "902514",
    game_name: "Andar Bahar mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-AB104-MINI",
  },
  {
    game_id: "902515",
    game_name: "Teen Patti Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-TP104-MINI",
  },
  {
    game_id: "902516",
    game_name: "Dragon Tiger Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-DT104-MINI",
  },
  {
    game_id: "902517",
    game_name: "Amar Akbar Anthony Mini",
    category: "Mini Games",
    provider_name: "RG",
    sub_provider_name: "Yuvi Games",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "YG-A3104-MINI",
  },
  {
    game_id: "903001",
    game_name: "Roulette Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_roulette.jpg",
    game_code: "RG-XRT101",
  },
  {
    game_id: "903002",
    game_name: "Sicbo Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-XSB101",
  },
  {
    game_id: "903003",
    game_name: "Baccarat Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/baccarat.webp",
    game_code: "RG-XBAC101",
  },
  {
    game_id: "903004",
    game_name: "Dragon Tiger Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger.webp",
    game_code: "RG-XDT101",
  },
  {
    game_id: "903005",
    game_name: "Teenpatti Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti.webp",
    game_code: "RG-XTP101",
  },
  {
    game_id: "903006",
    game_name: "Andar Bahar Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-XAB101",
  },
  {
    game_id: "903007",
    game_name: "Poker Vivo",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/poker.webp",
    game_code: "RG-XPOK101",
  },
  {
    game_id: "904001",
    game_name: "Roulette Vivo D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.royalgaming.online/rg_roulette.jpg",
    game_code: "RG-XRT102",
  },
  {
    game_id: "904002",
    game_name: "Sicbo Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "MAC88-XSB101",
  },
  {
    game_id: "904003",
    game_name: "Baccarat Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/baccarat.webp",
    game_code: "MAC88-XSB101",
  },
  {
    game_id: "904004",
    game_name: "Dragon Tiger Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_dragon_tiger.webp",
    game_code: "RG-XDT102",
  },
  {
    game_id: "904005",
    game_name: "Teenpatti Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_teen_patti.webp",
    game_code: "RG-XTP102",
  },
  {
    game_id: "904006",
    game_name: "Andar Bahar Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG-XAB102",
  },
  {
    game_id: "904007",
    game_name: "Poker Vivo  D",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/poker.webp",
    game_code: "RG-XPOK102",
  },
  {
    game_id: "904008",
    game_name: "Instant Teenpatti 1",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/itp1.webp",
    game_code: "RG-ITP",
  },
  {
    game_id: "904009",
    game_name: "Instant Teenpatti 2",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/itp2.webp",
    game_code: "RG-ITP2",
  },
  {
    game_id: "904010",
    game_name: "Test Teenpatti",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/ttp.webp",
    game_code: "RG-TTP",
  },
  {
    game_id: "904011",
    game_name: "Auto Roulette V2",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-ROT-V2",
  },
  {
    game_id: "904012",
    game_name: "Auto Roulette V3",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-ROT-V3",
  },
  {
    game_id: "904013",
    game_name: "Auto Roulette V4",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-ROT-V4",
  },
  {
    game_id: "904015",
    game_name: "Auto Roulette V5",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-ROT-V5",
  },
  {
    game_id: "904016",
    game_name: "Baccarat V2",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-BAC-V2",
  },
  {
    game_id: "904017",
    game_name: "Baccarat V3",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-BAC-V3",
  },
  {
    game_id: "904018",
    game_name: "Teenpatti V3",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "..",
    game_code: "RG-TPV3",
  },
  {
    game_id: "904019",
    game_name: "Teenpatti V2",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-TP-V2",
  },
  {
    game_id: "904020",
    game_name: "Note Number",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "",
    game_code: "RG-1NN",
  },
  {
    game_id: "904021",
    game_name: "Kaun Banega Crorepati",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/kbc.webp",
    game_code: "RG-KBC",
  },
  {
    game_id: "904022",
    game_name: "Auto Roulette V2",
    category: "Live",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming",
    status: "ACTIVE",
    url_thumb: "https://rg-assests.royalgaming.online/lobby-icon/kbc.webp",
    game_code: "RG-RT-V2",
  },
  {
    game_id: "950000",
    game_name: "Rich88 Lobby",
    category: "LOBBY",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/rich88_lobby.webp",
    game_code: "",
  },
  {
    game_id: "950001",
    game_name: "Big and Small",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/big_and_small.webp",
    game_code: "rich88_updown",
  },
  {
    game_id: "950002",
    game_name: "Fish Shrimp Crab",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fish_shrimp_crab.webp",
    game_code: "rich88_hooheyhow",
  },
  {
    game_id: "950003",
    game_name: "Ladder Climbing",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/ladder_climbing.webp",
    game_code: "rich88_ladder",
  },
  {
    game_id: "950004",
    game_name: "Snail Racing",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/snail_racing.webp",
    game_code: "rich88_snailracingslow",
  },
  {
    game_id: "950005",
    game_name: "Color Dish",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/color_dish.webp",
    game_code: "rich88_colorplate",
  },
  {
    game_id: "950006",
    game_name: "Rock Paper Scissors",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/rock_paper_scissors.webp",
    game_code: "rich88_rockpaperscissor",
  },
  {
    game_id: "950007",
    game_name: "Monkey Climb Tree",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/monkey_climb_tree.webp",
    game_code: "rich88_monkeygogo",
  },
  {
    game_id: "950008",
    game_name: "5PK",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/5pk.webp",
    game_code: "rich88_fivepk",
  },
  {
    game_id: "950009",
    game_name: "7PK",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/7pk.webp",
    game_code: "rich88_sevenpk",
  },
  {
    game_id: "950010",
    game_name: "Single Black Jack",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/single_black_jack.webp",
    game_code: "rich88_singleblackjack",
  },
  {
    game_id: "950012",
    game_name: "GEM Planet",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/gem_planet.webp",
    game_code: "rich88_slotgem",
  },
  {
    game_id: "950013",
    game_name: "Fast Snail Racing",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fast_snail_racing.webp",
    game_code: "rich88_snailracing",
  },
  {
    game_id: "950014",
    game_name: "88 Fortunes",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/88_fortunes.webp",
    game_code: "rich88_slotfortunes",
  },
  {
    game_id: "950016",
    game_name: "Battle of Five Carp",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/battle_of_five_carp.webp",
    game_code: "rich88_slotfivecarp",
  },
  {
    game_id: "950017",
    game_name: "The Journey to The West",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/the_journey_to_the_west.webp",
    game_code: "rich88_slotjourney",
  },
  {
    game_id: "950018",
    game_name: "Football Battle",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/football_battle.webp",
    game_code: "rich88_duelsoccer",
  },
  {
    game_id: "950019",
    game_name: "Vampire Hunter",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/vampire_hunter.webp",
    game_code: "rich88_slothunter",
  },
  {
    game_id: "950020",
    game_name: "Rave Jump",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/rave_jump.webp",
    game_code: "rich88_slotjump",
  },
  {
    game_id: "950021",
    game_name: "Great Blue",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/great_blue.webp",
    game_code: "rich88_slotripple",
  },
  {
    game_id: "950022",
    game_name: "Lucky Bats",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/lucky_bats.webp",
    game_code: "rich88_slotblisscomes",
  },
  {
    game_id: "950023",
    game_name: "PokDeng",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/pokdeng.webp",
    game_code: "rich88_bobabojiu",
  },
  {
    game_id: "950024",
    game_name: "Three Dice",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/three_dice.webp",
    game_code: "rich88_shaizisangong",
  },
  {
    game_id: "950025",
    game_name: "Golden Dragon Ball",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/golden_dragon_ball.webp",
    game_code: "rich88_slotgoldendragonball",
  },
  {
    game_id: "950026",
    game_name: "Baccarat",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/baccarat.webp",
    game_code: "rich88_baccaratlz",
  },
  {
    game_id: "950027",
    game_name: "Sichuan Opera Facial Makeup",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rich88/sichuan_opera_facial_makeup.webp",
    game_code: "rich88_slotfacechanging",
  },
  {
    game_id: "950028",
    game_name: "FAN TAN",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fan_tan.webp",
    game_code: "rich88_fantan",
  },
  {
    game_id: "950029",
    game_name: "100 PokDeng",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/100_pokdeng.webp",
    game_code: "rich88_pokdeng",
  },
  {
    game_id: "950030",
    game_name: "Lottery Ticket",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/lottery_ticket.webp",
    game_code: "rich88_instantlottery",
  },
  {
    game_id: "950031",
    game_name: "Thai Boxing King",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/thai_boxing_king.webp",
    game_code: "rich88_slotboxingking",
  },
  {
    game_id: "950032",
    game_name: "Thai Food Show",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/thai_food_show.webp",
    game_code: "rich88_slotthaisnack",
  },
  {
    game_id: "950033",
    game_name: "Ancient Giant Elephant",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/ancient_giant_elephant.webp",
    game_code: "rich88_slotprehistoricelephant",
  },
  {
    game_id: "950034",
    game_name: "Bingo",
    category: "Bingo game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/bingo.webp",
    game_code: "rich88_bingo",
  },
  {
    game_id: "950035",
    game_name: "CockFighting",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/cockfighting.webp",
    game_code: "rich88_cockfighting",
  },
  {
    game_id: "950036",
    game_name: "Happy Rich Joy",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/happy_rich_joy.webp",
    game_code: "rich88_sippata",
  },
  {
    game_id: "950037",
    game_name: "777",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/777.webp",
    game_code: "rich88_slot777",
  },
  {
    game_id: "950038",
    game_name: "King Kong",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/king_kong.webp",
    game_code: "rich88_slotkingkong",
  },
  {
    game_id: "950039",
    game_name: "Wild Fire Ranger",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/wild_fire_ranger.webp",
    game_code: "rich88_slotwildfireranger",
  },
  {
    game_id: "950040",
    game_name: "War of the Egyptian gods",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rich88/war_of_the_egyptian_gods.webp",
    game_code: "rich88_slotegyptiondeities",
  },
  {
    game_id: "950041",
    game_name: "Dragon four knives",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/dragon_four_knives.webp",
    game_code: "rich88_dragonfourknives",
  },
  {
    game_id: "950042",
    game_name: "Forest Dance",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/forest_dance.webp",
    game_code: "rich88_forestparty",
  },
  {
    game_id: "950043",
    game_name: "Thai Sic Bo",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/thai_sic_bo.webp",
    game_code: "rich88_sicbotha",
  },
  {
    game_id: "950044",
    game_name: "Fortune bull",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fortune_bull.webp",
    game_code: "rich88_slotbull",
  },
  {
    game_id: "950045",
    game_name: "Double slot",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/double_slot.webp",
    game_code: "rich88_slotdouble777",
  },
  {
    game_id: "950046",
    game_name: "Crazy Songkran",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/crazy_songkran.webp",
    game_code: "rich88_slotsplash",
  },
  {
    game_id: "950047",
    game_name: "Circle throwing",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/circle_throwing.webp",
    game_code: "rich88_throwingrings",
  },
  {
    game_id: "950048",
    game_name: "Dragon Tiger",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/dragon_tiger.webp",
    game_code: "rich88_dragontiger",
  },
  {
    game_id: "950049",
    game_name: "Color Game",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/color_game.webp",
    game_code: "rich88_colorgame",
  },
  {
    game_id: "950050",
    game_name: "Dragon Boat Festival",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/dragon_boat_festival.webp",
    game_code: "rich88_slotdragonboatfestival",
  },
  {
    game_id: "950051",
    game_name: "Red and Black",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/red_and_black.webp",
    game_code: "rich88_redblacklz",
  },
  {
    game_id: "950052",
    game_name: "Crushing Pot",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/crushing_pot.webp",
    game_code: "rich88_crushingpots",
  },
  {
    game_id: "950053",
    game_name: "Big and Small 2",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/big_and_small_2.webp",
    game_code: "rich88_updownv2",
  },
  {
    game_id: "950054",
    game_name: "Color Dish 2",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/color_dish_2.webp",
    game_code: "rich88_colorplatev2",
  },
  {
    game_id: "950055",
    game_name: "Cockfighting 2",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/cockfighting2.webp",
    game_code: "rich88_cockfighting2",
  },
  {
    game_id: "950056",
    game_name: "Lucky Fortune",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/lucky_fortune.webp",
    game_code: "rich88_slottreasure",
  },
  {
    game_id: "950057",
    game_name: "Chinese Valentine's Day",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/chinese_valentine_s_day.webp",
    game_code: "rich88_slotqixifestival",
  },
  {
    game_id: "950058",
    game_name: "Hundred People Niuniu",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/hundred_people_niuniu.webp",
    game_code: "rich88_bullbullhundredlz",
  },
  {
    game_id: "950059",
    game_name: "Book of Dead",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/book_of_dead.webp",
    game_code: "rich88_slotbookofdead",
  },
  {
    game_id: "950060",
    game_name: "Out or Bang",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/out_or_bang.webp",
    game_code: "rich88_deepseaadventure",
  },
  {
    game_id: "950061",
    game_name: "Jump High",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/jump_high.webp",
    game_code: "rich88_slotjumphigh",
  },
  {
    game_id: "950062",
    game_name: "Rich Fishing",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/rich_fishing.webp",
    game_code: "rich88_fishfafa",
  },
  {
    game_id: "950063",
    game_name: "Moon Festival Party",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/moon_festival_party.webp",
    game_code: "rich88_slotmoonfestivalparty",
  },
  {
    game_id: "950064",
    game_name: "Money Rolling",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/money_rolling.webp",
    game_code: "rich88_slotrollingmoney",
  },
  {
    game_id: "950065",
    game_name: "Wild Island",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/wild_island.webp",
    game_code: "rich88_slotadventureisland",
  },
  {
    game_id: "950066",
    game_name: "Mahjong King",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/mahjong_king.webp",
    game_code: "rich88_slotmahjong",
  },
  {
    game_id: "950067",
    game_name: "Squid Game",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/squid_game.webp",
    game_code: "rich88_slotsquidgame",
  },
  {
    game_id: "950069",
    game_name: "Gem Mine",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/gem_mine.webp",
    game_code: "rich88_slotgemmine",
  },
  {
    game_id: "950070",
    game_name: "Christmas Gift",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/christmas_gift.webp",
    game_code: "rich88_slotchristmasgift",
  },
  {
    game_id: "950071",
    game_name: "Mouse Gets Fortune",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/mouse_gets_fortune.webp",
    game_code: "rich88_mousegetsfortune",
  },
  {
    game_id: "950072",
    game_name: "Fortune Tiger",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fortune_tiger.webp",
    game_code: "rich88_slotfortunetiger",
  },
  {
    game_id: "950073",
    game_name: "New Year Money Rolling",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/new_year_money_rolling.webp",
    game_code: "rich88_slotrollingmoneyny",
  },
  {
    game_id: "950074",
    game_name: "Bingo Football",
    category: "Bingo game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/bingo_football.webp",
    game_code: "rich88_bingosoccer",
  },
  {
    game_id: "950075",
    game_name: "Legend of Neptune",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/legend_of_neptune.webp",
    game_code: "rich88_legendofneptune",
  },
  {
    game_id: "950076",
    game_name: "Multi Mega - Classic",
    category: "Bingo game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/multi_mega_-_classic.webp",
    game_code: "rich88_bingo20",
  },
  {
    game_id: "950077",
    game_name: "GOLDEN EMPIRE",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/golden_empire.webp",
    game_code: "rich88_slotgoldenempire",
  },
  {
    game_id: "950078",
    game_name: "SuperAce",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/super_ace.webp",
    game_code: "rich88_slotsuperace",
  },
  {
    game_id: "950079",
    game_name: "Fortune Gems",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fortune_gems.webp",
    game_code: "rich88_slotfortunegems",
  },
  {
    game_id: "950080",
    game_name: "Football Gold\nHOLD AND WIN",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rich88/football_gold_hold_and_win.webp",
    game_code: "rich88_slotgoldensoccer",
  },
  {
    game_id: "950081",
    game_name: "Thai Sic Bo 2",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/thai_sic_bo_2.webp",
    game_code: "rich88_sicbotha2",
  },
  {
    game_id: "950082",
    game_name: "Panda King\nHOLD AND WIN",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rich88/panda_king__hold_and_win.webp",
    game_code: "rich88_slotpandaking",
  },
  {
    game_id: "950083",
    game_name: "Fortune Dragon",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fortune_dragon.webp",
    game_code: "rich88_slotfortunedragon",
  },
  {
    game_id: "950084",
    game_name: "Dragon Legend",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/dragon_legend.webp",
    game_code: "rich88_slotlegendofdragon",
  },
  {
    game_id: "950085",
    game_name: "Enchantress",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/enchantress.webp",
    game_code: "rich88_slotenchantress",
  },
  {
    game_id: "950086",
    game_name: "Nakee",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/nakee.webp",
    game_code: "rich88_slotnakee",
  },
  {
    game_id: "950087",
    game_name: "Multi Mega Bingo Bonanza",
    category: "Bingo game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb:
      "https://cdn.dreamdelhi.com/rich88/multi_mega_bingo_bonanza.webp",
    game_code: "rich88_bingo20party",
  },
  {
    game_id: "950088",
    game_name: "Fortune Dice",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/fortune_dice.webp",
    game_code: "rich88_fortunedice",
  },
  {
    game_id: "950089",
    game_name: "Pirate Treasure",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/pirate_treasure.webp",
    game_code: "rich88_slotpiratetreasure",
  },
  {
    game_id: "950090",
    game_name: "Lucky God of Wealth",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/lucky_god_of_wealth.webp",
    game_code: "rich88_slotluckygodofwealth",
  },
  {
    game_id: "950091",
    game_name: "Future Stars",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/future_stars.webp",
    game_code: "rich88_slotfuturestars",
  },
  {
    game_id: "950092",
    game_name: "Lightning Fish",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/lightning_fish.webp",
    game_code: "rich88_slotlightningfish",
  },
  {
    game_id: "950093",
    game_name: "Ring of Odin",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/ring_of_odin.webp",
    game_code: "rich88_slotringofodin",
  },
  {
    game_id: "950094",
    game_name: "Serial Boat",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/serial_boat.webp",
    game_code: "rich88_slotserialboat",
  },
  {
    game_id: "950095",
    game_name: "Thor",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/thor.webp",
    game_code: "rich88_slotthor",
  },
  {
    game_id: "950096",
    game_name: "Winning Mask Deluxe",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/winning_mask_deluxe.webp",
    game_code: "rich88_slotrummikub",
  },
  {
    game_id: "950097",
    game_name: "Crazy Rich Man",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/crazy_rich_man.webp",
    game_code: "rich88_slotcrazyrichman",
  },
  {
    game_id: "950098",
    game_name: "100 Teen Patti",
    category: "Arcade game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/100_teen_patti.webp",
    game_code: "rich88_hundredsteenpatti",
  },
  {
    game_id: "950099",
    game_name: "Buffalo King MEGAWAYS",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/buffalo_king_megaways.webp",
    game_code: "rich88_slotbuffaloking",
  },
  {
    game_id: "950100",
    game_name: "Flaming Chillies",
    category: "Slot game",
    provider_name: "RICH88",
    sub_provider_name: "RICH88",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rich88/flaming_chillies.webp",
    game_code: "rich88_slotflamingchillies",
  },

];
