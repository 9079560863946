import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

import Button from "react-bootstrap/Button";
import logo from "../img/logoMain.webp";
import Dropdown from "react-bootstrap/Dropdown";

import { RiVerifiedBadgeFill } from "react-icons/ri";

import userImg from "../img/user.png";
import { HiLogout, HiOutlineRefresh } from "react-icons/hi";
import { FaFileExport, FaFileImport, FaHome, FaLock } from "react-icons/fa";
import { BiSolidFileExport, BiSolidFileImport } from "react-icons/bi";
import { MdCasino, MdOutlineBlindsClosed, MdWorkHistory } from "react-icons/md";

import { RiCustomerService2Line } from "react-icons/ri";
import { SiBetfair } from "react-icons/si";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";

import { useTranslation } from "react-i18next";
import English from "../img/icons/united-kingdom.png";
import Arabic from "../img/icons/united-arab-emirates.png";
import Kannad from "../img/icons/kannad.png";
import Hindi from "../img/icons/square.png";
import Spanish from "../img/icons/flag.png";
import Select, { components } from "react-select";
import { MdAccountBalance } from "react-icons/md";
import { MdAssignmentTurnedIn } from "react-icons/md";
function Header() {
  let {
    user,
    userCoins,
    amounutRefresh,
    refreshAmountLoader,
    message,
    logoutUser,
    changeLanguage,
    profileData,
    sportMarket,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const countries = [
    { value: "en", label: "English", icon: English },
    { value: "ka", label: "Kannad", icon: Kannad },
    { value: "ar", label: "Arabic", icon: Arabic },
    { value: "sp", label: "Spanish", icon: Spanish },
    { value: "hi", label: "Hindi", icon: Hindi },
  ];
  const Option = (props) => (
    <components.Option
      {...props}
      className="country-option"
      style={{ padding: "3px 10px", marginBottom: "10px" }}
    >
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={props.data.icon}
        alt="logo"
        className="country-logo"
      />
      {props.data.label}
    </components.Option>
  );
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={
          isEmpty(localStorage.getItem("language_dir"))
            ? English
            : countries?.find((res) => {
                return res?.value == localStorage.getItem("language_dir");
              })?.icon
        }
        alt="s-logo"
        className="selected-logo"
      />
      {children}
    </components.SingleValue>
  );

  return (
    <header className="sticky-top header">
      <Navbar
        expand="lg"
        className="justify-content-between"
        style={{ background: "#303030" }}
      >
        <Container fluid>
          <Navbar.Brand className="ms-2" href="#" onClick={() => navigate("/")}>
            <img src={logo} style={{ maxWidth: "150px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          {/* <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                className="d-flex align-items-center"
                onClick={() => navigate("/")}
                href="#"
              >
                <FaHome size={17} style={{ marginRight: "5px" }} />
                {t("Home")}
              </Nav.Link>{" "}
              <Nav.Link
                className="d-flex align-items-center"
                onClick={() => navigate("/casino/poker")}
                href="#"
              >
                <MdCasino size={17} style={{ marginRight: "5px" }} />
                {t("Indian_Poker")}
              </Nav.Link>{" "}
              <Nav.Link
                onClick={() => navigate("/casino/Live")}
                href="#"
                className="d-flex align-items-center"
              >
                <MdLiveTv size={17} style={{ marginRight: "5px" }} />
                {t("Live")}
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/casino/Table")}
                href="#"
                className="d-flex align-items-center"
              >
                <FaTable size={17} style={{ marginRight: "5px" }} />
                {t("Table")}
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/casino/Fishing")} href="#">
                <GiFishingLure size={17} style={{ marginRight: "5px" }} />
                {t("Fishing")}
              </Nav.Link>
              {!isEmpty(user) && (
                <>
                  <Nav.Link
                    onClick={() => navigate("/deposit")}
                    href="#"
                    className="d-flex align-items-center"
                  >
                    <FaFileImport size={17} style={{ marginRight: "5px" }} />
                    {t("Deposit")}
                  </Nav.Link>
                  <Nav.Link
                    className="d-flex align-items-center"
                    onClick={() => navigate("/withdraw")}
                    href="#"
                  >
                    <FaFileExport size={17} style={{ marginRight: "5px" }} />
                    {t("Withdraw")}
                  </Nav.Link>{" "}
                </>
              )}
            </Nav>

          </Navbar.Collapse> */}
          {!isEmpty(user) ? (
            <div className="d-flex me-2">
              {!isEmpty(user) && (
                <div className="d-flex align-items-center after-login">
                  {refreshAmountLoader ? (
                    <div class="loader-outer">
                      <span
                        class="loader-inner-icon"
                        style={{ animationDelay: "0s" }}
                      ></span>
                      <span
                        class="loader-inner-icon"
                        style={{ animationDelay: ".1s" }}
                      ></span>
                      <span
                        class="loader-inner-icon"
                        style={{ animationDelay: ".2s" }}
                      ></span>
                      <span
                        class="loader-inner-icon"
                        style={{ animationDelay: ".3s" }}
                      ></span>
                      <span
                        class="loader-inner-icon"
                        style={{ animationDelay: ".4s" }}
                      ></span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      {!isEmpty(user) && (
                        <div className="d-flex flex-column justify-content-between">
                          <Button
                            onClick={() => navigate("/deposit")}
                            style={{
                              padding: "3px 12px",
                              fontSize: "12px",
                              marginBottom: "5px",
                              borderRadius: "6px",
                              fontWeight: "600",
                              background: "green",
                              border: "none",
                            }}
                          >
                            {t("Deposit")}
                          </Button>{" "}
                          <Button
                            onClick={() => navigate("/withdraw")}
                            style={{
                              padding: "3px 12px",
                              fontSize: "12px",
                              borderRadius: "6px",
                              fontWeight: "600",
                              background: "red",
                              border: "none",
                            }}
                          >
                            {t("Withdraw")}
                          </Button>
                        </div>
                      )}
                      {/* <span className="amounts d-flex align-items-center justify-content-start"> */}
                        <div className="d-flex flex-column justify-content-between" style={{margin:"0 7px"}}>
                          <Button
                            style={{
                              padding: "3px 12px",
                              fontSize: "12px",
                              borderRadius: "6px",
                              fontWeight: "600",
                              background: "#ffb80c",
                              border: "none",
                              color: "black", marginBottom: "5px",

                            }}
                          >
                            <span style={{ fontWeight: "400" }}>Balance :</span>
                            <span style={{ marginLeft: "4px" }}>
                              {t("INR")} {userCoins?.balance?.toFixed()}
                            </span>
                          </Button>
                          <Button
                            style={{
                              padding: "3px 12px",
                              fontSize: "12px",
                              borderRadius: "6px",
                              fontWeight: "600",
                              background: "#ffb80c",
                              border: "none",
                              color: "black",
                            }}
                          >
                            {" "}
                            <span style={{ fontWeight: "400" }}>
                              {t("Exp")} :
                            </span>
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              {t("INR")} {userCoins?.exp?.toFixed()}
                            </span>
                          </Button>
                        </div>
                      {/* </span>{" "} */}
                    </div>
                  )}
                  <div onClick={amounutRefresh}>
                    <HiOutlineRefresh
                      size={20}
                      color="white"
                      className={refreshAmountLoader ? "refresh-icon" : ""}
                    />
                  </div>
                </div>
              )}
              <Dropdown className="uDetails d-flex align-items-center justify-content-center">
                <Dropdown.Toggle id="dropdown-basic" align="end">
                  <span className="u-name text-start">
                    {t("Welcome")},
                    <br />
                    {user?.user?.username}
                  </span>
                  <span className="u-img">
                    <img src={userImg} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <div
                    style={{ marginLeft: ".5rem" }}
                    className="whiteBox p-2 br-6"
                  >
                    <img src={userImg} className="uimage" />
                    <h2 className="uname"> {user?.user?.username}</h2>
                    <br />
                    <span style={{ fontSize: "13px", color: "white" }}>
                      {profileData?.phone} <RiVerifiedBadgeFill />
                    </span>
                  </div>
                  <div className="values  mx-2 my-2">
                    <Row className="mx-0">
                      <Col className="px-2 py-2">
                        {user?.user?.totalCoins}{" "}
                        <span>{t("Available_Balance")}</span>
                      </Col>
                      <Col className="px-2 py-2">
                        <span
                          style={{
                            color: "red",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          {user?.user?.exposure}
                        </span>{" "}
                        <span>{t("Exposure")}</span>
                      </Col>
                    </Row>
                  </div>

                  <div
                    style={{ marginLeft: ".5rem" }}
                    className="whiteBox p-2 br-6"
                  >
                    <ul className="u-menus">
                      <li>
                        <Link to="/current-bets">
                          <SiBetfair size={20} /> {t("CurrentBet")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/profit-and-loss">
                          <MdOutlineBlindsClosed size={20} /> {t("ProfitLoss")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/bets-history">
                          <MdWorkHistory size={20} /> {t("BetHistory")}
                        </Link>
                      </li>
                      {/* <li>
                          <Link to="/deposit">
                            <img src={LiveDrops} /> Deposit
                          </Link>
                        </li>{" "} */}
                      <li>
                        <Link to="/deposit-history">
                          <BiSolidFileImport size={20} /> {t("DepositHistory")}
                        </Link>
                      </li>{" "}
                      {/* <li>
                          <Link to="/withdraw">
                            <img src={LiveDrops} /> Withdraw
                          </Link>
                        </li> */}
                      <li>
                        <Link to="/withdraw-history">
                          <BiSolidFileExport size={20} /> {t("WithdrawHistory")}
                        </Link>
                      </li>
                      {/* <li>
                  <Link to="/balance-overview">
                    <img src={LiveDrops} /> Balance Overview
                  </Link>
                </li>{" "} */}
                      <li>
                        <Link to="/account-statement">
                          <MdAccountBalance
                            size={20}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          {t("AccountStatement")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/customer-support">
                          <RiCustomerService2Line size={20} />{" "}
                          {t("CustomerSupport")}
                        </Link>
                      </li>{" "}
                      <li>
                        <Link to="/turnover">
                          <MdAssignmentTurnedIn
                            size={20}
                            style={{ marginRight: "5px" }}
                          />
                          Turnover
                        </Link>
                      </li>
                      {/* <li>
                          <Link to="/customer-support">
                            <RiCustomerService2Line size={20} />{" "}
                            {t("CustomerSupport")}
                          </Link>
                        </li>{" "} */}
                      <li>
                        <Select
                          value={
                            isEmpty(localStorage.getItem("language_dir"))
                              ? English
                              : countries?.find((res) => {
                                  return (
                                    res?.value ==
                                    localStorage.getItem("language_dir")
                                  );
                                })
                          }
                          options={countries}
                          onChange={(e) => changeLanguage(e.value)}
                          styles={{
                            singleValue: (base) => ({
                              ...base,
                              display: "flex",
                              alignItems: "center",
                            }),
                          }}
                          components={{ Option, SingleValue }}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="d-grid gap-2">
                    <Button
                      onClick={() => logoutUser()}
                      variant="primary"
                      className="btn-red mx-2"
                      size="sm"
                    >
                      {t("LogOut")}
                    </Button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className="social-header">
              <Link to="/login" className="login">
                {t("Login")}{" "}
                <FaLock size={14} style={{ marginLeft: "4px" }} color="black" />
              </Link>{" "}
              <Link to="/register" className="login">
                {/* <IoMdLogIn
                  size={16}
                  style={{ marginRight: "4px" }}
                  color="black"
                /> */}
                {t("Singup")}
              </Link>
            </div>
          )}
        </Container>
      </Navbar>

      <div className="subNav">
        <ul>
          <li>
            <Link to="/" className={location?.pathname == "/" && "active"}>
              {t("Home")}
            </Link>
          </li>
          {!sportMarket.includes("4") && (
            <li>
              <Link
                className={
                  location?.pathname?.split("/")[1] == "cricket" && "active"
                }
                to="/cricket"
              >
                {t("Cricket")}
              </Link>
            </li>
          )}
          {!sportMarket.includes("2") && (
            <li>
              <Link
                to="/tennis"
                className={
                  location?.pathname?.split("/")[1] == "tennis" && "active"
                }
              >
                {t("Tennis")}
              </Link>
            </li>
          )}
          {!sportMarket.includes("1") && (
            <li>
              <Link
                to="/soccer"
                className={
                  location?.pathname?.split("/")[1] == "soccer" && "active"
                }
              >
                {t("Soccer")}
              </Link>
            </li>
          )}
          {/* <li>
            <Link
              className={
                location?.pathname?.split("/")[1] == "casino" && "active"
              }
              to="/casino/poker"
            >
              {t("Casino")}
            </Link>
          </li>{" "} */}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[1] == "horse" && "active"
              }
              to="/horse"
            >
              Horse
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname?.split("/")[1] == "greyhound" && "active"
              }
              to="/greyhound"
            >
              Greyhound
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "poker" && "active"
              }
              to="/casino/poker"
            >
              Indian Poker
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Live" && "active"
              }
              to="/casino/Live"
            >
              Live Casino
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Royal%20Gamming" &&
                "active"
              }
              to="/casino/Royal Gamming"
            >
              Royal Gamming
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Aviator" && "active"
              }
              to="/casino/Aviator"
            >
              Aviator
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Table" && "active"
              }
              to="/casino/Table"
            >
              Table
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Slot" && "active"
              }
              to="/casino/Slot"
            >
              Slot
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Dice" && "active"
              }
              to="/casino/Dice"
            >
              Dice
            </Link>
          </li>{" "}
          <li>
            <Link
              className={location?.pathname?.split("/")[2] == "AWC" && "active"}
              to="/casino/AWC"
            >
              AWC
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Egame" && "active"
              }
              to="/casino/Egame"
            >
              E-game
            </Link>
          </li>{" "}
          <li>
            <Link
              className={
                location?.pathname?.split("/")[2] == "Fishing" && "active"
              }
              to="/casino/Fishing"
            >
              Fishing
            </Link>
          </li>
          {/* {!isEmpty(user) && (
            <li>
              <Link
                className={
                  location?.pathname?.split("/")[1] == "deposit" && "active"
                }
                to="/deposit"
              >
                {t("Deposit")}
              </Link>
            </li>
          )}
          {!isEmpty(user) && (
            <li>
              <Link
                className={
                  location?.pathname?.split("/")[1] == "withdraw" && "active"
                }
                to="/withdraw"
              >
                {t("Withdraw")}
              </Link>
            </li>
          )} */}
        </ul>
      </div>
    </header>
  );
}

export default Header;
